import React, { CSSProperties } from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Grid,
  // Customizable Area Start
  IconButton,
  Divider,
  Modal,
  Paper,
  Avatar,
  // Customizable Area End
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Layout from "./Layout.web";
import { getYoutubeVideoId } from "../../DynamicContent/src/Slider";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import MemberModalWeb from "./common/MemberModalWeb.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  },
});

const Title = styled(Typography)({
  color: "#34373A",
  fontFamily: "Montserrat",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "46px",
  '@media(max-width: 675px)': {
    fontSize: "30px",
  },
});

const TaskContainer = styled('div')({
  '@media(max-width: 675px)': {
    flexDirection: 'column',
    minHeight: '200px !important',
    padding: '10px !important'
  },
});

const SubTaskContainer = styled('div')({
  '@media(max-width: 900px)': {
    width: '40% !important',
  },
  '@media(max-width: 675px)': {
    width: '100% !important',
  }
});

const SubTaskContainerLeft = styled('div')({
  '@media(max-width: 900px)': {
    width: '40% !important',
    flexDirection: "column"
  },
  '@media(max-width: 675px)': {
    width: '100% !important',
  }
});

const TaskDesc = styled(Typography)({
  '@media(max-width: 675px)': {
    maxWidth: 'none !important',
    textAlign: 'left'
  },
});

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
export default class GettingStarted extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getTasks();
    this.getMembers();
    this.isLogin()
    // Customizable Area End
  }
  renderProfileIcon = (assigned_to: any) => {

     return <Avatar style={{ height: '32px', width: '32px' }} >{assigned_to[0].toUpperCase()}</Avatar>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Layout>
          <div style={webStyle.root as React.CSSProperties} onClick={() => this.handleCloseMenu()}>
            {
              this.state.isShowWelcome && (
                <>
                  <div>
                    <div style={webStyle.titleContainer} className="CarbonzeroedTitle">
                      <Title>Welcome to CarbonZeroed! 🎉</Title>
                      <IconButton onClick={() => this.closeWelcome()} name="closewc" className="closeIconButton">
                        <CloseIcon style={webStyle.closeIcon} />
                      </IconButton>
                    </div>
                    <Typography style={webStyle.subtitle}>Let’s start automating your carbon zero goals so that you can focus on your business growth.</Typography>
                  </div>
                  <Divider />
                </>
              )
            }
          </div>
          {
            this.state.isShowResources && (
              <>
                <div onClick={() => this.handleCloseMenu()}>
                  <div style={webStyle.titleContainer}>
                    <Typography style={webStyle.description}>Here are some helpful resources that could help you get started on your carbon zero journey:</Typography>
                    <IconButton onClick={() => this.closeResources()} name="closercs" className="closeIconButton">
                      <CloseIcon style={webStyle.closeIcon} />
                    </IconButton>
                  </div>
                  <Grid container spacing={10} style={{ padding: '0 20px' }}>
                    {
                      this.state.resources.length !== 0 && this.state.resources.map((item: any, index: number) => (
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={2} key={index}>
                          <a href={item.document} style={{ textDecoration: 'none' }}>
                            <div style={webStyle.resources} data-test-id={`video${index}`} onClick={() => this.ShowVideo(item.internal_resource_type, item.video_url)} className="resourcesBox">
                              <img width={48} height={48} src={item.icon} />
                              <div>
                                <Typography style={webStyle.resourceName}>{item.title}</Typography>
                                <Typography style={webStyle.resourcedesc}>{item.description}</Typography>
                              </div>
                            </div>
                          </a>
                        </Grid>
                      ))
                    }
                  </Grid>
                </div>
                <Divider style={{ marginTop: '35px' }} />
              </>
            )
          }
          {this.state.tasks.length !== 0 && (
            <div style={webStyle.taskContainer}>
              <Typography style={webStyle.ready}>Ready to go?</Typography>
              {
                this.state.tasks.length !== 0 ? this.state.tasks.map((item: any, index: number) => (
            
                  <TaskContainer style={webStyle.tasks} key={index}>
                    <SubTaskContainerLeft style={webStyle.tasksStyles}>
                      <div>
                        <div data-test-id={`taskname${index}`} onClick={() => this.getRedirectLinks(item.task_code,item.card_id, item.id,item.material_factor_id,item.category_id,item.core_factor_id, item.title)} style={{ ...webStyle.resourcedesc, cursor: 'pointer' }}>
                          {item.title}
                          </div>
                        <Typography style={{
                          ...webStyle.status,
                          color: this.getColors(item.status)
                        }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" viewBox="0 0 6 7" fill="none">
                            <circle cx="3" cy="3.5" r="3" fill={this.getColors(item.status)} />
                          </svg> {item.status}
                        </Typography>
                      </div>
                      <TaskDesc style={webStyle.taskdcrpn as CSSProperties}>{this.getDescription(item.description)}
                      </TaskDesc>
                    </SubTaskContainerLeft>
                    <SubTaskContainer style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ ...webStyle.timeleft, background: this.getDaysLeft(item.days_left)?.background }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke={this.getDaysLeft(item.days_left)?.text} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8 4.66663V7.99996L10 9.99996" stroke={this.getDaysLeft(item.days_left)?.text} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <Typography style={{
                          color: this.getDaysLeft(item.days_left)?.text,
                          fontFamily: "Lato",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "12px",
                        }}>{item.days_left} days left</Typography>
                      </div>
                      {item.assigned_to && this.state.userType !== "secondary_user" ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '103px', maxWidth: '103px', gap: '8px' }}>
                          {item.assigned_to_profile_image !== '' ?
                            <img src={item.assigned_to_profile_image} alt="profileImage"
                                 style={{height: '32px', width: '32px'}}/>
                            :
                            <Avatar style={{height: '32px', width: '32px'}}>{item.assigned_to[0].toUpperCase()}</Avatar>
                          }
                          <div>
                            <Typography style={webStyle.assign}>Assigned to</Typography>
                            <Typography style={webStyle.asnName}>{item.assigned_to}</Typography>
                          </div>
                        </div> :
                        <Button data-test-id={`start${index}`} disabled={item.completed} onClick={() => this.handleTask(item)} style={{
                          ...webStyle.assignButton,
                          color: this.getButtonProperties(item.is_assign).color,
                          background: this.getButtonProperties(item.is_assign).background,
                        } as CSSProperties}>
                          {this.getButtonProperties(item.is_assign).title}
                        </Button>
                      }
                      <div style={webStyle.menuContainer as CSSProperties}>
                        <div style={{ ...webStyle.popup, display: this.isShowPopup(item.id) } as CSSProperties}>
                          <div style={{ ...webStyle.mainMenuItem, height: this.isTaskAssign(item.is_assign).height } as CSSProperties}>
                            <div style={webStyle.itemContainer as CSSProperties}>
                              <div data-test-id={`starttask${index}`} onClick={() => this.getRedirectLinks(item.task_code,item.card_id,item.id,item.material_factor_id,item.category_id,item.core_factor_id, item.title)} style={{ ...webStyle.items }}>
                                <div style={webStyle.menuText as CSSProperties}>Start task now</div>
                              </div>
                              {item.is_assign && this.state.isAssignTask && (
                                <div style={webStyle.items} onClick={this.handleShowMember}>
                                  <div style={webStyle.menuText as CSSProperties}>Assign to a team member</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div style={{ ...webStyle.downArrow, top: this.isTaskAssign(item.is_assign).position } as CSSProperties}></div>
                        </div>
                        {
                          !item.completed && (
                            <IconButton data-test-id={`menu${index}`} onClick={() => this.handleOpenMenu(item.id)} style={{ display: 'flex', justifyContent: 'end' }}>
                              <MoreHorizIcon />
                            </IconButton>
                          )
                        }
                      </div>
                    </SubTaskContainer>
                  </TaskContainer>
                )) :
                  <></>
              }
            </div>
          )}
          <Modal open={this.state.modelProp.open} onClose={this.handleClose} style={webStyle.modalStyle}>
            <Paper style={webStyle.videoModel as CSSProperties}>
              {this.state.modelProp.link ?
                <iframe
                  style={{ border: "none", background: "#efefef" }}
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${getYoutubeVideoId(this.state.modelProp.link)}?autoplay=1`}
                  title=""
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
                : <p style={webStyle.invalidUrl}>Invalid Youtube Video URL. </p>
              }
            </Paper>
          </Modal>
          <Modal open={this.state.isShowMember} onClose={this.handleCloseMember} style={webStyle.modalStyle} data-test-id="modalClose">
            <MemberModalWeb onAssign={this.handleChangeTeamMember} onClose={this.handleCloseMember} id={""} navigation={this.props.navigation} />
          </Modal>
        </Layout >
      </ThemeProvider >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '100%',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  title: {
    color: "#34373A",
    fontFamily: "Montserrat",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "46px",
  },
  subtitle: {
    color: "#34373A",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0.085px",
    padding: "0px 20px",
    marginBottom: '50px'
  },
  description: {
    color: "#34373A",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0.085px",
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px 10px 20px',
    marginTop: '20px'
  },
  resources: {
    width: "264px",
    height: "104px",
    borderRadius: "12px",
    border: "1px solid #EBEBEB",
    background: "#FFF",
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    paddingLeft: '24px',
    gap: '16px'
  },
  resourceName: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%",
  },
  resourcedesc: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "0.2px"
  },
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperStyle: {
    width: '460px',
    maxWidth: "100%",
    minHeight: '250px',
    padding: '20px 20px 32px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:900px)': {
      width: "40%",
    }
  },
  videoModel: {
    width: '700px',
    maxWidth: "100%",
    height: '60%',
    padding: '10px',
    borderRadius: '8px',
    '@media (max-width:900px)': {
      width: "90%",
    }
  },
  invalidUrl: {
    width: "100%",
    height: "100%",
    fontSize: "22px",
    opacity: 0.5,
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    alignItems: "center",
    margin: "0"
  },
  taskContainer: {
    padding: '20px',
  },
  ready: {
    color: "#34373A",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "160%",
    marginBottom: '35px',
    marginTop: "20px"
  },
  tasks: {
    width: '100%',
    padding: '20px',
    minHeight: "80px",
    maxwidth: "1126px",
    borderRadius: "12px",
    border: "1px solid #EBEBEB",
    heigth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    alignItems: 'center'
  },
  taskdcrpn: {
    color: "#7B7D7F",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%",
    maxWidth: "232px",
    width: '100%',
  },
  timeleft: {
    display: "inline-flex",
    height: "32px",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "8px",
  },
  assign: {
    color: "#7B7D7F",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%",
  },
  asnName: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "160%",
    letterSpacing: "0.2px",
  },
  labelInput: {
    color: "#34373A",
    fontFamily: "Lato !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center"
  },
  memberTitle: {
    color: "var(--neutral-100, #101010)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "120%",
    marginBottom: '12px'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    height: '40px',
    overflow: 'visible',
    maxWidth: '45px'
  },
  popup: {
    width: 167, height: 86,
    position: 'relative',
    right: 117,
    top: 3,
    cursor: 'pointer',
  },
  mainMenuItem: {
    width: 167, padding: 16, left: 0, top: 0,
    position: 'relative', background: 'white', boxShadow: '15px 20px 50px rgba(15, 23, 42, 0.12)',
    borderRadius: 12, flexDirection: 'column', justifyContent: 'flex-start',
    alignItems: 'flex-start', gap: 8, display: 'inline-flex'
  },
  itemContainer: {
    height: 46,
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex'
  },
  assignButton: {
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "110%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    textAlign: "center",
    borderRadius: "8px",
    textTransform: 'none',
    width: 97,
    height: 32,
  },
  downArrow: {
    width: 12, rotate: '134deg',
    height: 12, left: 129, position: 'absolute',
    background: 'white', boxShadow: '15px 20px 50px rgba(15, 23, 42, 0.12)'
  },
  items: {
    background: 'white',
    textDecoration: 'none',
    justifyContent: 'flex-start',
    alignItems: 'center', gap: 8,
    height: '100%'
  },
  menuText: {
    color: '#7B7D7F',
    fontSize: 12,
    fontFamily: 'Lato',
    fontWeight: 400,
    wordWrap: 'break-word'
  },
  tasksStyles: {
    width: '50%', display: 'flex', justifyContent: 'space-between'
  },
  status: {
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%",
    marginTop: '10px'
  },
  autocomplete: {
    maxWidth: '404px',
    minHeight: "48px",
    borderRadius: '8px',
    border: "1px solid #D9D9D9",
    padding: '12px',
    boxShadow: 'none'
  },
  popper: {
    maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    maxWidth: '404px',
    boxShadow: 'none !important',
    padding: '0px !important'
  },
  closeIcon: {
    color: "#34373A"
    }
};
// Customizable Area End
