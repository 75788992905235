import React, { CSSProperties } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  // Customizable Area Start
  Select, FormControl, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { down, noResultFoundImg } from "./assets";
import { Formik } from "formik";
import TablePagination from "../../dashboard/src/common/TablePagination.web";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const DownloadButtonPosition = styled("div")({
  display: 'flex', 
  justifyContent: 'flex-end', 
  marginTop: '-80px', 
  paddingRight: "45px",
  '@media(max-width: 445px)': {
    marginTop: "-40px",
    justifyContent: "flex-start",
    marginLeft: "40px",
  }
});

const DownloadContainer = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  border: '1px solid #DFDFDF',
  maxHeight: "36px",
  background: "#fff !important",
  minWidth: "126px !important",
  maxWidth: "126px !important",
})

const CompanyDropContainer = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  border: '1px solid #D9D9D9',
  maxHeight: "48px",
  background: "#fff !important",
  minWidth: "277px !important",
  maxWidth: "277px !important",
  '@media(max-width: 768px)': {
    minWidth: "200px !important",
    maxWidth: "200px !important", 
  }
})

const AreaDropContainer = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  border: '1px solid #D9D9D9',
  maxHeight: "48px",
  background: "#fff !important",
  minWidth: "243px !important",
  maxWidth: "243px !important",
  '@media(max-width: 768px)': {
    minWidth: "200px !important",
    maxWidth: "200px !important",
  }
})

const ObjectDropContainer = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  border: '1px solid #D9D9D9',
  maxHeight: "48px",
  background: "#fff !important",
  minWidth: "433px !important",
  maxWidth: "433px !important",
  '@media(max-width: 768px)': {
    minWidth: "200px !important",
    maxWidth: "200px !important",
  }
})

const YearDropContainer = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  border: '1px solid #D9D9D9',
  maxHeight: "48px",
  background: "#fff !important",
  minWidth: "143px !important",
  maxWidth: "143px !important",
})

const CusomDropDowns = styled(Select)({
  height: "48px",
  borderRadius: "8px",
  padding: "8px !important;",
  "& *": {
    background: "#fff !important;",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "19.2px",
    color: '#34373A',
  },
  '&:focus': {
    borderColor: '#cccccc',
  },
})

const DownloadDropDowns = styled(Select)({
  height: "36px",
  borderRadius: "8px",
  padding: "8px !important;",
  "& *": {
    background: "#fff !important;",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "19.2px",
    color: '#34373A',
    paddingRight: '0px !important'
  },
  '&:focus': {
    borderColor: '#cccccc',
  },
})

const GoButton = styled(Button)({
  width: "128px",
  height: "40px",
  color: "#34373A",
  display: "flex",
  padding: "10px 58px 11px 58px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  textTransform: 'none',
  border: "none",
  borderRadius: '8px',
  fontWeight: 700,
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  lineHeight: "160%",
  cursor: 'pointer',
  backgroundColor: "#43D270",
  "&:hover": {
    backgroundColor: "#43D270",
    color: "#34373A"
  }
});

const tableLabels = {
  name: 'Company Name',
  year: "Reporting Year",
  model: "Data Model",
  sector: 'SICS Sector',
  jurisdiction: "Jurisdiction",
  scope1: "Total Scope 1 Location-Based...",
  scope2: "Total Scope 2 Location-Based...",
};

const SearchTableContainer = styled(TableContainer)({
  minHeight: "420px",
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  marginTop: '39px',
  padding: '12px',
  paddingRight: '50px',
  paddingLeft: '42px',
  '@media(max-width: 768px)': {
    padding: 12
  }
});

const SearchTable = styled(Table)({
  width: "100%",
  borderCollapse: "separate",
});

const SearchTableBody = styled(TableBody)({
  color: "inherit",
  position: 'relative',
  top: '16px'
});

const SearchSpacing = styled("div")({
  height: 16,
  display: "table-header-group",
});

const SearchTableCellHead = styled(TableCell)({
  minHeight: "48px",
  padding: "13.5px 8px",
  flexShrink: 0,
  borderBottom: "none",
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
  maxWidth: '260px'
});

const SearchTableCell = styled(TableCell)({
  borderBottom: "1px solid #F1F5F9",
  padding: "15px 8px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
});

const SearchTableHead = styled(TableHead)({
  backgroundColor: "#F5F5F5",
  borderRadius: "10px",
});

const SearchTableRowHead = styled(TableRow)({
  borderRadius: "20px !important",
  overflow: "hidden",
  "& :first-child": {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  "& :last-child": {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
});

const Label = styled(Typography)({
  color: "#000",
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "25.6px",
  letterSpacing: "0.2px",
  whiteSpace: "nowrap",
  // marginBottom: '15px',
  '@media(max-width: 600px)': {
    marginBottom: 0,
  }
});

const PaginationContainer = styled(Box)({
  paddingRight: 60,
  margin: 0,
  '@media(max-width: 768px)': {
    paddingRight: 0,
    margin: "30px 0"
  }
});

const EmptySearchReultContainer = styled(Box)({
  margin: "100px 0 0 0",
  "@media(max-width: 768px)": {
    margin: "65px 0 0 0"
  },
  "@media(max-width: 425px)": {
    margin: "30px 0 30px 0"
  },
});


const Icon = (props: any) => {
  return <img {...props} alt="" style={{ marginRight: "15px", marginTop: '4px' }} src={down} />
}

const EmptySearchResult = () => (
  <EmptySearchReultContainer 
    display="flex" 
    flexDirection="column" 
    justifyContent="center" 
    alignItems="center"
  >
    <img src={noResultFoundImg} alt="no result found" />
    <Label>No data found</Label>
  </EmptySearchReultContainer>
);

const years = Array.from({ length: 16 }, (_, index) => 2015 + index);

// Customizable Area End

import CfnzdpusearchController, {
  Props,
  configJSON,
} from "./CfnzdpusearchController";

export default class Cfnzdpusearch extends CfnzdpusearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getIndustry(this.state.initialValues)
    this.getIndustryList()
    this.getCountryList()
    this.getscopeList()
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <DownloadButtonPosition>
          <DownloadContainer>
            <DownloadDropDowns
              displayEmpty
              data-test-id="download"
              value="Download Data"
              IconComponent={Icon}
              name="industry"
              disableUnderline
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "126px",
                    maxWidth: "100%",
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="Download Data" style={webStyle.menuItems}>Download Data</MenuItem>
            </DownloadDropDowns>
          </DownloadContainer>
        </DownloadButtonPosition>
        <Formik
          initialValues={this.state.initialValues}
          onSubmit={this.handleSearch}
        >
          {formik => {
            const {
              values,
              handleChange,
              handleSubmit,
              handleBlur
            } = formik;
            return (
              <form onSubmit={handleSubmit} data-testid="dataform">
                <Box style={webStyle.mainWrapper}>
                  <Box display="flex" style={{ gap: '17px', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                    <Box display="flex" style={{ gap: '17px', alignItems: 'center', flexWrap: 'wrap' }}>
                      <Label>I want to see ... </Label>
                      <Box style={webStyle.feildwrapper}>
                        <CompanyDropContainer>
                          <CusomDropDowns
                            multiple
                            displayEmpty
                            data-test-id="industry"
                            placeholder="select industry"
                            IconComponent={Icon}
                            name="industry"
                            value={values.industry}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disableUnderline
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                  width: "277px",
                                  maxWidth: "100%",
                                },
                              },
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {this.state.industryLists.map((item) => <MenuItem value={item.id} style={webStyle.menuItems}>{item.name}</MenuItem>)}
                          </CusomDropDowns>
                        </CompanyDropContainer>
                      </Box>
                    </Box>
                    <Box display="flex" style={{ gap: '23px', alignItems: 'center', flexWrap: 'wrap' }}>
                      <Label>companies headquartered in</Label>
                      <Box style={webStyle.feildwrapper}>
                        <AreaDropContainer>
                          <CusomDropDowns
                            multiple
                            displayEmpty
                            IconComponent={Icon}
                            data-test-id="country"
                            name="country"
                            disableUnderline
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                  width: "243px",
                                  maxWidth: "100%",
                                },
                              },
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                              },
                              getContentAnchorEl: null,
                            }}
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {this.state.countryList.map((item) => <MenuItem value={item.id} style={webStyle.menuItems}>{item.name}</MenuItem>)}
                          </CusomDropDowns>
                        </AreaDropContainer>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" style={{ gap: '17px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Box display="flex" style={{ gap: '17px', alignItems: 'center', flexWrap: 'wrap' }}>
                      <Label>and information on their disclosed</Label>
                      <Box style={webStyle.feildwrapper}>
                        <ObjectDropContainer>
                          <CusomDropDowns
                            displayEmpty
                            data-test-id="orgreportfilter"
                            IconComponent={Icon}
                            name="scope"
                            disableUnderline
                            value={values.scope}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                  width: "433px",
                                  maxWidth: "100%",
                                },
                              },
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {this.state.scopeList.map((item) => <MenuItem value={item.id} style={webStyle.menuItems}>{item.name}</MenuItem>)}
                          </CusomDropDowns>
                        </ObjectDropContainer>
                      </Box>
                    </Box>
                    <Box display="flex" style={{ gap: '23px', alignItems: 'center', flexWrap: 'wrap' }}>
                      <Label>in</Label>
                      <Box style={webStyle.feildwrapper}>
                        <YearDropContainer>
                          <CusomDropDowns
                            multiple
                            displayEmpty
                            IconComponent={Icon}
                            data-test-id="year"
                            name="year"
                            value={values.year}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disableUnderline
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                  width: "143px",
                                  maxWidth: "100%",
                                },
                              },
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {
                              years.map((year) => (
                                <MenuItem style={webStyle.menuItems} value={year}>{year}</MenuItem>
                              ))
                            }
                          </CusomDropDowns>
                        </YearDropContainer>
                      </Box>
                    </Box>
                    <GoButton type="submit">Go</GoButton>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
        <SearchTableContainer
          style={{display: this.state.industryList.length === 0 ? "none" : "flex"}}
        >
          <SearchTable aria-label="users table">
            <SearchTableHead>
              <SearchTableRowHead>
                <SearchTableCellHead>{tableLabels.name}</SearchTableCellHead>
                <SearchTableCellHead>{tableLabels.year} </SearchTableCellHead>
                <SearchTableCellHead>{tableLabels.model}</SearchTableCellHead>
                <SearchTableCellHead>{tableLabels.sector}</SearchTableCellHead>
                <SearchTableCellHead>{tableLabels.jurisdiction}</SearchTableCellHead>
                <SearchTableCellHead>{tableLabels.scope1}</SearchTableCellHead>
                <SearchTableCellHead>{tableLabels.scope2}</SearchTableCellHead>
              </SearchTableRowHead>
            </SearchTableHead>
            <SearchSpacing />
            <SearchTableBody>
              {this.state.industryList && this.state.industryList?.map((data) => {
                return (
                  <TableRow>
                    <SearchTableCell>
                      <Typography 
                        onClick={() => this.handleNavigateToEmissionDetailsPage(data.id)} 
                        style={webStyle.link as CSSProperties}
                        data-test-id="company_name"
                      >
                        {data.company_name}
                      </Typography>
                    </SearchTableCell>
                    <SearchTableCell>{data.reporting_year} </SearchTableCell>
                    <SearchTableCell style={webStyle.tablecell}>{data.data_model}</SearchTableCell>
                    <SearchTableCell>{data.sics_sector}</SearchTableCell>
                    <SearchTableCell style={webStyle.tablecell}>{data.jursdiction}</SearchTableCell>
                    <SearchTableCell>{data.total_scope_1_emission}</SearchTableCell>
                    <SearchTableCell>{data.total_scope_1_ghg_emission}</SearchTableCell>
                  </TableRow>
                );
              })}
            </SearchTableBody>
          </SearchTable>
        </SearchTableContainer>
        {
          !this.state.isLoading 
          && this.state.industryList.length === 0 
          && <EmptySearchResult />
        }
        <PaginationContainer
          style={{ 
            display: this.state.industryList.length === 0 ? "none" : "block" 
          }}
        >
          <TablePagination count={this.state.total}
            onChange={this.handleTablePageChange}
            handleChangeNumber={this.handleTableNumberChange}
            perPage={this.state.perPage} />
        </PaginationContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    width: "100%",
    background: "#ECFBF1",
    padding: '21px 54px',
    minHeight: '160px',
    flexDirection: 'column',
    gap: '34px',
    marginTop: '30px'
  } as React.CSSProperties,
  inputStyle: {
    border: "#D9D9D9 solid 1px",
    borderRadius: "6px",
    padding: "8.5px 14px",
    font: "inherit",
    height: "48px",
    fontSize: '15px',
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  inputext: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px"
  },
  menuItems: {
    maxWidth: '92px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: "1px !important",
    fontSize: '10px'
  } as React.CSSProperties,
  labels: {
    color: "#000",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "25.6px",
    letterSpacing: "0.2px",
    whiteSpace: "nowrap",
    marginBottom: '15px'
  } as React.CSSProperties,
  errors: {
    color: "red",
    marginTop: "10px",
    paddingLeft: '10px',
    fontSize: "12px",
    fontWeight: 300,
  },
  feildwrapper: {
    display: "flex",
    flexDirection: 'column',
    gap: '5px'
  } as React.CSSProperties,
  tablecell: {
    color: "#7D7D7F",
    fontSize: "12px",
    fontWeight: 400,
  },
  link: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.20000000298023224px",
    textAlign: "left",
    cursor: "pointer",
    color: "#329E54"
  },
};
// Customizable Area End
