import React from "react";

// Customizable Area Start
// Customizable Area End

import CustomformController, { Props } from "./CustomformController";
import OrganizationInfoForm from "./OrganizationInfoForm.web";

export default class OrganizationInfo extends CustomformController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getCompanyData();
  }

  render() {
    // Customizable Area Start 
    const { companyData, companyDataUpdateStatus } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start 
      <OrganizationInfoForm onSubmit={this.updateCompanyDataApi} status={companyDataUpdateStatus} data={companyData} removelogoimage={this.removeimage}
      logoimg={this.state.logoimg} />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
