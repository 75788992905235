import React, { FC, memo } from 'react'
import {
    makeStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, Button, Container, Grid } from "@material-ui/core";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { getDyanmicLink, removeTags } from './landingpage/LpDifferentFromOther';

const useStyles = makeStyles({
    headingCard: (props: any) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '333px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        boxSizing: 'border-box',
    }),
    cardContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "40px",
        '@media(max-width: 1040px)': {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0px"
        }
    },
    primaryImg: {
        width: "618px",
        height: "auto",
        borderRadius: "16px",
        objectFit: "cover",
        '@media(max-width: 1040px)': {
            width: "100%",
            height: "auto"
        }
    },
    maintag: (props: any) => ({
        backgroundColor: "#E0E0FA",
        padding: "8px 15px",
        textTransform: "uppercase",
        borderRadius: "5px",
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        lineHeight: '22.4px',
        color: '#6F66E9',
        marginBottom: '20px',
    }),
    heading: {
        fontSize: '32px',
        fontWeight: 700,
        fontFamily: 'Lato !important',
        lineHeight: '44.8px',
        marginBottom: '24px',
        maxWidth: "1100px",
        '@media(max-width: 600px)': {
            fontSize: '28px',
            lineHeight: '30px',
            marginBottom: "20px",
        },
        '& p ': {
            margin: "0"
        }
    },
    description: {
        display: 'flex',
        fontWeight: 600,
        fontSize: '17px',
        fontFamily: 'Montserrat',
        lineHeight: '26px',
        alignItems: "center",
        marginBottom: "30px",
        maxWidth: "700px",
    },
    buttonStyle: (props: any) => ({
        width: "150px",
        height: "58px",
        background: props.primaryButtonBg ?? '#43D270',
        color: '#000000',
        borderRadius: "6px",
        margin: "0 15px 15px 0",
        boxShadow: "none !important",
        alignItems: "center",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "21.6px",
        '&:hover': {
            background: '#897ecc',
        }
    }),
    buttonViewPricing: (props: any) => ({
        margin: "0 15px 15px 0",
        width: "150px",
        height: "58px",
        borderRadius: "8px",
        background: "#EBEDFC",
        color: "#000000",
        boxShadow: "none !important",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "21.6px",
        '&:hover': {
            background: '#897ecc',
        }
    }),
    buttonLinkStyle: {
        textDecoration: "unset",
    },
    buttonWrStyle: {
        marginBottom: "15px",
        '@media(max-width: 600px)': {
            display: 'flex',
        },
    }
})

interface PropTypes {
    image?: string;
    tag?: string;
    heading?: string;
    description?: string;
    button1Label?: string;
    button1Link?: string;
    button2Label?: string;
    button2Link?: string;
    bgColor?: string;
    tagColor?: string;
    primaryButtonBg?: string;
    secondaryButtonBg?: string;
    primaryButtonColor?: string;
    widget_item_buttons?: any;
}

const BlogPageHeadingSection: FC<PropTypes> = ({
    image,
    tag,
    heading,
    bgColor,
    tagColor,
    primaryButtonBg,
    secondaryButtonBg,
    primaryButtonColor,
    description,
    widget_item_buttons,
}: PropTypes) => {

    const classes = useStyles({ bgColor, tagColor, primaryButtonBg, secondaryButtonBg, primaryButtonColor });
    return (
        <>
            <Container>
                <Box mt={5} mb={5} className={classes.cardContainer}>
                    <img alt="primary image" src={image} className={classes.primaryImg} />
                    <Box className={classes.headingCard}>

                        {tag &&
                            <Typography component="p" className={classes.maintag}>{tag && parse(tag.toString())}</Typography>
                        }
                        {heading && heading != "" &&
                            <Typography component='h3'
                                        style={{marginBottom: "30px"}}
                                        className={classes.heading}>{heading && removeTags(heading.toString())}</Typography>
                        }
                        {description && description !== "" &&
                            <Typography className={classes.description}>{description && parse(description.toString())}</Typography>
                        }
                        {widget_item_buttons &&
                        <Box className={classes.buttonWrStyle}>
                            <Link className={classes.buttonLinkStyle}
                                    to={getDyanmicLink(widget_item_buttons.data[0].attributes.link, "/signup")}>
                                <Button variant="contained" className={classes.buttonStyle}>
                                    {widget_item_buttons.data[0].attributes.name}
                                </Button>
                            </Link>

                            <Link className={classes.buttonLinkStyle}
                                    to={getDyanmicLink(widget_item_buttons.data[1].attributes.link, "/pricing")}>
                                <Button variant="contained" className={classes.buttonViewPricing}>
                                    {widget_item_buttons.data[1].attributes.name}
                                </Button>
                            </Link>
                        </Box>
                        }
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default memo(BlogPageHeadingSection);