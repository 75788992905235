import React, { useState } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import parse from 'html-react-parser';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { Container, Box, Grid, Typography, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import { removeTags } from '../landingpage/LpDifferentFromOther';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        contentStyle: {
            color: '#34373A',
            fontSize: '34px',
            fontWeight: 700,
            fontFamily: 'Montserrat',
            lineHeight: '46px',
            fontStyle: 'normal',
            maxWidth: "584px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            textAlign: 'center',
            margin: "0 auto 60px auto",
            '@media (max-width:600px)': {
                fontSize: '28px',
                lineHeight: '30px',
                margin: "0 auto 30px",
            }
        },

        faqSection: {
            paddingBottom: "72px",
        },

        queStyle: {
            color: '#727272',
            fontSize: '20px',
            fontWeight: 700,
            fontFamily: 'Montserrat',
            lineHeight: '24px',
        },

        queStyleDark: {
            color: '#101010',
            fontSize: '20px',
            fontWeight: 700,
            fontFamily: 'Montserrat',
            lineHeight: '24px',
        },

        ansStyle: {
            color: '#34373A',
            fontSize: '17px',
            fontWeight: 400,
            fontFamily: 'Lato',
            lineHeight: '26px',
            "& *": {
                fontFamily: 'Lato',
                fontSize: '17px',
                fontWeight: 400,
                lineHeight: '26px',
                color: '#34373A',
            }
        },

        accordianBox: {
            backgroundColor: 'white',
            '& .MuiPaper-elevation1': {
                boxShadow: 'none'
            },
            '& :before': {
                backgroundColor: 'white',
            },
            '& .Mui-expanded': {
                '& .MuiSvgIcon-root:first-child': {
                    display: 'none',
                },
                '& .MuiSvgIcon-root:last-child': {
                    display: 'inline-block',
                },
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                '& .MuiTypography-root': {
                    color: '#34373A',
                }
            }
        },

        accordion: {
            boxShadow: "unset",
            marginBottom: "20px",
        },

        minimizeBtn: {
            display: 'inline-block',
            color: '#EB6E46'
        },

        addBtn: {
            display: 'inline-block'
        },
    })
)

const FaqsSection = (props: any) => {
    const classes = useStyles();
    const { header, widget_sub_items } = props;
    const questions = widget_sub_items?.data;
    const [accordionId, setAccordionId] = useState('')

    const handleChange = (id: string) => {
        if (accordionId === id) {
            id = ""
        }
        setAccordionId(id);
    }

    return (
        <Container>
            <Grid className={classes.faqSection}>
                <Box>
                    <Typography className={classes.contentStyle}>{header ? removeTags(header) : removeTags(props?.attributes?.header)}</Typography>
                </Box>
                <Box className={classes.accordianBox}>
                    {(questions || props?.attributes?.widget_sub_items?.data) && (questions || props?.attributes?.widget_sub_items?.data).map((data: any, index: number) => {
                        let questionText = data?.attributes?.title;
                        let answerText = data?.attributes?.description;

                        if (data.type === 'document_question') {
                            questionText = data?.attributes?.body;
                            answerText = data?.attributes?.document_answer;
                        }

                        if (data.type === 'faq_question') {
                            questionText = data?.attributes?.body;
                            answerText = data?.attributes?.faq_answer;
                        }

                        return (
                            <Accordion
                                onChange={() => handleChange(`panel${index}a-header`)}
                                className={classes.accordion}
                                expanded={accordionId === `panel${index}a-header`}
                                key={`accordion_id_${index}`}
                                data-test-id={"handle-change-function" + index}
                            >
                                <AccordionSummary
                                    expandIcon={accordionId === `panel${index}a-header` ?
                                        <MinimizeIcon className={classes.minimizeBtn} />
                                        :
                                        <AddIcon className={classes.addBtn} />
                                    }
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                >
                                    <Typography className={`panel${index}a-header` === accordionId ? classes.queStyleDark : classes.queStyle} >
                                        {questionText ? removeTags(questionText) : ""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className={classes.ansStyle}>
                                        {answerText ? parse(answerText) : ""}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Box>
            </Grid>
        </Container>
    )
}

export default FaqsSection;