Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfintermodalform";
exports.labelBodyText = "cfintermodalform Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiContentTypeAppJson = "application/json";
exports.postApiMethod = "POST";
exports.calculateEmissionApiEndpoint = "bx_block_cfintermodalform/intermodal_climatiq";
// Customizable Area End