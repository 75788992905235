Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfclimatiqshippingfreight";
exports.labelBodyText = "cfclimatiqshippingfreight Body";

exports.btnExampleTitle = "CLICK ME";
exports.postAPiMethod = "POST";
exports.getShippingFreightDetailsAPiEndPoint = "bx_block_dashboard/detailed_first_log";
exports.getVehicaltypeEndpoint = "bx_block_cfclimatiqshippingfreight/road_vehicle_type";
exports.getVehicalWeightEndpoint = "bx_block_cfclimatiqshippingfreight/get_vehicle_weight";
exports.getVehicleOptionEndpoint = "bx_block_cfclimatiqshippingfreight/get_road_fuel_sources";
exports.getVesseltypeEndpoint = "bx_block_cfclimatiqshippingfreight/sea_vessel_type";
exports.getTonnageEndpoint = "bx_block_cfclimatiqshippingfreight/get_tonnage_types";
exports.getSeaFuelOptionEndpoint = "bx_block_cfclimatiqshippingfreight/get_sea_fuel_sources";
exports.railFuelEndPoint = "bx_block_cfclimatiqshippingfreight/rail_fuel_type";
exports.railLoadEndPoint = "bx_block_cfclimatiqshippingfreight/rail_load_type";
exports.aircraftEndPoint = "bx_block_cfclimatiqshippingfreight/aircraft_type";
exports.airMethodologyEndPoint = "bx_block_cfclimatiqshippingfreight/aircraft_methodology";
exports.addMore = "+ Add More";
exports.getCalculateEndPoint = "bx_block_cfclimatiqshippingfreight/climatiq_shipping_batch";
exports.regionEndPoint = "bx_block_cfclimatiqbasicestimate/get_climatiq_regions";
exports.saveShippingAPiEndPoint = "bx_block_dashboard/materiality_answers";
// Customizable Area End