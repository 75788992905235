import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { 
  styled, 
  Typography as TypographyMUI,
  Snackbar,
} from "@material-ui/core";
import IntermodalForm from "./components/IntermodalForm";
import TreeView from "./components/TreeView";

const Container = styled("div")({
  width: "100%",
  maxWidth: "409px",
  height: "100vh",
  overflowY: "auto",
  overflowX: "hidden",
  "@media(max-width: 768px)": {
    maxWidth: "250px",
  },
  "@media(max-width: 600px)": {
    maxWidth: "100%",
  },
});

const EmissionInfoContainer = styled("div")({
  margin: "40px 52px"
});

const EmissionInfoSection = styled("div")({
  margin: "25px 0"
});

const EmissionTitle = styled(TypographyMUI)({
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 800,
  lineHeight: "22px",
  color: "#34373A"
});

const EmissionDescription = styled(TypographyMUI)({
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#34373A",
  marginTop: "12px",
});

const ErrorSnackbar = styled(Snackbar)({
  "& > div": {
    backgroundColor: "#fee2e2",
    color: "#000"
  }
});
// Customizable Area End

import CfintermodalformController, {
  Props,
  configJSON,
} from "./CfintermodalformController";

export default class Cfintermodalform extends CfintermodalformController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container>
        <IntermodalForm 
          showFormInfoCard={this.state.showFormInfoCard}
          onEditClick={this.showForm}
          onSubmit={this.calculateEmission} 
        />
        {
          this.state.showFormInfoCard && (
            <EmissionInfoContainer>
              <EmissionInfoSection>
                <EmissionTitle>Emissions</EmissionTitle>
                <EmissionDescription>
                  {`${this.state.co2Emissions} ${this.state.co2EmissionsUnit}`} CO2e emissions
                </EmissionDescription>
              </EmissionInfoSection>
              <EmissionInfoSection>
                <EmissionTitle>Route Data</EmissionTitle>
                <EmissionDescription>{this.state.distance}KM distance</EmissionDescription>
              </EmissionInfoSection>
            </EmissionInfoContainer>
          )
        }
        <TreeView routes={this.state.routes} />
        <ErrorSnackbar
          data-test-id="error-toast"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.errorToast.isOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({
            errorToast: {
              ...this.state.errorToast,
              isOpen: false,
            }
          })}
          message={this.state.errorToast.message}
        />
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
