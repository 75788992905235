import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import BlogPageHeadingSectionWeb from '../../../blocks/DynamicContent/src/BlogPageHeadingSection.web';
import { success_img } from '../../../blocks/DynamicContent/src/assets';
import Heading from "./Heading";

const useStyles = makeStyles({
  successBox: {
    padding: "15px 0",
    maxWidth: "1280px",
    margin: "0 auto",
    marginTop: "56px"
  },
  successImage: {
    display: "flex",
    margin: "0 auto",
    paddingBottom: "47px",
    maxWidth: "100%",
    '@media(max-width: 600px)': {
      marginBottom: "20px",
    },
  },
  massageStyle: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    paddingBottom: "100px",
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
  },
});

interface PropTypes {
  successResultMessage?: string;
}

const FreeAssessmentSuccessPage = ({ successResultMessage }: PropTypes) => {
  const classes = useStyles();

  const successMessageDefault = "We’ve received your information. We will analyze the information and deliver the assessment report to your email soon."

  return (
    <Box className={classes.successBox}>
      <Heading
        tag="Thank you!"
        heading="You've successfully submitted the form"
        description="DONE!"
      />
      {/* {successImage && */}
        <img src={success_img} className={classes.successImage} alt="" />
      {/* } */}
      <Typography className={classes.massageStyle}>
        {successResultMessage ?? successMessageDefault}
      </Typography>
    </Box>
  );
}

export default memo(FreeAssessmentSuccessPage);