import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import { StepIconProps } from "@material-ui/core/StepIcon";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Container, Box, Button, Step, StepLabel, Stepper, StepConnector } from "@material-ui/core";
import StepForm from "./StepForm";
import FreeAssessmentSuccessPage from "./FreeAssessmentSuccessPage";
import BlogPageHeadingSectionWeb from "../../../blocks/DynamicContent/src/BlogPageHeadingSection.web";
import Heading from "./Heading";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#43D270",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#43D270",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#43D270",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "70%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#43D270",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <FiberManualRecordIcon className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "1230px",
      maxWidth: "100%",
      margin: "auto",
    },

    actionBox: {
      marginBottom: "74px",
      '@media (max-width:600px)': {
        marginBottom: "40px",
      }
    },

    ButtonStyle: {
      display: "flex",
      justifyContent: "center",
    },

    button: {
      backgroundColor: "#B1B3C3",
      height: '56px',
      width: '184.5px',
      borderRadius: '8px',
      padding: '16px 12px 16px 12px',
      redius: "8px",
      "& .MuiButton-label": {
        display: "flex",
        marginTop: "10px",
        marginBottom: "10px",
        marginInline: "55px",
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
    tittleName: {
      fontSize: "15px",
      fontWeight: 900,
      fontFamily: "lato",
      lineHeight: "24px",
      margin: "6px 0px",
      minHeight: "50px",
      minWidth: "100px"
    },
    stepStyle: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "lato",
      lineHeight: "22px",
    },
    StepperStyle: {
      width: "100%",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
      }
    },
    container: {
      marginTop: 62
    }
  })
);

interface PropTypes {
  data?: any,
  submitForm?: any,
}

const FreeAssessmentPage = ({ data, submitForm }: PropTypes) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isSuccess, setSuccess] = useState(false);
  const [validation, setValidation] = useState({});
  const [enableNext, setEnableNext] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [validEmail, setValidEmail] = useState(false)
  const [validCompany, setValidCompany] = useState(false)
  const handleNext = () => {
    if (Object.keys(validation).length && enableNext) {
      window.scrollTo(0, 0)
      let previewActiveStep = activeStep + 1;
      if (previewActiveStep >= data?.length) {
        setSuccess(true);
        submitForm(validation);
      }
      setActiveStep(previewActiveStep);
      if (currentStep <= activeStep + 1) {
        setCurrentStep(previewActiveStep);
        setEnableNext(false)
        setErrorMessage(false)
      }
    } else {
      setErrorMessage(true)
    }
  };

  const handleStepChange = (step: any) => {
    if ((activeStep === currentStep || enableNext) && step <= currentStep) {
      setActiveStep(step - 1);
      setEnableNext(true);
    } else {
      setErrorMessage(true)
    }
  }

  return (
    <div className={classes.root}>
      {!isSuccess ? (
        <Container className={classes.container}>
          <Heading
            key={`free_assessment_heading`}
            tag="Free Assessment"
            heading="Tell us about your Company"
            description={`STEP ${activeStep + 1} OF ${data?.length}`}
          />
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            className={classes.StepperStyle}
          >
            {data?.map((label: any, index: any) => (
              <Step key={label?.attributes?.step_name}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <p className={classes.tittleName} onClick={() => handleStepChange(index + 1)}>{label?.attributes?.step_name}</p>
                  <p className={classes.stepStyle}>STEP {index + 1}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {
            <Box className={classes.actionBox}>
              <Box className={classes.instructions}>
                <StepForm
                  data={data[activeStep]?.attributes?.free_assessment_questions}
                  validation={validation}
                  setValidation={setValidation}
                  setEnableNext={setEnableNext}
                  errorMessage={errorMessage}
                  validEmail={validEmail}
                  setValidEmail={setValidEmail}
                  validCompany={validCompany}
                  setValidCompany={setValidCompany}
                />
              </Box>
              <div className={classes.ButtonStyle}>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  style={{ backgroundColor: Object.keys(validation).length && enableNext ? '#43D270' : '#B1B3C3' }}
                  className={classes.button}
                >
                  {activeStep + 1 === data?.length ? "Finish" : "Next"}
                </Button>
              </div>
            </Box>
          }
        </Container>
      ) : (
        <Container>
          <FreeAssessmentSuccessPage />
        </Container>
      )}
    </div>
  );
};
export default FreeAssessmentPage;
