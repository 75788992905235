Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfnzdpusearch";
exports.labelBodyText = "cfnzdpusearch Body";
exports.IndustryListDataUrl = "bx_block_cfnzdpusearch/metadata_list";
exports.IndustryDataUrl = "bx_block_cfnzdpusearch/nzdpu_industry";
exports.CountryDataUrl = "bx_block_cfnzdpusearch/nzdpu_country";
exports.ScopeDataUrl = "bx_block_cfnzdpusearch/nzdpu_emission_scope";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End