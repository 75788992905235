import React from "react";
// Customizable Area Start
import SubHeader from "../../../components/src/SubHeader.web";

// Customizable Area End

import HeaderController, {
  Props,
} from "./HeaderController";


export default class Header extends HeaderController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const headerDataFromApi = this.state.headerDataFromApi;

    return (
      // Customizable Area Start
      <>
        <SubHeader headerDataFromApi={headerDataFromApi} />
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// Customizable Area End