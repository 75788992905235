import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string};
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep:number;
  dynamicData:{
    total_count:number;
    dynamic_signups:{
        title:string;
        description:string;
        signup_link:string;
        signup_image:string;
        position:number
    }[]
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayDynamicContentID:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep:Number(localStorage.getItem('activeStep')) || 0,
      dynamicData:{
        total_count:0,
        dynamic_signups:[]
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getDynamicContent();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      this.handleDynamicResponse(requestCallId, message);
    }
    // Customizable Area End
  }
  handleDynamicResponse = (requestCallId: string, message: Message) => {
    if(this.displayDynamicContentID === requestCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson){
        this.setState({
          dynamicData:responseJson
        })
      }
    }
  }
  handleSteps = (step: number) => {
    if (step <= this.state.activeStep) {
        localStorage.setItem('activeStep', step.toString())
        this.setState({activeStep:step})
    }
};

setActiveStep = (text:React.Dispatch<React.SetStateAction<number>>) => {
    this.setState({activeStep: +text})
}
getDynamicContent = () => {
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      this.displayDynamicContentID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), 
        configJSON.dynamicContentApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
}
  // Customizable Area End
}