import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  heading: {
    "&, & > p": {
      marginTop: "12px",
      fontSize: "48px",
      fontWeight: 700,
      lineHeight: "52px",
      color: "#191919",
      textTransform: "capitalize",
      "@media (max-width: 768px)": {
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: "8px",
      },
    },
  },
}));

export default function WidgetHeadingText({
  children,
}: {
  children: React.ReactNode;
}) {
  const styles = useStyles();
  return <h2 className={styles.heading}>{children}</h2>;
}
