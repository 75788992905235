export const facebookIcon = require('./icons/facebook-icon.svg');
export const facebookDarkIcon = require('./icons/facebook.png');
export const googleIcon = require('./icons/google-icon.svg');
export const linkedInIcon = require('./icons/linkedin-icon.svg');
export const linkedInDarkIcon = require('./icons/linkedin.png');
export const pinterestIcon = require('./icons/pinterest-icon.svg');
export const twitterIcon = require('./icons/twitter-icon.svg');
export const twitterDarkIcon = require('./icons/x.png');
export const tumblrIcon = require('./icons/tumblr-icon.svg');
export const instragramDarkIcon = require('./icons/instagram.png');
export const youtubeDarkIcon = require('./icons/youtube.png');
export const pinterestDarkIcon = require('./icons/pinterest.svg');
export const telegramDarkIcon = require('./icons/telegram.svg');
export const redditDarkIcon = require('./icons/reddit.svg');
export const snapChatDarkIcon = require('./icons/snapchat.svg');
export const tiktokDarkIcon = require('./icons/tiktok.svg');
export const wechatDarkIcon = require('./icons/wechat.svg');
export const whatsappDarkIcon = require('./icons/whatsapp.svg');
export const tumblrDarkIcon = require('./icons/tumblr.svg');
export const discordDarkIcon = require('./icons/discord.svg');
export const viberDarkIcon = require('./icons/viber.svg');