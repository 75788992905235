import React from "react";
import { Typography, styled, Box, Button, Dialog } from "@material-ui/core";

const ConfirmDialog = styled(Dialog)({
  "& div:nth-child(3)>div": {
    padding: "27px 24px 26px 24px !important",
    borderRadius: "12px !important",
    width: "334px"
  },
  "& .MuiDialog-paperFullWidth": {
    maxWidth: "334px !important",
    width: "100%",
    minHeight: "185px",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    "@media (max-width: 340px)": {
      minWidth: "96% !important",
      overflowX: "hidden"
    }
  },
  "& .MuiDialog-paper": {
    padding: "27px 24px 26px 24px !important",
    borderRadius: "12px !important"
  }
});

const ConfirmTextDiv = styled(Box)({
  minWidth: "272px",
  "@media (max-width: 340px)": {
    minWidth: "92% !important"
  }
});

const ConfirmText = styled(Typography)({
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.2px"
});

const BorderLine = styled(Box)({
  minWidth: "286px",
  minHeight: "1px",
  backgroundColor: "#efefef",
  marginTop: "27px"
});

const ActionsStyled = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: "20px",
  marginTop: "25px",
  marginRight: "14px"
});

const CancelButtonStyled = styled(Button)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  height: "32px",
  minWidth: "97px",
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  backgroundColor: "#FFF",
  border: "1px solid #D9D9D9",
  color: "#34373A",
  textAlign: "center",
  fontFamily: "Inter !important",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  textTransform: "none",
  "& span": {
    fontFamily: "Inter !important"
  }
});

const ConfirmButtonStyled = styled(CancelButtonStyled)({
  backgroundColor: "#43D270",
  color: "#34373A",
  borderColor: "#43D270"
});

interface ConfirmModalPropTypes {
  navigation: any;
  id: string;
  description: string;
  confirmBtnLabel: string;
  cancelBtnLabel: string;
  onCancel: any;
  onConfirm: any;
  isOpen: boolean;
  dataTestId:string;
}

const ConfirmModal = (props: ConfirmModalPropTypes) => {
  const {
    description,
    isOpen,
    confirmBtnLabel,
    cancelBtnLabel,
    onCancel,
    onConfirm
  } = props;

  return (
    <ConfirmDialog open={isOpen} maxWidth="sm">
      <ConfirmTextDiv>
        <ConfirmText>{description}</ConfirmText>
      </ConfirmTextDiv>
      <BorderLine />
      <ActionsStyled>
        <CancelButtonStyled onClick={onCancel}>
          {cancelBtnLabel}
        </CancelButtonStyled>
        <ConfirmButtonStyled onClick={onConfirm}>
          {confirmBtnLabel}
        </ConfirmButtonStyled>
      </ActionsStyled>
    </ConfirmDialog>
  );
};

export default ConfirmModal;
