import React from "react";
// Customizable Area Start
import { Dimensions } from "react-native";
import { MapContainer, GeoJSON, TileLayer, Popup } from "react-leaflet";
import CustomMarker from "./components/CustomMarker";
import { greenCircle } from "./assets";
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    let geoJsonContent;
    if (this.state.routes.length > 0) {
      geoJsonContent = (
        <GeoJSON style={this.getFeatureStyle} data={this.state.featureCollection} />
      );
    }

    return (
      <MapContainer
        center={[12.8797, 121.7740]}
        zoom={5}
        minZoom={2}
        scrollWheelZoom={true}
        whenCreated={mapInstance => {
          // @ts-ignore
          this.mapRef.current = mapInstance
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
        />
        {this.state.routes.map(route => {
          return (
            <CustomMarker 
              key={route.latitude} 
              position={[route.latitude, route.longitude]}
              iconSize={[20, 20]}
              iconAnchor={[10, 10]}
              icon={greenCircle}
            >
              <Popup>{route.name}</Popup>
            </CustomMarker>
          );
        })}
        {geoJsonContent}
      </MapContainer>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
