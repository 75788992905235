Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.apiContentTypeApplicationJson = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfnzdpuscope1emissions";
exports.labelBodyText = "cfnzdpuscope1emissions Body";

exports.btnExampleTitle = "CLICK ME";
exports.scope1EmisstionDetailsAPiEndPoint = "bx_block_cfnzdpuscope1emissions/scope1_emission_list";
// Customizable Area End