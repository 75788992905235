import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import parse from "html-react-parser";

interface JourneyItemProps {
  id: number;
  image: string;
  title: string;
  description: string;
}

const useStyles = makeStyles(() => ({
  journeyItem: {
    rowGap: "16px",
    "@media (min-width: 768px)": {
      paddingTop: "24px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      rowGap: "24px",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "4px",
        top: 0,
        bottom: 0,
        backgroundColor: "#e4e4e4",
        left: "50%",
        transform: "translate(-50%)",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "10px",
        height: "10px",
        backgroundColor: "#43d270",
        borderRadius: "50%",
      },
    },
  },
  image: {
    width: "100%",
    borderRadius: "20px",
  },
  paddingRight: {
    paddingRight: "125px",
    "@media (max-width: 768px)": {
      paddingRight: "0",
    },
  },
  paddingLeft: {
    paddingLeft: "125px",
    "@media (max-width: 768px)": {
      paddingLeft: "0",
    },
  },
  journeyTextAlign: {
    "@media (max-width: 480px)": {
      textAlign: "left !important",
    },
  },
  date: {
    color: "#646570",
  },
  title: {
    color: "#1f1f1f",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    textTransform: "capitalize",
    "@media (max-width: 768px)": {
      fontSize: "18px",
      lineHeight: "26px",
    },
  },
  description: {
    color: "#686868",
    marginTop: "12px",
    lineHeight: "26px",
    "@media (max-width: 768px)": {
      marginTop: "8px",
    },
  },
}));

const JourneyItem: React.FC<JourneyItemProps> = ({
  id,
  image,
  title,
  description,
}) => {
  const styles = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, []);

  return (
    <Grid
      container
      key={id}
      style={{
        flexDirection: id % 2 === 0 ? "row" : "row-reverse",
        paddingTop: id !== 1 ? "30px" : "0",
      }}
      className={`benefit-item ${styles.journeyItem} ${
        id % 2 === 0 ? "even" : "odd"
      } ${isVisible ? "visible" : ""}`}
      ref={itemRef}
    >
      <Grid
        item
        md={6}
        className={`${id % 2 === 0 ? styles.paddingRight : styles.paddingLeft}`}
      >
        <div
          className={`benefit-text ${styles.journeyTextAlign}`}
          style={{
            textAlign: id % 2 === 0 ? "right" : "left",
          }}
        >
          <h2 className={styles.title}>
            {parse(title.toString(), { trim: true })}
          </h2>
          <p className={styles.description}>{parse(description)}</p>
        </div>
      </Grid>
      <Grid
        item
        md={6}
        className={`benefit-image ${
          id % 2 === 0 ? styles.paddingLeft : styles.paddingRight
        }`}
      >
        {image ? <img src={image} alt={title} className={styles.image}/> : null}
      </Grid>
    </Grid>
  );
};

export default JourneyItem;
