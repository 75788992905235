import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import { removeTags } from './landingpage/LpDifferentFromOther';
import { extractContentFromHTMLString, convertTo12HourFormat, formatDateToString } from './BlogListingSection.web';
import { useStyles } from './RelatedBlogListingSection.web';

const dummyColor: any = {
    Environmental: "#43D270",
    Social: "#7166F1",
    Governance: "#F96C30"
}

const dummyShadowColor: any = {
    Environmental: "rgba(67, 210, 112, 0.2)",
    Social: "rgba(171, 158, 255, 0.2)",
    Governance: "rgba(244, 154, 122, 0.2)"
}

interface PropTypes {
    enableButton?: boolean,
    enablePaymentTags?: boolean,
    limit?: number,
    data?: any,
    viewDetails?: any,
    enableMetaTags?:any
}

const RelatedEventBlogListingSection = ({ data, viewDetails}: PropTypes) => {
    const classes = useStyles();
    const url = `/${window?.location?.pathname?.toLowerCase()?.replace(/[0-9/]+/g, '')}`
    return (
        <Grid container style={{marginBottom: url === '/events' ? "137px" : "77px"}} spacing={4}>
            {Array.isArray(data) && data.map((item: any, index: any): ReactElement => {
                const fullRelatedDescription = extractContentFromHTMLString(item?.attributes?.event_description);
                const slicedRelatedDescription = `${fullRelatedDescription.slice(0, 74)}...`;
                const relatedBlogPostTimeAndDateArr = [];
                const relatedBlogPostTime = convertTo12HourFormat(item?.attributes?.event_time);
                if (relatedBlogPostTime) {
                    relatedBlogPostTimeAndDateArr.push(relatedBlogPostTime);
                }
                const relatedBlogPostDate = formatDateToString(item?.attributes?.event_date);
                if (relatedBlogPostDate) {
                    relatedBlogPostTimeAndDateArr.push(relatedBlogPostDate);
                }
                const relatedBlogPostTimeAndDate = relatedBlogPostTimeAndDateArr.join(", ");
                return <Grid item xs={12} sm={4} container key={`s_item_${index}`}>
                    <Box
                        className={classes.genericCard}
                        data-test-id='genericCard'
                        onClick={() => {
                            viewDetails(item.id, url, true);
                            window.scrollTo(0, 0)
                        }}>
                        <Box 
                            className={classes.imageCard} 
                            // sx={{ boxShadow: `0px 14px 34px ${dummyShadowColor[item?.attributes?.resource_tag_id]}` }}
                        >
                            {
                                item?.attributes?.event_image &&
                                <img 
                                    src={item.attributes.event_image} 
                                    className={`${url === '/marketplace' ? classes.images1 : classes.images}`} 
                                    alt="" 
                                />
                                
                            }
                        </Box>
                        <Box className={classes.relatedGcContent}>
                            <Typography 
                                variant='h3' 
                                className={classes.relatedTitle} 
                                style={{ 
                                    visibility: item?.attributes?.resource_tag_id ? "visible" : "hidden",
                                    backgroundColor: dummyColor[item?.attributes?.resource_tag_id] || "#F96C30" 
                                }}
                            >
                                {item?.attributes?.resource_tag_id}
                            </Typography>
                            <Typography variant='h4' className={classes.relatedText} >{removeTags(item?.attributes?.event_title)}</Typography>
                            <Typography className={classes.description}>{slicedRelatedDescription}</Typography>
                            <div className={classes.cardMetaDataStyle1}>
                                <Typography 
                                    style={{ margin: 0 }} 
                                    className={classes.description}
                                >
                                    {relatedBlogPostTimeAndDate}
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </Grid >
            })}
        </Grid>
    );
}
export default RelatedEventBlogListingSection;