import React, { CSSProperties } from "react";

// Customizable Area Start
import { Box, Paper, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { logo, success } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class ActiveAccount extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.activateAccount(this.props.navigation.getParam("token"));
    // Customizable Area End
  }

  render() {
    let topIcon;
    if (this.state.activateMessage.success) {
      topIcon = <img src={success} alt="email" />;
    } else {
      topIcon = (
        <CloseIcon
          style={{ color: "red", height: "66px", width: "66px" }}
        />
      );
    }

    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box style={webStyle.accountemailContainer as CSSProperties}>
          <Box style={webStyle.accountlogocontainer as CSSProperties}>
            <img src={logo} alt="logo" />
            <Link to="/login/secondary">
              <CloseIcon style={{ color: "#FFFF" }} />
            </Link>
          </Box>
          <Paper
            elevation={3}
            style={webStyle.accountemailverify as CSSProperties}
          >
            {
              this.state.isActivationLoading ? (
                <Typography style={webStyle.accountemailmaintitle as CSSProperties}>
                  Account activation in progress...
                </Typography>
              ) : (
                <>
                  {topIcon}
                  <Typography style={webStyle.accountemailmaintitle as CSSProperties}>
                    {this.state.activateMessage.success
                      ? "Success"
                      : "Verify your Email"}
                  </Typography>
                  {this.state.activateMessage.message && (
                    <Typography
                      style={webStyle.accountemailsecondtitle as CSSProperties}
                    >
                      Your account activation is successful, and you are now a valued member of the CarbonZeroed community.
                    </Typography>
                  )}
                  {this.state.activateMessage.message && (
                    <Typography
                      style={webStyle.stylelink as CSSProperties}
                    >
                      To access your account, please click <Link to={"/get-started"} style={{color:"#43D270",textDecoration:"none"}}>here</Link></Typography>
                  )}
                  {!this.state.activateMessage.success && (
                    <Typography style={webStyle.accountemailresend as CSSProperties}>
                      {this.state.isEmailSend ? (
                        `Resend in ${this.state.time.m} : ${this.state.time.s}`
                      ) : (
                        <>
                          click here to send mail again
                          <button
                            type="submit"
                            style={{
                              color: "#43D270",
                              border: "none",
                              backgroundColor: "#FFFF"
                            }}
                            onClick={() => this.handleResendMail()}
                          >
                            Resend
                          </button>
                        </>
                      )}
                    </Typography>
                  )}
                </>
              )
            }
          </Paper>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  accountcontainerStyle: {
    paddingTop: "75px"
  },
  accountemailContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#34373A",
    height: "100vh"
  },
  accountlogocontainer: {
    position: "relative",
    bottom: "110px",
    width: "90%",
    display: "flex",
    justifyContent: "space-between"
  },
  accountemailverify: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    gap: "32px",
    width: "40%",
    height: "400px",
    left: "495px",
    top: "250px",
    background: "#FFFFFF",
    borderRadius: "16px"
  },
  accountemailmaintitle: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "125%",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "#0F172A"
  },
  accountemailsecondtitle: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "26px",
    textAlign: "center",
    letterSpacing: "0.005em",
    color: "#34373A"
  },
  stylelink:{
    color: 'var(--greyscale-900, #0F172A)',
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "160%",
  },
  accountemailresend: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "160%",
    textAlign: "center",
    color: "#0F172A"
  }
};
