import React from 'react'
import { CSSProperties } from "@material-ui/styles";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { empty } from './assets';
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        margin: "0 auto",
        maxWidth: 1229,
        marginTop: 58,
        "@media(max-width:600px)": {
            marginTop: 30,
        },
    },
    contentBox: {
        display: "flex",
        justifyContent: "center",
    },
    headingStyle: {
        marginTop: "58px",
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "24px",
        maxWidth: 1005,
        color: "#34373A",
        textAlign: "center" as CSSProperties["textAlign"],
        "@media(max-width:600px)": {
            fontSize: "20px",
            marginTop: "28px",
        },
    },
    contentBox2: {
        display: "flex",
        justifyContent: "Center",
        marginBottom: "120px"
    },
    notFoundImage: {
        marginBottom: "47px",
        maxWidth: "100%",
        '@media(max-width: 600px)': {
            marginBottom: "20px",
        },
    },
});

const PageNotFound = () => {
    const classes = useStyles();
    const description = "Page Not Found."
    return (
        <Box>
            <div className={classes.root}>
                <Grid>
                    <Box className={classes.contentBox}>
                    <img src={empty} alt="no result" style={{ width: '276.14px', height: '255.29px' }} />
                    </Box>
                    <Box className={classes.contentBox2}>
                        <Typography className={classes.headingStyle}>
                            {description}
                        </Typography>
                    </Box>
                </Grid>
            </div>
        </Box>
    )
}

export default PageNotFound;

