import React from "react";
// Customizable Area Start
import parse from 'html-react-parser';
import {
    Box,
    Typography,
    Grid,
    TextField,
    Checkbox,
    Button,
    Container
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {
  phoneIcon,
  mailIcon,
  locationIcon,
  instaIcon,
  linkedinIcon,
  facebookIcon,
  twitterIcon,
  youTubIcon,
  uncheckIcon,
  checkIcon,
  pintrestIcon,
  whatsappIcon,
  wechatIcon,
  viberIcon,
  tumblrIcon,
  tiktokIcon,
  telegramIcon,
  snapchatIcon,
  redditIcon,
  discordIcon
} from "../assets"

 const theme = createTheme({
    typography:{
        fontFamily:'Lato,Montserrat',
    }
 })
// Customizable Area End

import ContactUsPageController, {
    Props,
    configJSON
} from "./ContactUsPageController";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

export class ContactUsPage extends ContactUsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    
    getSocialIcon = (item:{name:string,link:string},classes:string) => {
      switch (item.name) {
        case 'Facebook':
            return <img src={facebookIcon} className={classes} alt="facebookIcon" data-testid="facebook-link" onClick={() => this.handleLinkSocialMedia('Facebook')}/>;

        case 'Twitter':
            return <img src={twitterIcon} className={classes} alt="twitterIconn" data-testid="twitter-link" onClick={() => this.handleLinkSocialMedia('Twitter')}/>;

        case 'Instagram':
            return <img src={instaIcon} alt="instaIcon" className={classes} data-testid="insta-link" onClick={() => this.handleLinkSocialMedia('Instagram')}/>;

        case 'LinkedIn':
            return <img src={linkedinIcon} className={classes} alt="linkedinIcon" data-testid="linkedin-link" onClick={() => this.handleLinkSocialMedia('LinkedIn')}/>;

        case 'YouTube':
            return <img src={youTubIcon} className={classes} alt="youtubeIcon" data-testid="youtube-link" onClick={() => this.handleLinkSocialMedia('YouTube')}/>;

        case 'Pinterest':
          return <img src={pintrestIcon} className={classes} alt="pintrestIcon" data-testid="pintrest-link" onClick={() => this.handleLinkSocialMedia('Pinterest')}/>;

        case 'Snapchat':
          return <img src={snapchatIcon} className={classes} alt="snapchatIcon" data-testid="snapchat-link" onClick={() => this.handleLinkSocialMedia('Snapchat')}/>;

        case 'TikTok':
          return <img src={tiktokIcon} className={classes} alt="tiktokIcon" data-testid="tiktok-link" onClick={() => this.handleLinkSocialMedia('TikTok')}/>;

        case 'Reddit':
          return <img src={redditIcon} className={classes} alt="redditIcon" data-testid="reddit-link" onClick={() => this.handleLinkSocialMedia('Reddit')}/>;

        case 'WhatsApp':
          return <img src={whatsappIcon} className={classes} alt="whatsappIcon" data-testid="whatsapp-link" onClick={() => this.handleLinkSocialMedia('WhatsApp')}/>;

        case 'WeChat':
          return <img src={wechatIcon} className={classes} alt="wechatIcon" data-testid="wechat-link" onClick={() => this.handleLinkSocialMedia('WeChat')}/>;

        case 'Telegram':
          return <img src={telegramIcon} className={classes} alt="telegramIcon" data-testid="telegram-link" onClick={() => this.handleLinkSocialMedia('Telegram')}/>;

        case 'Tumblr':
          return <img src={tumblrIcon} className={classes} alt="tumblrIcon" data-testid="tumblr-link" onClick={() => this.handleLinkSocialMedia('Tumblr')}/>;

        case 'Discord':
          return <img src={discordIcon} className={classes} alt="discordIcon" data-testid="discord-link" onClick={() => this.handleLinkSocialMedia('Discord')}/>;

        case 'Viber':
          return <img src={viberIcon} className={classes} alt="viberIcon" data-testid="viber-link" onClick={() => this.handleLinkSocialMedia('Viber')}/>;

        default:
            return <>?</>;
    }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes,pageData } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
              <Grid container className={classes.mainContainer}>
              <Container>
                <Grid item xs={12}>
                {pageData?.data &&
                  <Grid container>
                    <Grid item xs={12} sm={6} className={classes.gridItem1}>
                      <Typography data-testid="contact-header" className={classes.contactHeader}>{pageData.data.attributes.contact_header1}</Typography>
                      <Typography className={classes.contactTitle}>{pageData.data.attributes.contact_title1}</Typography>
                      <Box className={classes.contactDetailsBox}>
                        <img src={phoneIcon} alt="phone"/>
                        <Typography className={classes.addressHead}>{pageData.data.attributes.contact_phone_number}</Typography>
                      </Box>
                      <Box className={classes.contactDetailsBox}>
                        <img src={mailIcon} alt="mailIcon"/>
                        <a href={`mailto:${pageData.data.attributes.contact_email}`}
                        className={classes.addressHead}>{pageData.data.attributes.contact_email}</a>
                      </Box>
                      <Box className={classes.contactDetailsBox}>
                        <img src={locationIcon} alt="locationIcon"/>
                        <a href={pageData.data.attributes.contact_address_link} target="_blank" className={classes.addressHead}>{pageData.data.attributes.contact_address}</a>
                      </Box>
                      <Box className={classes.socialMediaBox}>
                        {pageData.data.attributes.social_medias.map(item => {
                          return(
                            <>
                            {this.getSocialIcon(item,classes.socialMediaIcon)}
                            </>
                          )
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.formBox}>
                        <Typography className={classes.yourMessage}>{configJSON.yourMessage}</Typography>
                        <TextField 
                          placeholder={configJSON.nameLabel} 
                          variant="outlined" 
                          data-testid="name-field"
                          className={classes.textfieldName}
                          size="small"
                          value={this.state.nameValue}
                          onChange={this.handleNameChange}
                          error={this.state.nameValue === '' && this.state.nameError}
                          helperText={(this.state.nameValue === '' && this.state.nameError) && configJSON.nameErrorMessage}
                        />
                        <TextField 
                          placeholder={configJSON.companyNameLabel} 
                          variant="outlined" 
                          data-testid="companyname-field"
                          className={classes.textfieldName}
                          size="small"
                          value={this.state.companyNameValue}
                          onChange={this.handleCompanyNameChange}
                          error={this.state.companyNameValue === '' && this.state.nameError}
                          helperText={(this.state.companyNameValue === '' && this.state.nameError) && configJSON.companyNameErrorMessage}
                        />
                        <TextField 
                          placeholder={configJSON.emailLabel} 
                          variant="outlined" 
                          data-testid="email-field"
                          className={classes.textfieldName}
                          style={{marginBottom :this.state.emailError ? '0' : '15px'}}
                          size="small"
                          value={this.state.companyMailValue}
                          onChange={this.handleEmailChange}
                          error={(this.state.companyMailValue === '' && this.state.nameError) || this.state.emailError}
                          helperText={(this.state.companyMailValue === '' && this.state.nameError) && configJSON.emailErrorMessage}
                        />
                        {this.state.emailError &&
                        <Typography className={classes.validMail}>{configJSON.validEmailMessage}</Typography>
                        }
                        <Typography className={classes.checklistHead}>{configJSON.carbonZeroedChecklist}</Typography>
                        {this.state.checklistArray.map(data =>
                          <Box className={classes.checkboxContainer} key={data.id}>
                            <Checkbox
                              checkedIcon={<img src={checkIcon}/>}
                              data-testid={"checkbox-" + data.id}
                              icon={<img src={uncheckIcon}/>}
                              checked={data.checked}
                              onChange={() => this.handleCheck(data.id)}
                              className={classes.checkbox}
                            />
                            <Typography
                              className={data.checked ? classes.checkedList : classes.checklistdata}>{data.title}</Typography>
                          </Box>
                        )}
                        <TextField 
                          placeholder={configJSON.suggestionLabel} 
                          variant="outlined" 
                          data-testid="suggestion-field"
                          className={classes.textArea}
                          multiline
                          maxRows={20}
                          value={this.state.suggestionValue}
                          onChange={this.handleSuggestionlChange}
                        />
                        <Button className={classes.buttonSend} variant="contained" data-testid="send-message" onClick={this.handleSendMessage}>
                          {configJSON.SendMessage}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  }
                </Grid>
                {pageData?.data &&
                <>
                    <Grid item xs={12}>
                  <Typography className={classes.ourLocation}>{pageData.data.attributes.contact_title2}</Typography>
                  <Typography className={classes.locationSubHead}>{parse(pageData.data.attributes.contact_description2.toString())}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} className={classes.locationGridContainer}>
                    {pageData.data.attributes.contact_address_details.data.map(data =>
                        <Grid item xs={12} sm={6} md={4} lg={3} key={data.id} className={classes.gridItemLocation}>
                        <Box className={classes.locationBox}>
                          <Typography className={classes.contactPlace}>{data.attributes.contact_place}</Typography>
                          <Box className={classes.addressMainCOntainer}>
                          <Typography className={classes.addressLabel}>{configJSON.address}</Typography>
                          <Typography className={classes.contactAddressDetails}>{parse(data.attributes.contact_address.toString())}</Typography>
                          </Box>
                          <Box>
                          <Typography className={classes.addressLabel}>{configJSON.phoneNumber}</Typography>
                          <Typography className={classes.phonecontactDetails}>{data.attributes.contact_phone}</Typography>
                          </Box>
                          <Typography data-testid={'viewmap-' + data.id} className={classes.viewmap} onClick={() => this.handleViewMap(data.attributes.contact_text_link)}>{data.attributes.contact_text_name}</Typography>
                        </Box>
                      </Grid>
                    )}
                    
                  </Grid>
                </Grid>
                </>
                }
                </Container>
            </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle: {} = () => ({
  mainContainer:{
    background:'linear-gradient(180deg, #E5EAFF 4.96%, #FFFFFF 20.84%)',
    padding:'80px 10px',
    },
  gridItem1:{
    textAlign:'start'
  },
  contactHeader:{
    fontSize:'0.87rem',
    fontWeight:650,
    lineHeight:'14px',
    color:'#6F66E9',
    border:'1px solid #E5E1FF',
    background:'#E5E1FF',
    width:'fit-content',
    padding:'5px 15px',
    borderRadius:'4px',
    textTransform:'uppercase'
  },
  contactTitle:{
    fontSize:'2.6rem',
    fontWeight:800,
    lineHeight:'58px',
    letterSpacing:'1',
    margin:'15px 0 25px',
    maxWidth:'450px'
  },
  contactDetailsBox:{
    display:'flex',
    alignItems: 'center',
    gap:'10px',
    fontWeight:600,
    lineHeight:'22px',
    marginBottom:'25px'
  },
  socialMediaBox:{
    display:'flex',
    gap:'10px',
    alignItems:'center',
    marginTop:'50px',
    flexWrap:'wrap',
   "@media(max-width:600px)": {
      marginTop: "40px",
     marginBottom: 25
    },
  },
  socialMediaIcon:{
    cursor:'pointer',
    width:'30px'
  },
  formBox:{
    background:'#fff',
    boxShadow: '0px 3px 13px 4px #0000000F',
    borderRadius:'16px',
    padding:'25px 20px'
  },
  yourMessage:{
    fontSize:'1.5rem',
    fontWeight:600,
    lineHeight:'24px',
    fontFamily:'Montserrat',
    marginBottom:'30px'
  },
  textfieldName:{
    width:'100%',
    '& div':{
      borderRadius: '8px',
      background: '#F6F6F6',
      fontFamily:"Lato",
      fontSize:'16px'
    },
    marginBottom:'15px'
  }, 
  textArea:{
    width:'100%',
    '& div':{
      borderRadius: '8px',
      background: '#F6F6F6',
      '& textarea':{
        position: 'relative',
        top: '-8px',
        fontFamily: 'Lato',
        fontSize: '16px'
      }
    },
    margin:'15px 0',
    '& placeholder':{
      top:'-14px'
    }
  },
  checklistHead:{
    fontSize:'1.12rem',
    fontWeight:600,
    color:'#181818',
    lineHeight:'24px',
    margin:'10px 0'
  },
  checkboxContainer:{
    display:'flex',
    alignItems:'start'
  },
  checkbox:{
    cursor:'pointer'
  },
  checklistdata:{
    padding:'6px',
    fontWeight:400,
    color:'#475569'
  },
  checkedList:{
    fontWeight:600,
    padding:'6px',
    color:'#181818'
  }, 
  buttonSend:{
    background:'#70CF7A',
    borderRadius:'6px',
    color:'#000000',
    fontFamily:'Lato',
    fontSize:'1.12rem',
    fontWeight:500,
    width:'100%',
    boxShadow:'none',
    textTransform:'capitalize',
    '&:hover':{
      background:'#70CF7A',
      boxShadow:'none',
    }
  },
  ourLocation:{
    fontSize:'2.3rem',
    fontWeight:800,
    lineHeight:'53px',
    textAlign:'center',
    margin:'50px 0 10px',
    letterSpacing:'1'
  },
  locationSubHead:{
    '& p':{
      color:'#595959',
      textAlign:'center',
    }
  },
  locationBox:{
    background:'linear-gradient(180deg, #F8F6F1 0%, #F5F6F9 100%)',
    borderRadius:'16px',
    padding:'10px 16px',
    width:'260px',
    minHeight:'340px',
    display:'flex',
    flexDirection:'column',
    gap:'1rem',
    
    },
  gridItemLocation:{
    textAlign:'-moz-center',
    display:'flex',
    "@media(max-width:600px)": {
      display:'flex',
      textAlign:'center'
      },
  },
  contactPlace:{
    fontSize: '1.37rem',
    fontWeight: 700,
    lineHeight: '35px',
    height:'80px',
    flexShrink:'0'
  },
  addressLabel:{
    fontSize: '0.875rem',
    color: '#696969',
    lineHeight: '22px'
  },
  contactAddressDetails:{
    '& p':{
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '25px',
      margin: '3px 0 0px',
      letterSpacing:'0.4'
    }
  },
  phonecontactDetails:{
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '25px',
    margin: '3px 0 0px',
    letterSpacing:'0.4'
  },
  viewmap:{
    fontWeight:700,
    textDecoration:'underline',
    cursor:'pointer'
  },
  locationGridContainer:{
    marginTop:'15px'
  },
  validMail:{
    color:'#f44336',
    margin:'4px 14px 0',
    fontSize:'0.75rem'
  },
  addressHead:{
    fontWeight:600,
    lineHeight:'22.4px',
    fontFamily:'Lato'
  },
  addressMainCOntainer:{
    flexShrink: 0,
    flexGrow:1
  }
});

export default withStyles(webStyle)(ContactUsPage);
// Customizable Area End