import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const tableLabels = {
  title: "",
  value: "Value",
  source: "Source",
  update: "Last Updated",
  restatement: "Restatement",
};

const EmmitionTableContainer = styled(TableContainer)({
  minHeight: "583px",
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '12px',
  paddingLeft: "0px",
  marginLeft: '-9px'
});

const EmmitionTable = styled(Table)({
  width: "100%",
  borderCollapse: "separate",
});

const EmmitionTableBody = styled(TableBody)({
  color: "inherit",
});

const EmmitionSpacing = styled("div")({
  height: 16,
  display: "table-header-group",
});

const EmmitionTableCellHead = styled(TableCell)({
  minHeight: "48px",
  padding: "14px 0px 13px 18px",
  flexShrink: 0,
  borderBottom: "none",
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

const EmmitionTableCell = styled(TableCell)({
  borderBottom: "1px solid #F1F5F9",
  padding: "14px 0px 13px 30px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

const EmmitionTableCellChild = styled(TableCell)({
  borderBottom: "1px solid #F1F5F9",
  padding: "14px 0px 13px 45px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

const ExtraTableCell = styled(TableCell)({
  borderBottom: "1px solid #DFDFDF",
  padding: "14px 0px 13px 18px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

const MainExtraTableCell = styled(TableCell)({
  borderBottom: "1px solid #000000",
  padding: "18px 0px 18px 18px",
  color: "#329E54",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

const EmmitionTableHead = styled(TableHead)({
  backgroundColor: "#F5F5F5",
  borderRadius: "12px",
});

const EmmitionTableRowHead = styled(TableRow)({
  borderRadius: "20px !important",
  overflow: "hidden",
  "& :first-child": {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  "& :last-child": {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
});

interface EmissionData {
  id: number;
  name: string;
  value: string;
  source: string;
  last_updated: string;
  restatement?: string;
}

interface EmissionRowsProps {
  title: string;
  rowItems: EmissionData[]
}

function EmissionRows({title, rowItems}: EmissionRowsProps) {
  return (
    <>
      <TableRow>
        <EmmitionTableCell 
          style={webStyle.value}
          data-test-id="title"
        >
          {title}
        </EmmitionTableCell>
        <EmmitionTableCell />
        <EmmitionTableCell />
        <EmmitionTableCell />
        <EmmitionTableCell />
      </TableRow>
      {rowItems.map((item) => {
        return (
          <TableRow>
            <EmmitionTableCellChild>{item.name ?? "-"}</EmmitionTableCellChild>
            <EmmitionTableCellChild style={webStyle.value}>{item.value ?? "-"}</EmmitionTableCellChild>
            <EmmitionTableCellChild style={webStyle.name}>{item.source ?? "-"}</EmmitionTableCellChild>
            <EmmitionTableCellChild style={webStyle.name}>{item.last_updated ?? "-"}</EmmitionTableCellChild>
            <EmmitionTableCellChild style={webStyle.value}>{item.restatement ?? "-"}</EmmitionTableCellChild>
          </TableRow>
        );
      })}
    </>
  );
}

// Customizable Area End

import Cfnzdpuscope1emissionsController, {
  Props,
  configJSON,
} from "./Cfnzdpuscope1emissionsController";

export default class Cfnzdpuscope1emissions extends Cfnzdpuscope1emissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.props.value === this.props.index &&
          <EmmitionTableContainer>
            <EmmitionTable aria-label="users table">
              <EmmitionTableHead>
                <EmmitionTableRowHead>
                  <EmmitionTableCellHead style={{ minWidth: '272px' }}>{tableLabels.title}</EmmitionTableCellHead>
                  <EmmitionTableCellHead style={{ minWidth: '177px' }}>{tableLabels.value}</EmmitionTableCellHead>
                  <EmmitionTableCellHead style={{ minWidth: '170px' }}>{tableLabels.source}</EmmitionTableCellHead>
                  <EmmitionTableCellHead style={{ minWidth: '172px' }}>{tableLabels.update}</EmmitionTableCellHead>
                  <EmmitionTableCellHead style={{ minWidth: '244px' }}>{tableLabels.restatement}</EmmitionTableCellHead>
                </EmmitionTableRowHead>
              </EmmitionTableHead>
              <EmmitionSpacing />
              <EmmitionTableBody>
                <TableRow>
                  <MainExtraTableCell>Scope 1 Emissions</MainExtraTableCell>
                  <MainExtraTableCell />
                  <MainExtraTableCell />
                  <MainExtraTableCell />
                  <MainExtraTableCell />
                </TableRow>
                <TableRow>
                  <ExtraTableCell>Total Scope 1 Emissions</ExtraTableCell>
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                </TableRow>
                {this.state.totalScope1Emissions.map((item) => {
                  return (
                    <TableRow>
                      <EmmitionTableCell style={{ maxWidth: '267px' }}>{item.name ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.value}>{item.value ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.name}>{item.source ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.name}>{item.last_updated ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.value}>{item.restatement ?? "-"}</EmmitionTableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <ExtraTableCell>GHG Breakdown</ExtraTableCell>
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                </TableRow>
                <EmissionRows 
                  title="Rationale if Not Disclosed" 
                  rowItems={this.state.rationaleIfNotDisclosed} 
                />
                <EmissionRows 
                  title="Carbon Dioxide (CO₂)"
                  rowItems={this.state.carbonDioxide}
                />
                <EmissionRows 
                  title="Methane (CH₄)"
                  rowItems={this.state.methane}
                />
                <EmissionRows 
                  title="Nitrous Oxide (N₂O)"
                  rowItems={this.state.nitrousOxide}
                />
                <EmissionRows 
                  title="Hydrofluorocarbons (HFCs)"
                  rowItems={this.state.hydrofluorocarbons}
                />
                <EmissionRows 
                  title="Perfluorocarbons (PFCs)"
                  rowItems={this.state.perfluorocarbons}
                />
                <EmissionRows 
                  title="Sulphur Hexafluoride (SF₆)"
                  rowItems={this.state.sulphurHexafluoride}
                />
                <EmissionRows 
                  title="Nitrogen Trifluoride (NF₃)"
                  rowItems={this.state.nitrogenTrifluoride}
                />
                <EmissionRows 
                  title="Other greenhouse gas 1"
                  rowItems={this.state.otherGreenhouseGas1}
                />
                <EmissionRows 
                  title="Other greenhouse gas 2"
                  rowItems={this.state.otherGreenhouseGas2}
                />
                <EmissionRows 
                  title="Other greenhouse gas 3"
                  rowItems={this.state.otherGreenhouseGas3}
                />
                <EmissionRows 
                  title="Other greenhouse gas 4"
                  rowItems={this.state.otherGreenhouseGas4}
                />
                <EmissionRows 
                  title="Other greenhouse gas 5"
                  rowItems={this.state.otherGreenhouseGas5}
                />
                <EmissionRows 
                  title="Other greenhouse gas 6"
                  rowItems={this.state.otherGreenhouseGas6}
                />
                {/* Methodology starts */}
                <TableRow>
                  <ExtraTableCell>Methodology</ExtraTableCell>
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                </TableRow>
                {this.state.methodology.map((item) => {
                  return (
                    <TableRow>
                      <EmmitionTableCell style={{ maxWidth: '267px' }}>{item.name ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.value}>{item.value ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.name}>{item.source ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.name}>{item.last_updated ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.value}>{item.restatement ?? "-"}</EmmitionTableCell>
                    </TableRow>
                  );
                })}
                {/* Methodology ends */}
                {/* Exclutions starts */}
                <TableRow>
                  <ExtraTableCell>Exclusions</ExtraTableCell>
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                </TableRow>
                <EmissionRows 
                  title="Exclusion 1" 
                  rowItems={this.state.exclusion1} 
                />
                {/* Exclutions ends */}
                {/* Change in Scope 1 emissions calculation or reporting boundary starts */}
                <TableRow>
                  <ExtraTableCell>Change in Scope 1 emissions calculation or reporting boundary</ExtraTableCell>
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                </TableRow>
                {this.state.changeInScope1EmissionsCalculation.map((item) => {
                  return (
                    <TableRow>
                      <EmmitionTableCell style={{ maxWidth: '267px' }}>{item.name ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.value}>{item.value ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.name}>{item.source ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.name}>{item.last_updated ?? "-"}</EmmitionTableCell>
                      <EmmitionTableCell style={webStyle.value}>{item.restatement ?? "-"}</EmmitionTableCell>
                    </TableRow>
                  );
                })}
                {/* Change in Scope 1 emissions calculation or reporting boundary ends */}
              </EmmitionTableBody>
            </EmmitionTable>
          </EmmitionTableContainer>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  title: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "27.5px"
  },
  subtext: {
    color: "#34373A",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    marginTop: '29px'
  },
  appbar: {
    padding: '37px 0',
    maxWidth: '850px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    marginLeft: '-38px'
  },
  tabtext: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.4px",
  },
  name: {
    color: '#7D7D7F',
    fontSize: "12px",
    fontWeight: 400,
  },
  value: {
    fontWeight: 700,
  }
}
// Customizable Area End
