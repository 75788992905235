import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    mainHeading: {
      display: "flex",
      flexDirection: "column",
      minHeight: "333px",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#ECFBF1",
      borderRadius: "16px",
    },
    subHeading: {
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      lineHeight: "24.38px",
      color: "#43D270",
      marginBottom: "37px",
    },
    spanheadingstyle:{
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      lineHeight: "24.38px",
      color: "#ECFBF1",
    },
    heading: {
      fontSize: "48px",
      fontWeight: 800,
      fontFamily: "Montserrat",
      lineHeight: "56px",
      marginBottom: "24px",
      color: "#34373A",
    },
    spanHeading:{
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize:'20px',
      color:'#34373A'
    }
  })
);
interface HeadingProps {
  heading?: string;
  subHeading?: string;
  spanHeading?: string;
}

const PageHeadingCard: React.FC<HeadingProps> = ({
  heading,
  subHeading,
  spanHeading
}: HeadingProps) => {
  const classes = useStyles();
  return (
    <Box mt={7} mb={5}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.mainHeading}
        >
          <Typography variant="h3" component="p" className={classes.subHeading}>
            {heading}
          </Typography>
          <Typography className={classes.heading}>{subHeading}</Typography>
          <Typography className={classes.spanHeading}>{spanHeading}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PageHeadingCard;
