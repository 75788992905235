import React from "react";

// Customizable Area Start
import {
    IconButton,
    InputLabel,
    Typography,
    Paper,
    Modal,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { close } from "./assets";
import CustomDropDown from "../../../components/src/CustomDropDown.web";

interface Organization {
    id: number
    name: string
}

// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

// Customizable Area End
export default class PortfolioManagerLogin extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getOrganization();
        this.getOrganizations()
    }
    // Customizable Area End
    render() {
        return (
            // Required for all blocks
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Modal open={this.props.open} onClose={this.props.onClose} style={webStyle.modalStyle}>
                    <Paper style={webStyle.orgpaperStyle as React.CSSProperties}>
                        <div style={webStyle.titleContainer}>
                            <Typography style={webStyle.orgTitle}>Please select from the list</Typography>
                            <IconButton onClick={this.props.onClose}>
                                <img src={close} alt="close" />
                            </IconButton>
                        </div>
                        <Typography style={webStyle.orgdesc}>Select the Organization you want to check Data:</Typography>
                        <InputLabel
                            style={webStyle.labeltxt}>
                            Select the organization
                        </InputLabel>
                        <CustomDropDown
                            value={this.state.organization}
                            List={this.state.organizationList}
                            onChange={this.handleSelectOrg}
                            getOptionLabel={(option: Organization) => option.name}
                        />
                        {this.props.error &&
                            <Typography style={webStyle.errors}>{this.props.error}</Typography>
                        }
                    </Paper>
                </Modal>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    orgpaperStyle: {
        width: '460px',
        maxWidth: "100%",
        minHeight: '250px',
        height: 'auto',
        padding: '25px 21px 46px 27px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
    },
    labeltxt: {
        color: "#34373A",
        fontFamily: "Lato !important",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        marginBottom: '8px'
    },
    orgTitle: {
        color: "#34373A",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "140%",
        letterSpacing: "0.2px",
    },
    orgdesc: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "160%",
        marginBottom: '21px'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '11px',
        alignItems: 'center'
    },
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    autoComplete: {
        maxWidth: '404px',
        minHeight: "48px",
        borderRadius: '8px',
        border: "1px solid #D9D9D9",
        padding: '12px',
        boxShadow: 'none',
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "24px"
    },
    modelpopper: {
        maxHeight: '271px',
        borderRadius: "8px",
        border: "1px solid #D9D9D9",
        background: "#FFF",
        maxWidth: '404px',
        boxShadow: 'none !important',
        padding: '0px !important',
    },
    resourcename: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: '14px'
    },
    errors: {
        color: "red",
        marginTop: "10px",
        paddingLeft: '10px',
        fontSize: "12px",
        fontWeight: 300,
      },
};
// Customizable Area End
