import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
interface FooterSubAttributes1 {
    position:number;
    title:string;
    link_type:string | null;
    predefined_link:string;
    external_link:string;
    page:string | null;
}
interface FooterSubItems1 {
    id:string;
    type:string;
    attributes:FooterSubAttributes1
}
interface FooterAttributes1 {
    position:number;
    title:string;
    link_type:string | null;
    predefined_link:string;
    external_link:string;
    page:string | null;
    footer_sub_items:{
        data:FooterSubItems1[]
    }
}
interface ContactDetails1 {
    type:string;
    contact_phone:string;
    contact_email:string;
    contact_address:string;
    contact_address_link:string;
    social_media:{
        name:string;
        link:string;
    }[]
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string};
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  footerData:{
    company_infos:{
        type:string;
        description:string;
    },
    data:{
        id:string;
        type:string;
        attributes:FooterAttributes1
    }[]
    contact_details: ContactDetails1
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FooterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  footergetApiId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      footerData: {
        company_infos:{
            type: "",
            description: ""
            },
            data: [],
            contact_details:{
                type: "",
                contact_phone: "",
                contact_email: "",
                contact_address: "",
                contact_address_link:"",
                social_media: []
            }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getFooterAPICall();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      this.handleHeaderDetails(requestCallId, message);
    }
    // Customizable Area End
  }
  handleHeaderDetails = (requestCallId: string, message: Message) => {
    if(this.footergetApiId === requestCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson){
        this.setState({
          footerData:responseJson
        })
      }
    }
  }
  getFooterAPICall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    this.footergetApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.footerApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}