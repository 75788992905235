import React from 'react';

function Slash() {
  return (
    <svg stroke="currentColor" fill="currentColor" viewBox="0 0 16 16" color="#5b45f3" transform="rotate(-15)"
         className="makeStyles-slashIcon-842" xmlns="http://www.w3.org/2000/svg" strokeWidth="3"
         height="40" width="30">
      <path fillRule="evenodd"
            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0"></path>
    </svg>
  );
}

export default Slash;