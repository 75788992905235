import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import { Link } from "react-router-dom";
import { removeTags } from './landingpage/LpDifferentFromOther';
import { extractContentFromHTMLString, convertTo12HourFormat, formatDateToString } from './BlogListingSection.web';
import moment from 'moment';

const dummyColor: any = {
    Environmental: "#43D270",
    Social: "#7166F1",
    Governance: "#F96C30"
}

export const useStyles = makeStyles({
    genericCard: {
        background: "#FFFFFF",
        borderRadius: "16px",
        width: "100%",
        '& :hover': {
            transform: "scale(1)",
        },
        cursor: "pointer",
    },

    imageCard: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        overflow: 'hidden',
        height: "399px",
        width: "392px",
        maxWidth: "100%",
        borderRadius: "14px",
        background: "#FFFFFF",
        margin: "0 auto 30px auto",
    },

    images: {
        height: "100%",
        maxWidth: "100%",
        transition: "all ease 0.5s",
        objectFit: "cover",
        '&:hover': {
            transform: "scale(1.1)",
        },
    },
    images1:{
        transition: "all ease 0.5s",
        objectFit: "cover",
        maxWidth: "100%",
        '&:hover': {
            transform: "scale(1.1)",
        },
    },

    relatedGcContent: {
        padding: "0 10px",
    },

    noDecoration: {
        textDecoration: "none !important",
        cursor: 'pointer',
    },

    relatedTitle: {
        color: "#fff",
        fontFamily: 'Montserrat',
        fontStyle: "normal",
        fontWeight: 600,
        width: "fit-content",
        fontSize: "14px",
        padding: "6px 16px 6px 16px",
        marginBottom: "15px",
        borderRadius: "4px",
    },

    relatedText: {
        height: "70px",
        color: "#000000",
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "22px",
        width: "100%",
        display: "-webkit-box",
        lineHeight: "35.2px",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
    },

    authorName: {
        color: "#34373A",
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        marginBottom: "14px",
        flex: 1,
    },

    relatedCardButtonWr: {
        textAlign: "right",
    },

    cardButton: {
        minHeight: "48px",
        background: "#43D270",
        borderRadius: "8px",
        boxShadow: "none",
        display: "inline-flex",
        minWidth: "131px",
        letterSpacing: "0.4px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none !important",
        color: "#000",
        textTransform: "uppercase",
        fontFamily: 'Montserrat',
        fontWeight: 500,
    },

    cardMetaDataStyle1: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "7px"
    },

    cardMetaDataStyle2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "7px"
    },


    date: {
        color: "#34373A",
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        textAlign: "right",
        flex: 1,
    },

    tagPaidButton: {
        minHeight: "32px",
        background: "#E0E0FC",
        borderRadius: "32px",
        boxShadow: "none",
        display: "inline-flex",
        fontWeight: 600,
        minWidth: "72px",
        fontFamily: "Montserrat",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "capitalize",
        cursor: "pointer",
        textDecoration: "none !important",
        color: "#7166F1",
        fontSize: '14px',
    },

    tagButton: {
        minHeight: "32px",
        background: "#F3F3F3",
        textTransform: "capitalize",
        borderRadius: "32px",
        boxShadow: "none",
        display: "inline-flex",
        minWidth: "72px",
        color: "#727272",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
        cursor: "pointer",
        textDecoration: "none !important",
        fontSize: '14px',
    },
    description: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "25.6px",
        color: "#4F4F4F",
        marginTop: "10px",
        },
})

const dummyShadowColor: any = {
    Environmental: "rgba(67, 210, 112, 0.2)",
    Social: "rgba(171, 158, 255, 0.2)",
    Governance: "rgba(244, 154, 122, 0.2)"
}

interface PropTypes {
    enableButton?: boolean,
    enablePaymentTags?: boolean,
    limit?: number,
    data?: any,
    viewDetails?: any,
    enableMetaTags?:any
}

const RelatedBlogListingSection = ({ data, viewDetails}: PropTypes) => {
    const classes = useStyles();
    const url = `/${window?.location?.pathname?.toLowerCase()?.replace(/[0-9/]+/g, '')}`
    return (
        <Grid container style={{marginBottom: url === '/events' ? "137px" : "77px"}} spacing={4}>
            {Array.isArray(data) && data.map((item: any, index: any): ReactElement => {
                const fullRelatedDescription = extractContentFromHTMLString(item?.attributes?.description);
                const slicedRelatedDescription = `${fullRelatedDescription.slice(0, 74)}...`;
                const relatedBlogPostTimeAndDateArr = [];
                const relatedBlogPostTime = convertTo12HourFormat(item?.attributes?.insight_time);
                if (relatedBlogPostTime) {
                    relatedBlogPostTimeAndDateArr.push(relatedBlogPostTime);
                }
                const relatedBlogPostDate = formatDateToString(item?.attributes?.insight_date);
                if (relatedBlogPostDate) {
                    relatedBlogPostTimeAndDateArr.push(relatedBlogPostDate);
                }
                const relatedBlogPostTimeAndDate = relatedBlogPostTimeAndDateArr.join(", ");
                return <Grid item xs={12} sm={4} container key={`s_item_${index}`}>
                    <Box
                        className={classes.genericCard}
                        data-set-test='genericCard'
                        onClick={() => {
                            viewDetails(item.id, url, true);
                            window.scrollTo(0, 0)
                        }}>
                        <Box 
                            className={classes.imageCard} 
                            // sx={{ boxShadow: `0px 14px 34px ${dummyShadowColor[item?.attributes?.resource_tag_id]}` }}
                        >
                            {
                                item?.attributes?.insight_image &&
                                <img 
                                    src={item.attributes.insight_image} 
                                    className={`${url === '/marketplace' ? classes.images1 : classes.images}`} 
                                    alt="" 
                                />
                                
                            }
                        </Box>
                        <Box className={classes.relatedGcContent}>
                            <Typography 
                                variant='h3' 
                                className={classes.relatedTitle} 
                                style={{ 
                                    visibility: item?.attributes?.resource_tag_id ? "visible" : "hidden",
                                    backgroundColor: dummyColor[item?.attributes?.resource_tag_id] || "#F96C30" 
                                }}
                            >
                                {item?.attributes?.resource_tag_id}
                            </Typography>
                            <Typography variant='h4' className={classes.relatedText} >{removeTags(item?.attributes?.title)}</Typography>
                            <Typography className={classes.description}>{slicedRelatedDescription}</Typography>
                            {url === '/insights-and-trends' &&
                                <div className={classes.cardMetaDataStyle1}>
                                    <Typography 
                                        style={{ margin: 0 }} 
                                        className={classes.description}
                                    >
                                        {relatedBlogPostTimeAndDate}
                                    </Typography>
                                </div>
                            }
                            {url === '/events' &&
                                <div className={classes.cardMetaDataStyle2}>
                                    {item?.attributes?.pay_type?.toLowerCase() === "paid" ?
                                        <Link to="/events" className={classes.tagPaidButton}>{item?.attributes?.pay_type?.toLowerCase()}</Link>
                                        :
                                        <Link to="/events" className={classes.tagButton}>{item?.attributes?.pay_type?.toLowerCase()}</Link>
                                    }
                                    <Typography variant='h6' className={classes.date}>{`${item?.attributes?.insights_event_time}, ${moment(item?.attributes?.insights_event_date).format("MMMM D, YYYY")}`} </Typography>
                                </div>
                            }
                            {url === '/marketplace' &&
                                <div className={classes.relatedCardButtonWr}>
                                    <Link to={`/contact-us`} onClick={(e) => e.stopPropagation()} className={classes.cardButton}>{item?.attributes?.buttonLabel ?? "Contact"}</Link>
                                </div>
                            }
                        </Box>
                    </Box>
                </Grid >
            })}
        </Grid>
    );
}
export default RelatedBlogListingSection;