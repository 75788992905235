import React from "react";

// Customizable Area Start
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { plan, plan_active, bill, bill_active, invoice, invoice_active } from "./assets";
import Plans from "./Plans.web";
import Invoices from "./Invoices.web";
import Billing from "./Billing.web";
import Layout from "../../dashboard/src/Layout.web";

const InnerContainer = styled("div")({
    width: "100%",
    height: "auto",
    padding: "27px 0 44px 0px"
});

const PlanTabsSection = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
    marginLeft: '44px',
    marginRight: '32px',
    '@media(max-width: 450px)': {
        alignItems: 'end'
    },
});

const PlanTabItem = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#FFF",
    color: "grey",
    fontSize: "15px",
    height: "40px",
    cursor: "pointer",
});

const PlanTabItemSelected = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#ECFBF1",
    color: "grey",
    fontSize: "15px",
    cursor: "pointer",
});

const PlanTabText = styled(Typography)({
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.2px",
    cursor: "pointer",
});

const PlanTabTextSelected = styled(PlanTabText)({
    color: "#329E54",
    fontWeight: 700,
});

const PlanIcon = styled("span")({
    width: "20px",
    height: "20px",
    marginTop: '-5px'
});

const PlanTabContent = styled('div')({
    width: "100%",
})

const PlanTabLink = styled(Link)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
})

const PlanTabs = styled('div')({
    maxWidth: '404px',
    display: 'flex',
    gap: '8px',
    '@media(max-width: 450px)': {
        flexDirection: 'column',
        alignItems: 'start'
    },
})


const OrgBaseUrl = "/settings/plan&billing";
// Customizable Area End

import CustomisableusersubscriptionsController, {
    Props,
} from "./CustomisableusersubscriptionsController";

export default class PlanAndBilling extends CustomisableusersubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'select-plan';
        const tabsData = [
            {
                id: 1,
                text: "Select Plan",
                icon: plan,
                activeIcon: plan_active,
                component: <Plans navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "select-plan",
                link: "/select-plan"
            },
            {
                id: 2,
                text: "Confirm Billing",
                icon: bill,
                activeIcon: bill_active,
                component: <Billing navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "confirm-billing",
                link: "/confirm-billing"
            },
            {
                id: 3,
                text: "Invoices",
                icon: invoice,
                activeIcon: invoice_active,
                component: <Invoices navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "invoices",
                link: "/invoices"
            },
        ];
        return (
            <>
                <Layout>
                    <InnerContainer>
                        <PlanTabsSection>
                            <PlanTabs>
                                {tabsData.map((tab) => {
                                    return tab.isActive ? (
                                        <PlanTabItemSelected
                                            key={`selected_tab_${tab.id}`}
                                        >
                                            <PlanIcon><img src={tab.activeIcon} alt="" /></PlanIcon>
                                            <PlanTabTextSelected>{tab.text}</PlanTabTextSelected>
                                        </PlanTabItemSelected>
                                    ) : (
                                        <PlanTabItem
                                            key={`noneselected_tab_${tab.id}`}
                                        >
                                            <PlanTabLink to={OrgBaseUrl + tab.link}>
                                                <PlanIcon><img src={tab.icon} alt="" /></PlanIcon>
                                                <PlanTabText>{tab.text}</PlanTabText>
                                            </PlanTabLink>
                                        </PlanTabItem>
                                    );
                                })}
                            </PlanTabs>
                        </PlanTabsSection>

                        {tabsData.map((content) => {
                            return <PlanTabContent key={`content${content.id}`}>{content.isActive ? content.component : ""}</PlanTabContent>
                        })}
                    </InnerContainer>
                </Layout>
            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start
// Customizable Area End
