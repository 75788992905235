import parse from 'html-react-parser';
import { Container } from "@material-ui/core";
import React from 'react'

const ContentWidget = (props: any) => {
    const { description, containerType } = props;
    const containerWidth = containerType == "full-width" ? "1280px" : "870px";
    let ContentWidgetMargin = "13px auto 84px auto";

    if (window.location.pathname === "/careers") {
        ContentWidgetMargin = "0 auto";
    }

    return (
        <Container className='typograhy' style={{ maxWidth: "100%", width: containerWidth, margin: ContentWidgetMargin }}>
            <div className='ql-editor'>
                {description && parse(description.toString(), { trim: true })}
            </div>
        </Container>
    )
}

export default ContentWidget;