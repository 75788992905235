import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
    Box,
    Container,
    Typography,
    Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import ArrowOutwardIcon from '@material-ui/icons/ArrowForward';
import { getDyanmicLink } from "../landingpage/LpDifferentFromOther";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        darkTheme: {
            backgroundColor: "#34373A",
            width: "100%",
        },
        buttonStyle: {
            width: "252px",
            background: '#43D270',
            borderRadius: "8px",
            color: '#101010',
            margin: "0 15px 15px 15px",
            padding: "16px 12px",
            boxShadow: "none !important",
            alignItems: "center",
        },
        buttonIconStyle: {
            marginLeft: '8px',
            transform: "rotate(-45deg)"
        },
        buttonLinkStyle: {
            textDecoration: "unset",
        },
        lightTheme: {
            backgroundColor: "#ffffff",
            width: "100%",
        },

        ctaLinkWr: {
            flexDirection: "column",
            padding: "62px 0 65px 0",
            minHeight: "287px",
            display: "flex",
            margin: "0 auto",
            alignItems: "center",
            justifyContent: "center",
            "@media(max-width: 600px)": {
                padding: "50px 0 20px 0",
            },
        },

        darkThemeHeading: {
            fontSize: "48px",
            fontWeight: 700,
            fontFamily: "Montserrat",
            lineHeight: "56px",
            margin: "0 auto 30px auto",
            color: "#ffffff",
            maxWidth: "563px",
            textAlign: "center",
            "& p": {
                fontFamily: "Montserrat",
                fontWeight: 700,
            },
            "& b": {
                fontFamily: "Montserrat",
                color: "#43D270",
                display: "inline-block",
                fontWeight: 700,
            },
            "& span": {
                fontFamily: "Montserrat",
                color: "#43D270",
                display: "inline-block",
                fontWeight: 700,
            },
            "& strong": {
                fontFamily: "Montserrat",
                color: "#43D270",
                fontWeight: 700,
            },
            "@media(max-width: 600px)": {
                fontSize: "28px",
                lineHeight: "30px",
                marginBottom: "30px",
            },
        },

        lightThemeHeading: {
            fontSize: "48px",
            fontWeight: 700,
            fontFamily: "Montserrat",
            lineHeight: "56px",
            margin: "0 auto 30px auto",
            color: "#34373A",
            textAlign: "center",
            maxWidth: "800px",
            "& p": {
                fontFamily: "Montserrat",
                fontWeight: 700,
            },
            "& b": {
                color: "#43D270",
                fontFamily: "Montserrat",
                display: "inline-block",
                fontWeight: 700,
            },
            "& strong": {
                color: "#43D270",
                fontFamily: "Montserrat",
                fontWeight: 700,
            },
            "@media(max-width: 600px)": {
                fontSize: "30px",
                lineHeight: "1.4",
                fontFamily: "Montserrat",
                marginBottom: "30px",
            },
        },
 })
);

interface ThemeProps {
    themeMode?: "light" | "dark";
    header: any;
    widget_item_buttons: any;
}

const CallToAction = ({
    header,
    widget_item_buttons,
    themeMode,
}: ThemeProps) => {
    const classes = useStyles();

    const backgroundMode =
        themeMode === "dark" ? classes.darkTheme : classes.lightTheme;

    const ctaHeadingStyle =
        themeMode === "dark" ? classes.darkThemeHeading : classes.lightThemeHeading;

    const defaultHeading = "View <b style={{color:'#43D270'}}>Job</b> Opening!";

    return (
        <Container className={backgroundMode} maxWidth={false}>
            <Box className={classes.ctaLinkWr}
                data-test-id={'CtaBox'}
            >
                <Typography
                    className={ctaHeadingStyle}
                > {header ? parse(header.toString()) : defaultHeading}
                </Typography>

                <Link className={classes.buttonLinkStyle} to={getDyanmicLink(widget_item_buttons?.data[0]?.attributes?.link, "/job-openings-list")}>
                    <Button variant="contained" className={classes.buttonStyle}>{widget_item_buttons?.data[0]?.attributes?.name} <ArrowOutwardIcon className={classes.buttonIconStyle} /></Button>
                </Link>
            </Box>
        </Container>
    );
};

const CallToActionLink = (props: any) => {
    const { header, widget_item_buttons } = props;
    return <CallToAction {...props} header={header} widget_item_buttons={widget_item_buttons} />;
};

export default CallToActionLink;
