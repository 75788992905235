import { Box, Typography, Grid, Avatar, Container } from '@material-ui/core';
import React from 'react';
import parse from 'html-react-parser';
import { styled } from '@material-ui/core/styles';
import { removeTags } from './LpDifferentFromOther';


const TeamWidgetContainer = styled(Box)({
    padding: '100px 50px',
    alignItems: "center",
    '@media (max-width:600px)': {
        padding: '50px 0px 20px 0px',
    }
});

const TeamWidgetCardbox = styled(Box)({
    padding: '24px',
    borderRadius: '16px',
    maxWidth: '288px',
    '@media (max-width:600px)': {
        maxWidth: '100%',
        marginBottom: "10px",
        padding: '15px 20px',
    },
    '&:hover': {
        backgroundColor: '#D1F2DA'
    }
});

const TeamWidgetCardIcon = styled(Avatar)({
    width: "167px",
    height: "167px",
    backgroundColor: "#43D270",
    borderRadius: "36px",
    marginBottom: "50px",
    '@media (max-width:600px)': {
        marginBottom: "34px",
    }
});

const TeamWidgetCardHeading = styled(Typography)({
    color: 'var(--neutral - 100, #101010)',
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "120%",
    marginBottom: "20px",
    '@media (max-width:600px)': {
        marginBottom: "10px",
    }
});
const TeamWidgetCardDescription = styled(Typography)({
    fontFamily: 'Lato',
    fontSize: '15px',
    textAlign: "center",
    fontWeight: 400,
    lineHeight: '160%',
    marginBottom: "12px",
    color: '#34373A',
});
const TeamWidgetItemGrid = styled(Grid)({
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: 'nowrap',
    '@media (max-width:740px)': {
        flexWrap: 'wrap'
    }

});
const TeamWidgetHeading = styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: '48px',
    fontWeight: 700,
    width: "100%",
    maxWidth: "620px",
    lineHeight: '56px',
    letterSpacing: '0px',
    textAlign: "center",
    margin: "0 auto 56px auto",
    '@media (max-width:600px)': {
        fontSize: '28px',
        lineHeight: '30px',
        margin: "0 auto 30px auto",
    }
});
const TeamWidget = (props: any) => {
    const { description, widget_sub_items } = props;

    return (
        <>
            <TeamWidgetContainer>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} sm={12}><TeamWidgetHeading variant="h5">{description && removeTags(description)}</TeamWidgetHeading>
                        </Grid>
                    </Grid>
                    <TeamWidgetItemGrid container spacing={2}>
                        {widget_sub_items && widget_sub_items?.data.map((item: any, index: number) => {
                            return <Grid item>
                                <TeamWidgetCardbox
                                    key={`card_id_${index}`}>
                                    <TeamWidgetCardIcon>
                                        {item?.attributes?.image &&
                                            <img src={item?.attributes?.image} alt="" style={{ height: '100%', width: '100%' }} />
                                        }
                                    </TeamWidgetCardIcon>
                                    <TeamWidgetCardHeading>{item?.attributes?.title}</TeamWidgetCardHeading>
                                    <TeamWidgetCardDescription>
                                        {item?.attributes?.description && parse(item?.attributes?.description.toString())}
                                    </TeamWidgetCardDescription>
                                </TeamWidgetCardbox>

                            </Grid>
                        })
                        }
                    </TeamWidgetItemGrid>
                </Container>
            </TeamWidgetContainer>
        </>
    );
};
export default TeamWidget;