import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles(() => ({
  paginationItem: {
    '& li:first-child': {
      marginRight: "20px",
    },
    '& li:last-child': {
      marginLeft: "20px",
    },
    '& button': {
      backgroundColor: 'transparent !important',
      borderRadius: "8px !important",
      height: "36px",
      width: "36px",
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      fontFamily: "Lato",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "160%",
      letterSpacing: "0.2px",
      padding: "0px !important",
      minWidth: "0px !important",
    },
  },
  paginationHover: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "23px",
    backround: "#ffffff !important",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%",
    letterSpacing: "0.2px",
    "& *": {
      color: "#7D7D7F !important",
    },
    "& .Mui-selected": {
      borderColor: "#329E54 !important",
      color: "#329E54 !important",
      fontWeight: 700,
    },
    "@media (max-width: 600px)": {
      marginRight: "0px",
    },
  },
}));

interface SimplePaginationProps {
  count: number;
  variant?: "text" | "outlined";
  shape?: "round" | "rounded";
  color?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  boundaryCount?: number;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  onChange?: any;
}

const SimplePagination: React.FC<SimplePaginationProps> = ({
  count = 50,
  variant = "outlined",
  shape = "rounded",
  size = "large",
  boundaryCount = 1,
  showFirstButton = false,
  showLastButton = false,
  onChange
}) => {
  const classes = useStyles();
  const pageCounts = count > 0 ? Math.ceil(count / 9) : 0;
  return (
    <div className={classes.paginationHover}>
      {pageCounts > 0 &&
        <Pagination
          data-test-id="pagination_field"
          onChange={onChange}
          count={pageCounts}
          variant={variant}
          shape={shape}
          size={size}
          boundaryCount={boundaryCount}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          classes={{ ul: classes.paginationItem }}
        />
      }
    </div>
  );
};

export default SimplePagination;
