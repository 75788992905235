import React from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Label from "./Label";
import WidgetHeadingText from "./WidgetHeadingText";

const useStyles = makeStyles(() => ({
  mainBlock: {
    paddingTop: "60px",
    "@media (max-width: 768px)": {
      paddingTop: "32px",
    },
  },
  textBlock: {
    padding: "80px 50px",
    "@media (max-width: 768px)": {
      padding: "40px 20px",
    },
  },
  imageBlock: {
    borderRadius: "20px",
    "& img": {
      width: "100%",
    },
  },
  cta: {
    fontFamily: "'Lato', sans-serif",
    marginTop: "40px",
    "& span": {
      color: "#fff",
      backgroundColor: "#43d270",
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
      padding: "12px 32px",
      borderRadius: "4px",
      cursor: "pointer"
    },
  },
}));

export default function ReadyForFuture(props: any) {
  const { main_header, header, image, widget_item_buttons, navigate } = props;
  const styles = useStyles();

  return (
    <div className={styles.mainBlock}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={6} className={styles.textBlock}>
            <Label text={main_header} />
            <WidgetHeadingText text={header} />
            <div className={styles.cta}>
              <span onClick={() => navigate(widget_item_buttons.data[0].attributes.link)}>
                {widget_item_buttons.data[0].attributes.name}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.imageBlock}>
            <img src={image} alt="Ready for the Future" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
