import React from "react";
import {
  makeStyles,
  Typography,
  styled,
  Box,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { checkboxIcon, checkboxIconActive } from "../assets";

const CheckboxLabelStyled = styled(Typography)({
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "6px",
  fontSize: "15px !important",
});

const FormControlLabelStyled = styled(FormControlLabel)({
  marginBottom: "2px",
  fontSize: "14px",
  lineHeight: "22px",
  width: "100%",
  color: "#34373A",
  '& *': {
    fontSize: "14px",
    lineHeight: "22px",
  },
  '& :hover': {
    color: "#7D7D7F !important",
    '& img': {
      filter: "brightness(1.2) saturate(1.2) hue-rotate(0deg)"
    }
  },
});

const CheckboxGroupStyled = styled(Box)({
  marginBottom: "40px",
});

const useStyles = makeStyles((theme) => ({
  checkboxFieldStyle: {
    '& *': {
      fontFamily: "Lato",
      fontWeight: 400,
    },
  }
}));


interface CheckboxFieldStyledPropsType {
  onChange: any;
  value: string;
  options: {
    id: number;
    value: number;
    label: string;
  }[];
  label: string;
  name: string;
  testId?: string;
}

const CheckboxFieldStyled: React.FC<CheckboxFieldStyledPropsType> = ({
  onChange,
  options,
  label,
  name,
  testId,
}) => {
  const classes = useStyles();

  return (
    <CheckboxGroupStyled className={classes.checkboxFieldStyle}>
      <CheckboxLabelStyled>{label}</CheckboxLabelStyled>
      {options && options.map((option) => {
        return <FormControlLabelStyled
          control={
            <Checkbox
              data-test-id={testId}
              name={name}
              value={option.value}
              onChange={onChange}
              icon={<img src={checkboxIcon} alt="" />}
              checkedIcon={<img src={checkboxIconActive} alt="" />}
            />}
          label={option.label}
        />
      })}
    </CheckboxGroupStyled>
  );
};

export default CheckboxFieldStyled;
