import React from "react";
import { styled, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  label: {
    borderRadius: "4px",
    display: "inline-block",
    padding: "4px 12px",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Lato",
    lineHeight: "20px",
    letterSpacing: "1.2px",
  },
  labelSecondary: {
    backgroundColor: "#eaedfd",
    color: "#43d270",
  },
  labelPrimary: {
    backgroundColor: "#E5E1FF",
    color: "#6F66E9",
  }
}))

export default function Label({
  text,
  color = "default",
  ...props
}: {
  text: string;
  [key: string]: any;
}) {
  const styles = useStyles();
  return <div className={`${styles.label} ${color === 'green' ? styles.labelSecondary : styles.labelPrimary}`} {...props}>{text}</div>;
}
