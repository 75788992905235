import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import parse from "html-react-parser";

const useStyles = makeStyles(() => ({
  mainHeader: {
    background: "#E5EAFF",
    height: "25vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& h1": {
      fontSize: "48px",
      lineHeight: "68px",
      fontWeight: 800
    },
    "@media (max-width: 768px)": {
      padding: "0 20px",
      height: "250px",
      "& h1": {
        fontSize: "32px",
        lineHeight: "48px"
      }
    }
  },
  mainBlock: {
    display: "flex",
    backgroundColor: "#fff",
    marginTop: "60px",
    gap: "48px",
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse"
    }
  },
  textBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    flex: 1
  },
  pageHeading: {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: "18px"
  },
  header: {
    "& > *": {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "48px",
      "@media (max-width: 768px)": {
        fontSize: "20px",
        lineHeight: "28px"
      }
    }
  },
  description: {
    "& > *": {
      fontSize: "18px",
      lineHeight: "28px",
      color: "#696969",
      "@media (max-width: 768px)": {
        fontSize: "16px",
        lineHeight: "24px"
      }
    }
  },
  buttonBlock: {
    marginTop: "10px",
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    "& button": {
      fontSize: "18px",
      fontWeight: 500,
      display: "inline-flex",
      gap: "10px",
      color: "#101010",
      alignItems: "center",
      padding: "16px 24px",
      borderRadius: "8px",
      border: "none",
      cursor: "pointer",
      "& img": {
        width: "20px",
        height: "20px"
      }
    },
    "@media (max-width: 768px)": {
      gap: "10px",
      "& button": {
        fontSize: "16px",
        padding: "12px 20px"
      }
    }
  },
  btnPrimary: {
    backgroundColor: "#43d270"
  },
  btnSecondary: {
    backgroundColor: "#ebedfc"
  },
  image: {
    flex: 1,
    width: "100%",
    height: "400px",
    objectFit: "cover",
    borderRadius: "100px 0px",
    "@media (max-width: 768px)": {
      height: "300px",
      borderRadius: "50px 0"
    }
  }
}));

function HeadingToolkit(props: any) {
  const {
    page_heading,
    header,
    description,
    image,
    widget_item_buttons,
    navigate
  } = props;
  const styles = useStyles();

  return (
    <>
      <div className={styles.mainHeader}>
        <h1>Driving Change With Net Zero Carbon Emissions </h1>
      </div>
      <Container className={styles.mainBlock}>
        <div className={styles.textBlock}>
          <p className={styles.pageHeading}>{page_heading}</p>
          <h2 className={styles.header}>{parse(header)}</h2>
          <p className={styles.description}>{parse(description)}</p>
          <div className={styles.buttonBlock}>
            {widget_item_buttons.data.map((item: any, index: number) => {
              const { name, link, icon } = item.attributes;
              return (
                <button
                  key={item.id}
                  className={
                    index === 0
                      ? `${styles.btnPrimary}`
                      : `${styles.btnSecondary}`
                  }
                  onClick={() => navigate(link)}
                >
                  {name}
                  <img src={icon} alt="" />
                </button>
              );
            })}
          </div>
        </div>
        <img className={styles.image} src={image} alt="" />
      </Container>
    </>
  );
}

export default HeadingToolkit;
