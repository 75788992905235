// Customizable Area Start
import React from "react";
import { Box, Paper, Typography, Button } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { logo, success } from "./assets";
import { Link } from "react-router-dom";

import EmailNotifications2Controller, {
  Props,
} from "./EmailNotifications2Controller";

const SUCCESSMSG =
  "You have been successfully unsubscribed from Carbonzeroed 'Newsletter' emails.";
const ERRORMSG =
  "Please click the below link to try again.";

class UnsubscribeNewsLetter extends EmailNotifications2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let topIcon;
    if (this.state.hasUnsubscribed) {
      topIcon = <img src={success} alt="email" />;
    } else {
      topIcon = (
        <CloseIcon style={{ color: "red", height: "66px", width: "66px" }} />
      );
    }

    const successCTA = (
      <Typography className={this.props.classes.stylelink}>
        If you would like to subscribe to newsletter again, click{" "}
        <Link 
          data-test-id="resubscribe-cta"
          to="" 
          className={this.props.classes.link}
          onClick={this.handleResubscribeLinkClick}
        >
          here
        </Link>
      </Typography>
    );

    const errorCTA = (
      <Button 
        data-test-id="try-again-btn"
        className={this.props.classes.errorCTABtn}
        onClick={() => this.unsubscribeNewsletter(this.state.newsletterEmail)}
      >
        Try again
      </Button>
    );

    const loadingContent = (
      <Typography className={this.props.classes.accountemailmaintitle}>
        Unsubcription in progress...
      </Typography>
    );

    const mainContent = (
      <>
        {topIcon}
        <Typography className={this.props.classes.accountemailmaintitle}>
          {this.state.hasUnsubscribed ? "Success" : "Something went wrong"}
        </Typography>
        <Typography className={this.props.classes.accountemailsecondtitle}>
          {this.state.hasUnsubscribed ? SUCCESSMSG : ERRORMSG}
        </Typography>
        {this.state.hasUnsubscribed ? successCTA : errorCTA}
      </>
    );

    return (
      <Box className={this.props.classes.accountemailContainer}>
        <Box className={this.props.classes.accountlogocontainer}>
          <img src={logo} alt="logo" />
          <Link to="/">
            <CloseIcon style={{ color: "#FFFF" }} />
          </Link>
        </Box>
        <Paper elevation={3} className={this.props.classes.accountemailverify}>
          {this.state.isUnsubscribing ? loadingContent : mainContent}
        </Paper>
      </Box>
    );
  }
}

const styles = (theme: Theme) => ({
  accountcontainerStyle: {
    paddingTop: "75px",
  },
  accountemailContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#34373A",
    height: "100vh",
  },
  accountlogocontainer: {
    position: "relative",
    bottom: "110px",
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  accountemailverify: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    gap: "32px",
    width: "95%",
    height: "500px",
    left: "495px",
    top: "250px",
    background: "#FFFFFF",
    borderRadius: "16px",
    [theme.breakpoints.up("md")]: {
      width: "40%",
      height: "400px",
    },
  },
  accountemailmaintitle: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "125%",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "#0F172A",
  },
  accountemailsecondtitle: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "26px",
    textAlign: "center",
    letterSpacing: "0.005em",
    color: "#34373A",
  },
  stylelink: {
    color: "#0F172A",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "160%",
  },
  accountemailresend: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "160%",
    textAlign: "center",
    color: "#0F172A",
  },
  errorCTABtn: {
    textTransform: "capitalize",
  },
  link: {
    color: "#43D270", 
    textDecoration: "none",
  }
});

// @ts-ignore
export default withStyles(styles)(UnsubscribeNewsLetter);

// Customizable Area End
