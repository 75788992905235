import React from "react";
import { Container, Grid } from "@material-ui/core";
import Counter from "../Counter.web";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  blockContainer: {
    paddingTop: "40px",
    "@media(max-width: 768px)": {
      rowGap: "16px",
      padding: "20px 0",
    },
  },
  counterItem: {
    borderLeft: "2px solid #000",
    padding: "0 20px",
    fontFamily: "Montserrat",
  },
  number: {
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "52px",
    textTransform: "capitalize",
    "@media(max-width: 768px)": {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  title: {
    fontSize: "18px",
    lineHeight: "30px",
    marginTop: "4px",
    "@media(max-width: 768px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
}));

export default function Statistics({ items }: { items: any }) {
  const data = [
    {
      id: 1,
      title: items.data[0].attributes?.custom_label_1,
      number: +items.data[0].attributes?.custom_label_1_value,
    },
    {
      id: 2,
      title: items.data[0].attributes?.custom_label_2,
      number: +items.data[0].attributes?.custom_label_2_value,
    },
    {
      id: 3,
      title: items.data[0].attributes?.custom_label_3,
      number: +items.data[0].attributes?.custom_label_3_value,
    },
    {
      id: 4,
      title: items.data[0].attributes?.custom_label_4,
      number: +items.data[0].attributes?.custom_label_4_value,
    },
  ];
  const styles = useStyles();

  return (
    <Container>
      <Grid container className={styles.blockContainer}>
        {data.map((item) => (
          <Grid item xs={6} md={3} key={item.id} className={styles.counterItem}>
            <Counter
              end={item.number}
              duration={1000}
              className={styles.number}
            />
            <div className={styles.title}>{item.title}</div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
