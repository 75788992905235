import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 56,
		height: 32,
		overflow: "unset !important",
		padding: 0,
		margin: 0,
	},
	switchBase: {
		padding: 1,
		paddingLeft: "6px",
		paddingTop: "4px",
		"&$checked": {
			transform: "translateX(16px)",
			color: "#ffffff",
			paddingLeft: "12px !important",
			paddingTop: "4px !important",
			"& + $track": {
				backgroundColor: "#52d869",
				opacity: 1,
				borderRadius: "20px",
			},
		},
		"&$focusVisible $thumb": {
			color: "#52d869",
			border: "6px solid #fff",
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 40 / 2,
		border: `none !important`,
		backgroundColor: "#DFDFDF",
		opacity: 1,
		transition: theme.transitions.create(["background-color", "border"]),
	},
	checked: {},
	focusVisible: {},
}));

interface Props {
	handleChange: (index: number, id: number) => void;
	index: number;
	id: number;
	checked: boolean;
}

function SwitchButton(props: Props) {
	const classes = useStyles();
	const { checked, index, id, handleChange } = props;

	return (
		<FormControlLabel
			key={id}
			data-test-id="switch-test-button"
			control={
				<Switch
					classes={{
						root: classes.root,
						switchBase: classes.switchBase,
						thumb: classes.thumb,
						track: classes.track,
						checked: classes.checked,
					}}
					checked={checked}
					onChange={() => handleChange(index, id)}
					name="checked"
				/>
			}
			label={false}
		/>
	);
}

export default SwitchButton;
