import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import parse from "html-react-parser";
import WidgetHeadingText from "./WidgetHeadingText";
import Statistics from "./Statistics";

const useStyles = makeStyles(() => ({
  mainBlock: {
    marginTop: "60px",
    fontFamily: "Montserrat",
    "@media (max-width: 768px)": {
      marginTop: "32px",
    },
  },
  content: {
    padding: "40px",
    borderRadius: "16px",
    background: "linear-gradient(90deg, rgba(111, 102, 233, 0.75) 0%, rgba(249, 108, 48, 0.75) 100%)",
    "@media (max-width: 768px)": {
      padding: "20px",
    },
  },
  upperContent: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  leftBlock: {
    width: "calc(100% * 7 / 12)",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  rightBlock: {
    width: "calc(100% * 6 / 12)",
    "@media (max-width: 768px)": {
      width: "100%",
      margin: "20px 0",
    },
  },
  description: {
    lineHeight: "26px",
  },
  textLabel: {
    padding: "4px 12px",
    borderRadius: "2px",
    display: "inline-block",
    fontSize: "12px",
    fontWeight: 600,
    letterSpacing: "1.2px",
    textTransform: "uppercase",
    backgroundColor: "#43d270",
  },
  textLink: {
    display: "inline-block",
    borderBottom: "2px solid #fff",
    position: "relative",
    marginTop: "16px",
    fontSize: "14px",
    fontWeight: 600,
    cursor: "pointer",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "-2px",
      left: 0,
      width: "0",
      height: "2px",
      backgroundColor: "#43d270",
      transition: "all linear 0.3s",
    },
    "&:hover::before": {
      width: "100%",
    },
  },
}));

export default function AboutUs(props: any) {
  const {
    main_header,
    header,
    description,
    widget_link_text,
    widget_link,
    analytics_widget_items,
    navigate
  } = props;
  const styles = useStyles();
  return (
    <div className={styles.mainBlock}>
      <Container>
        <div className={styles.content}>
          <div className={styles.upperContent}>
            <div className={styles.leftBlock}>
              <div className={styles.textLabel}>{main_header}</div>
              <WidgetHeadingText text={header} />
            </div>
            <div className={styles.rightBlock}>
              <p className={styles.description}>{parse(description)}</p>
              <span data-test-id={'text-press'} onClick={() => navigate(widget_link)} className={styles.textLink}>
                {widget_link_text}
              </span>
            </div>
          </div>
          <Statistics items={analytics_widget_items} />
        </div>
      </Container>
    </div>
  );
}
