import React from "react";
// Customizable Area Start
import parse from 'html-react-parser';
import {
    Box,
    Typography,
    Grid,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    StepIconProps
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close'
import Carousel from 'react-material-ui-carousel'
import EmailAccountRegistration from "../../../email-account-registration/src/EmailAccountRegistaration.web";
import {logo,outerCircle,innerCircle} from "../assets"

 const theme = createTheme({
    typography:{
        fontFamily:'Lato'
    }
 })

 const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: "#43D270",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#43D270",
        },
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

function QontoStepIcon (props:StepIconProps) {
    const { active, completed } = props;
    return (
        <div
            style={{color: active ? "#43D270" :"#eaeaf0",
            display: "flex",
            height: 22,
            alignItems: "center"}}
        >
            {completed ? (
                <FiberManualRecordIcon style={{color: "#43D270",
                zIndex: 1,
                fontSize: 18}} />
            ) : (
                <div style={{width: 15,
                    height: 15,
                    borderRadius: "70%",
                    backgroundColor: "currentColor"}} />
            )}
        </div>
    );
}

// Customizable Area End

import SignUpController, {
    Props,
} from "./SignUpController";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

export class SignUp extends SignUpController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const steps = ["General", "Privacy"];
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 1, }} className={classes.mainBox}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} md={7} lg={7} style={{ backgroundColor: 'white' }}>
                        <Carousel
                            className={classes.corosol}
                            navButtonsAlwaysInvisible
                            animation={"slide"}
                            indicatorContainerProps={{
                                style: {
                                    textAlign: 'center',
                                    zIndex: 1,
                                    position: 'absolute',
                                    bottom: '60px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    maxWidth: '708px',
                                },
                                className: classes.carouselIndicator
                            }}
                            indicatorIconButtonProps={{
                                style: {
                                    color: '#5d5f61',
                                    width: '10px',
                                    height: '10px',
                                    marginRight: '10px'
                                }
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    color: '#FFFFFF'
                                }
                            }}
                        >
                            {this.state.dynamicData.dynamic_signups.map((item) =>
                                    <Box className={classes.dynamicContentBox}>
                                        <img src={logo} className={classes.logo}/>
                                        <Box className={classes.imageBox}>
                                        <Box className={classes.imageBox2}>
                                        <img src={outerCircle} alt="outerCircle" className={classes.outerCircle}/>
                                        <img src={innerCircle} alt="innerCircle" className={classes.innerCircle}/>
                                        <img src={item.signup_image} data-testId="ImageComponent" className={classes.cover} alt="content" key={item.position} />
                                        </Box>
                                        </Box>
                                        <Typography className={classes.dynamicTitle}>{item.title}</Typography>
                                        <Typography className={classes.dynamicDescription}>{parse(item.description.toString())}</Typography>
                                    </Box>
                                )
                            }
                        </Carousel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} style={{ backgroundColor: 'white' }}>
                        <Box className={classes.rightContent}>
                            <Box className={classes.closeicon}>
                                <Link to="/" style={{ color: 'black' }}>
                                    <CloseIcon style={{ color: 'black' }} />
                                </Link>
                            </Box>
                            <Typography className={classes.title}>Sign up for an account</Typography>
                            <Stepper
                                alternativeLabel
                                activeStep={this.state.activeStep}
                                connector={<QontoConnector />}
                                className={classes.stepper}
                            >
                                {steps.map((label, index) => (
                                    <Step key={label} className={classes.steps} onClick={() => this.handleSteps(index)}>
                                        <StepLabel StepIconComponent={QontoStepIcon}>
                                            <p className={classes.tittleName}>{label}</p>
                                            <p className={classes.stepStyle}>STEP {index + 1}</p>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <Box mb={5} className={classes.form}>
                                <Box className={classes.instructions}>
                                    <EmailAccountRegistration navigation={this.props.navigation} id={""} setActiveStep={this.setActiveStep as React.Dispatch<React.SetStateAction<number>>} activeStep={this.state.activeStep} />
                                </Box>
                            </Box>
                            {
                                this.state.activeStep === 0 &&
                                <Typography className={classes.logintxt}>Already have an account?
                                    <Link to="login/primary" className={classes.loginlink}> Log In</Link>
                                </Typography>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box >
            </ThemeProvider>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    tittleName: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    rightContent: {
        padding: '50px 30px 0 30px',
        maxWidth: '732px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            maxWidth: "852px"
        }
    },
    cover: {
        width: '100%',
        maxHeight: '250px',
        maxWidth: '250px',
        textAlign:'center',
        margin:'auto',
        position:'absolute',
        right:'135px',
        bottom:'103px',
        '@media(max-width:600px)':{
            maxHeight: '250px',
        maxWidth: '250px',
        right:'74px',
        bottom:'55px',
        }
    },
    title: {
        fontFamily: 'Montserrat !important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "46px",
        color: "#34373A",
        marginTop: '30px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            marginTop: '30px',
            textAlign:'center'
        }
    },
    stepStyle: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    form:{
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            width:'100%',
            display:'flex',
            alignItems:'center',
            placeContent:'center',
            marginBottom:'30px'
        }
    },
    instructions: {
        marginTop: "8px",
        marginBottom: "8px",
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            marginTop: '10px',
            width:"413px"
        }
    },
    logintxt: {
        fontFamily: 'Lato !important',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "160%",
        color: "#0F172A",
        textAlign: 'center',
        maxWidth: '404px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            maxWidth: '780px',
        }
    },
    loginlink: {
        color: '#43D270',
        textDecoration: 'none'
    },
    closeicon: {
        display: 'flex',
        justifyContent: 'end'
    },
    stepper: {
        maxWidth: '500px',
        padding: "24px 0 !important",
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            maxWidth: "800px"
        }
    },
    steps: {
        right: '51px',
        '@media(max-width: 1100px)': {
            right: '0',
        },
    },
    corosol: {
        maxWidth: "708px",
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            maxWidth: "852px"
        }
    },
    carouselIndicator:{
        '@media(max-width: 600px)':{
            marginBottom:"34px"
        },
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            marginLeft: '88px',
            bottom: '65px',
           
        }
    },
   dynamicContentBox:{
        background:'#34373A',
        height:'100%'
    },
    dynamicTitle:{
        color:'#fff',
        fontSize:'20px',
        fontWeight:600,
        textAlign:'center',
        lineHeight:'24px',
        marginTop:'20px'
    },
    dynamicDescription:{
        '& p':{
            color:'#fff',
            fontSize:'14px',
            textAlign:'center',
            lineHeight:'26px',
          }
    },
    imageBox:{
        textAlign:'center',
        width:'max-content',
        margin:'auto'
        
    },
    imageBox2:{
        position:'relative',
        width:'max-content'
    },
    logo:{
        padding:'40px 60px'
    },
    innerCircle:{
        position:'absolute',
        right:'0',
        left:'77px',
        bottom:'89px',
        '@media(max-width: 600px)':{
            left:'45px',
        bottom:'31px',
        width:"309px"
        }
        },
    outerCircle:{
        '@media(max-width:600px)':{
        width:"393px",
}
    },
    mainBox:{
        display: 'grid', 
        placeItems: 'center', 
        height: '100vh', 
        backgroundColor: '#e9eefa', 
    },
    gridContainer:{
        boxShadow: '4px 2px 7px #8C8C8C'
    }
});

export default withStyles(webStyle)(SignUp);

// Customizable Area End
