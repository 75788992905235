Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";
exports.contactusAPIEndPoint = "bx_block_custom_form/contacts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.contactusAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
exports.urlGetValidations = "profile/validations";

exports.endpointGetCompanyData = "account_block/get_company_data";
exports.endpointGetDepartment= "/account_block/department";
exports.DesignationsAPiEndPoint = "account_block/designation";
exports.APIMethodTypePost = "POST";
exports.APIMethodTypeGet = "GET";
exports.ContentTypeApplicationJson = "application/json";
exports.updateCompanyDataEndPoint = "/account_block/company";
exports.accountCreateEndPoint = "/account_block/accounts";
exports.tasklistEndPoint = "bx_block_dashboard/get_task_list";
exports.userNameValidation = "^[a-zA-Z0-9]{6,15}$";
exports.APIMethodTypePatch = "PATCH";
// Customizable Area End
