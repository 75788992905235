import React from 'react'
import {
    makeStyles,
    styled
} from "@material-ui/core/styles";
import { Grid, Container, Box, Button, Chip, CircularProgress } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RelatedPosts from './RelatedPosts.web';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {  
    facebookDarkIcon, 
    linkedInDarkIcon, 
    googleIcon, 
    pinterestIcon, 
    tumblrIcon, 
    twitterDarkIcon,
    youtubeDarkIcon,
    instragramDarkIcon,
    pinterestDarkIcon,
    telegramDarkIcon,
    redditDarkIcon,
    snapChatDarkIcon,
    tiktokDarkIcon,
    wechatDarkIcon,
    whatsappDarkIcon,
    tumblrDarkIcon,
    discordDarkIcon,
    viberDarkIcon,
} from "./assets/index";
import parse from 'html-react-parser';
import { removeTags } from '../../../blocks/DynamicContent/src/landingpage/LpDifferentFromOther';

export const IMAGE_HEIGHT = "516px";

export const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        margin: 'auto',
        width: '1230px'
    },

    IconStyle: {
        marginLeft: '8px',
        transform: "rotate(-45deg)"
    },

    IconStyleDownArrow: {
        marginLeft: '7px',
    },

    ArrowIconStyle: {
        color: '#28303F',
        width: '24px',
        height: '24px', 
        position: "relative",
        top: "20px",
        left: "0px",
        '@media(max-width: 600px)': {
            top: "0px",
        },
    },

    HeadingStyle: {
        display: 'flex',
        justifyContent: 'center', 
        textAlign: 'center',
        marginBottom: '70px',
        minHeight: "139px",
        flexDirection: "column",
    },

    ImageStyle: {
        borderRadius: "16px",
        marginBottom: "45px",
        '@media (max-width:900px)': {
            borderRadius: '8px',
        },
        '@media (max-width:600px)': {
            marginBottom: "20px",
        }
    },

    MarketImageStyle: {
        minHeight: '48px',
        maxWidth: "100%",
        '@media (max-width:900px)': {
            borderRadius: '8px',
        },
        '@media (max-width:600px)': {
            marginBottom: "20px",
        }
    },

    imageContainer: {
        float: "right",
        width: "507px",
        height: 'auto',
        padding: "0 0 20px 20px",
        '@media (max-width:960px)': {
            float: "unset",
            width: "100%",
            height: "auto",
            padding: "0 0 20px 0",
        }
    },

    ImageStyle1: {
        width: "100%",
        height: "auto",
        objectFit: "cover",
        borderRadius: "14px",
    },

    ShareIconStyle: {
        // display: 'none',
        padding: '0px',
        fontFamily: 'Lato',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0em',
        textAlign: 'left',
        textTransform: "uppercase"
    },

    ShareIconStyle2: {
        // display: 'none',
        padding: '0px',
    },

    ContentStyle: {
        marginBottom: '77px',
        flex: 1,
        flexDirection: 'row',
        '@media (max-width:600px)': {
            minWidth: "50px"
        }
    },

    paragraphStyle: {
        "& > p": {
            fontFamily: "Lato",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "32px",
            color: "#000000",
            marginBottom: "20px",
        },
        '@media (max-width:960px)': {
            minHeight: "unset !important"
        }
    },

    BoxStyle: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: '7px',
        marginBottom: '19px',
        marginRight: '20px'
    },

    contactButton: {
        backgroundColor: '#43D270 !important',
        width: '180px',
        fontFamily: "Lato",
        fontWeight: 500,
        height: '56px',
        lineHeight: "normal",
        letterSpacing: "0.4px",
        marginRight: "20px",
        '@media (max-width:600px)': {
            width: 'auto',
            minWidth: "130px"
        }
    },

    registerButton: {
        backgroundColor: '#7166F1 !important',
        width: '180px',
        letterSpacing: "0.4px",
        height: '56px',
        marginRight: "20px",
        color: "white",
        fontFamily: "Lato",
        lineHeight: "normal",
        fontWeight: 500,
        '@media (max-width:600px)': {
            width: 'auto',
            minWidth: "130px"
        }
    },

    websiteButton: {
        backgroundColor: '#FFFFFF',
        borderColor: "black",
        letterSpacing: "0.4px",
        width: '210px',
        fontFamily: "Lato",
        fontWeight: 500,
        lineHeight: "normal",
        height: '56px',
        '@media (max-width:600px)': {
            width: 'auto',
            minWidth: "130px"
        }
    },

    ButtonBoxSTyle: {
        marginTop: '39px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '50px',
        '@media (max-width:600px)': {
            marginBottom: '35px',
            marginTop: '35px',
        },
    },

    firstGrid: {
        marginBottom: "70px",
        position: "relative",
        '@media (max-width:768px)': {
            marginBottom: '30px',
        }
    },

    secondGrid: {
        marginTop: "70px",
        marginBottom: '62px'
    },

    IconBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: "60px",
        alignItems: 'center',
        '@media(max-width: 768px)': {
            flexDirection: "column",
            gap: "20px",
            marginBottom: "20px",
        },
    },

    imgStyle: {
        textAlign: 'center',
    },

    GridStyle: {
        marginTop: '70px'
    },

    IconStyle2: {
        display: 'flex',
        flexWrap: "wrap",
        borderRadius: '0px',
        fontFamily: 'Lato',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0em',
        textAlign: 'left',
        gap: "16px",
        // marginBottom: "20px",
    },

    IconStyle3: {
        display: 'flex',
        borderRadius: '0px',
        fontFamily: 'Lato',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0em',
        textAlign: 'left',
        '@media(max-width: 600px)': {
            marginBottom: "20px",
        }
    },

    mobileStyle: {
        '@media(max-width: 900px)': {
            display: 'grid'
        }
    },
    miniContainer: {
        maxWidth: "870px",
        margin: "0 auto",
        padding: "0px"
    },
    metaInfoBox: {
        display: "flex",
        justifyContent: "space-between"
    },
    metaInfoTime: (props: any) => ({
        color: props.tagColor
    }),
    tagStyle: {
        marginLeft: "5px",
        textTransform: "capitalize",
    },

    lineBreakStyle: {
        width: '869px',
        height: '1px',
        top: '1228px',
        left: '289px',
        Opacity: '10%',
        background: 'red'
    },
    tagCompanyAuthorUl: {
        paddingTop: "70px",
        display: "flex",
        alignItems: "center",
        listStyle: "none",
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",
        color: "#595959",
        margin: "0",
        placeContent:"center",
        paddingLeft: '0px',
        "& > li": {
            position: "relative",
        },
        "& > li:not(:last-child)::after": {
            content: "'•'",
            margin: "0 10px",
            fontFamily: "Lato",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "25.2px",
            color: "#595959"
        }
    },
    topGradiant: {
        width: "100%",
        height: "750px",
        zIndex: -1,
        position: "absolute",
        background: "linear-gradient(0deg, #fff 0%, #e5eaff 100%)"
    },
    tagNameContainer: {
        display: "flex",
        flexWrap: "wrap", 
        gap: "10px",
    },
    tagName: {
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "25.2px",
        textAlign: "center",
        color: "#6F66E9",
        backgroundColor: "#E0E0FA",
        padding: "9px 15px",
        borderRadius: "4px"
    },
    arrowContianer: {
        display: "flex",
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "25.2px",
        color: "#393939"
    },
    nextPrevBlogTitle: {
        fontFamily: "Montserrat",
        fontSize: "26px",
        fontWeight: 600,
        lineHeight: "36.4px",
        color: "#252525",
        marginTop: "10px",
    },
    socialIcon: {
        maxWidth: "42px",
        maxHeight: "42px",
    }
})

export const HeadingText = styled("h1")({
    color: '#000000',
    fontSize: '48px',
    fontWeight: 800,
    fontFamily: 'Lato',
    lineHeight: '67.2px',
    textAlign: 'center',
    '@media(max-width: 600px)': {
        fontSize: '28px',
        lineHeight: '30px',
    },
});

const PrimaryContentText = styled(Typography)({
    color: '727272',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    lineHeight: '13px',
})

const SecondryContentText = styled(Typography)({
    color: '#43D270',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    lineHeight: '13px',
    textAlign: 'right',
    textTransform: 'uppercase'
})

const Button1 = styled(Button)({
    fontSize: '15px',
    fontWeight: 500,
    fontFamily: 'lato',
    lineHeight: '18px',
    borderRadius: '8px',
    '& .MuiButton-label': {
        marginInline: '25px',
        marginBlock: '19px'
    },
    '@media(max-width: 900px)': {
        '& .MuiButton-label': {
            marginInline: '5px',
            marginBlock: '5px'
        },
    },
})

export const ProgressContainer = styled("div")({
    color: "grey", 
    height: "calc(100vh - 145px)", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center"
});

export const socialIcons = {
    Facebook: facebookDarkIcon,
    Twitter: twitterDarkIcon,
    LinkedIn: linkedInDarkIcon,
    Google: googleIcon,
    Pinterest: pinterestDarkIcon,
    YouTube: youtubeDarkIcon,
    Instagram: instragramDarkIcon,
    Telegram: telegramDarkIcon,
    Reddit: redditDarkIcon,
    Snapchat: snapChatDarkIcon,
    TikTok: tiktokDarkIcon,
    WeChat: wechatDarkIcon,
    WhatsApp: whatsappDarkIcon,
    Tumblr: tumblrDarkIcon,
    Discord: discordDarkIcon,
    Viber: viberDarkIcon,
}

export function getSocialLink(linkName: string) {
    switch (linkName) {
        case "Twitter":
            return "https://x.com";
        case "YouTube":
            return "https://www.youtube.com";
        case "LinkedIn":
            return "https://www.linkedin.com/login";
        case "Instagram":
            return "https://www.instagram.com/";
        case "Pinterest":
            return "https://www.pinterest.com/";
        case "Telegram":
            return "https://telegram.org/";
        case "Reddit":
            return "https://www.reddit.com/";
        case "Snapchat":
            return "https://www.snapchat.com/";
        case "TikTok":
            return "https://www.tiktok.com/en/";
        case "WhatsApp":
            return "https://web.whatsapp.com/";
        case "WeChat":
            return "https://www.wechat.com/";
        case "Tumblr":
            return "https://www.tumblr.com/";
        case "Discord":
            return "https://discord.com/";
        case "Viber":
            return "https://www.viber.com/en/";
        default:
            return "";
    }
    
}

interface PropTypes {
    tag?: string;
    tagColor?: string;
    heading?: string;
    date?: string;
    author?: string;
    eventTime?: string;
    description?: string;
    button1Label?: string;
    button1Link?: string;
    button2Label?: string;
    button2Link?: string;
    primaryButtonBg?: string;
    secondaryButtonBg?: string;
    primaryButtonColor?: string;
    enableButtons?: boolean;
    postType?: string,
    data?: any,
    viewDetails?: any,
    isLoading?: boolean;
}

const BlogDetails = ({ enableButtons, postType, data, viewDetails, isLoading }: PropTypes) => {
    const classes = useStyles({ enableButtons, postType });
    const attributes = data?.attributes;
    const postId = data?.id;

    // const tags = `${window?.location?.pathname?.toLowerCase()?.replace(/[0-9/]+/g, '').replace("/", "")}`
    const fullPath = window.location.pathname;
    const pathParts = fullPath.split("/");
    const jobOpeningsListPath = `/${pathParts[1]}`;
    const tagCompanyAuthorInfo = [];
    attributes?.resource_tag_id && tagCompanyAuthorInfo.push(attributes.resource_tag_id);
    attributes?.compay && tagCompanyAuthorInfo.push(attributes.compay);
    attributes?.author && tagCompanyAuthorInfo.push(`By ${attributes.author}`);

    const tagCompanyAuthorContent = (
        <ul className={classes.tagCompanyAuthorUl}>
            {tagCompanyAuthorInfo.map(info => {
                return <li key={info}>{info}</li>
            })}
        </ul>
    );

    if (isLoading) {
        return (
            <ProgressContainer>
              <CircularProgress color="inherit" />
            </ProgressContainer>
        );
    }

    return (
        <Box>
            <Box className={classes.topGradiant}></Box>
            <Container>
                {tagCompanyAuthorContent}
                <Grid className={classes.firstGrid}>
                    <Container className={classes.miniContainer}>
                        {attributes?.title &&
                            <Box className={classes.HeadingStyle}>
                                <HeadingText>{removeTags(attributes?.title)}</HeadingText>
                            </Box>
                        }
                    </Container>

                    {attributes?.description &&
                        <Box>
                            {jobOpeningsListPath !== "/job-openings-list" && <Box className={classes.ImageStyle}>
                                {
                                    attributes?.insight_image &&
                                    <Box className={classes.imageContainer}>
                                        <img 
                                            src={attributes.insight_image} 
                                            alt="blog image" 
                                            className={
                                                postType === "marketplace" ? classes.MarketImageStyle : classes.ImageStyle1
                                            } 
                                        />
                                    </Box>
                                }
                                <Box 
                                    className={classes.paragraphStyle}
                                    style={{ minHeight: attributes?.insight_image ? IMAGE_HEIGHT: "unset", }}
                                >
                                    {parse(attributes?.description)}
                                </Box>
                            </Box>}
                        </Box>
                    }

                    <Box className={classes.IconBoxStyle}>
                        <Box className={classes.tagNameContainer}>
                            {attributes?.tag_names.map((tagName: string) => {
                                return <Typography className={classes.tagName}>{tagName}</Typography>
                            })}
                        </Box>
                        <Box className={classes.IconStyle2}>
                            {attributes?.social_media_links.map((iconName: string) => {
                                const socialUrl = getSocialLink(iconName);
                                return (<IconButton onClick={() => window.open(socialUrl, "_blank")} className={classes.ShareIconStyle2}>
                                    <img className={classes.socialIcon} src={socialIcons[iconName as keyof typeof socialIcons]} />
                                </IconButton>)
                            })}
                        </Box>
                        {jobOpeningsListPath === "/job-openings-list" && <Box className={classes.IconStyle3}>
                            <Chip className={classes.tagStyle} label={attributes?.resource_tag_id} />
                        </Box>}
                    </Box>
                </Grid>
                <hr style={{ color: "#000000", opacity: '20%', margin: "0px", }}></hr>
                <Grid 
                    container 
                    justifyContent='space-between'
                    style={{ padding: "65px 0" }}
                    spacing={2}
                >
                    <Grid item md={6} xs={12}>
                        {attributes?.prev_item && (
                            <Box 
                                style={{ cursor: "pointer" }} 
                                onClick={() => viewDetails(attributes.prev_item.id, "/insights-and-trends", true)}
                            >
                                <Box className={classes.arrowContianer}>
                                    <ChevronLeftIcon />
                                    Previous
                                </Box>
                                <Typography 
                                    className={classes.nextPrevBlogTitle}
                                >
                                    {attributes.prev_item.item}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {attributes?.next_item && (
                            <Box
                                style={{ cursor: "pointer" }} 
                                onClick={() => viewDetails(attributes.next_item.id, "/insights-and-trends", true)}
                            >
                                <Box justifyContent="flex-end" className={classes.arrowContianer}>
                                    Next
                                    <ChevronRightIcon />
                                </Box>
                                <Typography 
                                    className={classes.nextPrevBlogTitle}
                                    style={{ textAlign: "right" }}
                                >
                                    {attributes.next_item.item}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <hr style={{ color: "#000000", opacity: '20%', margin: "0px", }}></hr>
            </Container>
            {jobOpeningsListPath !== "/job-openings-list" && 
                <RelatedPosts
                    heading="recent new articles"
                    subHeading=""
                    data={attributes?.other_resources.event_items.data}
                    viewDetails={viewDetails}
                />
            }
        </Box>
    )
}


export default BlogDetails