import React from "react";
import {
  makeStyles,
  Typography,
  styled,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import { checkboxIcon, checkboxIconActive } from "../assets";

const RadioLabelStyled = styled(Typography)({
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "6px",
  fontSize: "15px !important",
});

const FormControlLabelStyled = styled(FormControlLabel)({
  marginBottom: "2px",
  fontSize: "14px",
  lineHeight: "22px",
  width: "100%",
  color: "#34373A",
  '& *': {
    fontSize: "14px",
    lineHeight: "22px",
  },
  '& :hover': {
    color: "#7D7D7F !important",
    '& img': {
      filter: "brightness(1.2) saturate(1.2) hue-rotate(0deg)"
    }
  },
});

const RadioGroupStyled = styled(Box)({
  marginBottom: "40px",
  paddingBottom: "20px",
  borderBottom: "1px solid #d9d9d9"
});

const useStyles = makeStyles((theme) => ({
  RadioFieldStyle: {
    '& *': {
      fontFamily: "Lato",
      fontWeight: 400,
    },
  },
  radio: {
    '& svg': {
      fontSize: "18px",
      color: "#D9D9D9",
    },
    '&$checked': {
      '& svg': {
        color: '#43D270 !important'
      },
    }
  },
  checkboxAlign: {
    display:"flex", 
    alignItems:"flex-start"
  },
  checked: {}
}));


interface RadioFieldStyledPropsType {
  onChange: any;
  value: string;
  options: {
    id: number;
    value: number;
    label: string;
  }[];
  label: string;
  name: string;
  testId?: string;
}

const RadioFieldStyled: React.FC<RadioFieldStyledPropsType> = ({
  onChange,
  options,
  label,
  name,
  testId,
  value,
}) => {
  const classes = useStyles();
  return (
    <>
      <RadioGroupStyled className={classes.RadioFieldStyle}>
        <RadioLabelStyled>{label}</RadioLabelStyled>
        <RadioGroup aria-label={name} name={name} value={value ? value : ""} onChange={onChange}>
          {options && options.map((option) => {
            if (option.value) {
              return <FormControlLabelStyled
                key={`option${option.value}`}
                className={classes.checkboxAlign}
                control={
                  <Radio
                  style={{paddingTop: '4px'}}
                    disableRipple
                    icon={<img src={checkboxIcon} alt="" />}
                    checkedIcon={<img src={checkboxIconActive} alt="" />}
                    classes={{ root: classes.radio, checked: classes.checked }}
                    data-test-id={testId}
                  />}
                label={option.label}
                value={option.value.toString()}
              />
            }
            return <></>
          })}
        </RadioGroup>
      </RadioGroupStyled>
    </>
  );
};

export default RadioFieldStyled;
