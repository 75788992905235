import React from "react";
import { Typography, Paper, Dialog, styled } from "@material-ui/core";
import { sadFace } from "../assets";

const CustomPaper = styled(Paper)({
  borderRadius: 16,
  padding: 40,
  '@media(max-width: 425px)': {
      padding: "30px 10px"
  }
});

interface Props {
  open: boolean;
  onClose: () => void;
  onCtaClick: () => void;
}

export default function AccessRestrictedDialog({ 
  open, 
  onClose,
  onCtaClick,
}: Props) {
  return (
    <Dialog
      data-test-id="access-restricted-dialog"
      open={open}
      onClose={onClose}
      PaperComponent={CustomPaper}
    >
      <img src={sadFace} style={webStyle.dialogImg} alt="" />
      <Typography style={webStyle.dialogTitle}>
        Access Restricted
      </Typography>
      <Typography style={webStyle.dialogContent}>
        We're sorry, you don't have permission to access this page. Please
        reach out to your ESG Admin if you believe you need access or
        require further assistance.
      </Typography>
      <Typography style={webStyle.ctaText}>
        Go to{" "}
        <span style={webStyle.ctaLink} onClick={onCtaClick}>
          To Do List
        </span>{" "}
        page
      </Typography>
    </Dialog>
  );
}

const webStyle = {
  dialogImg: {
    width: "200px",
    height: "200px",
    margin: "0 auto"
  } as React.CSSProperties,
  dialogTitle: {
    alignSelf: "stretch",
    color: "var(--greyscale-900, #0F172A)",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "125%",
    letterSpacing: "0.2px"
  } as React.CSSProperties,
  dialogContent: {
    alignSelf: "stretch",
    color: "#34373A",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0.085px",
    margin: "20px 0 30px 0"
  } as React.CSSProperties,
  ctaText: {
    color: "var(--greyscale-900, #0F172A)",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%"
  } as React.CSSProperties,
  ctaLink: {
    color: "#43D270",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "160%",
    cursor: "pointer"
  },
  emailLink: {
    color: "#43D270",
    cursor: "pointer"
  }
};