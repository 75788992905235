import React from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";

interface Props {
  position: L.LatLngExpression;
  icon: string;
  children?: React.ReactNode;
  iconSize: [number, number];
  iconAnchor: [number, number];
}

export default function CustomMarker(props: Props) {
  const customIcon = L.icon({
    iconUrl: props.icon,
    iconSize: props.iconSize,
    iconAnchor: props.iconAnchor, // [left/right, top/bottom]
  });

  return (
    <Marker position={props.position} icon={customIcon}>
      {props.children}
    </Marker>
  );
}