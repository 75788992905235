import React, { CSSProperties } from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import Layout from "../../dashboard/src/Layout.web";
import { arrow } from "./assets"

const TitleText = styled(Typography)({
    color: '#34373A',
    fontSize: '22px',
    fontWeight: 700,
    fontFamily: 'Lato',
    lineHeight: '46px',
    textAlign: 'left',
    '@media(max-width: 600px)': {
        fontSize: '18px',
        lineHeight: '30px',
    },
});

const TagText = styled(Typography)({
    color: '#43D270',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    lineHeight: '13px',
    textAlign: 'left',
    textTransform: 'capitalize'
})

const LinkButton = styled(Button)({
    fontSize: '15px',
    fontWeight: 500,
    fontFamily: 'lato',
    lineHeight: '18px',
    borderRadius: '8px',
    color: '#101010',
    '& .MuiButton-label': {
        marginInline: '25px',
        marginBlock: '19px'
    },
    '@media(max-width: 900px)': {
        '& .MuiButton-label': {
            marginInline: '5px',
            marginBlock: '5px'
        },
    },
})

const FullContainer = styled(Container)({
    maxWidth: "870px",
    margin: "0 auto",
    padding: "0px",
    marginTop: '32px',
    marginLeft: "44px",
    '@media(max-width: 900px)': {
        padding: "10px !important",
        marginLeft: "0px !important",
    },
})
// Customizable Area End

import VendoruseraccountmarketplacehyperlinksController, {
    Props,
    configJSON,
} from "./VendoruseraccountmarketplacehyperlinksController";

export default class MarketplaceDetails extends VendoruseraccountmarketplacehyperlinksController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const pageid = this.props.navigation.getParam("id")
        this.getmarketplacDetailseData(pageid)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { marketplaceDetails } = this.state

        return (
            <Layout>
                <FullContainer>
                    <Box style={webStyle.TitleStyle as CSSProperties}>
                        <TitleText>{marketplaceDetails.title}</TitleText>
                    </Box>
                    <Box style={webStyle.DescStyle as CSSProperties}>
                        <TagText style={{ color: configJSON.Colors[marketplaceDetails.category] || "#F96C30" }} >{marketplaceDetails.category}</TagText>
                    </Box>
                    <Box style={webStyle.ImageContainer}>
                        <img src={marketplaceDetails.image} alt=""
                            style={webStyle.mainImageStyle1} />
                    </Box>
                    <Box style={webStyle.paraStyle as CSSProperties}>
                        {parse(marketplaceDetails.description ?? "")}
                    </Box>
                    <Box style={webStyle.visitBoxStyle}>
                        <a href={marketplaceDetails.link} target="_blank" style={{ textDecoration: 'none', }}>
                            <LinkButton style={webStyle.outlinedButton as any} variant='outlined'>Visit Website
                                <img src={arrow} alt="" style={{ position: 'relative', left: '27px' }} />
                            </LinkButton>
                        </a>
                    </Box>
                </FullContainer>
            </Layout >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    TitleStyle: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: '29px',
        flexDirection: "column",
    },
    DescStyle: {
        marginBottom: '22px',
        flex: 1,
        flexDirection: 'row',
    },
    mainImageStyle1: {
        maxWidth: "144px",
        width: "100%",
    },
    paraStyle: {
        color: '#34373A',
        fontSize: '15px',
        fontWeight: 400,
        fontFamily: 'Lato',
        lineHeight: '26px',
        textAlign: 'left',
        marginBottom: "40px",
        marginTop: '29px'
    },
    visitBoxStyle: {
        marginTop: '39px',
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '50px',
    },
    outlinedButton: {
        backgroundColor: '#FFFFFF',
        borderColor: "black",
        letterSpacing: "0.4px",
        minWidth: '210px',
        fontFamily: "Lato",
        fontWeight: 500,
        lineHeight: "normal",
        height: '40px',
        display: "flex",
        padding: "16px 12px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        textTransform: 'none',
        color: '#101010',
        textAlign: "center"
    },
    ArrowIconStyle: {
        marginLeft: '8px',
        transform: "rotate(-45deg)"
    },
    ImageContainer: {
        Width: '337px',
        height: "237px",
        padding: "94px 101px 95px 91px",
    },
};
// Customizable Area End