import React from "react";

import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { reports, repot_active, framwork_active, framworkicon, selected_unsdg, selected_unsdg_active } from "./assets";
import Reports from "./pages/Reports.web";
import ReportsFramwork from "./pages/ReportsFramwork.web";
import Layout from "../../dashboard/src/Layout.web";
import SelectedUnsdgGoals from "./pages/SelectedUnsdgGoals.web";

const InnerContainer = styled("div")({
	width: "100%",
	height: "auto",
	padding: "24px 37px 44px 24px"
});

const ReportTabsSection = styled("div")({
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
	gap: "8px",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "32px",
	marginLeft:'21px',
	'@media(max-width: 450px)': {
		alignItems: 'end'
	},
});

const ReportTabItem = styled("div")({
	display: "flex",
	justifyContent: "center",
	padding: "8px 12px",
	alignItems: "center",
	gap: "8px",
	flexShrink: 0,
	borderRadius: "8px",
	backgroundColor: "#FFF",
	color: "grey",
	fontSize: "15px",
	height: "40px",
	cursor: "pointer",
});

const ReportTabItemSelected = styled("div")({
	display: "flex",
	justifyContent: "center",
	padding: "8px 12px",
	alignItems: "center",
	gap: "8px",
	flexShrink: 0,
	borderRadius: "8px",
	backgroundColor: "#ECFBF1",
	color: "grey",
	fontSize: "15px",
	cursor: "pointer",
});

const ReportTabText = styled(Typography)({
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 400,
	letterSpacing: "0.2px",
	cursor: "pointer",
});

const ReportTabTextSelected = styled(ReportTabText)({
	color: "#329E54",
	fontWeight: 700,
});

const Icon = styled("span")({
	width: "20px",
	height: "20px",
});

const ReportTabContent = styled('div')({
	width: "100%",
})

const ReportTabLink = styled(Link)({
	width: "100%",
	display: "flex",
	justifyContent: "center",
	textDecoration: "none",
	alignItems: "center",
	gap: "8px",
	flexShrink: 0,
})

const ReportTabs = styled('div')({
	maxWidth: '404px',
	display: 'flex',
	gap: '8px',
	'@media(max-width: 450px)': {
		flexDirection: 'column',
		alignItems: 'start'
	},
})


const OrgBaseUrl = "/transform";
// Customizable Area End

import Multiplelandingpage2Controller, {
  Props,
  configJSON,
} from "./Multiplelandingpage2Controller";

export default class Report extends Multiplelandingpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
	// Customizable Area Start

  const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'reports';

		const tabsData = [
			{
				id: 1,
				text: configJSON.SelectedUNSGs,
				icon: selected_unsdg,
				activeIcon: selected_unsdg_active,
				component: () => <SelectedUnsdgGoals showSelectedGoals navigation={this.props.navigation} id={""} />,
				isActive: activeTab === "selected-unsdgs",
				link: "/reporting/selected-unsdgs"
			},
			{
				id: 2,
				text: configJSON.Reports,
				icon: reports,
				activeIcon: repot_active,
				component: <Reports navigation={this.props.navigation} id={""}/>,
				isActive: activeTab === "reports",
				link: "/reporting/reports"
			},
			{
				id: 3,
				text: configJSON.ReportingFramework,
				icon: framworkicon,
				activeIcon: framwork_active,
				component: <ReportsFramwork navigation={this.props.navigation} id={""}/>,
				isActive: activeTab === "reporting-framework",
				link: "/reporting/reporting-framework"
			},
		];
    return (
      <>
				<Layout>
					<InnerContainer>
						<ReportTabsSection>
							<ReportTabs>
								{tabsData.map((ReportTab) => {
									return ReportTab.isActive ? (
										<ReportTabItemSelected
											key={`selected_tab_${ReportTab.id}`}
										>
											<Icon><img src={ReportTab.activeIcon} alt="" /></Icon>
											<ReportTabTextSelected>{ReportTab.text}</ReportTabTextSelected>
										</ReportTabItemSelected>
									) : (
										<ReportTabItem
											key={`noneselected_tab_${ReportTab.id}`}
										>
											<ReportTabLink to={OrgBaseUrl + ReportTab.link}>
												<Icon><img src={ReportTab.icon} alt="" /></Icon>
												<ReportTabText>{ReportTab.text}</ReportTabText>
											</ReportTabLink>
										</ReportTabItem>
									);
								})}
							</ReportTabs>
						</ReportTabsSection>

						{tabsData.map((content) => {
							return <ReportTabContent key={`content${content.id}`}>{content.isActive ? content.component : ""}</ReportTabContent>
						})}
					</InnerContainer>
				</Layout>
			</>
    );
	// Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
