Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'appointment_management/availabilities'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.ApponntmentsAPiEndPoint = 'bx_block_appointment_management/expert_meeting_list'
exports.availabletimeAPiEndPoint = 'bx_block_appointment_management/get_available_timeslots'
exports.availableDateAPiEndPoint = 'bx_block_appointment_management/get_available_dates'
exports.cancleShedualAPiEndPoint = 'bx_block_appointment_management/delete_meeting'
exports.createShedualAPiEndPoint = 'bx_block_appointment_management/create_meeting'
exports.ReShedualAPiEndPoint = 'bx_block_appointment_management/reschedule_meeting'
exports.Colors = {
  scheduled: "#329E54",
  completed: "#34373A",
}
exports.status = {
  scheduled: "Scheduled",
  completed: "Completed",
}
// Customizable Area End
