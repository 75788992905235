import React, { CSSProperties } from "react";

// Customizable Area Start
import {
    Box,
    Paper,
    Typography,
    Container
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import { logo, email } from "./assets";
import { Link } from 'react-router-dom';
// Customizable Area End

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class ResendEmail extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <Box style={webStyle.emailContainerResend as CSSProperties}>
                    <Box style={webStyle.logocontainervvResend as CSSProperties}>
                        <img src={logo} alt="logo" />
                        <Link to="/forgot-password">
                            <CloseIcon style={{ color: '#FFFF' }} />
                        </Link>
                    </Box>
                    <Paper elevation={3} style={webStyle.emailverifyResend as CSSProperties}>
                        <img src={email} alt="email" />
                        <Typography style={webStyle.emailmaintitle as CSSProperties}>
                            {
                                this.state.isEmailSent ? "Success" : "Verify your Email"
                            }
                        </Typography>
                        <Typography style={webStyle.emailsecondtitle as CSSProperties}>
                            {
                                this.state.isEmailSent ?
                                    "Thank you, Email Resend Successfully check your email and click on the Reset Password link to confirm your account"
                                    : "Thank you, check your email and click on the Reset Password link to confirm your account"
                            }
                        </Typography>
                        <Typography style={webStyle.emailresend as CSSProperties}>
                            {
                                this.state.isEmailSent ? `Resend in ${this.state.time.m} : ${this.state.time.s}` :
                                    <>
                                        Don’t receive Reset password email?
                                        <button type="submit"
                                            style={{ color: '#43D270', border: 'none', backgroundColor: '#FFFF', cursor: 'pointer' }}
                                            onClick={() => this.handleResend()}>Resend</button>
                                    </>
                            }
                        </Typography>
                    </Paper>
                </Box>
            </ThemeProvider >
        );
    }
}

const webStyle = {
    emailContainerResend: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#34373A',
        height: "100vh",
    },
    logocontainervvResend: {
        position: "relative",
        bottom: "18%",
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
    },
    emailverifyResend: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
        gap: "32px",
        maxWidth: "450px",
        height: "400px",
        left: "495px",
        top: "250px",
        background: "#FFFFFF",
        borderRadius: "16px"
    },
    emailmaintitle: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "125%",
        textAlign: "center",
        letterSpacing: "0.2px",
        color: "#0F172A",
    },
    emailsecondtitle: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "17px",
        lineHeight: "26px",
        textAlign: "center",
        letterSpacing: "0.005em",
        color: "#34373A",
    },
    emailresend: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "160%",
        textAlign: "center",
        color: "#0F172A",
    }
};
