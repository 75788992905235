Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfclimatiqbasicestimate";
exports.labelBodyText = "cfclimatiqbasicestimate Body";

exports.btnExampleTitle = "CLICK ME";
exports.postAPiMethod = "POST";
exports.getEnergyConsumptionDetailsAPiEndPoint = "bx_block_dashboard/detailed_first_log";
exports.getRegionAPiEndPoint = "bx_block_cfclimatiqbasicestimate/get_climatiq_regions";
exports.getEnergyUnitsAPiEndPoint = "bx_block_cfclimatiqbasicestimate/get_climatiq_energy_units";
exports.getEnergySourceAPiEndPoint = "bx_block_cfclimatiqbasicestimate/get_energy_sources";
exports.calculateCo2APiEndPoint = "bx_block_cfclimatiqbasicestimate/climatiq_basic_estimate_batch";
exports.saveMetricsAPiEndPoint = "bx_block_dashboard/materiality_answers";
// Customizable Area End