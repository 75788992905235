import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core';
import RelatedBlogListingSection from '../../../blocks/DynamicContent/src/RelatedBlogListingSection.web';
import RelatedEventBlogListingSection from '../../../blocks/DynamicContent/src/RelatedEventBlogListingSection.web';
import RelatedMarketplaceBlogListingSection from "../RelatedMarketplaceBlogListingSection";

const useStyles = makeStyles({
    youmaylikecontainer: {
        paddingTop: "84px",
        '@media(max-width: 600px)': {
            paddingTop: "40px",
        },
    },
    mainHeading: {
        fontSize: '48px',
        fontWeight: 700,
        fontFamily: 'Lato',
        lineHeight: '67.2px',
        color: '#000000',
        textAlign: "center",
        textTransform: "capitalize",
        '@media(max-width: 600px)': {
            fontSize: '28px',
            lineHeight: '30px',
            marginBottom: "30px",
        },
    },
    subHeading: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",
        color: "#595959",
        textAlign: "center",
        textTransform: "capitalize",
        margin: "15px 0 50px 0",
    }
});

const RelatedPosts = (props: any) => {
    const classes = useStyles();

    const { heading, subHeading, data, viewDetails, ...rest } = props;
    let relatedBlogListingContent;

    if (props.containerPage === "events") {
        relatedBlogListingContent = (
            <RelatedEventBlogListingSection
                enableButton={true}
                limit={3}
                data={data}
                viewDetails={viewDetails}
                {...rest}
            />
        );
    } else if (props.containerPage === "marketplace") {
        relatedBlogListingContent = (
          <RelatedMarketplaceBlogListingSection
            enableButton={true}
            limit={3}
            data={data}
            viewDetails={viewDetails}
            {...rest}
          />
        );
    } else {
        relatedBlogListingContent = (
            <RelatedBlogListingSection
                enableButton={true}
                limit={3}
                data={data}
                viewDetails={viewDetails}
                {...rest}
            />
        );
    }

    return (
        <Container maxWidth="lg" className={classes.youmaylikecontainer} >
            <Typography variant="h2" className={classes.mainHeading} >{heading ?? "You may also like"}</Typography>
            <Typography className={classes.subHeading} >{subHeading}</Typography>
            {relatedBlogListingContent}
        </Container>

    );
}
export default RelatedPosts;