import React from "react";

import {
  // Customizable Area Start
  Box,
  Input,
  Button,
  Typography,
  Select,
  MenuItem,
  Modal,
  Snackbar,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { editImg, crossImage } from "../assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
 },
 typography: {
    h6: {
    fontWeight: 500,
    },
   subtitle1: {
      margin: "20px 0px",
   },
 },
});

const StyledBox = styled(Box)({
 display: "flex",
 width: "100%",
 gap: "389px",
 justifyContent: "space-between",
 '@media(max-width: 1200px)': {
   gap: "20px",
 },
});

const StyledBox2 = styled(Box)({
 alignItems: "center",
 gap: "24px",
 display: "flex",
 '@media(max-width: 700px)': {
   flexDirection: "column"
 },
});
const StyledBox4= styled(Box)({
  '@media(max-width: 700px)':{
    width:"100% !important",
    },
 });
 const StyledBox5= styled(Box)({
  '@media(max-width: 700px)':{
    width:"100% !important",
  },
 });
 const StyledBox6= styled(Box)({
  '@media(max-width: 700px)':{
    width:"100% !important",
  },
 });
const StyledBox3 = styled(Box)({
 justifyContent: "space-between",
 alignItems: "flex-start",
 width: "95%",
 display: "flex",
 '@media(max-width: 700px)': {
   flexDirection: "column"
 },
});

const DivWrapper = styled(Box)({
   width: "28%",
   padding: "16px",
   borderRadius: "12px",
   border: "0.6px solid rgba(0, 0, 0, 0.18)",
   background: "#FEFEFF",
   boxShadow: "4px 8px 20px 0px rgba(0, 0, 0, 0.08)",
   position: "absolute",
   left: "40%",
   top: "100px",
   height: "593px",
 '@media(max-width: 700px)': {
   width: "90%",
   left: "5%"
 },
});

const VisuallyHiddenInput = styled('input')({
 clip: 'rect(0 0 0 0)',
 clipPath: 'inset(50%)',
 height: 1,
 overflow: 'hidden',
 position: 'absolute',
 bottom: 0,
 left: 0,
 whiteSpace: 'nowrap',
 width: 1,
});

const StyledError = styled('p')({
  color: "#F5222D",
  fontFamily: "Lato",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px"
})
// Customizable Area End

import DashboardController, {
  Props,
  configJSON,
} from "../DashboardController";

export default class OragnizationInfo extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCompanyInfoDataApi()
    let data=configJSON
       data=[]
       console.log(data)
    
  }

  renderIcon=(props:any)=>(
    <IconButton
        {...props}
        onClick={this.handleSelectBoxOpenIcon}
        data-test-id="rendorIconid"
        style={{height:"16px",width:"16px",display:"flex",justifyContent:"center", alignItems:"center", marginRight: '12px',}}
      >
      { !this.state.openSelectbox ? <div>
        <ExpandMoreIcon style={{color: '#34373A', width:"16px"}} />
        </div> :
        <div style={{transform: "rotate(180deg)"}}>
        <ExpandMoreIcon style={{color: '#34373A', width:"16px" }} />
        </div>}
      </IconButton>)
  // Customizable Area End

  render() {
    // Customizable Area Start
    let { name, website, numberOfEmployees, address, phoneNo, logo } = this.state.companyData
    let { company_name, company_website, company_registered_address, no_of_employees_text,
      company_logo, main_contact, main_contact_email, phone_no} = this.state.companyInfo
      let { status, btnColor, employeesData, selectedCountry } = this.state
    return (
      <ThemeProvider theme={theme}>
      <StyledBox4 
        style={webStyle.mainDiv}
      >
        <StyledBox>
          <StyledBox2>
            <div>
              <img src={company_logo} alt=""
                style={{ width: '118.024px', height: "118px", borderRadius: "118.024px", border: "2px solid #DBDADF" }}
              />
            </div>
            <div>
              <Typography style={webStyle.text} >{company_name}</Typography>
              <Typography style={webStyle.typoText}>Company Address</Typography>
              <Typography
                style={webStyle.addText}>{company_registered_address || '-'}</Typography>
            </div>
          </StyledBox2>
          <div style={webStyle.editDiv} data-test-id='infoModalId' onClick={() => this.setState({ editInfoModal: true })}>
            <div >
              <Typography style={webStyle.editText}>Edit </Typography>
            </div>
            <div>
              <img src={editImg} alt="" style={{ width: "20px", height: "20px" }} />
            </div>
          </div>

        </StyledBox>
      </StyledBox4>
      <StyledBox5
        style={{
          display: "flex",
          padding: "24px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          borderRadius: "8px",
          border: "0.6px solid rgba(52, 55, 58, 0.20)",
          marginTop: "24px",
          width: "80%"
        }}
      >
        <Typography style={webStyle.compInfo}>Company Information</Typography>
        <StyledBox3>
          <div >
            <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <div>
                <Typography style={webStyle.multiUsedTypo}>Name</Typography>
                <Typography style={webStyle.multiUsedTypo2}>{company_name || '-'}</Typography>
              </div>
              <div>
                <Typography style={webStyle.multiUsedTypo}>Main Email Address</Typography>
                <Typography style={webStyle.multiUsedTypo2}>{main_contact_email || '-'}</Typography>
              </div>
            </div>

          </div>
          <div >
            <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <div>
                <Typography style={webStyle.multiUsedTypo}>Website</Typography>
                <Typography style={webStyle.multiUsedTypo2}>{company_website || '-'}</Typography>
              </div>
              <div>
                <Typography style={webStyle.multiUsedTypo}>Main Phone</Typography>
                <Typography style={webStyle.multiUsedTypo2}>{phone_no ? `+${this.state.countryCode} ${phone_no}` : "-"}</Typography>
              </div>
            </div>

          </div>
          <div >
            <Typography style={webStyle.multiUsedTypo}>Main Contact</Typography>
            <Typography style={webStyle.multiUsedTypo2}>{main_contact || "-"}</Typography>
          </div>

        </StyledBox3>
      </StyledBox5>

      <StyledBox6
        style={{
          display: "flex",
          padding: "24px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          borderRadius: "8px",
          border: "0.6px solid rgba(52, 55, 58, 0.20)",
          marginTop: "24px",
          width: "80%"
        }}
      >
        <Typography style={webStyle.compInfo}>General Information</Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "95%"
          }}>
          <div >
            <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <div>
                <Typography style={webStyle.multiUsedTypo}>Number of employees</Typography>
                <Typography style={webStyle.multiUsedTypo2}>{no_of_employees_text || '-'}</Typography>
              </div>
              <div>
                <Typography style={webStyle.multiUsedTypo}>Registered Address</Typography>
                <Typography style={webStyle.multiUsedTypo2}>{company_registered_address || '-'}</Typography>
              </div>
            </div>

          </div>
        </div>
      </StyledBox6>

        <Modal
        data-testid="edit-info"
          open={this.state.editInfoModal}
        >
          <DivWrapper>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "22px" }}>
              <Typography
               style={{
                color: "#34373A",
                fontFamily: "Lato",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "140%",
                letterSpacing: "0.2px"
              }}> Company Information</Typography>
              <img src={crossImage}  data-test-id='infoModalId2' onClick={() => this.closeEditOrganization()}  style={{height:"24px",width:"24px",cursor: "pointer"}}/>
            </div>
            <div style={{ display: "flex", flexDirection: "column",overflow:"auto", height:"520px" }}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                <Typography style={webStyle.formLabel}> Company Registered Name</Typography>
                <Input type="text" placeholder="Enter company name"
                  value={name}
                  data-testid="company-name"
                  name="name"
                  onChange={this.handleCompanyData}
                  disableUnderline style={{ ...webStyle.formInput, ...webStyle.placeholderStyle }} />
                {this.state.formError && !name && <StyledError>Can't leave this field blank</StyledError>}
                {this.state.formError && this.state.companyErrorMessage !== '' &&
                  <StyledError>{this.state.companyErrorMessage}</StyledError>
                  }
              </div>

              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                <Typography style={webStyle.formLabel}> Company Website</Typography>
                <Input type="text" placeholder="Enter company URL"
                  value={website}
                  data-testid="company-website"
                  name="website"
                  onChange={this.handleCompanyData}
                  disableUnderline style={{ ...webStyle.formInput, ...webStyle.placeholderStyle }} />
                {this.state.formError && !website && <StyledError>Can't leave this field blank</StyledError>}
              </div>

              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                <Typography style={webStyle.formLabel}> Company phone no</Typography>
                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                  <PhoneInput
                    country={selectedCountry} 
                    placeholder=" "
                    data-testid="phone-number"
                    value={phoneNo}
                    onChange={this.handleOnChange}
                    inputStyle={{ ...webStyle.formInput, ...webStyle.placeholderStyle, paddingLeft: "50px" }}
                  />
                  </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                <Typography style={webStyle.formLabel}> Company Logo</Typography>
                <Input type="text" placeholder="Upload a file"
                  value={logo}
                  name="logo"
                  disableUnderline style={{ ...webStyle.formInput, ...webStyle.placeholderStyle }} />
                     <Button component="label" style={webStyle.uploadButton}>
                        Upload file
                        <VisuallyHiddenInput type="file" style={{ ...webStyle.formInput, ...webStyle.placeholderStyle }} onChange={this.handleProfileImage} />
                      </Button>
              </div>


              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "16px" }}>
                <Typography style={webStyle.formLabel}> Company Registered Address</Typography>
                <Input type="text" placeholder="Enter complete registered address with zipcode"
                  value={address}
                  data-testid="company-address"
                  name="address"
                  onChange={this.handleCompanyData}
                  style={{ ...webStyle.formInput2, ...webStyle.placeholderStyle }}
                  disableUnderline />
                  {this.state.formError && !address && <StyledError>Can't leave this field blank</StyledError>}
              </div>

              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "52px" }}>
                <Typography style={webStyle.formLabel}> Number of Employees</Typography>
                <Select type="text" defaultValue="Select"
                 IconComponent={(props) => this.renderIcon(props)}
                 data-testid="select-list"
                  value={numberOfEmployees}
                  name="numberOfEmployees"
                  onChange={this.handleCompanyData}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    
                    PaperProps: {
                        
                        style: {
                            height: "200px",
                            overflow:"auto"
                        },
                      },

                  }} 
                  disableUnderline style={{ ...webStyle.formInput, ...webStyle.placeholderStyle }}>
                    {employeesData.map((item:any) =>
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}
                </Select>
              </div>
              <Button
                style={{
                  display: "flex",
                  height: "40px",
                  padding: "7px 16px 7px 12px",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "1 0 0",
                  borderRadius: "8px",
                 background:`${ (this.state.formError || !btnColor || !address || !website || !name) ? "#B1B3C3" : "#43D270"}`,
                }}
                onClick = {this.updateCompanyInfoDataApi}
                data-testid="update-company"
              > Save</Button>

            </div>

          </DivWrapper>
        </Modal>
        
        {<Snackbar
        data-test-id={'snackbar-alert'}
        open={status}
        onClose={() => this.setState({status: false})}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={"success"}>
          Organization Info Saved Successfully.
        </Alert>
      </Snackbar>
      }
    </ThemeProvider>
    );
    // Customizable Area End
  }

}

// Customizable Area Start
const webStyle = {
  text: {
    fontFamily: "Lato",
    color: "#34373A",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0.2px",
    fontWeight: 700,
    paddingBottom: "12px"
  },
  mainDiv: {
    padding: "24px",
    display: "flex",
    gap: "10px",
    alignItems: "flex-start",
    border: "0.6px solid rgba(52, 55, 58, 0.20)",
    borderRadius: "8px",
    width: "80%"
  },
  typoText: {
    fontFamily: "Lato",
    color: "#34373A",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "160%"
  },
  addText: {
    fontFamily: "Lato",
    color: "#7D7D7F",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "160%",
    fontWeight: 400,
  },
  editText: {
    color: "#7D7D7F",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "160%"
  },
  compInfo: {
    fontFamily: "Lato",
    color: "#34373A",
    fontStyle: "normal",
    fontSize: "17px",
    lineHeight: "140%",
    fontWeight: 700,
    paddingBottom: "16px"
  },
  multiUsedTypo: {
    fontFamily: "Lato",
    color: "#7D7D7F",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "160%",
    fontWeight: 400,
  },
  multiUsedTypo2: {
    fontFamily: "Lato",
    color: "#34373A",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "160%"
  },
  editDiv: {
    padding: "8px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    gap: "4px",
    width: "73px",
    border: "1px solid #DBDADF",
    height: "38px",
    cursor: "pointer"
  },
  generalInfoDiv: {
    padding: "24px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    borderRadius: "8px",
    gap: "10px",
    marginTop: "24px",
    border: "0.6px solid rgba(52, 55, 58, 0.20)",
    width: "80%"
  },
  formInput: {
    height: "48px",
    width: "100%",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    borderRadius: "8px",
    padding: "12px"
  },
  formInput2: {
    padding: "12px", 
    width: "100%",
    height: "78px",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF"
  },
  formLabel: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px"
  },
  placeholderStyle: {
    fontFamily: "Lato",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  uploadButton:{
    color: '#fff',
    background: '#329E54',
    textAlign: 'center' as 'center',
    fontWeight: 600
  }

};
// Customizable Area End
