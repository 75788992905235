Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "vendoruseraccountmarketplacehyperlinks";
exports.labelBodyText = "vendoruseraccountmarketplacehyperlinks Body";
exports.MarketplaceAPiEndPoint = "bx_block_content_management/market_places?"
exports.MarketplaceAPiDtailsEndPoint = "bx_block_content_management/market_places/"
exports.TagListEndPoint = "/bx_block_content_management/resource_tags"
exports.Colors = {
  Environmental: "#43D270",
  Environment: "#43D270",
  Social: "#7166F1",
  Governance: "#F96C30",
  Flexible: "#F96C30",
  Onsite: "#43D270",
  Remote: "#7166F1"
}

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End