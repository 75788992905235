import React from "react";
import {
    Box,
    Container,
    Typography
} from "@material-ui/core";
import PageHeadingCard from "../PageHeadingCard";
import { contactus } from "../assets";


const ContactSuccessPage = (props: any) => {

    const heading = "We will get back to you soon"
    const page = props?.navigation?.getParam('page');

    return (
        <>
            <Container maxWidth="lg">
                <Box mt={5} mb={5} justifyContent="center">
                    <PageHeadingCard subHeading={heading} heading="Thank You" />
                    <Box sx={{ height: "300px", display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                        <img src={contactus} alt="icon" height={228.84} width={116.5} />
                    </Box>
                    <Typography variant="h5" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "24px",
                        lineHeight: "36px",
                        textAlign: "center",
                        color: "#34373A",
                        marginBottom: '150px'
                    }}>
                        {
                            page === "contact" ?
                                "We’ve received your information. We will get back to your query soon." :
                                "Thank you for subscribing. You’ll get a welcome mail shortly."
                        }
                    </Typography>
                </Box>
            </Container >   
        </>
    );
};

export default ContactSuccessPage;
