import React from "react";
import { TableRow, IconButton, TableCell, Collapse } from "@material-ui/core";
import { MainExtraTableCell, ScopeTable, ScopeTableBody, ScopeTableCell, EmissionRows, ExtraTableCell } from "../../../cfnzdpuscope2emissions/src/Cfnzdpuscope2emissions.web";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface EmissionRowsProps {
  title?: string;
  rowItems: EmissionData[];
}

function NestedEmissionRows({ title, rowItems }: EmissionRowsProps) {
  return (
    <>
      <TableRow>
        <ScopeTableCell 
          style={webStyle.value}
          data-test-id="title"
        >
          {title}
        </ScopeTableCell>
        <ScopeTableCell />
        <ScopeTableCell />
        <ScopeTableCell />
        <ScopeTableCell />
      </TableRow>
      {rowItems.map((item) => {
        return (
          <TableRow key={item.id}>
            <ScopeTableCell style={{ paddingLeft: 54 }}>{item.name ?? "-"}</ScopeTableCell>
            <ScopeTableCell style={webStyle.value}>{item.value ?? "-"}</ScopeTableCell>
            <ScopeTableCell style={webStyle.name}>{item.source ?? "-"}</ScopeTableCell>
            <ScopeTableCell style={webStyle.name}>{item.last_updated ?? "-"}</ScopeTableCell>
            <ScopeTableCell style={webStyle.value}>{item.restatement ?? "-"}</ScopeTableCell>
          </TableRow>
        );
      })}
    </>
  )
}

interface EmissionData {
  id: number;
  name: string;
  value: string;
  source: string;
  last_updated: string;
  restatement?: string;
}

interface Props {
  heading: string;
  onToggle: () => void;
  isOpen: boolean;
  emissions: EmissionData[];
  relevancyAndRationale: EmissionData[];
  methodology1: EmissionData[];
  exclustion1: EmissionData[];
  changeInScope3Emissions: EmissionData[];
}

export default function Category1({
  heading,
  onToggle,
  isOpen,
  emissions,
  relevancyAndRationale,
  methodology1,
  exclustion1,
  changeInScope3Emissions,
}: Props) {

  let relevancyAndRationaleContent = null;
  if (relevancyAndRationale) {
    relevancyAndRationaleContent = (
      <EmissionRows
        title="Relevancy and rationale if not disclosed"
        rowItems={relevancyAndRationale}
      />
    );
  }

  return (
    <>
      <TableRow>
        <MainExtraTableCell>{heading}</MainExtraTableCell>
        <MainExtraTableCell />
        <MainExtraTableCell />
        <MainExtraTableCell />
        <MainExtraTableCell align="right">
          <IconButton
            data-test-id="location-collapse-btn"
            aria-label="expand row"
            size="small"
            onClick={onToggle}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </MainExtraTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse
            unmountOnExit
            timeout="auto"
            data-test-id="location-based-collapse"
            in={isOpen}
          >
            <ScopeTable>
              <ScopeTableBody>
                <EmissionRows
                  title="Emissions"
                  rowItems={emissions}
                />
                {relevancyAndRationaleContent}
                {/* Methodolgy starts */}
                <TableRow>
                  <ExtraTableCell>Methodology</ExtraTableCell>
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                </TableRow>
                <NestedEmissionRows
                  title="Methodology 1"
                  rowItems={methodology1}
                />
                {/* Methodology ends */}
                {/* Exclusions starts */}
                <TableRow>
                  <ExtraTableCell>Exclusions</ExtraTableCell>
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                  <ExtraTableCell />
                </TableRow>
                <NestedEmissionRows
                  title="Exclusion 1"
                  rowItems={exclustion1}
                />
                {/* Exclustions ends */}
                <EmissionRows
                  title="Change in Scope 3 Emissions calculation or reporting boundary"
                  rowItems={changeInScope3Emissions}
                />
              </ScopeTableBody>
            </ScopeTable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const webStyle = {
  title: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "27.5px"
  },
  subtext: {
    color: "#34373A",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    marginTop: '29px'
  },
  appbar: {
    padding: '37px 0',
    maxWidth: '850px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    marginLeft: '-38px'
  },
  tabtext: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.4px",
  },
  name: {
    color: '#7D7D7F',
    fontSize: "12px",
    fontWeight: 400,
  },
  value: {
    fontWeight: 700,
  }
}