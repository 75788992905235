import React from "react";
import { Container, Grid,Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Label from "./Label";
import parse from "html-react-parser";

const useStyles = makeStyles(() => ({
  mainBlock: {
    marginTop: "10px",
    padding: "80px 20px",
    "@media (max-width: 768px)": {
      padding: "40px 20px",
    },
  },
  container: {
    textAlign: "left",
  },
  serviceBlock: {
    marginTop: "40px",
    display: "flex",
    gap: "16px",
    flexWrap: "nowrap",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      marginTop: "24px",
    },
  },
  serviceItem: {
    position: "relative",
    borderRadius: "20px",
    padding: "20px",
    backgroundColor: "#fff",
    height: "100%",
    transition: "0.3s",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&:hover": {
      backgroundColor: "#E3F8EA",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  heading4: {
    position: "relative",
    marginTop: "24px",
    fontSize: "22px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "32px",
    color: "#101010",
    textTransform: "capitalize",
    "&::before": {
      position: "absolute",
      content: "''",
      left: 0,
      bottom: 0,
      width: 0,
      height: "1px",
      backgroundColor: "#000",
      transition: "0.3s",
    },
    "@media (max-width: 768px)": {
      fontSize: "18px",
      marginTop: "16px",
    },
  },
  description: {
    marginTop: "12px",
    fontSize: "15px",
    lineHeight: "26px",
    color: "#34373A",
    textAlign: "left"
  },
  headerText:{
    '& p':{
      color: '#34373A',
      fontSize: '38px',
      fontFamily: "Montserrat",
      '& span':{
        color: '#7B7B7B !important',
        fontSize:'2.7rem',
        lineHeight:'64.4px',
        fontFamily: "Lato",
      },
    '& strong':{
      color:'#000000 !important',
      fontFamily: "Lato",
      fontSize:'2.7rem',
      lineHeight:'64.4px',
    }
    }
  }
}));

export default function HowWeDo(props: any) {
  const { main_header, header, widget_sub_items, navigate } = props;
  const styles = useStyles();

  return (
    <div
      className={styles.mainBlock}
      style={{
        background:
          props.static_page_code === "carbonzeroed-software" ||
          props.static_page_code === "industries"
            ? "#fff"
            : "linear-gradient(225deg, #FAF7EF 0%, #F4F6FC 100%)"
      }}
    >
      <Container className={styles.container}>
        <div className={styles.header}>
          {main_header && <Label text={main_header} />}
          <Typography className={styles.headerText}>
            {parse(header)}
          </Typography>
        </div>
        <Grid container className={styles.serviceBlock}>
          {widget_sub_items.data.map((service: any) => {
            const { title, description, image } = service.attributes;
            return (
              <ServiceItem
                key={service.id}
                icon={image}
                title={title}
                navigate={navigate}
                description={description}
              />
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}

function ServiceItem({
  icon,
  title,
  navigate,
  description,
}: {
  icon: string;
  title: string;
  navigate: any;
  description: string;
}) {
  const styles = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <div onClick={() => navigate('/')} className={styles.serviceItem}>
        <img src={icon} alt={title} />
        <h4 className={styles.heading4}>{title}</h4>
        <p className={styles.description}>{parse(description)}</p>
      </div>
    </Grid>
  );
}
