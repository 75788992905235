Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPointone = "account_block/registration/step1";
exports.accountsAPiEndPointtwo = "account_block/registration/step2";
exports.accountsAPiEndPointthree = "account_block/registration/step3";
exports.resendEmailAPiEndPoint = "account_block/registration/resend";
exports.verifyEmailAPiEndPoint = "account_block/registration/verify_email_activated";
exports.activateAccountAPiEndPoint = "account/accounts/email_confirmation";
exports.DesignationsAPiEndPoint = "account_block/designation";
exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.userNameValidation = "^[a-zA-Z0-9]{6,15}$";
exports.urlValidation = "^(https?:\\/\\/)?([a-zA-Z0-9.-]+)(\\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\\/[^\\s]*)?(\\?[^\\s]*)?(#[^\\s]*)?$";
exports.businessEmailValidation = "^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$";
exports.resendMailText = "Didn't receive verification email?";
exports.creatingAccount = "By creating an account, you agree to our";
exports.and = "and ";
exports.privacyPolicy = "Privacy Policy.";
exports.activateResentAccountAPiEndPoint = "account_block/registration/resend_activation_email"
// Customizable Area End
