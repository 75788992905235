import React from "react";
import { styled } from "@material-ui/core/styles";
import { Button, Typography, Paper, Dialog } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getDyanmicLink } from "../../../DynamicContent/src/landingpage/LpDifferentFromOther";
import { sadFace } from "../assets";

const CardMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "30px 24px",
  margin: "0 auto",
  flexShrink: 0,
  borderRadius: "16px",
  marginBottom: "25px",
  minHeight: "291px"
});

const TextAndSupportingText = styled("div")({
  marginBottom: "14px",
  textAlign: "center"
});

const HeadingText = styled(Typography)({
  color: "#101010",
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px",
  marginBottom: "12px"
});

const SupportText = styled(Typography)({
  alignSelf: "stretch",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  marginTop: "10px"
});

const ButtonBox = styled("div")({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  width: "100%",
  marginBottom: "0"
});

const CustomButton = styled(Button)({
  display: "flex",
  minHeight: "56px",
  padding: "16px 12px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  borderRadius: "8px",
  backgroundColor: "#43D270",
  cursor: "pointer",
});

const ButtonText = styled(Link)({
  color: "#101010",
  textAlign: "center",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 500,
  width: "100%",
  lineHeight: "normal",
  letterSpacing: "0.4px",
  textTransform: "uppercase",
  textDecoration: "none"
});

const CustomPaper = styled(Paper)({
  borderRadius: 16,
  padding: 40,
  '@media(max-width: 425px)': {
      padding: "30px 10px"
  }
});

export default class PlanAndBillingCard extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isModalOpen: false
    }
  }
  
  handleOpenModal = (status: boolean) => {
    this.setState({ isModalOpen: status });
  }

  render() {
    const {
      heading,
      description,
      buttonLabel,
      buttonLink,
      expiryDate,
      plan,
      higherPlan,
      width,
      buttonwidth
    }: any = this.props;

    const defaultHeading = "Access Restricted !";
    const defaultDescription = `It looks like you don't have access to this page. Your current pricing plan, ${plan}, is valid until ${expiryDate}. To unlock this feature, you'll need to upgrade to our ${higherPlan}.`;
    const defaultButtonLabel = "Upgrade Now";
    const defaultButtonLink = "/settings/plan&billing/select-plan";
    const defaultWidth = width ?? "700px";
    const button = "100%";
    const userDetails = localStorage.getItem("userDetails");
    const isSecondaryUser = JSON.parse(userDetails as string)?.meta?.user_account_type === "secondary_user";

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (isSecondaryUser) {
        event.preventDefault();
        this.handleOpenModal(true);
      }
    }

    return (
      <CardMain style={{ maxWidth: defaultWidth }}>
        <img src={sadFace} style={webStyle.dialogImg} alt="" />
        <TextAndSupportingText>
          <HeadingText>{heading ? heading : defaultHeading}</HeadingText>
          <SupportText>
            {description ? description : defaultDescription}
          </SupportText>
          <SupportText>Would you like to explore upgrade options? </SupportText>
        </TextAndSupportingText>
        <ButtonBox>
          <CustomButton style={{ width: button, marginTop: "20px" }}>
            <ButtonText
              data-test-id="upgrade-btn"
              onClick={handleClick}
              to={getDyanmicLink(buttonLink, defaultButtonLink)}
            >
              {buttonLabel ? buttonLabel : defaultButtonLabel}
            </ButtonText>
          </CustomButton>
        </ButtonBox>
        <Dialog
          data-test-id="upgrade-dialog"
          open={this.state.isModalOpen}
          onClose={() => this.handleOpenModal(false)}
          PaperComponent={CustomPaper}
        >
          <img src={sadFace} style={webStyle.dialogImg} alt="" />
          <Typography style={webStyle.dialogTitle}>
            Access Restricted
          </Typography>
          <Typography style={webStyle.dialogContent}>
            We're sorry, you don't have permission to access this page. Please
            reach out to your ESG Admin if you believe you need access or
            require further assistance.
          </Typography>
          <Typography style={webStyle.ctaText}>
            Go to{" "}
            <span style={webStyle.ctaLink} onClick={this.props.onTodoListClick}>
              To Do List
            </span>{" "}
            page
          </Typography>
        </Dialog>
      </CardMain>
    );
  }
}

const webStyle = {
  dialogImg: {
    width: "200px",
    height: "200px",
    margin: "0 auto"
  } as React.CSSProperties,
  dialogTitle: {
    alignSelf: "stretch",
    color: "var(--greyscale-900, #0F172A)",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "125%",
    letterSpacing: "0.2px"
  } as React.CSSProperties,
  dialogContent: {
    alignSelf: "stretch",
    color: "#34373A",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "0.085px",
    margin: "20px 0 30px 0"
  } as React.CSSProperties,
  ctaText: {
    color: "var(--greyscale-900, #0F172A)",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%"
  } as React.CSSProperties,
  ctaLink: {
    color: "#43D270",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "160%",
    cursor: "pointer"
  },
  emailLink: {
    color: "#43D270",
    cursor: "pointer"
  }
};