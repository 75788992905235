import React from 'react'
import { CSSProperties } from "@material-ui/styles";
import { Box, Typography, Grid, makeStyles } from "@material-ui/core";
import { success_img } from "../assets"
import Heading from "../../../../components/src/FreeAssessmentPage/Heading";
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        margin: "0 auto",
        maxWidth: 1229,
        marginTop: 58,
        "@media(max-width:600px)": {
            marginTop: 30,
        },
    },
    img: {
        maxWidth: "245px",
        height: "100%",
        objectFit: "cover" as CSSProperties["objectFit"],
    },
    contentBox: {
        display: "flex",
        justifyContent: "center",
    },
    headingStyle: {
        marginTop: "58px",
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "24px",
        maxWidth: 1005,
        color: "#34373A",
        textAlign: "center" as CSSProperties["textAlign"],
        "@media(max-width:600px)": {
            fontSize: "20px",
            marginTop: "28px",
        },
    },
    contentBox2: {
        display: "flex",
        justifyContent: "Center",
        paddingBottom: "139px",
        '@media(max-width: 600px)': {
            marginBottom: "60px",
        },
    },
});

const JobSuccess = () => {
    const classes = useStyles();
    const description = " We've received your information. Someone from the recruitment will get in touch with you soon. All the best!"

    return (
        <Box style={{ marginTop: "50px" }}>
            <Heading
                tag={"Thank you!"}
                heading={"You've successfully submitted the form"}
                description={"DONE!"}
            />
            <div className={classes.root}>
                <Grid>
                    <Box className={classes.contentBox}>
                        <img src={success_img} alt="" className={classes.img} />
                    </Box>
                    <Box className={classes.contentBox2}>
                        <Typography className={classes.headingStyle}>
                            {description}
                        </Typography>
                    </Box>
                </Grid>
            </div>
        </Box>
    )
}

export default JobSuccess;

