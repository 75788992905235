import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from "@material-ui/core";
import { zoho, other } from "../../blocks/appointmentmanagement/src/assets"


const useStyles = makeStyles({
    paper: {
        border: "none",
        boxShadow: 'none',
        maxHeight: '250px',
    },
    autocompleteInput: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "24px"
    },
    textField: {
        "& input::placeholder": {
            color: "#34373A",
            opacity: 1
        }
    }
})

const webStyle = {
    modelpopper: {
        maxHeight: '271px',
        borderRadius: "8px",
        border: "1px solid #D9D9D9",
        background: "#FFF",
        maxWidth: '404px',
        boxShadow: 'none !important',
        padding: '0px !important',
    },
    autoComplete: {
        maxWidth: '404px',
        minHeight: "48px",
        borderRadius: '8px',
        border: "1px solid #D9D9D9",
        padding: '12px',
        boxShadow: 'none',
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "24px"
    },
}

interface PropTypes {
    List: any,
    onChange: any,
    renderOption?: any,
    getOptionLabel?: any,
    value?: any,
    multiple?: boolean,
    placeholder?: string
}

const CustomDropDown = ({ List, onChange, renderOption, getOptionLabel, value, multiple = false, placeholder = "Select one" }: PropTypes) => {
    const classes = useStyles();
    const icons = value === "Zoho Meeting app" ? zoho : value === "Other Custom Link" ? other : ""

    return (
        <Autocomplete
            multiple={multiple}
            options={List}
            disableCloseOnSelect
            onChange={onChange}
            classes={{ paper: classes.paper }}
            className={classes.autocompleteInput}
            value={value}
            closeIcon={false}
            popupIcon={<div style={{ margin: '5px 12px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 7.5L10 12.5L5 7.5" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>}
            PopperComponent={({ children, ...other }) => (
                <div {...other} style={webStyle.modelpopper}>
                    {children}
                </div>
            )}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderInput={params => (
                <TextField
                    {...params}
                    name="app"
                    placeholder={placeholder}
                    classes={{ root: classes.textField }}
                    InputProps={icons ? {
                        ...params.InputProps,
                        disableUnderline: true,
                        style: webStyle.autoComplete,
                        startAdornment: (
                            <InputAdornment position="start" style={{ marginRight: '3px', display: icons ? 'flex' : 'none' }}>
                                {value ? <img src={icons} alt="" width={34} height={25} style={{ marginLeft: '-9px' }} /> : <></>}
                            </InputAdornment>
                        ),
                    } :
                        {
                            ...params.InputProps,
                            disableUnderline: true,
                            style: webStyle.autoComplete,
                        }}
                />
            )}
        />
    );
};
export default CustomDropDown;
