import React from "react";
import { TableRow, IconButton, TableCell, Collapse } from "@material-ui/core";
import { MainExtraTableCell, ScopeTable, ScopeTableBody, EmissionRows } from "../../../cfnzdpuscope2emissions/src/Cfnzdpuscope2emissions.web";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface EmissionData {
  id: number;
  name: string;
  value: string;
  source: string;
  last_updated: string;
  restatement?: string;
}

interface Props {
  heading: string;
  onToggle: () => void;
  isOpen: boolean;
  rowItems: EmissionData[];
}

export default function TitleLessRows({
  heading,
  onToggle,
  isOpen,
  rowItems,
}: Props) {
  return (
    <>
      <TableRow>
        <MainExtraTableCell data-test-id="heading">{heading}</MainExtraTableCell>
        <MainExtraTableCell />
        <MainExtraTableCell />
        <MainExtraTableCell />
        <MainExtraTableCell align="right">
          <IconButton
            data-test-id="location-collapse-btn"
            aria-label="expand row"
            size="small"
            onClick={onToggle}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </MainExtraTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse
            unmountOnExit
            timeout="auto"
            data-test-id="location-based-collapse"
            in={isOpen}
          >
            <ScopeTable>
              <ScopeTableBody>
                <EmissionRows leftAlignTableCells rowItems={rowItems} />
              </ScopeTableBody>
            </ScopeTable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}