Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.landingPageApiMethodTypeGet = "GET";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DynamicContent";
exports.labelBodyText = "DynamicContent Body";
exports.landingPageApiEndPoint = "bx_block_content_management/get_landingpage";
exports.contactuspageApiEndpoint = "bx_block_custom_form/contact_us_page";
exports.headerPageApiEndPoint='bx_block_multiplelandingpage2/header_items';
exports.footerPageApiEndPoint='bx_block_multiplelandingpage2/footer_items';
exports.jobApplicationEndPoint='bx_block_content_management/job_applications';
exports.landingPageApiMethodPost = "POST";
exports.landingPageApiContentType = "application/json";
exports.contentTypeMultipartFormData = "multipart/form-data";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.faqApiContentType = "application/json";
exports.faqApiMethodType = "GET";
exports.faqApiEndpoint = "bx_block_content_management/faqs";
exports.documentApiEndpoint = "bx_block_content_management/documents";
exports.privacyPolicyApiEndpoint = "bx_block_blogpostsmanagement/privacy_policies";
exports.termsconditionApiEndpoint = "bx_block_blogpostsmanagement/terms_conditions";
exports.homeNoticeApiEndPoint = "bx_block_content_management/homepage_banner";
exports.dynamicRoutesApiEndPoint = "bx_block_content_management/dynamic_routes";
exports.apiMethodGet = "GET";
exports.apiMethodPost = "POST";
exports.btnExampleTitle = "CLICK ME";
exports.socialMediaLinksApiEndPoint = "bx_block_content_management/social_media_links";
exports.yourMessage = "Your Message";
exports.nameLabel = "Name*";
exports.emailLabel = "Email*";
exports.companyNameLabel = "Company Name*";
exports.carbonZeroedChecklist = "How can we at CarbonZeroed help you?";
exports.suggestionLabel = "Suggestion";
exports.SendMessage = "Send Message";
exports.address = "Address";
exports.phoneNumber = "Phone Number";
exports.nameErrorMessage = "Name is required";
exports.companyNameErrorMessage = "Company Name is required";
exports.emailErrorMessage = "Email is required";
exports.validEmailMessage = "Enter valid Email";
exports.senMessageAPIEndPoint = "bx_block_custom_form/contacts";
exports.dynamicOptionsEndpoint = "bx_block_content_management/dynamic_contact_form_options";
exports.contactUs = "Contact Us";
exports.login = "Login";
exports.Signup = "Sign Up";
exports.header = "Header";
exports.headerApiEndPoint = "bx_block_multiplelandingpage2/header_items";
exports.footer = "Footer";
exports.footerApiEndPoint = "bx_block_multiplelandingpage2/footer_items";
exports.dynamicContentApiEndPoint = "bx_block_content_management/dynamic_signups";
exports.privacyPolicyErrorMessage = "Please accept Terms & Conditions and Privacy Policy"
// Customizable Area End
