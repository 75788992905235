import React from "react";

// Customizable Area Start
import { Button, Grid, InputLabel, TextField, Container, InputAdornment, IconButton } from "@material-ui/core";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class NewPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container>
          <Formik
            initialValues={this.state.initialValuePassword}
            validationSchema={this.state.passwordSchema}
            onSubmit={values => this.handleSetNewPassword(values)}
          >
            {formik => {
              const {
                values,
                touched,
                handleChange,
                handleSubmit,
                errors,
                handleBlur
              } = formik;
              return (
                <form
                  onSubmit={handleSubmit}
                  className="form-one"
                  data-test-id="accountLogin"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: '15%'
                  }}
                >
                  <Grid container spacing={5} style={{ width: "30%" }}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="password" style={webStyle.labelInput}>
                        New Password
                      </InputLabel>
                      <TextField
                        id="password"
                        data-test-id="email-text-field"
                        name="password"
                        variant="standard"
                        type={this.state.enablePasswordField ? "password" : "text"}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                                name="eye"
                              >
                                {this.state.enablePasswordField ? (
                                  <VisibilityOutlined style={webStyle.eyeIcon} />
                                ) : (
                                  <VisibilityOffOutlined style={webStyle.eyeIcon} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          disableUnderline: true
                        }}
                        margin="normal"
                        onChange={handleChange}
                        value={values.password}
                        style={{
                          ...webStyle.textFieldBorder,
                          border: touched.password && errors.password ? 
                            "#FFA39E solid 1px" : 
                            "#CBD5E1 solid 1px",
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.password && errors.password ? (
                        <div style={webStyle.red}>{errors.password}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel
                        htmlFor="confirmPassword"
                        style={webStyle.labelInput}
                      >
                        Confirm Password
                      </InputLabel>
                      <TextField
                        id="confirmPassword"
                        data-test-id="email-text-field"
                        name="confirmPassword"
                        type={this.state.enablePasswordField1 ? "password" : "text"}
                        variant="standard"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword1}
                                edge="end"
                                name="eye1"
                              >
                                {this.state.enablePasswordField1 ? (
                                  <VisibilityOutlined style={webStyle.eyeIcon} />
                                ) : (
                                  <VisibilityOffOutlined style={webStyle.eyeIcon} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          disableUnderline: true
                        }}
                        margin="normal"
                        onChange={handleChange}
                        value={values.confirmPassword}
                        style={{
                          ...webStyle.textFieldBorder,
                          border: touched.confirmPassword && errors.confirmPassword ? 
                            "#FFA39E solid 1px" : 
                            "#CBD5E1 solid 1px",
                        }}
                        fullWidth
                        onBlur={handleBlur}
                      />
                      {touched.confirmPassword && errors.confirmPassword ? (
                        <div style={webStyle.red}>{errors.confirmPassword}</div>
                      ) : null}
                    </Grid>
                  </Grid>
                  <div style={webStyle.ButtonStyleNew}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: this.isGoNext(errors, values),
                        borderRadius: "8px",
                        width: "184px",
                        height: "56px",
                        color: "#FFFFFF"
                      }}
                      type="submit"
                    >
                      SUBMIT
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  labelInput: {
    color: "#34373A",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center"
  },
  textFieldBorder: {
    border: "#D9D9D9 solid 1px",
    borderRadius: "8px",
    padding: "8.5px 14px",
    font: "inherit",
    height: "48px"
  },
  red: {
    color: "#F5222D",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px"
  },
  ButtonStyleNew: {
    display: "flex",
    justifyContent: "center",
    marginTop: "70px"
  },
  button: {
    backgroundColor: "#34373A",
    redius: "8px",
    width: "184px",
    height: "56px",
    color: "#FFFFFF"
  },
  icon: {
    rotate: "45deg",
    marginLeft: "5px"
  },
  eyeIcon: {
    color: '#B1B3C3'
  },
};
