import React from "react";

// Customizable Area Start
import { Typography, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { es, es_active } from "./assets";
import Appointments from "./Appointments.web";
import Layout from "../../dashboard/src/Layout.web";
import PlanAndBillingCard from "../../dashboard/src/common/PlanAndBillingCard.web";

const InnerContainer = styled("div")({
    width: "100%",
    height: "auto",
    padding: "27px 0 44px 0px"
});

const ExpertTabsSection = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
    marginLeft: '38px',
    marginRight: '32px',
    '@media(max-width: 450px)': {
        alignItems: 'end'
    },
});

const ExpertTabItem = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#FFF",
    color: "grey",
    fontSize: "15px",
    height: "40px",
    cursor: "pointer",
});

const ExpertTabItemSelected = styled("div")({
    display: "flex",
    justifyContent: "center",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#ECFBF1",
    color: "grey",
    fontSize: "15px",
    cursor: "pointer",
});

const ExpertTabText = styled(Typography)({
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.2px",
    cursor: "pointer",
});

const ExpertTabTextSelected = styled(ExpertTabText)({
    color: "#329E54",
    fontWeight: 700,
});

const ExpertIcon = styled("span")({
    width: "20px",
    height: "20px",
});

const ExpertTabContent = styled('div')({
    width: "100%",
})

const ExpertTabLink = styled(Link)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
})

const ExpertTabs = styled('div')({
    maxWidth: '404px',
    display: 'flex',
    gap: '8px',
    '@media(max-width: 450px)': {
        flexDirection: 'column',
        alignItems: 'start'
    },
})
const StyledBox = styled(Box)({
     '@media(max-width: 600px)': {
       marginLeft:'0px !important'
    },
})
const StyledBox2 = styled(Box)({
    '@media(max-width: 600px)': {
        marginLeft:'0px !important'
    },
})



const OrgBaseUrl = "/climate-concierge";
// Customizable Area End

import AppointmentmanagementController, {
    Props,
} from "./AppointmentmanagementController";

export default class ExpertSupport extends AppointmentmanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'portfolio-manager';
        const description = "Personal ESG data consultant who will be responsible for managing and overseeing environmental and sustainability efforts will be assigned to you as you upgrade to the next tier. Please click the below link to upgrade."
        const tabsData = [
            {
                id: 1,
                text: "Portfolio Manager",
                icon: es,
                activeIcon: es_active,
                component: <Appointments navigation={this.props.navigation} id={""} />,
                isActive: activeTab === "portfolio-manager",
                link: "/portfolio-manager"
            },
            {
                id: 2,
                text: "ESG Data Consultant",
                icon: es,
                activeIcon: es_active,
                component: <StyledBox style={{ marginLeft: '38px' }}>
                    <PlanAndBillingCard navigation={this.props.navigation} id={""}
                        heading="ESG Data Consultant"
                        description={description}
                        width="536px"
                        buttonwidth = "227px"
                    />
                </StyledBox>,
                isActive: activeTab === "ESG-data-consultant",
                link: "/ESG-data-consultant"
            },
            {
                id: 3,
                text: "Sustainability Advisor",
                icon: es,
                activeIcon: es_active,
                component: <StyledBox2 style={{ marginLeft: '38px' }}>
                <PlanAndBillingCard navigation={this.props.navigation} id={""}
                    heading="Sustainability Advisor"
                    description={description}
                    width="536px"
                    buttonwidth = "227px"
                />
            </StyledBox2>,
                isActive: activeTab === "sustainability-advisor",
                link: "/sustainability-advisor"
            }
        ];
        return (
            <>
                <Layout>
                    <InnerContainer>
                        <ExpertTabsSection>
                            <ExpertTabs>
                                {tabsData.map((tab) => {
                                    return tab.isActive ? (
                                        <ExpertTabItemSelected
                                            key={`selected_tab_${tab.id}`}
                                        >
                                            <ExpertIcon><img src={tab.activeIcon} alt="" /></ExpertIcon>
                                            <ExpertTabTextSelected>{tab.text}</ExpertTabTextSelected>
                                        </ExpertTabItemSelected>
                                    ) : (
                                        <ExpertTabItem
                                            key={`noneselected_tab_${tab.id}`}
                                        >
                                            <ExpertTabLink to={OrgBaseUrl + tab.link}>
                                                <ExpertIcon><img src={tab.icon} alt="" /></ExpertIcon>
                                                <ExpertTabText>{tab.text}</ExpertTabText>
                                            </ExpertTabLink>
                                        </ExpertTabItem>
                                    );
                                })}
                            </ExpertTabs>
                        </ExpertTabsSection>

                        {tabsData.map((content) => {
                            return <ExpertTabContent key={`content${content.id}`}>{content.isActive ? content.component : ""}</ExpertTabContent>
                        })}
                    </InnerContainer>
                </Layout>
            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start
// Customizable Area End
