import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  outerBlock: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 0,
    transition: "opacity 0.8s ease, visibility 0.8s ease",
    opacity: 0,
    visibility: "hidden",
  },
  outerBlockVisible: {
    opacity: 1,
    visibility: "visible",
    zIndex: 1000,
  },
  innerBlock: {
    position: "fixed",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    borderRadius: "24px",
    overflow: "hidden",
    backgroundColor: "#fff",
    transition: "all ease 0.8s",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid white",
    padding: "50px 24px",
    zIndex: 10,
    "@media (max-width: 768px)": {
      width: "calc(100% - 48px)",
      padding: "24px",
    }
  },
  innerBlockVisible: {
    top: "50%",
  },
  imageBlock: {
    width: "50%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "@media (max-width: 768px)": {
      display: "none",
    }
  },
  contentBlock: {
    width: "50%",
    padding: "40px",
    "& h3": {
      textTransform: "capitalize",
      fontSize: "26px",
      fontWeight: 600,
      lineHeight: "40px",
    },
    "& p": {
      marginTop: "16px",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#646570",
    },
    "@media (max-width: 768px)": {
      width: "100%",
      paddingLeft: 0,
      paddingTop: "20px",
      "& h3": {
        fontSize: "20px",
        lineHeight: "30px",
      }
    }
  },
  closeBlock: {
    cursor: "pointer",
    position: "absolute",
    top: "24px",
    right: "24px",
    display: "inline-block",
  },
  bannerImage: {
    width: "20px",
    height: "20px",
  },
}));

type Props = {
  bannerData: any;
};

export default function ServicePopup({bannerData}: Props) {
  const styles = useStyles();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (bannerData) {
      setShowPopup(bannerData.attributes.banner_enabled ?? false);
    }
  }, [bannerData]);

  const handleClose = () => {
    setShowPopup(false);
  };

  if (!bannerData) {
    return null
  }

  const {banner_image, banner_main_image, banner_content, banner_enabled} = bannerData.attributes;
  if (showPopup) {
    return (
      <div
        className={`${styles.outerBlock} ${banner_enabled ? styles.outerBlockVisible : ""}`}
      >
        <div
          className={`${styles.innerBlock} ${
            banner_enabled ? styles.innerBlockVisible : ""
          }`}
        >
          <div className={styles.imageBlock}>
            <img
              src={banner_main_image}
              alt="placeholder"
            />
          </div>
          <div className={styles.contentBlock}>
            <div className={styles.closeBlock} onClick={handleClose}>
              <CloseIcon/>
            </div>
            <h3>Discover our services</h3>
            <p>
              <img src={banner_image} className={styles.bannerImage} alt={""}/>
              {banner_content}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
