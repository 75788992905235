import React from "react";
import { Container, Grid } from "@material-ui/core";
import parse from "html-react-parser";
import { makeStyles } from "@material-ui/core/styles";
import WidgetHeadingText from "./WidgetHeadingText";
import {MdArrowOutward} from "react-icons/md";

const useStyles = makeStyles(() => ({
  background: {
    position: "absolute",
    width: "100%",
    height: "750px",
    zIndex: -1,
    background: "linear-gradient(0deg, #fff 0%, #e5eaff 100%)"
  },
  mainBlock: {
    position: "relative",
    padding: "80px 24px",
    "@media(max-width: 768px)": {
      padding: "40px 20px",
      marginTop: 0
    }
  },
  container: {
    display: "flex",
    "@media(max-width: 768px)": {
      flexDirection: "column-reverse"
    }
  },
  headingBlock: {
    textAlign: "center",
    marginBottom: "60px",
    "@media(max-width: 768px)": {
      marginBottom: "30px"
    }
  },
  careerDescription: {
    fontSize: "18px",
    color: "#4c4c4c",
    marginTop: "12px"
  },
  bigImage: {
    borderRadius: "12px",
    width: "90%",
    height: "380px",
    objectFit: "cover",
    "@media(max-width: 768px)": {
      height: "auto",
      width: "100%"
    }
  },
  heading3: {
    fontWeight: 800,
    fontSize: "26px",
    lineHeight: "26px",
    color: "#272727",
    marginTop: "36px",
    "@media(max-width: 768px)": {
      fontSize: "22px",
      lineHeight: "28px"
    }
  },
  paragraph: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "#4A4A4A",
    marginTop: "12px",
    "@media(max-width: 768px)": {
      fontSize: "16px",
      lineHeight: "24px"
    }
  },
  paragraph2: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#4A4A4A",
    marginTop: "12px",
    "@media(max-width: 768px)": {
      fontSize: "14px",
      lineHeight: "20px",
      marginTop: "6px"
    }
  },
  specificityItem: {
    display: "flex",
    marginTop: "10px",
    fontWeight: 600,
    fontFamily: "Lato"
  },
  label: {
    fontWeight: 600
  },
  value: {
    marginLeft: "4px"
  },
  date: {
    marginTop: "24px",
    color: "#4c4c4c",
    fontSize: "18px"
  },
  featureBlock: {
    display: "flex",
    marginTop: "36px",
    gap: "24px",
    "@media(max-width: 768px)": {
      flexDirection: "column-reverse"
    }
  },
  feature: {
    "@media(min-width: 768px)": {
      borderLeft: "1px solid #C6C6C6",
      paddingLeft: "16px"
    }
  },
  explanation: {
    flex: 1,
    "& > :first-child": {
      fontWeight: 800,
      fontSize: "26px",
      lineHeight: "26px",
      color: "#272727",
      "@media(max-width: 768px)": {
        fontSize: "22px",
        lineHeight: "22px"
      }
    },
    "& > :nth-child(2)": {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#4A4A4A",
      marginTop: "12px",
      "@media(max-width: 768px)": {
        fontSize: "14px",
        lineHeight: "20px"
      }
    }
  },
  title: {
    fontWeight: 800,
    fontSize: "28px",
    lineHeight: "40px",
    "@media(max-width: 768px)": {
      fontSize: "20px",
      lineHeight: "28px"
    }
  },
  description: {
    "& > *": {
      fontSize: "18px",
      lineHeight: "24px",
      color: "#4C4C4C",
      "@media(max-width: 768px)": {
        fontSize: "16px",
        lineHeight: "22px"
      }
    }
  },
  featureItem: {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  button: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    backgroundColor: "#43d270",
    color: "#000",
    fontWeight: 600,
    fontFamily: "Lato",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 16px",
    borderRadius: "6px",
    marginTop: "24px",
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "#897ecc"
    }
  },
  heading: {
    "&, & > p": {
      marginTop: "12px",
      fontSize: "48px",
      fontWeight: 800,
      fontFamily: "Lato",
      lineHeight: "52px",
      color: "#191919",
      textTransform: "capitalize",
      "@media (max-width: 768px)": {
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: "8px",
      },
    },
  },
}));

function CareerDetail(props: any) {
  const {
    attributes: {
      career_title,
      career_description,
      location,
      career_role,
      no_of_openings,
      career_types,
      job_summary,
      job_explanation,
      job_features,
      career_link,
      career_big_image,
      career_features,
      career_technologies
    },
    navigate
  } = props;

  const technologyNames = career_technologies.data.map(
    (item: any) => item.attributes.technology_name
  );
  const specificity = [
    { label: "Technology", value: technologyNames.join(", ") },
    { label: "Role", value: career_role },
    { label: "Type", value: career_types },
    { label: "Location", value: location },
    { label: "No of Openings", value: no_of_openings }
  ];
  const styles = useStyles();
  return (
    <>
      <div className={styles.background}/>
      <Container className={styles.mainBlock}>
        <div className={styles.headingBlock}>
          <div className={styles.heading}>Job Description</div>
          <p className={styles.careerDescription}>
            Towards a Carbon Free Future
          </p>
        </div>
        <Grid container spacing={4} className={styles.container}>
          <Grid item xs={12} md={8}>
            <img
              src={career_big_image}
              className={styles.bigImage}
              alt="big image"
            />
            <h3 className={styles.heading3}>Job Summary</h3>
            <div className={styles.paragraph}>{parse(job_summary)}</div>

            <div className={styles.featureBlock}>
              <div className={styles.explanation}>
                {parse(job_explanation)}
              </div>
              <div className={`${styles.explanation} ${styles.feature}`}>
                {parse(job_features)}
                {career_features.data.map((item: any) => {
                  const {feature} = item.attributes;
                  return (
                    <div className={styles.featureItem}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                          fill="#43D270"/>
                      </svg>
                      <p className={styles.paragraph2}>{feature}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <h3 className={styles.title}>{career_title}</h3>
              <p className={styles.careerDescription}>
                {parse(career_description)}
              </p>
              <div>
                {specificity.map(item => {
                  return (
                    <div className={styles.specificityItem}>
                      <span className={styles.label}>{item.label}: </span>
                      <span className={styles.value}>{item.value}</span>
                    </div>
                  );
                })}
                <p className={styles.date}>February 28, 2024</p>
              </div>
            </div>

            <div className={styles.button} onClick={() => navigate(career_title.toLowerCase().replace(/ /g, '-'))}>
              Apply Now
              <MdArrowOutward/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CareerDetail;