export const location = require("../assets/location.png");
export const wishlist = require("../assets/wishlist.png");
export const es = require("../assets/es.png");
export const es_active = require("../assets/es_active.png");
export const close = require("../assets/close.svg");
export const checkbox = require("../assets/checkbox.svg");
export const checked = require("../assets/checked.svg");
export const down = require("../assets/down.svg");
export const date = require("../assets/date.png");
export const time = require("../assets/Time.png");
export const more = require("../assets/more.svg");
export const file = require("../assets/file.png");
export const download = require("../assets/download.png");
export const successLogo = require("../assets/success_icon.svg");
export const empty = require("../assets/empty.png");
export const zoho = require("../assets/zoho.png");
export const other = require("../assets/other.svg");
export const folderIcon = require("../assets/folder.svg");
export const locationIcon = require("../assets/location_on.svg");
export const descriptionIcon = require("../assets/description.svg");
export const formatIcon = require("../assets/format_align_left.svg");
export const sadFace = require("../assets/sad_face.svg");


