import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import ServicePopup from "./ServicePopup";

const useStyles = makeStyles(() => ({
  mainBlock: {
    marginTop: "60px",
    "@media (max-width: 768px)": {
      marginTop: "32px",
    },
  },
  heading: {
    "&, & > p": {
      fontSize: "48px",
      fontWeight: 800,
      lineHeight: "63px",
      textTransform: "capitalize",
      "@media (max-width: 768px)": {
        fontSize: "28px",
        lineHeight: "42px",
      },
    }
  },
  body: {
    color: "#000",
    marginTop: "16px",
    fontSize: "19px",
    lineHeight: "28px",
    "@media (max-width: 768px)": {
      fontSize: "14px",
    },
  },
  buttonBlock: {
    marginTop: "40px",
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      marginTop: "20px",
    },
  },
  button: {
    display: "inline-flex",
    padding: "12px 32px",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'Lato', sans-serif",
    lineHeight: "24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    borderRadius: "4px",
    textTransform: "capitalize",
    transition: "all ease 0.3s",
    color: "#101010",
    "@media (max-width: 480px)": {
      width: "100%",
    },
    "&::before": {
      position: "absolute",
      content: "''",
      top: "10px",
      left: "10px",
      transform: "translate(-50%, -50%)",
      width: "0px",
      height: "0px",
      borderRadius: "50%",
      transition: "all linear 0.3s",
      zIndex: 1,
    },
  },
  button1: {
    color: "#000",
    backgroundColor: "#43d270",
  },
  button2: {
    color: "#000",
    backgroundColor: "#eaedfd",
  },
  container: {
    display: "flex",
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse",
    },
  },
}));

export default function Heading(props: any) {
  const {header, description, video, widget_item_buttons, navigate} = props;
  const styles = useStyles();

  return (
    <>
      <div className={styles.mainBlock}>
        <Container>
          <Grid container className={styles.container}>
            <Grid item xs={12} md={6}>
              <div>
                <h2 className={styles.heading}>{parse(header)}</h2>
                <p className={styles.body}>{parse(description)}</p>
              </div>
              <div className={styles.buttonBlock}>
                <span
                  onClick={() => navigate(widget_item_buttons.data[0].attributes.link)}
                  className={`${styles.button} ${styles.button1}`}
                >
                  {widget_item_buttons.data[0].attributes.name}
                  <img
                    src={widget_item_buttons.data[0].attributes.icon}
                    alt="arrow"
                  />
                </span>
                <span
                  onClick={() => navigate(widget_item_buttons.data[1].attributes.link)}
                  className={`${styles.button} ${styles.button2}`}
                >
                  {widget_item_buttons.data[1].attributes.name}
                  <img
                    src={widget_item_buttons.data[1].attributes.icon}
                    alt="arrow"
                  />
                </span>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <video width="100%" height={400} playsInline autoPlay loop muted>
                <source src={video}/>
              </video>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
