import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep: any;
  freeAssessmentData: any;
  submitResponse: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipageForms2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFreeAssessmentStepsApiCallId: string = ""
  submitFormApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep: 0,
      freeAssessmentData: [],
      submitResponse: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestId === this.getFreeAssessmentStepsApiCallId) {
      this.setFreeAssessmentData(response);
    }

    if (apiRequestId === this.submitFormApiCallId) {
      this.setSubmitFormResponse(response)
    }
    // Customizable Area End+
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  // Customizable Area Start
  formSubmited = (postData: any) => {
    const user_answers: any = [];
    this.state.freeAssessmentData.map((element: any) => {
      element?.attributes?.free_assessment_questions.map((question: any) => {
        if (postData[question?.attributes?.question]) {
          let answer = postData[question?.attributes?.question];

          if (Array.isArray(postData[question?.attributes?.question])) {
            answer = answer.map((str: any) => {
              return parseInt(str);
            });
          }
          user_answers.push({
            question_id: question?.attributes?.question_id,
            question_type: question?.attributes?.question_type,
            answers: answer,
            score: 0
          })
        }
      })
    })

    this.submitFreeAssessmentForm({
      data: {
        user_email: postData['Email Address'] || '',
        user_answers: user_answers || []
      }
    })
  }

  getFreeAssessmentSteps = () => {
    const header = {
      "Content-Type": configJSON.freeAssessmentApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFreeAssessmentStepsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.freeAssessmentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.freeAssessmentApiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitFreeAssessmentForm = (httpBody: any) => {
    const header = {
      "Content-Type": configJSON.submitFormApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitFormApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitFormApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.submitFormApiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount() {
    this.getFreeAssessmentSteps()
  }

  setFreeAssessmentData = (res: any) => {
    this.setState({ freeAssessmentData: res.data });
  };

  setSubmitFormResponse = (res: any) => {
    this.setState({ submitResponse: res })
  }
  // Customizable Area End
}
