import React from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";

const useStyles = makeStyles(() => ({
  blockAwards: {
    paddingBottom: "50px",
    "@media (max-width: 768px)": {
      padding: "0px 40px 40px 40px",
    },
  },
  heading3: {
    fontWeight: 600,
    fontSize: "32px",
    textTransform: "capitalize",
    "@media (max-width: 768px)": {
      fontSize: "24px",
    },
  },
  listAwards: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    "@media (max-width: 768px)": {
      justifyContent: "center",
      flexWrap: "wrap",
      marginTop: "20px",
    },
  },
  awardImage: {
    width: "100px",
    height: "100px",
  },
}));

export default function Recognition(props: any) {
  const { header, customer_service_widget_items } = props;
  const styles = useStyles();
  return (
    <Container>
      <Grid container className={styles.blockAwards}>
          <Grid item lg={4}>
            <h3 className={styles.heading3}>{parse(header)}</h3>
          </Grid>
          <Grid item lg={8}>
            <div className={styles.listAwards}>
              {customer_service_widget_items.data.map((item: any) => (
                <div key={item.id}>
                  <img
                    src={item.attributes.customer_service_image}
                    alt={`item-${item.id}`}
                    className={styles.awardImage}
                  />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
  );
}
