import React from "react";
import { Grid, Container } from "@material-ui/core";
import parse from "html-react-parser";
import { makeStyles } from "@material-ui/core/styles";
import Label from "./Label";

const useStyles = makeStyles(() => ({
  blogBlock: {
    padding: "40px 0",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "stretch",
    gap: "24px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  blogItem: {
    position: "relative",
    transition: "all ease 0.5s",
    borderBottom: "4px solid transparent",
    boxShadow: "0px 10px 25px 0px rgba(54, 95, 104, 0.1)",
    borderRadius: "12px",
    padding: "12px 24px",
    backgroundColor: "#fff",
    display: "block",
    "&:hover": {
      borderBottom: "4px solid #43d270",
      backgroundColor: "rgba(12,18,24,0.5)",
    },
    "&:hover .bg-img": {
      opacity: 1,
    },
    "&:hover h4, &:hover p": {
      color: "#fff",
    },
  },
  backgroundImageBlock: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    zIndex: -1,
    transition: "all ease 0.5s",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  heading4: {
    marginTop: "12px",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "capitalize",
    "@media (max-width: 768px)": {
      fontSize: "18px",
      lineHeight: "26px",
    },
  },
  description: {
    marginTop: "12px",
    fontSize: "16px",
    color: "#646570",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }
}));

export default function Info(props: any) {
  const {
    info_widget_items: { data },
    navigate
  } = props;
  const styles = useStyles();
  return (
    <Container>
      <Grid container className={styles.blogBlock}>
        {data.map((item: any) => {
          const {
            info_title,
            info_description,
            info_link,
            info_button,
            info_hover_bg_image,
          } = item.attributes;
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={item.id}
              className={styles.blogItem}
            >
              <div onClick={() => navigate(info_link)}>
                <div className={`${styles.backgroundImageBlock} bg-img`}>
                  <img
                    className={styles.image}
                    src={info_hover_bg_image}
                    alt={info_title}
                  />
                </div>

                <div className={styles.info}>
                  <div>
                    <h4 className={styles.heading4}>{info_title}</h4>
                    <p className={styles.description}>
                      {parse(info_description)}
                    </p>
                  </div>
                  <div>
                    <Label
                      color="green"
                      style={{textTransform: "none", marginTop: "20px"}}
                      text={info_button}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
