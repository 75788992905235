import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { v4 as uuidv4 } from 'uuid';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";
import * as yup from "yup";

interface CardDetails {
  taskId: number;
  materialFactorId: number | null;
  categoryId: number;
  coreFactorId: number;
  cardId: number;
  status: string;
}

interface InputResponse {
  param_name: string;
  param_type: string;
}

interface Question {
  id: number;
  answer: string;
  question_type: string;
  traditional_input: InputValueResponse[];
  api_datas: {
    api_params_data: InputResponse[];
  },
}

interface Activity {
  image?: string;
  activity_date_time: string;
  activity_by?: null;
  activity: string;
}

interface ShippingFreightResponse {
  success: boolean;
  data: {
    card_title: string;
    card_description: string;
    questions: Question[];
    general_answer_data: unknown;
    activity_logs_data: Activity[];
  }
}

interface Input {
  type: string | undefined;
  name: string;
  value: string | unknown;
}

interface Inputs {
  origin: Input;
  destination: Input;
  weight: Input;
  weight_unit: Input;
  transport_mode: Input;
  [key: string]: Input;
}

export interface InputRow {
  id: string;
  inputs: Inputs;
}

interface VehicleType1 {
  id: number
  vehicle_code: string
  vehicle_name: string
}

interface VehicleWeight1 {
  id:number
  weight_code:string
  weight_name:string
}

interface RoadFuel1 {
  id:number;
  fuel_code:string;
  fuel_name:string;
}

interface RoadLoad1 {
  id:number;
  load_code:string;
  load_name:string;
}

interface VesselType1 {
  id:number;
  vessel_code:string;
  vessel_name:string;
}

interface TonnageType1 {
  id:number;
  tonnage_code:string;
  tonnage_name:string;
}

interface SeaFuelSource1 {
  id:number
  sea_fuel_code:string
  sea_fuel_name:string
}

interface SeaLoadType1 {
  id:number
  sea_load_code:string
  sea_load_name:string
}

interface RailFuelData1 {
  id:number
  rail_fuel_code:string
  rail_fuel_name:string
}

interface RailLoadData1 {
  id:number
  rail_load_code:string
  rail_load_name:string
}

interface AirCraftData1 {
  id:number
  aircraft_code:string
  aircraft_name:string
}

interface AirMethodology1 {
  id:number
  methodology_code:string
  methodology_name:string
}

interface Region1 {
  id:string
  name:string
}

interface InputValueResponse {
  destination: string;
  origin: string;
  weight: string;
  weight_unit: string;
  transport_details:{
    transport_mode:string,
    leg_details:{
      [key: string]: string;
    }
  }[];
}
 interface TransportDetails1 {
    transport_mode: string;
    leg_details: {
      [key: string]: string;
    }
 }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  cardId: number;
  taskId: number;
  pageTitle: string;
  pageDescription: string;
  questionId: number;
  questionType: string;
  co2Result: string;
  inputRows: InputRow[];
  vehicleTypeData:VehicleType1[];
  vehicleWeightData:VehicleWeight1[];
  roadFuelData:RoadFuel1[];
  roadloadData:RoadLoad1[];
  vesselTypeData:VesselType1[];
  tonnageData:TonnageType1[];
  seaFuelData:SeaFuelSource1[];
  seaLoadTypeData:SeaLoadType1[];
  railFuelData:RailFuelData1[];
  railLoadData:RailLoadData1[];
  aircraftData:AirCraftData1[];
  airMethodologyData:AirMethodology1[];
  transportModeValue:string;
  regionOption:Region1[];
  openFailureDialog: boolean;
  failureMessage: string;
  hasSavedMetrics: boolean;
  userActivites: Activity[];
  comment: string;
  openSuccessDialog: boolean;
  vehicleWeightValue:string | unknown;
  tonnageValue:string | unknown;
  disableFields:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfclimatiqshippingfreightController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getShipFreightApiCallId: string = "";
  getVehicalTypeDropdown: string = "";
  getVehicleWeightDropdown: string = "";
  getVehicleOptionId: string = "";
  getVesselTypeApiID: string = "";
  getTonnageApiId: string = "";
  getSeaFuelApiId: string = "";
  getRailFuelApiId: string = "";
  getRailLoadApiId: string = "";
  getAircraftApiId: string = "";
  getAirMethodologyApiId: string = "";
  calculateApiId:string = "";
  getRegionApiId:string = "";
  saveShippingApiCallId:string = "";
  
  initialRow = {
    id: uuidv4(),
    isFullRow:true,
    inputs: {
      origin: {type: "dropdown", name: "origin", value: ""},
      destination: {type: "dropdown", name: "destination", value: ""},
      weight: {type: "text", name: "weight", value: ""},
      weight_unit: {type: "dropdown", name: "weight_unit", value: ""},
      transport_mode: {type: "dropdown", name: "transport_mode", value: ""},
    }
  };
  initialTransportRow = {
    id: this.initialRow.id,
    isFullRow:false,
    inputs:{
      transport_mode: {type: "dropdown", name: "transport_mode", value: ""},
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      cardId: -1,
      taskId: -1,
      pageTitle: "",
      pageDescription: "",
      questionId: -1,
      questionType: "",
      co2Result: "",
      inputRows: [],
      vehicleTypeData:[],
      vehicleWeightData:[],
      roadFuelData:[],
      roadloadData:[],
      vesselTypeData:[],
      tonnageData:[],
      seaFuelData:[],
      seaLoadTypeData:[],
      railFuelData:[],
      railLoadData:[],
      aircraftData:[],
      airMethodologyData:[],
      transportModeValue:'',
      regionOption:[],
      openFailureDialog: false,
      failureMessage: "",
      hasSavedMetrics: false,
      userActivites: [],
      comment: '',
      openSuccessDialog: false,
      vehicleWeightValue:'',
      tonnageValue:'',
      disableFields:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Handle api responses
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.getShipFreightApiCallId) {
        this.handleShippingFreightDetails(responseJson);
      }
      this.handleGetVehicleTypeResponse(apiRequestCallId,message);
      this.handleGetVehicleWeightResponse(apiRequestCallId,message);
      this.handleGetVehicleOption(apiRequestCallId,message);
      this.getVesselTypeAPIResponse(apiRequestCallId,message);
      this.getTonnageAPIResponse(apiRequestCallId,message);
      this.getSeaFuelAPIResponse(apiRequestCallId,message);
      this.getRailFuelAPIResponse(apiRequestCallId,message);
      this.getRailLoadAPIResponse(apiRequestCallId,message);
      this.getAircraftAPIResponse(apiRequestCallId,message);
      this.getAirMethodologyAPIResponse(apiRequestCallId,message);
      this.getRegionAPIResponse(apiRequestCallId,message);
      this.getCalculateResponse(apiRequestCallId,message);
      this.getSaveShippingResponse(apiRequestCallId,message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const cardDetails = await getStorageData("shipFreightPayload");
    const cardDetailsJson = JSON.parse(cardDetails);
    this.getShippingFreightDetails(cardDetailsJson);
    this.getVehicletypeDropDownApiCall();
    this.getVesselTypeDropDownApiCall();
    this.getRailFuelDropDownApiCall();
    this.getRailLoadDrodownApiCall();    
    this.getAircraftdDrodownApiCall();
    this.getAirMethodoligyApiCall();
    this.getRegionApiCall();
  }

  validationSchema = yup.object().shape({
    inputRows: yup.array().of(
      yup.lazy((row:any) =>
      yup.object().shape({
        inputs: yup.object().shape({
          origin: yup.object({ value: yup.string().when([], {
            is: () => row.isFullRow,
            then: yup.string().required("Please select one option"),
            otherwise: yup.string().notRequired(),
          }) }),
          destination: yup.object({ value: yup.string().when([], {
            is: () => row.isFullRow,
            then: yup.string().required("Please select one option"),
            otherwise: yup.string().notRequired(),
          }) }),
          weight: yup.object({ value:yup.number().when([], {
            is: () => row.isFullRow,
            then: yup.number().required("Weight is required").typeError("Weight must be a number"),
            otherwise: yup.number().notRequired(),
          })}),
          weight_unit: yup.object({ value: yup.string().when([], {
            is: () => row.isFullRow,
            then: yup.string().required("Please select one option"),
            otherwise: yup.string().notRequired(),
          }) }),
          transport_mode: yup.object({ value: yup.string().required("Please select one option") }),
        })
      })
    )
    )
  });

  getRailFuelDropDownApiCall = () =>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }

    this.getRailFuelApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.railFuelEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getRailLoadDrodownApiCall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }

    this.getRailLoadApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.railLoadEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAircraftdDrodownApiCall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }

    this.getAircraftApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aircraftEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAirMethodoligyApiCall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }

    this.getAirMethodologyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.airMethodologyEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGetVehicleTypeResponse = (requestCallId:string,message:Message) => {
    if(this.getVehicalTypeDropdown === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.vehicle_types){
         this.setState({
          vehicleTypeData:responseJson.vehicle_types
         })
      }
  }
  }

  handleGetVehicleWeightResponse = (requestCallId:string,message:Message) => {
    if(this.getVehicleWeightDropdown === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.vehicle_weights){
         this.setState({
          vehicleWeightData:responseJson.vehicle_weights
         },() => this.handleCallVehicleOption(this.state.vehicleWeightValue))
      }
    }
  }

  handleGetVehicleOption = (requestCallId:string,message:Message) => {
    if(this.getVehicleOptionId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.road_fuel_sources){
         this.setState({
          roadFuelData:responseJson.road_fuel_sources
         })
      }
      if(responseJson.road_load_types){
        this.setState({
         roadloadData:responseJson.road_load_types
        })
     }
    }
    
  }

  getVesselTypeAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getVesselTypeApiID === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.vessel_types){
         this.setState({
          vesselTypeData:responseJson.vessel_types
         })
      }
  }
  }

  getTonnageAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getTonnageApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.tonnage_types){
         this.setState({
          tonnageData:responseJson.tonnage_types
         },() => this.getSeaFuelAPICall(this.state.tonnageValue))
      }
  }
  }

  getSeaFuelAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getSeaFuelApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.sea_fuel_sources){
         this.setState({
          seaFuelData:responseJson.sea_fuel_sources
         })
      }
      if(responseJson.sea_load_types){
        this.setState({
          seaLoadTypeData:responseJson.sea_load_types
        })
      }
  }
  }

  getRailFuelAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getRailFuelApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.rail_fuel_types){
         this.setState({
          railFuelData:responseJson.rail_fuel_types
         })
      }
  }
  }

  getRailLoadAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getRailLoadApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.rail_load_types){
         this.setState({
          railLoadData:responseJson.rail_load_types
         })
      }
  }
  }

  getAircraftAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getAircraftApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.aircraft_types){
         this.setState({
          aircraftData:responseJson.aircraft_types
         })
      }
  }
  }

  getAirMethodologyAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getAirMethodologyApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.aircraft_methodologies){
         this.setState({
          airMethodologyData:responseJson.aircraft_methodologies
         })
      }
  }
  }

  getRegionAPIResponse = (requestCallId:string,message:Message) => {
    if(this.getRegionApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.data){
         this.setState({
          regionOption:responseJson.data
         })
      }
  }
  }
  getCalculateResponse = (requestCallId:string,message:Message) => {
    if(this.calculateApiId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!responseJson.success || responseJson.error){
        this.setState({
          openFailureDialog: true,
          failureMessage: responseJson.data || responseJson.error,
        });
        return;
      }
      this.setState({co2Result:responseJson.data})
  }
  }
  getSaveShippingResponse = async(requestCallId:string,message:Message) => {
    if(this.saveShippingApiCallId === requestCallId){
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        isLoading:false
      })
      if(!responseJson.success){
      const defaultErrorMessage = "An error has occured. Please try again later"
      const failureMessage = responseJson?.errors?.account ?? defaultErrorMessage;
        this.setState({
          openFailureDialog: true,
          failureMessage
        });
        return;
      }
      this.setState({ openSuccessDialog: true});
      const cardDetails = await getStorageData("shipFreightPayload");
      const cardDetailsJson = JSON.parse(cardDetails);
      this.getShippingFreightDetails(cardDetailsJson);
  }
  }

  // Get energy consumption details
  getShippingFreightDetails = async (cardDetails: CardDetails) => {
    this.setState({ 
      isLoading: true,
      cardId: cardDetails.cardId,
      taskId: cardDetails.taskId,
    });

    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    const payload = { 
      account_id: userDetails.meta.id,
      card_id: cardDetails.cardId,
      category_id: cardDetails.categoryId,
      core_factor_id: cardDetails.coreFactorId,
      material_factor_id: cardDetails.materialFactorId,
      task_id: cardDetails.taskId
    };

    this.getShipFreightApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getShippingFreightDetailsAPiEndPoint,
      payload: JSON.stringify(payload),
    }); 
  }

  handleShippingFreightDetails = (response: ShippingFreightResponse) => {
    if (!response.success) return;

    this.handleGeneralResponseData(response);
    let savedInputs:any = [];
    if (response.data.general_answer_data !== null) {
    savedInputs = this.getSavedInputs(response);
    }
    this.setState({
      isLoading: false,
      questionId: response.data.questions[0].id,
      questionType: response.data.questions[0].question_type,
      co2Result: response.data.questions[0].answer,
      pageTitle: response.data.card_title,
      pageDescription: response.data.card_description,
      userActivites: response.data.activity_logs_data,
    });

    const inputDefinitions = response.data.questions[0].api_datas.api_params_data;
    if (savedInputs.length === 0) {
    this.setState({ 
      inputRows: [
        ...this.state.inputRows,
        this.initialRow,
      ]  
    });
  }else {
    const inputRows = this.generateInputRows(savedInputs, inputDefinitions);
    this.setState({ inputRows });
    this.setState({disableFields:true})
  }
  }

  handleGeneralResponseData = (response: ShippingFreightResponse) => {
    if (response.data.general_answer_data !== null) {
      this.setState({ hasSavedMetrics: true });
    }
  };
  getSavedInputs = (response: ShippingFreightResponse) => {
    return response?.data?.questions[0]?.traditional_input[0] ?? [];
  };

  generateInputRows = (savedInputs: InputValueResponse[], inputDefinitions: InputResponse[]) => {
    return savedInputs.map(savedInput => {
      const inputs: Inputs = this.generateInputs(savedInput, inputDefinitions);
      
      return {
        id: uuidv4(),
        inputs: inputs,
      };
    });
  };

  generateInputs = (savedInput: InputValueResponse, inputDefinitions: InputResponse[]) => {
    const inputs: any = {};
    const transportDetails = savedInput.transport_details || [];
    
    for (const [property, value] of Object.entries(savedInput)) {
      if (property === "transport_details") {
        this.processTransportDetails(transportDetails, inputs);
      } else {
        this.processInputField(property, value, inputDefinitions, inputs);
      }
    }
  
    return inputs;
  };

  processTransportDetails = (transportDetails: TransportDetails1[], inputs: Inputs) => {
    transportDetails.forEach((transportDetail: TransportDetails1, index: number) => {
      inputs[`transport_mode_${index}`] = {
        type: "dropdown",
        name: "transport_mode",
        value: transportDetail.transport_mode,
        
      };
      for (const [legProperty, legValue] of Object.entries(transportDetail.leg_details)) {
        inputs[`leg_detail_${legProperty}_${index}`] = {
          type: "dropdown",
          name: legProperty,
          value: legValue,
        };
        switch(legProperty){
          case "vehicle_type":
            this.handleCallVehicleWeight(legValue)
          break;
          case "vehicle_weight":
            this.setState({
              vehicleWeightValue:legValue
            })
          break;
          case "vessel_type":
            this.getTonnageAPICall(legValue)
          break;
          case "tonnage":
            this.setState({
              tonnageValue:legValue
            })
          break;
          default:

        }
      }
    });
  };

  processInputField = (property: string, value: any, inputDefinitions: any[], inputs: Inputs) => {
    const inputDefinition = inputDefinitions.find(definition => definition.param_name === property);
    if (inputDefinition) {
      inputs[property] = {
        type: inputDefinition.param_type,
        name: property,
        value,
      };
    }
  };

  generateLabelFromParamName = (paramName: string): string => {
    switch (paramName) {
      case "origin":
        return "Origin";
      case "destination":
        return "Destination";
      case "weight":
        return "Weight";
      case "weight_unit":
        return "Weight Unit";
      case "transport_mode":
        return "Transport Mode";
      case "aircraft_type":
        return "Aircraft Type";
      case "methodology":
        return "Methodology";
      case "fuel_type":
        return "Fuel Type";
      case "vehicle_type":
        return "Vehicle Type";
      case "vehicle_weight":
          return "Vehicle Weight";
      case "fuel_source":
          return "Fuel Source";
      case "load_type":
          return "Load Type";
      case "road_load_type":
          return "Load Type";
      case "vessel_type":
          return "Vessel Type";
      case "tonnage":
          return "Tonnage";
      case "sea_fuel_source":
          return "Fuel Source";
      case "sea_load_type":
          return "Load Type";

      default:
        return "";
    }
  }

  generateOptionsFromParamName = (paramName: string) => {
    const weightUnitOptions = [
      { id: 1, value: "g", label: "G" },
      { id: 2, value: "kg", label: "Kg" },
      { id: 3, value: "t", label: "T" },
      { id: 4, value: "lb", label: "Lb" },
      { id: 5, value: "ton", label: "Ton" },
    ];
    const transportModes = [
      {id: 1, label: "Road", value: "road"},
      {id: 2, label: "Sea", value: "sea"},
      {id: 3, label: "Rail", value: "rail"},
      {id: 4, label: "Air", value: "air"},
    ];
    switch (paramName) {
      case "origin":
        case "destination":
        return this.state.regionOption.map((region,index) => ({
          id:index,
          label:region.name,
          value:region.name
        }));
      case "weight_unit":
        return weightUnitOptions;
      case "transport_mode":
        return transportModes;
      case "fuel_type":
        return this.state.railFuelData.map(fuel => ({
          id: fuel.id,
          label: fuel.rail_fuel_name, 
          value: fuel.rail_fuel_code,
        }));
      case "load_type":
        return this.state.railLoadData.map(load => ({
          id: load.id,
          label: load.rail_load_name,
          value: load.rail_load_code
        }));
      case "aircraft_type":
        return this.state.aircraftData.map(air => ({
          id: air.id,
          label: air.aircraft_name,
          value: air.aircraft_code
        }));
      case "methodology":
        return this.state.airMethodologyData.map(air => ({
          id: air.id,
          label: air.methodology_name,
          value: air.methodology_code
        }))
      case "vehicle_type":
        return this.state.vehicleTypeData.map(vehicle => ({
          id: vehicle.id,
          label: vehicle.vehicle_name, 
          value: vehicle.vehicle_code,
        }));
      case "vehicle_weight":
        return this.state.vehicleWeightData.map(vehicle => ({
          id: vehicle.id,
          label: vehicle.weight_name, 
          value: vehicle.weight_code,
        }));
      case "fuel_source":
        return this.state.roadFuelData.map(fuel => ({
          id: fuel.id,
          label: fuel.fuel_name, 
          value: fuel.fuel_code,
        }));
      case "road_load_type":
        return this.state.roadloadData.map(road => ({
          id: road.id,
          label: road.load_name, 
          value: road.load_code,
        }));
      case "vessel_type":
        return this.state.vesselTypeData.map(vessel => ({
          id: vessel.id,
          label: vessel.vessel_name, 
          value: vessel.vessel_code,
        }));
      case "tonnage":
        return this.state.tonnageData.map(tonnage => ({
          id: tonnage.id,
          label: tonnage.tonnage_name, 
          value: tonnage.tonnage_code,
        }));
      case "sea_fuel_source":
        return this.state.seaFuelData.map(fuel => ({
          id: fuel.id,
          label: fuel.sea_fuel_name, 
          value: fuel.sea_fuel_code,
        }));
      case "sea_load_type":
        return this.state.seaLoadTypeData.map(load => ({
          id: load.id,
          label: load.sea_load_name, 
          value: load.sea_load_code,
        }));
    
      default:
        return [];
    }
  }

  getVehicletypeDropDownApiCall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content_Type": configJSON.validationApiContentType
    }

    this.getVehicalTypeDropdown = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVehicaltypeEndpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleCallVehicleWeight = (vehicleValue:string | unknown) => {
    const requestVehicleWeightMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const vehicleTypeid = this.state.vehicleTypeData.filter(data => data.vehicle_code === vehicleValue)
    setStorageData('vehicleTypeId',JSON.stringify(vehicleTypeid[0]?.id));
    const body = {
      "vehicle_type_id": vehicleTypeid[0]?.id
    }

    this.getVehicleWeightDropdown = requestVehicleWeightMessage.messageId;

    requestVehicleWeightMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestVehicleWeightMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    requestVehicleWeightMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestVehicleWeightMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVehicalWeightEndpoint
    );

    runEngine.sendMessage(requestVehicleWeightMessage.id, requestVehicleWeightMessage);
  }

  handleCallVehicleOption = async(weightValue:string | unknown) => {
    const requestVehicleOptionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const vehicleTypeID = await getStorageData('vehicleTypeId')
    
    const vehicleWeightid = this.state.vehicleWeightData.filter(data => data.weight_code === weightValue)
    const body = {
      "vehicle_type_id": `${vehicleTypeID}`,
      "vehicle_weight_id": vehicleWeightid[0]?.id
    }
    this.getVehicleOptionId = requestVehicleOptionMessage.messageId;
    requestVehicleOptionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestVehicleOptionMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestVehicleOptionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    requestVehicleOptionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVehicleOptionEndpoint
    );
    runEngine.sendMessage(requestVehicleOptionMessage.id, requestVehicleOptionMessage);
  }

  getVesselTypeDropDownApiCall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content_Type": configJSON.validationApiContentType
    }

    this.getVesselTypeApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVesseltypeEndpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getTonnageAPICall = (vesselValue:string) => {
    const requestTonnageMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const vesselId = this.state.vesselTypeData.filter(data => data.vessel_code === vesselValue)
    setStorageData('vesselId',JSON.stringify(vesselId[0]?.id));
    const body = {
      "vessel_type_id": vesselId[0]?.id
    }

    this.getTonnageApiId = requestTonnageMessage.messageId;

    requestTonnageMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestTonnageMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    requestTonnageMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestTonnageMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTonnageEndpoint
    );

    runEngine.sendMessage(requestTonnageMessage.id, requestTonnageMessage);
  }

  getSeaFuelAPICall = async(tonnageValue:string | unknown) => {
    const requestSeaFuelMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const vesselID = await getStorageData('vesselId')
    
    const tonnageId = this.state.tonnageData.filter(data => data.tonnage_code === tonnageValue)
    const body = {
      "vessel_type_id": `${vesselID}`,
      "tonnage_type_id": tonnageId[0]?.id
    }
    this.getSeaFuelApiId = requestSeaFuelMessage.messageId;
    requestSeaFuelMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestSeaFuelMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestSeaFuelMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    requestSeaFuelMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSeaFuelOptionEndpoint
    );
    runEngine.sendMessage(requestSeaFuelMessage.id, requestSeaFuelMessage);
  }

  handleCaluculate = async(values:{inputRows:InputRow[]}) => {
    const transformedValues = values?.inputRows.reduce((acc: any[], row: any, index: number) => {
      const { transport_mode, vehicle_type, vehicle_weight, fuel_source, road_load_type, fuel_type, load_type, vessel_type, tonnage, sea_fuel_source, sea_load_type,aircraft_type,methodology,origin,destination,weight,weight_unit } = row.inputs;
      const transportDetails = [];
      if (transport_mode?.value === 'road') {
        transportDetails.push({
          transport_mode:transport_mode.value,
          "leg_details":{
            vehicle_type:vehicle_type.value || null,
            vehicle_weight:vehicle_weight.value || null,
            fuel_source:fuel_source.value || null,
            load_type:road_load_type.value || null
          }
          
        });
      }else if (transport_mode?.value === 'rail') {
        transportDetails.push({
          transport_mode:transport_mode.value,
          "leg_details":{
          fuel_type:fuel_type.value || null,
          load_type:load_type.value || null
          }
        });
      }else if (transport_mode?.value === 'sea') {
        transportDetails.push({
          transport_mode:transport_mode.value,
          "leg_details":{
          vessel_type:vessel_type.value || null,
          tonnage:tonnage.value || null,
          fuel_source:sea_fuel_source.value || null,
          load_type:sea_load_type.value || null
          }
        });
      }else if (transport_mode?.value === 'air') {
        transportDetails.push({
          transport_mode:transport_mode.value,
          "leg_details":{
          aircraft_type:aircraft_type.value || null,
          methodology:methodology.value || null
          }
        });
      }
      if (!row.inputs.origin) {
        const previousRow = acc[acc.length - 1];
        if (previousRow) {
          previousRow.transport_details = [
            ...previousRow.transport_details,
            ...transportDetails
          ];
        }
      } else {
        acc.push({
            origin:origin.value,
            destination:destination.value,
            weight:Number(weight.value),
            weight_unit:weight_unit.value,
            transport_details: transportDetails
          
        });
      }
      return acc;
      },[]);
      this.handleCalculateApiCall(transformedValues);
  };
  
  handleCalculateApiCall = async(transformedValues:any) => {
    const requestCalculateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    const cardDetails = await getStorageData("shipFreightPayload");
    const cardDetailsJson = JSON.parse(cardDetails);
    const body = {
      "account_id":userDetails.meta.id,
      "monthly_assessment_card_id":cardDetailsJson.cardId,
      "end_point": "traditionalHydro",
      "api_params":transformedValues
    }
    this.calculateApiId = requestCalculateMessage.messageId
    requestCalculateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestCalculateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestCalculateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    requestCalculateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCalculateEndPoint
    );
    runEngine.sendMessage(requestCalculateMessage.id, requestCalculateMessage);
  }

  handleTransportModeValue = (transportMode:string) => {
    this.setState({transportModeValue:transportMode})
  }
  getRegionApiCall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }

    this.getRegionApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCloseFailureDialog = () => {
    this.setState({
      openFailureDialog: false,
      failureMessage: "",
    });
  }
  handleSave = async() => {
    this.setState({
      isLoading:true
    })
    const formData = new FormData();
    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    formData.append("data[account_id]",  userDetails.meta.id);
    formData.append("data[user_answers][][question_id]",  `${this.state.questionId}`);
    formData.append("data[user_answers][][question_type]",  this.state.questionType);
    formData.append("data[user_answers][][answers]",  this.state.co2Result);
    formData.append("data[activity]",  this.state.comment);
    formData.append("data[task_id]",  `${this.state.taskId}`);
    formData.append("data[card_id]",  `${this.state.cardId}`);
    if (userDetails.meta.user_account_type === "portfolio_manager") {
      const organizationDetails = JSON.parse(localStorage.getItem("organization") as string);
      formData.append("data[company_id]", organizationDetails.id);
    }
    this.saveShippingApiCallId = await apiCall({
      method: configJSON.postAPiMethod,
      endPoint: configJSON.saveShippingAPiEndPoint,
      payload: formData,
    });
  }
  handleCloseSuccessDialog = () => {
    this.setState({
      openSuccessDialog: false,
    });
  }
  navigateToContactUs = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ContactUsPage");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }
  // Customizable Area End
}
