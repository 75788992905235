import React from "react";

import {
  // Customizable Area Start
  Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import TablePagination from "../../../dashboard/src/common/TablePagination.web";
import { Link } from 'react-router-dom';


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const tableLabels = {
    title: "Report Title",
    description: "Description",
    action: "Action",
};

const ReportTableContainer = styled(TableContainer)({
    minHeight: "583px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: '26px',
    padding: '12px',
    paddingLeft: "0px"
});

const ReportTable = styled(Table)({
    width: "100%",
    borderCollapse: "separate",
});

const ReportTableBody = styled(TableBody)({
    color: "inherit",
});

const ReportSpacing = styled("div")({
    height: 16,
    display: "table-header-group",
});

const ReportTableCellHead = styled(TableCell)({
    minHeight: "48px",
    padding: "8px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableCellHeadTitle = styled(TableCell)({
    minHeight: "48px",
    padding: "8px 18px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    minWidth: '240px'
});

const ReportTableCell = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableCellTitle = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px 8px 8px 18px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const ReportTableHead = styled(TableHead)({
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
});

const ReportTableRowHead = styled(TableRow)({
    borderRadius: "20px !important",
    overflow: "hidden",
    "& :first-child": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    "& :last-child": {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
});

const tableData = [{
    title: "Organizational Breakdown",
    description: "Breakdown of company footprint into business units and locations"
},
{
    title: "Emission Type Breakdown",
    description: "Breakdown of company footprint into scopes, categories and subcategories"
},
{
    title: "Company Overview",
    description: "Overview of company footprint"
},
{
    title: "Customer Segments Report",
    description: "Illustrates the analysis of product footprint, sales emissions for associated customer & customer groups"
},
{
    title: "World Map",
    description: "Global overview of CCF emissions by regions"
}]
// Customizable Area End

import Multiplelandingpage2Controller, {
  Props,
  configJSON,
} from "../Multiplelandingpage2Controller";

export default class Reports extends Multiplelandingpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
                <Typography style={webStyle.title}>Reports</Typography>
                <Typography style={webStyle.description}>Select a report from the below list to get a detailed view of selected metrics.</Typography>
                <ReportTableContainer>
                    <ReportTable aria-label="users table">
                        <ReportTableHead>
                            <ReportTableRowHead>
                                <ReportTableCellHeadTitle>
                                    {tableLabels.title}
                                </ReportTableCellHeadTitle>
                                <ReportTableCellHead>{tableLabels.description}</ReportTableCellHead>
                                <ReportTableCellHead>{tableLabels.action}</ReportTableCellHead>

                            </ReportTableRowHead>
                        </ReportTableHead>
                        <ReportSpacing />
                        <ReportTableBody>
                            {tableData && tableData.map((user: any, index: number) => {
                                return (
                                    <TableRow>
                                        <ReportTableCellTitle>
                                            {user.title}
                                        </ReportTableCellTitle>
                                        <ReportTableCell>
                                            {user.description}
                                        </ReportTableCell>
                                        <ReportTableCell>
                                            <Link to='/transform/reporting/reporting-framework' style={webStyle.button}>View Sample</Link>
                                        </ReportTableCell>
                                    </TableRow>
                                );
                            })}
                        </ReportTableBody>
                    </ReportTable>
                </ReportTableContainer>
                <div style={webStyle.paginator}>
                    <TablePagination count={5} />
                </div>
            </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    title: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "125%",
        margin: '32px 0 23px 0'
    },
    description: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
        maxWidth: '882px',
    },
    button: {
        minWidth: '140px',
        maxWidth: '140px',
        minHeight: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        borderRadius: "8px",
        textDecoration: "none",
        border: "1px solid #000"
    },
    paginator: {
        // margin: '0 80px 0 35px'
    }
};
// Customizable Area End
