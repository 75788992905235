import React from "react";

import {
  Button,
  // Customizable Area Start
  Grid,
  TextField,
  Checkbox,
  Box,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { checkedIcon, unCheckedIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const GridTextField = styled(Grid)({
  position: "relative",
  '@media (max-width:415px)': {
    maxWidth: '100%',
    width: '100%',
  },
})

// Customizable Area End

import EmailNotifications2Controller, {
  Props,
} from "./EmailNotifications2Controller";

export default class EmailNotifications2 extends EmailNotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
          <Box>
            <Grid container style={webStyle.ctaBox} spacing={2}>
              <GridTextField item>
                <TextField
                  variant="standard"
                  placeholder={this.props.placeholder}
                  style={webStyle.ctaInput}
                  onChange={(e: any) => this.setInputValue(e.target.value)}
                  value={this.state.txtInputValue}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { 
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22.4px",
                      color: "#606060"
                    }
                  }}
                  data-test-id="email-text-field"
                />
                {this.state.errors && <Typography style={webStyle.error}>{this.state.errors}</Typography>}
              </GridTextField>
              <Grid item>
                <Button
                  data-test-id="email-submit-button"
                  variant="contained"
                  disableElevation
                  style={{
                    ...webStyle.ctaButton,
                    pointerEvents: this.state.readPrivacePolicy ? "auto" : "none",
                  } as React.CSSProperties}
                  onClick={() => this.handleSubscribe("14-day trial")}
                >
                  {this.props.buttonLabel}
                </Button>
              </Grid>
            </Grid>
            <label style={webStyle.label}>
              <Checkbox 
                data-test-id="privay-checkbox"
                disableRipple
                checked={this.state.readPrivacePolicy}
                onChange={this.togglePrivacyPolicyCheckbox}
                icon={<img src={unCheckedIcon} />}
                checkedIcon={<img src={checkedIcon} />}
                style={{ paddingLeft: 0, marginBottom: "3px" }} 
              />
              I have read the {" "}
                <span onClick={this.props.onPrivacyPolicyClick} style={webStyle.emphasis}>Privacy Policy</span> 
              {" "} and agree to its terms
            </label>
          </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  ctaInput: {
    backgroundColor: "#F1F1F5",
    width: "348px",
    maxWidth: "100%",
    fontSize: "15px",
    height: "50px",
    borderRadius: "8px",
    overflow: "hidden",
    padding: "8.5px 14px",
  },
  ctaBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  ctaButton: {
    backgroundColor: "#43D270",
    color: "#000000",
    fontSize: "18px",
    cursor: "pointer",
    fontWeight: 600,
    fontFamily: "Lato",
    lineHeight: "25.2px",
    minHeight: "50px",
    width: "130px",
    borderRadius: "8px",
    boxShadow: "none !important",
    textTransform: "capitalize",
  },
  error: {
    fontFamily: "Inter",
    color: 'red',
    fontSize: "13px",
    lineHeight: "13px",
    marginTop: "5px",
    maxWidth: "348px",
    minHeight: "26px",
  },
  label: {
    display: "block",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFFFFF",
    marginTop: "10px"
  },
  emphasis: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textDecoration: "underline",
    cursor: "pointer"
  }
};
// Customizable Area End
