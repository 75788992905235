import React from "react";
import {
    Box,
    Typography,
    Grid
} from "@material-ui/core";
import {
    makeStyles,
} from '@material-ui/core/styles';
import { fpassword } from "../assets";
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close'
import ForgotPasswordWeb from "../../../forgot-password/src/ForgotPassword.web";
import { NavigationProp, ParamListBase } from "@react-navigation/native";

const useStyles = makeStyles({
    rightContent: {
        padding: '50px 30px 0 30px',
        maxHeight: '900px',
        maxWidth: '732px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
    },
    cover: {
        width: '100%',
        maxHeight: '900px',
        maxWidth: '708px'
    },
    title: {
        fontFamily: 'Montserrat !important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "46px",
        color: "#34373A",
        marginTop: '14px',
    },
    logintxt: {
        fontFamily: 'Lato !important',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "160%",
        color: "#0F172A",
        textAlign: 'center',
        marginTop: '40px',
        maxWidth: '404px'
    },
    loginlink: {
        color: '#7166F1',
        textDecoration: 'none',
        fontFamily: "Lato",
        fontSize: "15px",
        fontWeight: 700,
        lineHeight: "24px"
    },
    closeicon: {
        display: 'flex',
        justifyContent: 'end',
    },
    form: {
        maxWidth: "500px",
        marginTop: "70px",
    },
    red: {
        color: "red",
        marginTop: "10px",
    },
})

interface Props {
    navigation?: NavigationProp<ParamListBase>;
    id?: string;
}

const ForgotPassword = (props: Props) => {

    const classes = useStyles()

    return (
        <>
            <Box sx={{flexGrow: 1,}}
                 style={{display: 'grid', placeItems: 'center', height: '100vh', backgroundColor: '#e9eefa'}}>
                <Grid container style={{ maxWidth: '1440px', boxShadow: '4px 2px 7px #8C8C8C' }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ backgroundColor: 'white' }}>
                        <img src={fpassword} className={classes.cover} alt="content" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ backgroundColor: 'white' }}>
                        <Box className={classes.closeicon}>
                            <Link to="/" style={{ color: 'black', position: 'relative', top: '48px', right: '90px' }}>
                                <CloseIcon style={{ color: 'black' }} />
                            </Link>
                        </Box>
                        <Box className={classes.rightContent}>
                            <Typography className={classes.title}>Forgot password?</Typography>
                            <Box className={classes.form}>
                                <ForgotPasswordWeb navigation={props.navigation} />
                            </Box>
                            <Typography className={classes.logintxt}>
                                <Link to="/login/primary" className={classes.loginlink}>Back</Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ForgotPassword;
