import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react";
import L, { Map, LatLngExpression, Marker, GeoJSON } from "leaflet";
import { pointOnFeature } from "@turf/turf";
import { truckCircle, shipCircle, airplaneCircle, trainCircle } from "./assets";

interface Route {
  name: string;
  latitude: number;
  longitude: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  transportMode: string;
  routes: Route[];
  hasLoadedFeatureCollection: boolean;
  featureCollection: GeoJSON.FeatureCollection;
  featureCenterMarkers: Marker[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.IntermodalRouteMessage),
    ];

    this.state = {
      transportMode: "",
      routes: [],
      hasLoadedFeatureCollection: false,
      featureCollection: {
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          properties: [],
          geometry: {
            coordinates: [],
            type: "LineString"
          }
        }]
      },
      featureCenterMarkers: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.IntermodalRouteMessage)) {
      const { routes, featureCollection, transportMode } = message.getData(getName(MessageEnum.IntermodalRouteData));
     
      this.setState({ 
        routes, 
        featureCollection,
        transportMode,
        hasLoadedFeatureCollection: routes.length === 0 ? false : true
      });

      if (routes.length === 0) {
        this.removeMarkers();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  mapRef = createRef<Map>();

  createMarker = (map: Map) => {    
    if (this.state.featureCenterMarkers.length === 0) {
      const newMarkers = this.state.featureCollection.features.map((feature, featureIndex) => {
        let center: LatLngExpression = this.getCenterCoordinates(feature);
        const customIcon = L.icon({
          iconUrl: this.getFeatureCenterIcon(featureIndex),
          iconSize: [26, 26],
          iconAnchor: [15, 10]
        });
        const marker = L.marker(center, { icon: customIcon });
        return marker.addTo(map);
      });
      this.setState({ featureCenterMarkers: newMarkers });
    }
  }

  getCenterCoordinates = (feature: GeoJSON.Feature) => {
    if (feature.geometry.type === "LineString") {
      const numberOfCoords = feature.geometry.coordinates.length;
      if (numberOfCoords > 2) {
        const centerIndex = Math.floor(numberOfCoords/2);
        return [...feature.geometry.coordinates[centerIndex]].reverse() as LatLngExpression;
      } else {
        const centerObj = pointOnFeature(feature as GeoJSON.Feature<GeoJSON.Geometry | any>);
        return [...centerObj.geometry.coordinates].reverse() as LatLngExpression;
      }
    }
    return [0, 0] as LatLngExpression;
  }

  removeMarkers = () => {
    if (this.state.featureCenterMarkers.length > 0) {
      this.setState(prevState => {
        prevState.featureCenterMarkers.forEach(marker => {
          marker.remove();
        });
        return {
          ...prevState,
          featureCenterMarkers: []
        }
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.mapRef.current && this.state.routes.length > 0) {
      const map = this.mapRef.current;
      const geoJsonLayer = L.geoJSON(this.state.featureCollection);
      const bounds = geoJsonLayer.getBounds();
      map.fitBounds(bounds);
    }

    if (this.mapRef.current && this.state.hasLoadedFeatureCollection) {
      this.createMarker(this.mapRef.current);
    }
  }
  
  getFeatureStyle = (feature: any) => {
    const featureStyle = {
      fillColor: "black",
      weight: 3,
      opacity: 1,
      color: "#2ecc71",
      fillOpacity: 1
    };
    const featureIndex = this.state.featureCollection.features.indexOf(feature);
    const { features } = this.state.featureCollection;
    if (features.length === 1) {
      featureStyle.color = "#000000";
    } else if (featureIndex === 0 || featureIndex === features.length - 1) {
      featureStyle.color = "#000000";
    } else {
      featureStyle.color = "#3096FC";
    }

    return featureStyle;
  }

  getFeatureCenterIcon = (featureIndex: number) => {
    const { transportMode } = this.state;
    let icon;
    if (transportMode === "air") {
      icon = airplaneCircle;
    } else if (transportMode === "road") {
      icon = truckCircle;
    } else if (transportMode === "sea") {
      icon = shipCircle;
    } else if (transportMode === "rail") {
      icon = trainCircle;
    }

    if (
      this.state.featureCollection.features.length === 1 ||
      featureIndex === 0 ||
      featureIndex === this.state.featureCollection.features.length - 1
    ) {
      icon = truckCircle;
    }

    return icon;
  }
  // Customizable Area End
}
