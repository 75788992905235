import React, { CSSProperties } from "react";
import {
  IconButton,
  Modal,
  Paper,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { sadFace } from "../assets";

interface FailureDialogProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

export default function FailureDialog(props: FailureDialogProps) {
  return (
    <Modal open={props.open} onClose={props.onClose} style={webStyle.modalStyle}>
      <Paper style={webStyle.paperStyle as CSSProperties}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <img src={sadFace}
            style={{ height: "183px", width: "198px" }}
          />
          <Typography style={webStyle.accountemailmaintitle as CSSProperties}>
            {props.message}
          </Typography>
        </div>
      </Paper>
    </Modal>
  );
}

const webStyle = {
  accountemailmaintitle: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "#34373A",
  },
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperStyle: {
    width: '460px',
    maxWidth: "100%",
    minHeight: '300px',
    padding: '20px 20px 40px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
};