Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiContentTypeApplicationJson = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.postAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfunsdgs";
exports.labelBodyText = "cfunsdgs Body";
exports.btnExampleTitle = "CLICK ME";
exports.getUNSDGGoalsAPiEndPoint = "bx_block_cfunsdgs/unsdg_goals_list";
exports.getSavedUNSDGGoalsAPiEndPoint = "bx_block_cfunsdgs/selected_unsdg_goals_list";
exports.saveUNSDGGoalsAPiEndPoint = "bx_block_cfunsdgs/save_unsdg_goals";
// Customizable Area End