import React, { useState } from "react";
import {
  Typography as TypographyMUI,
  Box,
  Button as ButtonMUI,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { check } from "../assets";

interface Props {
  showRemove?: boolean;
  goalNumber?: string;
  goalEnabled: boolean;
  goalTitle?: string;
  goalIcon?: string;
  goalSelectedIcon?: string;
  goalDescription?: string;
  onSelectClick: () => void;
}

export default function Card(props: Props) {
  const [isHovered, setIsHovered] = useState(false);
  let selectButtonLabel = "Select";
  if (props.goalEnabled && props.showRemove) {
    selectButtonLabel = "Remove";
  } else if (props.goalEnabled && !props.showRemove) {
    selectButtonLabel = "Unselect";
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <GoalCard
      data-test-id={props.goalNumber}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ width: "100%" }}>
        <GoalNumberContainer>
          <GoalNumber>{props.goalNumber}</GoalNumber>
          <img 
            src={check} 
            alt="check"
            style={{ visibility: props.goalEnabled ? "visible" : "hidden" }} 
          />
        </GoalNumberContainer>
        <GoalTitle>{props.goalTitle}</GoalTitle>
        <GoalDescription>{props.goalDescription}</GoalDescription>
      </div>
      <GoalActionContainer>
        <img 
          src={props.goalEnabled ? props.goalSelectedIcon : props.goalIcon} 
          alt={props.goalTitle}
          style={{ maxWidth: 53 }}
        />
        <SelectButton
          data-test-id="select-btn"
          style={{ visibility: isHovered ? "visible" : "hidden" }}
          onClick={props.onSelectClick}
        >
          {selectButtonLabel}
        </SelectButton>
      </GoalActionContainer>
    </GoalCard>
  );
}

// Styled components
const GoalNumberContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const GoalActionContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const GoalCard = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "18px 16px",
  flexShrink: 0,
  borderRadius: "12px",
  width: "100%",
  minHeight: "212px",
  backgroundColor: "#ffffff",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#ECFBF1",
  }
});

const GoalNumber = styled(TypographyMUI)({
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "19.2px",
  textAlign: "left",
  color: "#7D7D7F",
});

const GoalTitle = styled(TypographyMUI)({
  fontFamily: "Lato",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "21.6px",
  letterSpacing: "0.20000000298023224px",
  textAlign: "left",
  color: "#34373A",
  margin: "15px 0 5px 0"
});

const GoalDescription = styled(TypographyMUI)({
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "19.2px",
  textAlign: "left",
  color: "#7D7D7F"
});

const SelectButton = styled(ButtonMUI)({
  minHeight: "40px",
  minWidth: "97px",
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  backgroundColor: "#000000",
  color: "#ffffff",
  textTransform: "none",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  '& span': {
    fontFamily: "Inter",
    fontWeight: 700,
  },
  '&:hover': {
    backgroundColor: "#000000",
  },
});