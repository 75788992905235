import React from "react";
import {CircularProgress} from '@material-ui/core';

import BlogPostsManagementController, {
  Props
} from "./BlogPostsManagementController";
import CareerDetail from "../../../components/src/CareerDetail";

export default class JobDetailWeb extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (this.state.jobDetails.length === 0) {
      return <div
      style={webStyle.circularProgress}
    >
      <CircularProgress color="inherit" />
    </div>;
    }

    return <CareerDetail {...this.state.jobDetails} navigate={this.navigateToJobApplication}/>;
  }
}

const webStyle = {
  circularProgress:{
    color: "grey", 
    height: "calc(100vh - 145px)", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center"
  }
}