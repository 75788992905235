import React from "react";
import { Container, Grid, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlayArrow from "@material-ui/icons/PlayArrow";
import parse from "html-react-parser";
import {BsArrowUp} from "react-icons/bs";

const useStyles = makeStyles(() => ({
  mainBlock: {
    paddingTop: "60px",
  },
  container: {
    padding: "25px",
    borderRadius: "15px",
    background: "#EEF1F4",
    display: "flex",
    rowGap: "24px",
  },
  leftBlock: {
    paddingRight: "65px",
    "@media (max-width: 768px)": {
      paddingRight: "0",
    },
  },
  rightBlock: {
    padding: "40px",
    borderRadius: "16px",
    backgroundColor: "#fff",
    "@media (max-width: 768px)": {
      padding: "20px",
    },
  },
  imageBlock: {
    marginTop: "28px",
    position: "relative",
    borderRadius: "20px",
    overflow: "hidden",
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    padding: "10px",
    color: "#fff",
    backgroundColor: "#000",
    transition: "all ease 0.3s",
    "&:hover": {
      backgroundColor: "#c72929",
      cursor: "pointer",
    },
  },
  descriptionTextBlock: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    padding: "12px",
    borderRadius: "20px",
    width: "90%",
    background: "linear-gradient(46deg, #231A87 0%, #43d270 100%)",
  },
  descriptionTextBlockInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "6px",
    padding: "20px",
    "@media (max-width: 768px)": {
      padding: "10px",
    }
  },
  heading5: {
    fontSize: "26px",
    fontWeight: 700,
    lineHeight: "32px",
    textTransform: "capitalize",
    "@media (max-width: 768px)": {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  description1: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
  buttonPercentage: {
    backgroundColor: "#43d270",
    borderRadius: "4px",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "20px",
    padding: "12px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "4px",
    "@media (max-width: 768px)": {
      padding: "12px",
      fontSize: "14px",
    }
  },
  rightBlockHeading: {
    "& p": {
      fontFamily: "Montserrat",
      fontSize: "32px",
      color: "#1e1e1e",
      fontWeight: 600,
      marginBottom: "10px",
      "@media (max-width: 768px)": {
        fontSize: "24px",
      }
    }
  },
  reasonBlock: {
    marginTop: "10px",
    "& h5": {
      color: "#1e1e1e",
      fontSize: "22px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      lineHeight: "32px",
      textTransform: "capitalize",
      "@media (max-width: 768px)": {
        fontSize: "18px",
        lineHeight: "26px",
      },
    },
    "& p": {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#747474",
    },
  },
  reasonItem: {
    borderRadius: "20px",
    padding: "20px",
    display: "flex",
    gap: "24px",
    " &> img": {
      with: "60px",
      height: "60px",
    },
    "@media (max-width: 768px)": {
      padding: "10px",
    },
  },
  buttonBlock: {
    fontFamily: "'Lato', sans-serif",
    marginTop: "32px",
    color: "#000",
    backgroundColor: "#43d270",
    padding: "12px 32px",
    borderRadius: "4px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "0.3s",
    cursor: "pointer",
    "& img": {
      marginLeft: "10px",
    },
    "&:hover": {
      backgroundColor: "#897ecc",
    },
    "@media (max-width: 768px)": {
      padding: "12px"
    },
  },
}));

export default function WhyCarbonzeroed(props: any) {
  const {
    header,
    description,
    info_1,
    info_2,
    info_3,
    image,
    video,
    widget_item_buttons: { data },
    widget_sub_items,
    navigate
  } = props;
  const styles = useStyles();
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenCloseModal = (status: boolean) => {
    setOpenModal(status);
  };

  return (
    <div className={styles.mainBlock}>
      <Container>
        <Grid container className={styles.container}>
          <Grid item xs={12} lg={6} className={styles.leftBlock}>
            <div className={styles.imageBlock}>
              <img style={{ width: "100%" }} src={image} alt="solar" />
              <PlayArrow
                className={styles.playButton}
                onClick={() => handleOpenCloseModal(true)}
              />
              <div className={styles.descriptionTextBlock}>
                <Grid container className={styles.descriptionTextBlockInner}>
                  <Grid item md={8}>
                    <h5 className={styles.heading5}>{info_1}</h5>
                    <p className={styles.description1}>{info_2}</p>
                  </Grid>
                  <Grid item md={4}>
                    <div className={styles.buttonPercentage}>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.57643 12.3474V3.03905L11.6431 7.10572C11.9681 7.43072 12.5014 7.43072 12.8264 7.10572C13.1514 6.78072 13.1514 6.25572 12.8264 5.93072L7.33477 0.439051C7.17907 0.283007 6.9677 0.195312 6.74727 0.195312C6.52683 0.195312 6.31546 0.283007 6.15977 0.439051L0.659766 5.92238C0.334766 6.24738 0.334766 6.77238 0.659766 7.09738C0.984766 7.42238 1.50977 7.42238 1.83477 7.09738L5.90977 3.03905V12.3474C5.90977 12.8057 6.28477 13.1807 6.7431 13.1807C7.20143 13.1807 7.57643 12.8057 7.57643 12.3474Z"
                          fill="#0F172A"/>
                      </svg>
                      {info_3}</div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <span onClick={() => navigate(data[0].attributes.link)} className={styles.buttonBlock}>
              {data[0].attributes.name}
              <img src={data[0].attributes.icon} alt="arrow"/>
            </span>
          </Grid>

          <Grid item xs={12} lg={6}>
            <div className={styles.rightBlock}>
              <div className={styles.rightBlockHeading}>{parse(header)}</div>
              <p style={{lineHeight: "26px", color: "#747474"}}>{parse(description)}</p>

              <div className={styles.reasonBlock}>
                {widget_sub_items.data.map((item: any) => {
                  const {title, description, image} = item.attributes;
                  return (
                    <div key={item.id} className={styles.reasonItem}>
                      <img src={image} alt=""/>
                      <div>
                        <h5>{title}</h5>
                        <p>{parse(description)}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={openModal}
          onClose={() => handleOpenCloseModal(false)}
          maxWidth="md"
        >
          <video height="100%" width="100%" controls>
            <source src={video} type="video/mp4" />
            <source src={video} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </Dialog>
      </Container>
    </div>
  );
}
