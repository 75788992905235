import React from "react";

// Customizable Area Start
import {
    Button,
    Grid,
    InputLabel,
    TextField
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import { ArrowUpward } from '@material-ui/icons';

// Customizable Area End

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class ForgotPasswordWeb extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <Formik
                    initialValues={this.state.initialValue}
                    validationSchema={this.state.emailSchema}
                    onSubmit={values => this.handleLogin(values)}
                >
                    {formik => {
                        const {
                            values,
                            touched,
                            handleChange,
                            handleSubmit,
                            errors,
                            handleBlur
                        } = formik;
                        return (

                            <form
                                onSubmit={handleSubmit}
                                className="form-one"
                                data-test-id="accountLogin"
                            >
                                <Grid container>
                                    <Grid item>
                                        <InputLabel htmlFor="email" style={webStyle.labelInput}>
                                            Enter your email to reset your password
                                        </InputLabel>
                                        <TextField
                                            id="email"
                                            data-test-id="email-text-field"
                                            name="email"
                                            placeholder="Enter your work email you used at the signup"
                                            fullWidth
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            margin="normal"
                                            onChange={handleChange}
                                            value={values.email}
                                            style={{
                                                ...webStyle.textFieldBorder,
                                                border: touched.email && errors.email ? "#FFA39E solid 1px" : "#CBD5E1 solid 1px",
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        {touched.email && errors.email ? (
                                            <div style={webStyle.red}>{errors.email}</div>
                                        ) : null}
                                        {this.state.errors && (
                                            <div style={webStyle.red}>{this.state.errors}</div>
                                        )}
                                    </Grid>
                                </Grid>
                                <div style={webStyle.ButtonStyle}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: this.isGoNext(errors, values),
                                            borderRadius: "8px",
                                            width: '184px',
                                            height: '56px',
                                            color: '#FFFFFF',
                                        }}
                                        type="submit"
                                        endIcon={<ArrowUpward style={webStyle.icon} />}
                                    >
                                        SUBMIT
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </ThemeProvider >
        );
    }
}

const webStyle = {
    containerStyle: {
        paddingTop: "75px"
    },
    form: {
        maxWidth: "500px",
        margin: "20px auto"
    },
    btn: {
        background: "#B1B3C3",
        borderRadius: "8px",
        padding: "16px 12px",
        width: "186px",
        color: "#101010",
        fontSize: "15px",
        fontWeight: 700,
        display: "flex",
        margin: "auto"
    },
    labelInput: {
        color: "#34373A",
        fontFamily: "Lato !important",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
    },
    textFieldBorder: {
        border: "#D9D9D9 solid 1px",
        borderRadius: "8px",
        padding: "8.5px 14px",
        font: "inherit",
        height: "48px",
        maxWidth: '404px',
        width: '404px'
    },
    red: {
        color: "#F5222D",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px"
    },
    ButtonStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "70px",
        maxWidth: '404px'
    },
    button: {
        backgroundColor: "#34373A",
        redius: "8px",
        width: '184px',
        height: '56px',
        color: '#FFFFFF',
    },
    icon: {
        rotate: "45deg",
        marginLeft: "5px"
    }
};
