Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EmailNotifications2";
exports.labelBodyText = "EmailNotifications2 Body";
exports.emalAPIEndPoint = "bx_block_email_notifications/news_letter_subscribers";
exports.btnExampleTitle = "CLICK ME";
exports.urlGetValidations ="profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.newsleterUnsubscriptionAPIEndPoint = "bx_block_email_notifications/blog_unsubscriptions";
// Customizable Area End