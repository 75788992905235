import React from "react";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import {
  styled
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { folderIcon, locationIcon, descriptionIcon, formatIcon } from "../assets";

const CustomPaper = styled(Paper)({
  padding: "40px 30px",
  borderRadius: 12,
  position: "relative",
  "@media(max-width: 600px)": {
    padding: "25px 15px",
  },
});

const CloseIconContainer = styled(Box)({
  position: "absolute",
  top: 15,
  right: 15,
});

const PrimaryText = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "16px",
  textAlign: "left",
  color: "#34373A",
});

const SecondaryText = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "21px",
  textAlign: "left",
  color: "#34373A"
});

const Anchor = styled("a")({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "21px",
  textAlign: "left",
  color: "#0131A8",
  textDecoration: "none"
});

const CapitalizedText = styled("span")({
  textTransform: "capitalize"
});

function formatMettingDate(dateString: string) {
  const date: Date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long' };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  return formattedDate;
}

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  location: string;
  description: string;
  host: string;
  meetingType: string;
  meetingDate: string;
  meetingStartTime: string;
  meetingEndTime: string;
  meetingJoinLink: string;
  meetingId: string;
  meetingPassCode: string;
  meetingStatus: string;
}

export default function AppointmentDetailsDialog({
  open,
  onClose,
  title,
  location,
  description,
  host,
  meetingType,
  meetingDate,
  meetingStartTime,
  meetingEndTime,
  meetingJoinLink,
  meetingId,
  meetingPassCode,
 meetingStatus,
}: Props) {
  const meetingDateWithDayOfWeek = formatMettingDate(meetingDate);
  const meetingDateWithTime = `${meetingDateWithDayOfWeek} / ${meetingStartTime} - ${meetingEndTime}`;
  
  let meetingIdText;
  let meetingPassCodeText;
  if (meetingId) {
    meetingIdText = <SecondaryText style={{ marginBottom: 10 }}>Meeting ID: {meetingId}</SecondaryText>;
  }
  if (meetingPassCode) {
    meetingPassCodeText = <SecondaryText>Passcode: {meetingPassCode}</SecondaryText>;
  }

  return (
    <Dialog
      data-test-id="appiontment-details"
      maxWidth="sm"
      PaperComponent={CustomPaper}
      open={open}
      onClose={onClose}
    >
      <CloseIconContainer>
        <IconButton onClick={onClose}>
          <CloseIcon style={{ color: "#000" }} />
        </IconButton>
      </CloseIconContainer>
      {/* Meeting title starts */}
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={12} sm={1}>
          <img src={folderIcon} alt="Appointment title" />
        </Grid>
        <Grid item xs={12} sm={11}>
          <PrimaryText>{title}</PrimaryText>
          <SecondaryText style={{ marginTop: 10 }}>{meetingDateWithTime}</SecondaryText>
        </Grid>
      </Grid>
      {/* Meeting title ends */}
      {/* Meeting link starts */}
      <Grid container alignItems="center" style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={1}>
          <img src={locationIcon} alt="Appointment location" />
        </Grid>
        <Grid item xs={12} sm={11}>
          <SecondaryText>
            {location}
          </SecondaryText>
        </Grid>
      </Grid>
      {/* Meeting link starts */}
      {/* Meeting descriptions starts */}
      <Grid container alignItems="flex-start" style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={1}>
          <img src={descriptionIcon} alt="Appointment description" />
        </Grid>
        <Grid item xs={12} sm={11}>
          <SecondaryText>
            {description}
          </SecondaryText>
        </Grid>
      </Grid>
      {/* Meeting descriptions ends */}
      {/* Meeting details starts */}
      <Grid container alignItems="flex-start" style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={1}>
          <img src={formatIcon} alt="Appointment details" />
        </Grid>
        <Grid item xs={12} sm={11}>
        <SecondaryText>
          <CapitalizedText>{host}</CapitalizedText> is inviting you to a scheduled{" "}
          <CapitalizedText>{meetingType}</CapitalizedText> meeting.
        </SecondaryText>
          <Box style={{ margin: "30px 0" }}>
            <SecondaryText 
              style={{ 
                textTransform: "capitalize",
                marginBottom: 10
              }}
            >
              Join {meetingType} Meeting
            </SecondaryText>
            {meetingStatus !== "Completed " ?
              <Anchor href={meetingJoinLink} target="_blank">
                {meetingJoinLink}
              </Anchor>
              :
              <SecondaryText>{meetingJoinLink}</SecondaryText>
            }
          </Box>
          <Box style={{ margin: "30px 0 0 0" }}>
            {meetingIdText}
            {meetingPassCodeText}
          </Box>
        </Grid>
      </Grid>
      {/* Meeting details ends */}
    </Dialog>
  )
}