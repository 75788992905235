import React, {useState} from "react";
import {Box, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import parse from "html-react-parser";
import WidgetHeadingText from "./WidgetHeadingText";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles(theme => ({
  background: {
    position: "absolute",
    width: "100%",
    height: "750px",
    zIndex: -1,
    background: "linear-gradient(0deg, #fff 0%, #e5eaff 100%)"
  },
  mainBlock: {
    position: "relative",
    padding: "80px 10px"
  },
  headingBlock: {
    textAlign: "center"
  },
  subHeading: {
    fontSize: "18px",
    color: "#595959",
    marginTop: "12px"
  },
  jobBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "80px 0px",
    "&:hover": {
      cursor: "pointer"
    },
    "@media(max-width: 768px)": {
      padding: "40px 20px"
    }
  },
  jobItem: {
    border: "1px solid #cecece",
    borderRadius: "16px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "30px",
    overflow: "hidden",
    "@media(max-width: 768px)": {
      gridTemplateColumns: "1fr",
      gap: "0"
    }
  },
  careerTitle: {
    fontSize: "32px",
    fontWeight: 800,
    lineHeight: "32px",
    textTransform: "capitalize",
    "@media(max-width: 768px)": {
      fontSize: "18px",
      lineHeight: "28px"
    }
  },
  description: {
    marginTop: "12px",
    color: "#4a4a4a"
  },
  specificity: {
    padding: "32px 32px 32px 0",
    "@media(max-width: 768px)": {
      padding: "20px"
    }
  },
  specificityItem: {
    display: "flex",
    gap: "12px",
    marginTop: "8px",
    alignItems: "center",
    fontFamily: "Lato",
    fontWeight: 600
  },
  imageBlock: {
    overflow: "hidden",
    "& img": {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "0px",
      objectFit: "cover",
      transition: "all ease 0.5s",
      width: "100%",
      height: "100%",
      transform: "scale(1.01)"
    },
    "&:hover img": {
      transform: "scale(1.1)"
    }
  },
  label: {
    fontSize: "18px",
  },
  value: {
    fontSize: "18px",
  },
  date: {
    marginTop: "12px",
    color: "#5a5a5a",
    textTransform: "uppercase"
  },
  pagination: {
    color: "red",
    "& .Mui-selected": {
      background: "#43D270 !important",
      height: "52px",
      width: "52px",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22.4px",
      color: "#000000"
    },
    "& > ul > li": {
      marginRight: "15px"
    },
    "& > ul > li > button": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22.4px",
      color: "#000000"
    },
  },
  heading: {
    "&, & > p": {
      marginTop: "12px",
      fontSize: "48px",
      fontWeight: 800,
      fontFamily: "Lato",
      lineHeight: "52px",
      color: "#191919",
      textTransform: "capitalize",
      "@media (max-width: 768px)": {
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: "8px",
      },
    },
  },
}));

function Career(props: any) {
  const {
    page_heading,
    page_sub_heading,
    career_items,
    viewJobDetails
  } = props;
  const styles = useStyles();
  const [currentPage, setCurrentPage] = useState(1)
  const itemPerPage = 3;
  const totalPage = Math.ceil(career_items.data.length / itemPerPage);

  const handlePageChange = (event: any, value: number) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  }

  const startIndex = (currentPage - 1) * itemPerPage;
  const currentItems = career_items.data.slice(startIndex, startIndex + itemPerPage);

  return (
    <>
      <div className={styles.background}/>
      <div className={styles.mainBlock}>
        <Container>
          <div className={styles.headingBlock}>
            <div className={styles.heading}>{page_heading}</div>
            <p className={styles.subHeading}>{page_sub_heading}</p>
          </div>
          <div className={styles.jobBlock}>
            {currentItems.map((item: any) => {
              const {
                career_title,
                career_description,
                experience,
                location,
                career_image,
                career_technologies,
                created_at
              } = item.attributes;
              const date = new Date(created_at);

              const options = {year: 'numeric', month: 'long', day: 'numeric'};
              const humanReadableDate = date.toLocaleDateString('en-GB', options);

              const technologyNames = career_technologies.data.map(
                (item: any) => item.attributes.technology_name
              );
              const technologies = technologyNames.join(", ");
              const specificity = [
                {label: "Technology", value: technologies},
                {label: "Experience", value: experience},
                {label: "Location", value: location}
              ];

              return (
                <div
                  key={item.id}
                  className={styles.jobItem}
                  onClick={() => viewJobDetails(item.id, item.type)}
                >
                  <div className={styles.imageBlock}>
                    <img
                      src={career_image}
                      style={{opacity: career_image ? 1 : 0}}
                      alt={career_title}
                    />
                  </div>
                  <div className={styles.specificity}>
                    <h3 className={styles.careerTitle}>{career_title}</h3>
                    <p className={styles.description}>
                      {parse(career_description)}
                    </p>
                    <div>
                      {specificity.map(item => {
                        return (
                          <div className={styles.specificityItem}>
                            <span className={styles.label}>
                              {item.label}:{" "}
                            </span>
                            <span className={styles.value}>{item.value}</span>
                          </div>
                        );
                      })}
                      <p className={styles.date}>{humanReadableDate}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>

        <Box display="flex" justifyContent="center" pt={5} pb={5}>
          <Pagination
            count={totalPage}
            shape="rounded"
            className={styles.pagination}
            onChange={handlePageChange}
          />
        </Box>
      </div>
    </>
  );
}

export default Career;