import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { linkedInIcon } from "../../../blocks/DynamicContent/src/assets";
import WidgetHeadingText from "../WidgetHeadingText";

const useStyles = makeStyles(() =>
  createStyles({
    block: {
      padding: "80px 50px",
      "@media (max-width: 768px)": {
        padding: "40px 20px",
      },
    },
    blockHeading: {
      textAlign: "center",
    },
    heading: {
      fontWeight: 700,
      color: "#191919",
      textTransform: "capitalize",
    },
    body: {
      marginTop: "16px",
      color: "#686868",
    },
    listLeaders: {
      marginTop: "40px",
      rowGap: "32px",
    },
    leaderItem: {
      padding: "0 15px",
      "&:hover img": {
        transform: "scale(1.05)",
        transition: "all ease 0.3s",
      },
      "&:hover h5::before": {
        width: "100%",
        transition: "all ease 0.3s",
      },
    },
    blockImage: {
      display: "block",
      borderRadius: "28px",
      overflow: "hidden",
      textDecoration: "none",
      maxWidth: "100%",
      maxHeight: "370px",
    },
    leaderImage: {
      width: "100%",
      objectFit: "cover",
      aspectRatio: "1/1",
    },
    info: {
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    contact: {
      width: "42px",
      height: "42px",
      borderRadius: "50%",
      border: "2px solid #000",
      transition: "all ease 0.3s",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    left: {
      textDecoration: "none",
      color: "#000",
    },
    name: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",
      textTransform: "capitalize",
      position: "relative",
      display: "inline-block",
      "&::before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 0,
        height: "2px",
        backgroundColor: "#000",
        transition: "all ease 0.3s",
      },
      "@media (max-width: 768px)": {
        fontSize: "18px",
        lineHeight: "26px",
      },
    },
    role: {
      marginTop: "4px",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#686868",
    },
    icon: {
      width: "16px",
      height: "16px",
    },
  })
);

export default function Leader(props: any) {
  const { header, description, meet_leader_widget_items } = props;
  const styles = useStyles();
  return (
    <div className={styles.block}>
      <Container style={{ padding: 0 }}>
        <div className={styles.blockHeading}>
          <WidgetHeadingText>{parse(header)}</WidgetHeadingText>
          <p className={styles.body}>{parse(description)}</p>
        </div>

        <Grid container className={styles.listLeaders}>
          {meet_leader_widget_items.data.map((item: any) => {
            const {
              leader_name,
              leader_designation,
              leader_image,
              leader_link,
              leader_company,
            } = item.attributes;
            return (
              <Grid item sm={6} lg={4} key={item.id}>
                <div className={styles.leaderItem}>
                  <div className={styles.blockImage}>
                    <img
                      src={leader_image}
                      alt={leader_name}
                      className={styles.leaderImage}
                    />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.left}>
                      <h5 className={styles.name}>{leader_name}</h5>
                      <p className={styles.role}>
                        {leader_designation}{" "}
                        {leader_company && ` - ${leader_company}`}
                      </p>
                    </div>
                    <div>
                      <div onClick={() => window.open(leader_link, "_blank")} className={styles.contact}>
                        <img
                          src={linkedInIcon}
                          alt="linkedin"
                          className={styles.icon}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
