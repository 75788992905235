import React from "react";
import {
    Box,
    Typography,
    Grid,
} from "@material-ui/core";
import {
    makeStyles,
} from '@material-ui/core/styles';
import { login } from "../assets";
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close'
import EmailAccountLoginBlock from "../../../email-account-login/src/EmailAccountLoginBlock.web";
import { NavigationProp, ParamListBase } from "@react-navigation/native";

const useStyles = makeStyles({
    rightContent: {
        padding: '50px 30px 0 30px',
        maxHeight: '900px',
        maxWidth: '732px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            maxHeight: '850px',
             maxWidth: '852px',
        }
    },
    cover: {
        width: '100%',
        maxHeight: '900px',
        maxWidth: '708px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            maxHeight: '850px',
             maxWidth: '852px',
        }
    },
    title: {
        fontFamily: 'Montserrat !important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "46px",
        color: "#34373A",
        marginTop: '100px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            marginTop: '30px',
            textAlign:'center'
        }
    },
    logintxt: {
        fontFamily: 'Lato !important',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "160%",
        color: "#0F172A",
        textAlign: 'center',
        marginTop: '40px',
        marginRight: '18px',
        maxWidth: '404px',
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            maxWidth: '780px',
        }
    },
    loginlink: {
        color: '#7166F1',
        textDecoration: 'none'
    },
    closeicon: {
        display: 'flex',
        justifyContent: 'end'
    },
    form: {
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            width:'100%',
            display:'flex',
            alignItems:'center',
            placeContent:'center',
            marginBottom:'30px'
        }
    },
    instructions: {
        marginTop: "8px",
        marginBottom: "8px",
        '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
            marginTop: '40px',
            width:"400px"
        }
    },
    red: {
        color: "red",
        marginTop: "10px",
    }
})

interface Props {
    navigation?: NavigationProp<ParamListBase>;
    id?: string;
}

const Login = (props: Props) => {

    const classes = useStyles()

    return (
        <>
            <Box sx={{flexGrow: 1,}}
                 style={{display: 'grid', placeItems: 'center', height: '100vh', backgroundColor: '#e9eefa'}}>
                <Grid container style={{ maxWidth: '1450px', boxShadow: '4px 2px 7px #8C8C8C' }}>
                    <Grid item xs={12} sm={12} md={7} lg={7} style={{ backgroundColor: 'white' }}>
                        <Box>
                            <img src={login} className={classes.cover} alt="content" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} style={{ backgroundColor: 'white' }}>
                        <Box className={classes.rightContent}>
                            <Box className={classes.closeicon}>
                                <Link to="/" style={{ color: 'black' }}>
                                    <CloseIcon style={{ color: 'black' }} />
                                </Link>
                            </Box>
                            <Typography className={classes.title}>Login to your account</Typography>
                            <Box className={classes.form}>
                                <Box className={classes.instructions}>
                                <EmailAccountLoginBlock navigation={props.navigation} id={""} open={false} onClose={undefined} />
                                </Box>
                            </Box>
                            <Typography className={classes.logintxt}>Don’t have an account?
                                <Link to="/signup" className={classes.loginlink}> Sign Up</Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Login;
