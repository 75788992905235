// Customizable Area Start
import React, { useEffect, useState } from "react";
import { makeStyles, ThemeProvider, createTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Drawer,
  IconButton,
  Box,
  Grid,
  Button,
  styled,
  Menu,
  MenuItem,
  Typography,
  Fade, Container
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Link } from "react-router-dom";

import {logo, logoLight, triangle_img} from "../../blocks/DynamicContent/src/assets";
import {removeStorageData} from "../../framework/src/Utilities";

interface SubSubItemsAttributes1{
  sub_header_position:number
  sub_header_title:string
  sub_link_type:string | null
  sub_predefined_link:string
  sub_external_link:string
  sub_header_sub_icon:string
  page:string
}

interface HeaderSubSubItems1{
  id:string
  type:string
  attributes:SubSubItemsAttributes1
}

interface SubItemsAttributes1 {
  position:number
  title:string
  link_type:string |  null
  predefined_link:string
  external_link:string
  page:string | null
  header_sub_sub_items:{
      data:HeaderSubSubItems1[]
  }
}
interface HeaderSubItems1 {
  id:string
  type:string
  attributes:SubItemsAttributes1
}
interface HeaderAttribute1{
  position:number
  title:string
  link_type:string | null
  predefined_link:string | null
  external_link:string | null
  page:string | null
  header_sub_items:{
      data:HeaderSubItems1[]
  }
}
interface Props {
  headerDataFromApi:{
    data:{
      id:string
      type:string
      attributes:HeaderAttribute1
  }[]
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily:'Lato,Montserrat,Instrument Sans,Plus Jakarta Sans,sans-serif',
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    backgroundColor: "transparent !important"
  },
  menuPaperWhite: {
    backgroundColor: "transparent !important",
    padding:'15px',
    borderRadius:'0 0 16px 16px',
    boxShadow:'none',
    width:'100%'
  },
  appbar: {
    background: "#34373A",
    padding:'13px 0',
    boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.1)",
    justifyContent: "center",
  },
  appbarLight: {
    background: "#fff",
    boxShadow: "none",
    justifyContent: "center",
    padding:'20px 0',
  },
  toolbar: {
    justifyContent: "space-between",
    width: "100%",
    overflow: "hidden",
    margin: "0 auto",
    '& .MuiToolbar-gutters': {
      paddingLeft: '0px',
      paddingRight: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '12px'
    },
    '@media (max-width: 1229px)': {
      padding: '0px 30px',
    },
    '@media (max-width:1130px)': {
      '& div:first-child': {
        width: "100%",
        display: "flex",
        justifyContent: " space-between"
      },
    },
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarLink: {
    fontWeight: 500,
    fontSize: "15px",
    letterSpacing: "0.2px",
    color: "rgba(255, 255, 255, 0.5)",
    fontFamily: "Lato",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#fff",
      fontWeight: 700,
    },
  },
  menuLabel: {
    fontSize: "14px",
    color: "#0C1228",
    fontFamily: "Plus Jakarta Sans",
    fontWeight:700,
    padding:'6px 0',
    margin:'0 16px',
    background:'linear-gradient(to right, #43D270, #43D270),linear-gradient(to right, #43D270, #43D270, #43D270)',
    backgroundSize: '0 3px, 0 3px',
    backgroundPosition: '100% 100%, 0 100%',
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 400ms',
    '&:hover':{
        backgroundSize:'0 3px, 100% 3px',
        backgroundColor:'transparent'
    },
  },
  menuLabelActive: {
    fontSize: "14px",
    color: "#0C1228",
    fontFamily: "Plus Jakarta Sans",
    fontWeight:700,
    padding:'6px 0',
    margin:'0 16px',
    background:'linear-gradient(to right, #43D270, #43D270),linear-gradient(to right, #43D270, #43D270, #43D270)',
    // backgroundSize: '0 3px, 0 3px',
    backgroundPosition: '100% 100%, 0 100%',
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 400ms',
    backgroundSize:'0 3px, 100% 3px',
    backgroundColor:'transparent'
  },
  highlighthome: {
    fontSize: "15px",
    color: "#FFFFFF",
    fontFamily: "Lato",
  },
  hightlight: {
    fontSize: "14px",
    color: "#0C1228",
    fontFamily: "Plus Jakarta Sans",
    fontWeight:700,
    background:'linear-gradient(to right, #43D270, #43D270),linear-gradient(to right, #43D270, #43D270, #43D270)',
    backgroundSize: '0 3px, 100% 3px',
    backgroundPosition: '100% 100%, 0 100%',
    backgroundRepeat: 'no-repeat',
    padding:'6px 0',
    margin:'0 16px',
  },

  subMenuLabel: {
    background: '#ecfbf1',
    padding:"0px 16px",
    '& li': {
      minHeight: '20px',
      lineHeight: '16px',
      fontSize: '16px',
      
    },
  },
  logo: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(1),
  },
  btnPrimary: {
    color: "inherit",
    borderRadius: "8px",
    fontSize: "12px",
    lineHeight: "14px",
    width: "85px",
    height: "38px",
    fontWeight: 500,
    "&:hover": {
      background: "transparent",
    },
  },
  btnbg: {
    textTransform: "capitalize",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#fff",
  },
  btnbgDark: {
    textTransform: "capitalize",
    fontFamily: "Lato",
    fontSize: "15px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#34373A",
  },
  btnBorder: {
    border: "1px solid #fff",
    marginLeft: "20px",
    color: "#ffff",
  },
  btnBorderDark: {
    border: "1px solid #34373A",
    marginLeft: "20px",
    color: "#34373A",
    borderRadius: "8px",
  },
  flexLink: {
  display: "flex",
    textDecoration: "none",
    '@media (max-width: 1130px)': {
      '& div:first-child': {
        width: "100%",
        display: "flex",
        justifyContent: " space-between",
       }
    },

  },
  drawerPaper: {
    width: 240,
  },
  listItem: {
    listStyle: "none",
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  toolbarItem: {
    padding: 0,
    margin: 0,
  },
  dropBox: {
    '& ul': {
      background: '#34373A',
    },
    '& div': {
      '@media (max-width: 780px)': {
        left: '120px!important',
      }
    }
  },
  dropTopPosition: {
    marginLeft:'300px'
  },
  dropTopSubPosition:{
  },
  dropTopPositonTwo:{
    marginLeft:'230px',
    marginRight:'130px'
  },
  dropdownItemText: {
    color: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      color: '#fff'
    }
  },
  ListText: {
    color: "#34373A",
    backgroundColor: "#7F7F7F",
    textDecoration: "none",
  },
  listLogo: {
    background: "#34373A",
    padding: "20px",
  },
  navBar: {
    display: "flex",
    justifyContent: "center",
    flexWrap:'wrap',
    width:'635px',
  },
  mainNavContainer:{
    flexWrap:'nowrap',
  },
  LightLogo: {
    '& img': {
      display: "none"
    }
  },
  headerDark: {
    '& .appbar': {
      color: '#fff',
      backgroundColor: '#34373A'
    },
    '& .logoLight': {
      '& img': {
        display: "block",
        width: "100%"
      }
    },
  },

  headerLight: {
    '& .MuiAppBar-root': {
      background: "#fff"
    },
    '& .toolbar-link': {
      color: "rgba(52, 55, 58, 0.5)",
      fontFamily: "Lato",
      '&:hover': {
        color: "rgba(52, 55, 58, 0.5)",
      }
    },
    "& .MuiListItem-button": {
      '&:hover': {
        background: "transparent",
        fontWeight: 700,
        color: "#34373A",
      },
    },
    '& .MuiButton-text': {
      color: "#34373A",
    },
    '& .MuiButton-root': {
      color: "#34373A"
    },
    '& .MuiButton-outlined': {
      border: "1px solid #34373A"
    },
    '& .MuiToolbar-regular': {
      '& .logoDark': {
        display: "none"
      }
    },
    '& .logoLight': {
      '& img': {
        display: "block",
        width: "100%"
      }
    },
    '& .MuiIconButton-colorInherit': {
      color: "#000"
    }
  },
  iconButtonStyle: {
    border: "1px solid #34373A",
    borderRadius: "8px",
    padding: "6px 8px",
  },
  mobileDiv: {
    display: 'flex',
    justifyContent: "end",
  },
  link: {
  },
  profileText: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: 'white', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#34373A',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    cursor: "pointer",
},
  profile: {
    width: "36px",
    height: "36px",
    borderRadius: "50px",
    backgroundColor: "#42d270",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '@media (max-width:600px)':{
      height:'40px' 
    }

   },
  loginButtonStyle:{
    background:'#70CF7A',
    borderRadius:'4px',
    fontFamily:'Plus Jakarta Sans',
    fontWeight:700,
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'14px',
    boxShadow:'none',
    padding:'12px 32px',
    transition: 'all .3s ease-in-out',
    '&:hover':{
        background:'#897ECC',
        boxShadow:'none',
    }
},
menuSpan:{
  maxWidth:"108px",
  whiteSpace:"pre-wrap",
},
signUpButtonStyle:{
    background:'#EBEDFC',
    borderRadius:'4px',
    fontFamily:'Plus Jakarta Sans',
    fontWeight:700,
    color:'#000',
    textTransform:'capitalize',
    padding:'12px 32px',
    fontSize:'14px',
    boxShadow:'none',
    '&:hover':{
        background:'#70CF7A',
        boxShadow:'none',
        color:'#fff'
    }
},
HideBoxMainContainer:{
  width: "100%",
  display: "flex",
  alignItems: "center"
},
arrowDown:{
  fontSize:'1.1rem'
},
titleStyle:{
  fontFamily:'Instrument Sans',
  fontSize:'20px',
  fontWeight:600,
  color:'#0C1228',
  lineHeight:'28px',
  padding:'20px 0 0 20px',
  textTransform: 'capitalize'
},
subHeaderBox:{
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(330px, 1fr))',
  margin:'20px 20px 10px',
  '@media(max-width: 1130px)': {
    flexDirection:'column',
    gap:'10px'
  },
},
imageMenuBox:{
  width:'330px',
  borderRadius:'16px',
  padding:'16px 0 16px 20px',
  display:'flex',
  gap:'10px',
  '&:hover':{
    background: 'linear-gradient(238deg, #FAF7EF 0%, #F4F6FC 99.66%)',
    cursor: "pointer",
  },
  '&:hover $subSubMenu': {
    backgroundSize: '0 1px, 100% 1px',
    backgroundColor: "transparent",
  },
  '@media(max-width: 1130px)': {
    width:'fit-content',
  },
},
MenuSubBox:{
  width: '100%'
},
subSubMenu:{
  padding:'0',
  fontFamily:'Lato',
  fontWeight:600,
  fontSize:'16px',
  color:'#262626',
  textWrap: 'wrap',
  lineHeight:'24px',
  background:'linear-gradient(to right, #000, #000),linear-gradient(to right, #000, #000, #000)',
  backgroundSize: '0 2px, 0 2px',
  backgroundPosition: '100% 100%, 0 100%',
  backgroundRepeat: 'no-repeat',
  transition: 'background-size 400ms',
},
profileMenuItem:{
  minHeight: "0px",
  fontSize:'14px',
  fontWeight:700,
  background:'#fff',
  '&:hover':{
    color:'green',
    background:'#EBEDFC'
  },
  '& p':{
    fontSize:'14px',
    fontWeight:700,
  }
},
MenuInsideBox:{
  backgroundColor: "#fff",
  borderRadius:'0 0 16px 16px',
  boxShadow: '0px 10px 25px 0px rgba(54, 95, 104, 0.1)',
  marginTop:'0',
  padding: '20px 20px 25px',
  transformOrigin: 'top center',
  transition:'all ease-in-out 0.3s',
  transitionDelay: '10s'
},
menuItemBoxtwo:{
  padding:'16px 20px',
  borderRadius:'8px',
  '&:hover':{
    background: 'linear-gradient(238deg, #FAF7EF 0%, #F4F6FC 99.66%)',
    cursor: 'pointer'
  },
  '&:hover $menuItemtwo': {
    backgroundSize: '0 1px, 100% 1px',
    width: 'max-content',
    backgroundColor: "transparent",
  }
},
menuItemtwo:{
  fontSize:'16px',
  fontWeight:600,
  lineHeight:'24px',
  position:'relative',
  color:'#000000',
  padding:'0',
  background:'linear-gradient(to right, #000, #000),linear-gradient(to right, #000, #000, #000)',
  backgroundSize: '0 1px, 0 1px',
  backgroundPosition: '100% 100%, 0 100%',
  backgroundRepeat: 'no-repeat',
  transition: 'background-size 400ms',
  zIndex: 1,
  width:'max-content',
},
listImages:{
  marginRight:'10px'
}
}));


const HiddenBox1 = styled('div')({
  width: '100%',
  '@media(max-width: 1130px)': {
    display: 'none',
  },
});

const HiddenBox2 = styled('div')({
  '@media(min-width: 1130px)': {
    display: 'none',
  },
});

export const getNavLinkByType = (item: any) => {
  const linkType = item?.attributes?.link_type;
  let returnLink = "/";
  if (linkType === "widget_page" && item.attributes.page) {
    returnLink = item.attributes.page
  } else if (linkType === "external" && item.attributes.external_link) {
    returnLink = item?.attributes?.external_link;
  } else if (linkType === "predefined" && item.attributes.predefined_link) {
    returnLink = item?.attributes?.predefined_link;
  }
  return returnLink;
}
export const getSubNavLinkByType = (item: HeaderSubSubItems1) => {
  const linkType = item?.attributes?.sub_link_type;
  let returnLink = "/";
  const encodedTitle = encodeURIComponent(item.attributes.sub_header_title.toLowerCase().replace(' ', ''));

  if (linkType === "widget_page" && item.attributes.page) {
    returnLink = `${item.attributes.page}?title=${encodedTitle}`;
  } else if (linkType === "external" && item.attributes.sub_external_link) {
    returnLink = `${item?.attributes?.sub_external_link}?title=${encodedTitle}`;
  } else if (linkType === "predefined" && item.attributes.sub_predefined_link) {
    returnLink = `${item?.attributes?.sub_predefined_link}?title=${encodedTitle}`;
  }
  return returnLink;
}
export const shouldHighlightDropdown = (item: any, pathname: any) => {
  if (item?.attributes?.header_sub_items?.data) {
    const index = item?.attributes?.header_sub_items?.data.findIndex(
      (subItem: any) => getNavLinkByType(subItem) === pathname || "/" + pathname.split("/")[1] === getNavLinkByType(subItem)
    );
    return index !== -1;
  }
  else {
    return false;
  }
};

export const ListRender = (props: any) => {
  const { headerDataFromApi, handleDrawerClose, handlePopoverOpen, handlePopoverClose } = props;
  const [expandedState, setExpandedState] = useState<any>({});
  const classes = useStyles();
  const handleExpand = (key: string) => {
    setExpandedState((prevState: any) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach(itemKey => {
        newState[itemKey] = false;
      });
      newState[key] = !prevState[key];
      return newState;
    });
  };


  return (
    <div>
      <ul className={classes.toolbarItem}>
        {headerDataFromApi && headerDataFromApi.data.length > 0 && headerDataFromApi.data.map((item:{id:string
            type:string
            attributes:HeaderAttribute1}, index: number) => {
          if (item?.attributes?.header_sub_items?.data.length > 0) {
            const isExpanded = expandedState[item?.attributes?.title];
            return (
              <div key={`lim${index}`} data-test-id="handle-function-click">
                <MenuItem
                  data-test-id="menu-item-click"
                  onClick={() => handleExpand(item?.attributes?.title)}
                >
                  {item?.attributes?.title}
                  <ArrowDownwardIcon className={classes.arrowDown}/>
                </MenuItem>
                <div className={classes.subMenuLabel}>
                  {isExpanded &&
                    item?.attributes?.header_sub_items?.data.map((option: HeaderSubItems1, subIndex: number) => {
                      if(option?.attributes?.header_sub_sub_items?.data.length > 0){
                        return(
                          <Box>
                            <Typography className={classes.titleStyle}>{option?.attributes?.title}</Typography>
                            <Box className={classes.subHeaderBox}>
                              {option?.attributes?.header_sub_sub_items?.data.map(data =>{
                                  return(
                                    <Box className={classes.imageMenuBox} key={data.id}>
                                      
                                      <MenuItem
                                      component={Link}
                                      to={getSubNavLinkByType(data)}
                                      data-test-id="menuItem"
                                      style={{padding:'0'}}
                                      onClick={() => {
                                        handleDrawerClose();
                                        window.scrollTo(0, 0);
                                      }}
                                    >
                                      {data?.attributes?.sub_header_sub_icon &&
                                      <img src={data?.attributes?.sub_header_sub_icon} alt="icon" className={classes.listImages}/>
                                      }
                                      <span
                                      className={classes.menuSpan}>{data?.attributes?.sub_header_title}</span>
                                    </MenuItem>
                                    </Box>
                                  )
                              })}
                            </Box>
                          </Box>
                        )
                      }else{
                      return(
                        <MenuItem
                        component={Link}
                        to={getNavLinkByType(option)}
                        data-test-id="menuItem"
                        onClick={() => {
                          handleDrawerClose();
                          window.scrollTo(0, 0);
                        }}
                        key={`subItem${subIndex}`}
                      >
                        {option?.attributes?.title}
                      </MenuItem>
                      )
                    }
                    }
                      
                    )}
                </div>
              </div>
            );
          } else {
            return (
              <Link
                key={`link${index}`}
                to={getNavLinkByType(item)}
                onMouseEnter={(e) => handlePopoverOpen(e, index)}
                onMouseLeave={handlePopoverClose}
                className={classes.ListText}
                data-test-id="handle-link"
              >
                <MenuItem data-test-id="testId1" onClick={() => { handleDrawerClose(); window.scrollTo(0, 0); }}>
                  {item?.attributes?.title}
                </MenuItem>
              </Link>
            );
          }
        })}
      </ul>
    </div>
  )
};

interface ProfileProps {
  classes?: any;
  anchorElUser?: any;
  handleOpenUserMenu?: any;
  handleCloseUserMenu?: any;
  handleLogout?: any;
}

const MenuBox = styled('div')({
  '@media(max-width: 1200px)': {
    width: "40px !important",
    display: "flex !important",
    justifyContent: "center !important"
  }
})

const StyledDiv = styled('div')({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  '@media(max-width: 768px)': {
    position: "fixed",
    top: "49px",
  }
})

export const Profile = (props: ProfileProps) => {
  const classes = useStyles();
  const accountDtails = JSON.parse(localStorage.getItem("userDetails") as string)?.meta
  return <>
    <MenuBox data-test-id="profile-button-test" className={classes.profileText} onClick={props.handleOpenUserMenu}>
      {!accountDtails?.photo ?
        <div className={classes.profile}
        style={{display:'flex',justifyContent:'center'}}>{accountDtails?.first_name.charAt(0).toUpperCase()}</div> :
        <img src={accountDtails?.photo} style={{width: "36px", height: "36px", borderRadius: "50px"}}/>}
    </MenuBox>
    {Boolean(props.anchorElUser) ?
        <StyledDiv>
            <img style={{position: "absolute", top: "25px", marginRight: "10px"}} src={triangle_img} alt=""/>
        </StyledDiv> :
        <StyledDiv>
          <img style={{position: "absolute", top: "25px", marginRight: "10px"}} src={''} alt=""/>
        </StyledDiv>
    }
    <Menu
      id="menu-appbar"
      anchorEl={props.anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(props.anchorElUser)}
      onClose={props.handleCloseUserMenu}
      PaperProps={{
        style: {
          marginTop: '58px',
          overflow: "hidden",
          borderRadius: "12px",
        },
      }}
    >
      <div style={{
        borderRadius: "12px",
        background: "#FFF",
        boxShadow: "none"
      }}>
        <MenuItem onClick={props.handleCloseUserMenu} className={classes.profileMenuItem}>
          <Link to='/get-started' >To the dashboard</Link>
        </MenuItem>
        <MenuItem onClick={props.handleCloseUserMenu} className={classes.profileMenuItem}>
          <Typography data-test-id="log-off-btn" onClick={props.handleLogout}>Log
            off</Typography>
        </MenuItem>
      </div>
    </Menu>
  </>
}

const SubHeader = (props: Props) => {
  const { headerDataFromApi } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openPophover, setOpenPophover] = useState(-1);
  const [isSticky, setIsSticky] = useState(false);
  const isLoggedin = JSON.parse(localStorage.getItem("isLogin") as string);
  const headerData = {
    "buttons": [
      {
        "id": 1,
        "label": "Login",
        "link": "/login/primary"
      },
      {
        "id": 2,
        "label": "Sign up",
        "link": "/signup"
      }
    ]
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setOpenPophover(index)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPophover(-1)
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleClickWeb = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOpenPophover(index)
    setAnchorEl(event && event.currentTarget);
  };
  
  
  const handleClose = () => {
    setOpenPophover(-1);
    setAnchorEl(null);
  };

  const handleScroll = () => {
    setIsSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("isLogin")
    localStorage.removeItem("activeStep")
    localStorage.removeItem("signUpValue")
    localStorage.removeItem("userDetails")
    removeStorageData('organization')
    window.location.replace("/login/primary")
  };

  const pathname = window.location.pathname
  const menuPaperClass = classes.menuPaperWhite;
  const menuClassName = classes.dropTopPosition;
  const menuClassnametwo = classes.dropTopPositonTwo
  const menuClassSubName = classes.dropTopSubPosition
  const highlight = (item: any) => {
    return "/" + window.location.pathname.split("/")[1] === getNavLinkByType(item)
  }

  return (
    <div className={classes.headerLight}>
      <ThemeProvider theme={theme}>
        <AppBar position={isSticky ? "fixed" : "static"} className={classes.appbarLight}>
          <Container className={clsx(classes.flexLink, classes.toolbar)}>
            <HiddenBox2>
              <IconButton
                className={classes.iconButtonStyle}
                data-test-id="toggle-button-test"
                color='default'
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Box className={classes.mobileDiv} style={{ gap: isLoggedin ? "0px" : "30px" }}>
                {
                  isLoggedin ?
                    <>
                      <Profile
                        classes={classes}
                        anchorElUser={anchorElUser}
                        handleOpenUserMenu={handleOpenUserMenu}
                        handleCloseUserMenu={handleCloseUserMenu}
                        handleLogout={handleLogout}
                      />
                    </>
                    :
                    headerData?.buttons.map((item: {
                      id: number
                      label: string
                      link: string
                    }, index: number) => {
                      return <Link
                        to={item?.link}
                        key={`opt2_login${index}`}
                        className={`${classes.toolbarLink} toolbar-link`}
                      >
                        <Button
                          variant='contained'
                          size="small"
                          className={item?.label === "Login" ? classes.loginButtonStyle : classes.signUpButtonStyle}
                        >
                          {item?.label}
                        </Button>
                      </Link>
                    }) 
                }
              </Box>
            </HiddenBox2>
            <HiddenBox1>
              <Box className={classes.HideBoxMainContainer}>
                <Grid container alignItems="center" justifyContent="space-between" className={classes.mainNavContainer}>
                  <Grid item md={2} lg={2}>
                    <Link href="/" className={classes.flexLink} to={""} >
                        <img src={logoLight} alt="Logo" className={`${classes.logo} logoDark`} />
                    </Link>
                  </Grid>
                  <Grid item md={7} lg={7} className={classes.navBar}>
                    {
                      headerDataFromApi && headerDataFromApi.data.length > 0 && headerDataFromApi.data.map((item: {id:string
                        type:string
                        attributes:HeaderAttribute1}, index: number) => {
                        const hasSubItems = item?.attributes?.header_sub_items?.data?.length > 0;
                        const hasSubSubItems = item?.attributes?.header_sub_items?.data?.some((each) => each?.attributes?.header_sub_sub_items?.data?.length > 0) ;

                        if (hasSubItems) {
                          let menuLabelClass = classes.menuLabel;
                          return (
                            <div 
                              key={`opdft_${index}`} 
                              className={`${classes.toolbarLink} toolbar-link`}
                            >
                              <MenuItem
                                className={openPophover === index ? classes.menuLabelActive : menuLabelClass}
                                data-test-id="menu-item-main"
                                onClick={(event) => handleClickWeb(event, index)}
                              >
                                {item?.attributes?.title}
                                <ArrowDownwardIcon className={classes.arrowDown}/>
                              </MenuItem>
                              <Menu
                                anchorEl={anchorEl}
                                open={openPophover === index}
                                onClose={handleClose}
                                onBlur={handleClose}
                                TransitionProps={{ timeout: 300 }}
                                data-test-id="handle-close-func"
                                style={{ 
                                  marginTop: "40px", 
                                  boxShadow: "none",
                                 }}
                                classes={{ paper: menuPaperClass }}
                                className={ hasSubSubItems ? menuClassSubName : isLoggedin ? menuClassName : menuClassnametwo}
                                anchorOrigin={{
                                  vertical: hasSubSubItems?"center": 500,
                                  horizontal: hasSubSubItems?"center":"left",
                                    }}
                                TransitionComponent={Fade}
                                transformOrigin={{
                                        vertical: hasSubSubItems?"center":400,
                                        horizontal: hasSubSubItems?"center":"right",
                                          }}
                              >
                                <Container className={classes.MenuInsideBox}
                                           style={{width: hasSubSubItems ? '100%' : 'max-content'}}>
                                {item?.attributes?.header_sub_items?.data.map((option: HeaderSubItems1, index: number) => {
                                  if(option?.attributes?.header_sub_sub_items?.data.length > 0){
                                    return(
                                      <Box className={classes.MenuSubBox}>
                                        <Typography
                                          className={classes.titleStyle}>{option?.attributes?.title}</Typography>
                                        <Box className={classes.subHeaderBox}>
                                          {option?.attributes?.header_sub_sub_items?.data.map(data => {
                                            return (
                                              <Link to={getSubNavLinkByType(data)}>
                                                <Box className={classes.imageMenuBox}>
                                                  {data?.attributes?.sub_header_sub_icon &&
                                                      <img src={data?.attributes?.sub_header_sub_icon} alt="icon"/>
                                                  }
                                                  <MenuItem
                                                    key={`mitd_${index}`}
                                                    className={classes.subSubMenu}
                                                    style={{}}
                                                    onClick={() => {
                                                      handleClose();
                                                      window.scrollTo(0, 0);
                                                    }}
                                                    data-test-id="handle-close"
                                                  >
                                                    <div>
                                                      {data?.attributes?.sub_header_title}
                                                    </div>
                                                  </MenuItem>
                                                </Box>
                                              </Link>
                                            )
                                          })}
                                        </Box>
                                      </Box>
                                    )
                                  }else{
                                    return(
                                      <Link to={getNavLinkByType(option)}>
                                        <Box className={classes.menuItemBoxtwo}>
                                          <MenuItem
                                            key={`mitd_${index}`}
                                            onClick={() => {
                                              handleClose();
                                              window.scrollTo(0, 0);
                                            }}
                                            data-test-id="handle-close"
                                            className={classes.menuItemtwo}
                                          >
                                            <div>
                                              {option?.attributes?.title}
                                            </div>
                                          </MenuItem>
                                        </Box>
                                      </Link>
                                    )
                                  }
                        })}
                        </Container>
                              </Menu>
                            </div>
                          );
                        } else {
                          let menuBar = classes.menuLabel;
                          if (pathname === getNavLinkByType(item) || highlight(item)) {
                              menuBar = classes.hightlight;
                          }
                          return (
                            <Link
                              to={getNavLinkByType(item)}
                              key={`opt2_${index}`}
                              onMouseLeave={() => handleClose()}
                              className={`${classes.toolbarLink} toolbar-link`}
                            >
                              <MenuItem
                                className={menuBar}
                                data-test-id="testId3"
                                onClick={() => { window.scrollTo(0, 0); }}
                              >
                                {item?.attributes?.title}
                              </MenuItem>
                            </Link>
                          );
                        }
                      })}
                  </Grid>
                  <Grid item>
                    <Box className={classes.mobileDiv} style={{ gap: isLoggedin ? "0px" : "30px" }}>
                      {
                        isLoggedin ?
                          <>
                            <Profile
                              classes={classes}
                              anchorElUser={anchorElUser}
                              handleOpenUserMenu={handleOpenUserMenu}
                              handleCloseUserMenu={handleCloseUserMenu}
                              handleLogout={handleLogout}
                            />
                          </>
                          :
                          headerData?.buttons.map((item: {
                            id: number
                            label: string
                            link: string
                          }, index: number) => {
                            return <Link
                              to={item?.link}
                              key={`opt2_login${index}`}
                              className={`${classes.toolbarLink} toolbar-link`}
                            >
                              <Button
                                variant='contained'
                                size="small"
                                className={item?.label === "Login" ? classes.loginButtonStyle : classes.signUpButtonStyle}
                              >
                                {item?.label}
                              </Button>
                            </Link>
                          }) 
                      }
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </HiddenBox1>
          </Container>
        </AppBar>
        <HiddenBox2>
          <Drawer
            open={drawerOpen}
            onClose={handleDrawerClose}
            classes={{ paper: classes.drawerPaper }}
            data-test-id="drawer-close"
          >
            <Box>
              <div className={classes.listLogo}>
                <Link to="/" className={classes.flexLink}  onClick={handleDrawerClose}>
                  <img src={logo} style={{ width: "100%" }} />
                </Link>
              </div>
              <ListRender
                handleDrawerClose={handleDrawerClose}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
                headerDataFromApi={headerDataFromApi}
              />
            </Box>
          </Drawer>
        </HiddenBox2>
      </ThemeProvider>
    </div >
  );
}
export default SubHeader;
// Customizable Area End