import React, { useState } from "react";
import { Container, Grid, Dialog } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import PlayArrow from "@material-ui/icons/PlayArrow";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import WidgetHeadingText from "../WidgetHeadingText";

const useStyles = makeStyles(() =>
  createStyles({
    block: {
      padding: "25px",
      paddingTop: "50px",
      borderRadius: "15px",
      background: "linear-gradient(238deg, #FAF7EF 0%, #F4F6FC 99.66%)"
    },
    heading: {
      "&, & > p": {
        fontSize: "32px",
        lineHeight: "44px",
        fontWeight: 700,
        textTransform: "capitalize",
      },
      "@media (max-width: 768px)": {
        "&, & > p": {
          fontSize: "24px",
          lineHeight: "36px",
        },
      },
    },
    image: {
      position: "relative",
      borderRadius: "20px",
      overflow: "hidden",
      marginTop: "28px",
    },
    textTitle: {
      position: "absolute",
      left: "20px",
      bottom: "20px",
      color: "#fff",
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 500,
      lineHeight: "28px",
      textTransform: "capitalize",
    },
    playIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "60px",
      height: "60px",
      cursor: "pointer",
      transition: "all ease 0.3s",
      borderRadius: "50%",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      backgroundColor: "#000",
      "&:hover": {
        backgroundColor: "#c72929",
        cursor: "pointer",
      },
    },
    content: {
      backgroundColor: "#fff",
      borderRadius: "20px",
      padding: "40px",
      "@media (max-width: 768px)": {
        padding: "20px",
        marginTop: "24px",
      },
    },
    listFeature: {
      marginTop: "28px",
    },
    featureItem: {
      display: "flex",
      marginTop: "24px",
      "@media (max-width: 768px)": {
        marginTop: "16px",
      },
    },
    checkIcon: {
      fontSize: "20px",
      color: "#43d270",
      width: "24px",
      height: "24px",
    },
    text: {
      color: "#515151",
      paddingLeft: "8px",
      fontWeight: 500,
      textTransform: "capitalize",
    },
    blockButton: {
      marginTop: "36px",
    },
    button: {
      backgroundColor: "#43d270",
      fontFamily: "Lato",
      color: "#000",
      border: "none",
      display: "inline-flex",
      padding: "12px 32px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      borderRadius: "4px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      textTransform: "capitalize",
      transition: "all ease 0.4s",
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#897ecc",
      },
    },
    grid: {
      "@media (min-width: 768px)": {
        padding: "20px",
      },
    },
  })
);

export default function Potential(props: any) {
  const {
    main_header,
    header,
    info_1,
    info_2,
    info_3,
    image,
    video,
    service_widget_items,
    widget_link_text,
    widget_link,
    navigate
  } = props;
  const styles = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenCloseModal = (status: boolean) => {
    setOpenModal(status);
  };

  return (
    <Container>
      <Grid container className={styles.block}>
        <Grid item xs={12} lg={6} className={styles.grid}>
          <h3 className={styles.heading}>{main_header}</h3>
          <div className={styles.image}>
            <img style={{ width: "100%" }} src={image} alt="" />
            <PlayArrow
              data-test-id="play-icon"
              className={styles.playIcon}
              onClick={() => handleOpenCloseModal(true)}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} spacing={4}>
          <div className={styles.content}>
            <h3 className={styles.heading}>{parse(header)}</h3>
            <div className={styles.listFeature}>
              {service_widget_items.data.map((item: any) => {
                const { service_image, service_name } = item.attributes;
                return (
                  <div key={item.id} className={styles.featureItem}>
                    <img className={styles.checkIcon} src={service_image} alt={""}/>
                    <p className={styles.text}>{service_name}</p>
                  </div>
                );
              })}
            </div>
            <div className={styles.blockButton}>
              <div onClick={() => navigate(widget_link)} className={styles.button}>
                {widget_link_text}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={() => handleOpenCloseModal(false)}
        maxWidth="md"
      >
        <video height="100%" width="100%" controls>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </Dialog>
    </Container>
  );
}
