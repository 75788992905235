import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const tableLabels = {
  title: "",
  value: "Value",
  source: "Source",
  update: "Last Updated",
  restatement: "Restatement",
};

export const ScopeTableContainer = styled(TableContainer)({
  minHeight: "583px",
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '12px',
  paddingLeft: "0px",
  marginLeft: '-9px'
});

export const ScopeTable = styled(Table)({
  width: "100%",
  borderCollapse: "separate",
});

export const ScopeTableBody = styled(TableBody)({
  color: "inherit",
});

export const ScopeSpacing = styled("div")({
  height: 16,
  display: "table-header-group",
});

export const ScopeTableCellHead = styled(TableCell)({
  minHeight: "48px",
  padding: "14px 5px 12px 27px",
  flexShrink: 0,
  borderBottom: "none",
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

export const ScopeTableCell = styled(TableCell)({
  borderBottom: "1px solid #F1F5F9",
  padding: "14px 0px 13px 30px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "21px",
  letterSpacing: "0.2px",
  // maxWidth: 200
});

export const ExtraTableCell = styled(TableCell)({
  borderBottom: "1px solid #DFDFDF",
  padding: "14px 0px 13px 18px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

export const MainExtraTableCell = styled(TableCell)({
  borderBottom: "1px solid #000000",
  padding: "14px 0px 13px 18px",
  color: "#329E54",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

export const ScopeTableHead = styled(TableHead)({
  backgroundColor: "#F5F5F5",
  borderRadius: "12px",
});

export const ScopeTableRowHead = styled(TableRow)({
  borderRadius: "20px !important",
  overflow: "hidden",
  "& :first-child": {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  "& :last-child": {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
});

const tableData = [{
  title: "Total Scope 2 GHG Emissions in tCO2e",
  value: "9,248.000",
  source: "CDP Climate Change 2022",
  update: "2022-10-31",
  restatement: null,
},
{
  title: "Rationale if total scope 2 GHG emissions not disclosed",
  value: null,
  source: "CDP Climate Change 2022",
  update: "2022-10-31",
  restatement: null,
}]

interface EmissionData {
  id: number;
  name: string;
  value: string;
  source: string;
  last_updated: string;
  restatement?: string;
}

interface EmissionRowsProps {
  title?: string;
  rowItems: EmissionData[];
  removeTopPadding?: boolean;
  leftAlignTableCells?: boolean;
}

export function EmissionRows({ 
  title, 
  rowItems, 
  removeTopPadding, 
  leftAlignTableCells 
}: EmissionRowsProps) {
  return (
    <>
      {
        title && (
          <TableRow>
            <ExtraTableCell
              style={{ paddingTop: removeTopPadding ? 0 : "revert-layer" }}
              data-test-id="row-title"
            >
              {title}
            </ExtraTableCell>
            <ExtraTableCell />
            <ExtraTableCell />
            <ExtraTableCell />
            <ExtraTableCell />
          </TableRow>
        )
      }
      {rowItems.map((item) => {
        return (
          <TableRow key={item.id}>
            <ScopeTableCell 
              style={{ 
                paddingLeft: leftAlignTableCells ? 18 : 36,
                width: "40%", 
                minWidth: 200 
              }}
            >
              {item.name ?? "-"}
            </ScopeTableCell>
            <ScopeTableCell style={{ ...webStyle.value, width: "15%" }}>{item.value ?? "-"}</ScopeTableCell>
            <ScopeTableCell style={{ ...webStyle.name, width: "15%" }}>{item.source ?? "-"}</ScopeTableCell>
            <ScopeTableCell style={{ ...webStyle.name, width: "15%" }}>{item.last_updated ?? "-"}</ScopeTableCell>
            <ScopeTableCell style={{ ...webStyle.value, width: "15%" }}>{item.restatement ?? "-"}</ScopeTableCell>
          </TableRow>
        );
      })}
    </>
  );
}

// Customizable Area End

import Cfnzdpuscope2emissionsController, {
  Props,
  configJSON,
} from "./Cfnzdpuscope2emissionsController";

export default class Cfnzdpuscope2emissions extends Cfnzdpuscope2emissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.props.value === this.props.index &&
          <ScopeTableContainer>
            <ScopeTable aria-label="users table">
              <ScopeTableHead>
                <ScopeTableRowHead>
                  <ScopeTableCellHead style={{ width: "40%" }} align="left">{tableLabels.title}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.value}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.source}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.update}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.restatement}</ScopeTableCellHead>
                </ScopeTableRowHead>
              </ScopeTableHead>
              <ScopeSpacing />
              <ScopeTableBody>
                {/* Scope 2 Location-Based Emissions start */}
                <TableRow>
                  <MainExtraTableCell>Scope 2 Location-Based Emissions</MainExtraTableCell>
                  <MainExtraTableCell />
                  <MainExtraTableCell />
                  <MainExtraTableCell />
                  <MainExtraTableCell align="right">
                    <IconButton
                      data-test-id="location-collapse-btn"
                      aria-label="expand row"
                      size="small"
                      onClick={this.handleToggleCollapseLocationBasedEmission}
                    >
                      {this.state.openLocationBasedEmission ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </MainExtraTableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={5}>
                    <Collapse 
                      unmountOnExit
                      timeout="auto"
                      data-test-id="location-based-collapse" 
                      in={this.state.openLocationBasedEmission} 
                    >
                      <ScopeTable>
                        <ScopeTableHead style={{ backgroundColor: "unset" }}>
                          <ScopeTableRowHead>
                            <ScopeTableCellHead style={{ width: "40%", minWidth: 200 }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                          </ScopeTableRowHead>
                        </ScopeTableHead>
                        <ScopeTableBody>
                          <EmissionRows
                            removeTopPadding
                            title="Total Scope 2 Location-Based Emissions"
                            rowItems={this.state.totalScope2LocationBasedEmissions}
                          />
                          <EmissionRows title="Methodology" rowItems={this.state.methodology} />
                          <TableRow>
                            <ExtraTableCell>Exclusions</ExtraTableCell>
                            <ExtraTableCell />
                            <ExtraTableCell />
                            <ExtraTableCell />
                            <ExtraTableCell />
                          </TableRow>
                          <TableRow>
                            <ScopeTableCell style={webStyle.value}>Exclusion 1</ScopeTableCell>
                            <ScopeTableCell />
                            <ScopeTableCell />
                            <ScopeTableCell />
                            <ScopeTableCell />
                          </TableRow>
                          {this.state.exclusion1.map((item) => {
                            return (
                              <TableRow>
                                <ScopeTableCell style={{ paddingLeft: 54 }}>{item.name ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.value}>{item.value ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.name}>{item.source ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.name}>{item.last_updated ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.value}>{item.restatement ?? "-"}</ScopeTableCell>
                              </TableRow>
                            );
                          })}
                          <EmissionRows
                            title="Change in Scope 2 location-based emissions calculation or reporting boundary"
                            rowItems={this.state.changeInScope2LocationBasedEmissionsCalculation}
                          />
                        </ScopeTableBody>
                      </ScopeTable>
                    </Collapse>
                  </TableCell>
                </TableRow>
                {/* Scope 2 Location-Based Emissions ends */}

                {/* Scope 2 Market-Based Emissions starts */}
                <TableRow>
                  <MainExtraTableCell>Scope 2 Market-Based Emissions</MainExtraTableCell>
                  <MainExtraTableCell />
                  <MainExtraTableCell />
                  <MainExtraTableCell />
                  <MainExtraTableCell align="right">
                    <IconButton
                      data-test-id="market-collapse-btn"
                      aria-label="expand row"
                      size="small"
                      onClick={this.handleToggleCollapseMarketBasedEmission}
                    >
                      {this.state.openMarketBasedEmission ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </MainExtraTableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={5}>
                    <Collapse 
                      unmountOnExit
                      timeout="auto" 
                      data-test-id="market-based-collapse"
                      in={this.state.openMarketBasedEmission} 
                    >
                      <ScopeTable>
                        <ScopeTableHead style={{ backgroundColor: "unset" }}>
                          <ScopeTableRowHead>
                            <ScopeTableCellHead style={{ width: "40%", minWidth: 200 }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                            <ScopeTableCellHead style={{ width: "15%" }}></ScopeTableCellHead>
                          </ScopeTableRowHead>
                        </ScopeTableHead>
                        <ScopeTableBody>
                          <EmissionRows
                            removeTopPadding
                            title="Total Scope 2 Market-Based Emissions"
                            rowItems={this.state.totalScope2MarketBasedEmissions}
                          />
                          <EmissionRows title="Methodology" rowItems={this.state.methodology} />
                          <TableRow>
                            <ExtraTableCell>Exclusions</ExtraTableCell>
                            <ExtraTableCell />
                            <ExtraTableCell />
                            <ExtraTableCell />
                            <ExtraTableCell />
                          </TableRow>
                          <TableRow>
                            <ScopeTableCell style={webStyle.value}>Exclusion 1</ScopeTableCell>
                            <ScopeTableCell />
                            <ScopeTableCell />
                            <ScopeTableCell />
                            <ScopeTableCell />
                          </TableRow>
                          {this.state.exclusion1.map((item) => {
                            return (
                              <TableRow>
                                <ScopeTableCell style={{ paddingLeft: 54 }}>{item.name ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.value}>{item.value ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.name}>{item.source ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.name}>{item.last_updated ?? "-"}</ScopeTableCell>
                                <ScopeTableCell style={webStyle.value}>{item.restatement ?? "-"}</ScopeTableCell>
                              </TableRow>
                            );
                          })}
                          <EmissionRows
                            title="Change in Scope 2 market-based emissions calculation or reporting boundary"
                            rowItems={this.state.changeInScope2MarketBasedEmissionsCalculation}
                          />
                        </ScopeTableBody>
                      </ScopeTable>
                    </Collapse>
                  </TableCell>
                </TableRow>
                {/* Scope 2 Market-Based Emissions ends */}
              </ScopeTableBody>
            </ScopeTable>
          </ScopeTableContainer>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  title: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "27.5px"
  },
  subtext: {
    color: "#34373A",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    marginTop: '29px'
  },
  appbar: {
    padding: '37px 0',
    maxWidth: '850px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    marginLeft: '-38px'
  },
  tabtext: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.4px",
  },
  name: {
    color: '#7D7D7F',
    fontSize: "12px",
    fontWeight: 400,
  },
  value: {
    fontWeight: 700,
  }
};
// Customizable Area End
