export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require('../assets/logo.svg');
export const arrowUpRight = require('../assets/arrow-up-right.png');
export const cloudIconWhite = require('../assets/cloud-icon-white.png');
export const dashBoardGraphImage = require('../assets/dashboard-graph-image.png');
export const gearIconWhite = require('../assets/gear-icon-white.png');
export const graphIconWhite = require('../assets/graph-icon-white.png');
export const SliderBackgroundImage = require('../assets/slider-background-image.png');
export const PhilosophyBg = require('../assets/philosophy-bg.png');
export const ourLatestPostsBg = require('../assets/our-latest-posts-bg.png');
export const talkToBg = require('../assets/talk-to-bg.png');
export const twitterIconGreen = require('../assets/twitter-icon-green.svg');
export const FormCircleIcon = require('../assets/form-circle-icon.svg');
export const successgirl = require('../assets/free-assessment-success.svg');
export const logoLight = require('../assets/Logo-light.png')
export const contactus = require('../assets/contactus.png')
export const playCircleIcon = require('../assets/play-circle-icon.png')
export const empty = require('../assets/empty.png')
export const signUpContent0 = require('../assets/Cover.png')
export const signUpContent1 = require('../assets/Cover1.png')
export const signUpContent2 = require('../assets/Cover2.png')
export const email = require('../assets/email.png')
export const login = require('../assets/login.png')
export const fpassword = require('../assets/fpassword.png')
export const cover = require('../assets/Cover.png')
export const ic_search = require('../assets/search2.svg')
export const success_img=require('../assets/JobSuccess.png')
export const triangle_img= require('../assets/triangle_img.svg')
export const linkedInIcon = require('../assets/linkedin.svg')
export const phoneIcon = require('../assets/phone-icon.svg')
export const mailIcon = require('../assets/email-icon.svg')
export const locationIcon = require('../assets/location-icon.svg')
export const instaIcon = require('../assets/insta-icon.svg')
export const linkedinIcon = require('../assets/linkedin-icon.svg')
export const facebookIcon = require('../assets/facebook-icon.svg')
export const twitterIcon = require('../assets/twitter-icon.svg')
export const youtubeIcon = require('../assets/youtube-icon.svg')
export const uncheckIcon = require('../assets/uncheckbox.svg')
export const checkIcon = require('../assets/checkbox.svg')
export const outerCircle = require('../assets/outer-circle.svg')
export const innerCircle = require('../assets/inner-circle.svg')
export const whatsappIcon = require('../assets/whatsapp.svg')
export const wechatIcon = require('../assets/wechat.svg')
export const pintrestIcon = require('../assets/pintrest.svg')
export const viberIcon = require('../assets/viber.svg')
export const tumblrIcon = require('../assets/tumblr.svg')
export const tiktokIcon = require('../assets/tiktok.svg')
export const telegramIcon = require('../assets/telegram.svg')
export const snapchatIcon = require('../assets/snapchat.svg')
export const redditIcon = require('../assets/reddit.svg')
export const discordIcon = require('../assets/discord.svg')
export const youTubIcon = require('../assets/youtube.svg')
export const downArrowIcon = require('../assets/keyboard_arrow_down.svg')
