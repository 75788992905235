import React from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Label from "./Label";
import WidgetHeadingText from "./WidgetHeadingText";

const useStyles = makeStyles(() => ({
  mainBlock: {
    marginTop: "80px",
    "@media (max-width: 768px)": {
      marginTop: "32px",
    },
  },
  headingBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textLink: {
    textAlign: "right",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
    lineHeight: "24px",
    textTransform: "capitalize",
    cursor: "pointer",
    borderBottom: "1px solid #43d270",
    transition: "0.3s",
    "&:hover": {
      color: "#43d270",
    },
  },
  helperLink: {
    textAlign: "right",
    "@media (max-width: 768px)": {
      textAlign: "left",
    },
  },
  expertiseBlock: {
    marginTop: "40px",
    gap: "16px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
      gap: "10px",
    },
  },
  expertiseItem: {
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    backgroundColor: "f3f5f9",
    transition: "0.3s",
    "&:hover": {
      paddingLeft: "32px",
      color: "#fff",
      backgroundColor: "#43d270",
    },
  },
  text: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    lineHeight: "28px",
    textTransform: "capitalize",
    "@media (max-width: 768px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
}));

export default function WhatWeDo(props: any) {
  const {
    main_header,
    header,
    widget_link_text,
    widget_link,
    expertise_widget_items,
    navigate
  } = props;
  const styles = useStyles();
  return (
    <div className={styles.mainBlock}>
      <Container>
        <Grid container className={styles.headingBlock}>
          <Grid item xs={12} md={9} xl={8}>
            <Label text={main_header} />
            <WidgetHeadingText text={header} />
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
          </Grid>
        </Grid>

        <div className={styles.expertiseBlock}>
            {expertise_widget_items.data.map((item: any) => {
            const { expertise_name, expertise_image } = item.attributes;
            return (
              <div key={item.id}>
                <div className={styles.expertiseItem}>
                  <img src={expertise_image} alt="" />
                  <p className={styles.text}>{expertise_name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
