import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import IntermodalForm from "../../cfintermodalform/src/Cfintermodalform.web";
import Maps from "../../maps/src/Maps.web";

const Container = styled("div")({
  display: 'flex',
  "@media(max-width: 600px)": {
    flexDirection: "column",
  }
});
// Customizable Area End

import CfintermodaldatavisualizationandinteractionController, {
  Props,
  configJSON,
} from "./CfintermodaldatavisualizationandinteractionController";

export default class Cfintermodaldatavisualizationandinteraction extends CfintermodaldatavisualizationandinteractionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container>
        <IntermodalForm navigation={undefined} id={""} />
        <Maps navigation={undefined} id={""} />
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
