export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const logo = require("../assets/Logo-light.png");
export const pen = require("../assets/pen.png");
export const locationCircle = require("../assets/location_circle.svg");
export const truckCircle = require("../assets/truck_circle.svg");
export const shipCircle = require("../assets/ship_circle.svg");
export const trainCircle = require("../assets/train_circle.svg");
export const airplaneCircle = require("../assets/airplane_circle.svg");