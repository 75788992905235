import React, { useState, useEffect, useRef } from "react";

interface CounterProps {
  end: number;
  duration: number;
  className?: string;
}

const Counter: React.FC<CounterProps> = ({ end, duration, className }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef<HTMLSpanElement | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

 useEffect(() => {
    let observer: IntersectionObserver;
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let start = 0;
          const increment = end / (duration / 100);
          const interval = setInterval(() => {
            start += increment;
            if (start > end) {
              start = end;
              clearInterval(interval);
            }
            setCount(Math.round(start));
          }, 100);
        }
      });
    };

    observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      if (observer && countRef.current) {
        observer.unobserve(countRef.current);
      }
    };
  }, [end, duration]);

  const formatCount = (value: number) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    }
    return value.toString();
  };

  return (
    <span ref={countRef} className={className}>
      {formatCount(count)}
    </span>
  );
};

export default Counter;
