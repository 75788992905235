import React from 'react';
// Customizable Area Start
import SubFooter from '../../../components/src/SubFooter.web';
// Customizable Area End

import FooterController, {
    Props,
} from "./FooterController";

export default class Footer extends FooterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const footerDataFromApi = this.state.footerData;
        return (
            // Customizable Area Start
            <>
                {
                    footerDataFromApi &&
                    footerDataFromApi.toString() !== "{}" &&
                    <SubFooter footerDataFromApi={footerDataFromApi} />
                }
            </>
            // Customizable Area End
        );
        // Customizable Area End
    }
}
// Customizable Area Start
// Customizable Area End