
import React, { useState } from 'react';
import parse from 'html-react-parser';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Typography, Box, Button, Avatar, Grid, Paper, Dialog } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import { playCircleIcon } from '../assets';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: "#F5F9F1",
            padding: '117px 15px 147px 15px',
            '@media (max-width:900px)': {
                padding: '50px 20px',
            }
        },
        platformBox: {
            maxWidth: '1229px',
            margin: 'auto',
        },
        imageWr: {
            marginLeft: 'auto',
        },

        image: {
            borderRadius: '16px',
            width: '100%',
            maxWidth: "436px",
            background: "#AB9EFF",
            '@media (max-width:600px)': {
                marginTop: "20px",
            },
        },
        imageContainer: {
            position: 'relative',
            marginBottom: '40px',
            minHeight: '436px',
            display: 'flex',
            alignItems: 'end'
        },
        iconOver: {
            position: 'absolute',
            top: 'calc(50% - 30px)',
            left: 'calc(50% - 30px)',
            cursor: "pointer",
        },
        playIcon: {
            position: "absolute",
            top: "43%",
            left: "45%",
            cursor: "pointer"
        },
        boxOverImage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '20px',
            position: 'absolute',
            width: '163px',
            height: '145px',
            right: '21px',
            bottom: '21px',
            background: '#FFFFFF',
            boxShadow: '20px 40px 80px rgba(45, 51, 81, 0.12)',
            borderRadius: '8px',
            '& h4': {
                color: "#101010",
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
                fontSize: "24px",
                margin: "0 0 23px 0"
            },
            '& p': {
                fontSize: "12px",
                lineHeight: "20px",
                color: "#808D9E"
            },
            '& Button': {
                borderRadius: "4px",
                background: "#E0E0FC",
                color: "#7166F1",
                fontWeight: 700,
                lineHeight: "16px",
                fontSize: "14px",
                padding: "4px 6px",
            }
        },
        arrowUPStyles: {
            fontSize: '14px',
            color: '#7166F1',
            marginRight: '6px'
        },

        buttonIcon: {
            marginLeft: "16px"
        },
        plateFormButtonLink: {
            textDecoration: "none",
            display: "block",
        },
        plateFormButton: {
            backgroundColor: '#AB9EFF',
            borderRadius: '8px',
            minHeight: "56px",
            minWidth: "300px",
            alignItem: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        iconStyle: {
            backgroundColor: '#D1F2DA',
            color: '#34373A',
            width: '60px',
            height: "60px",
            marginRight: "40px",
            '@media (max-width:600px)': {
                marginRight: "20px",
            }
        },
        plateformItems: {
            marginBottom: "26px",
            '@media (max-width:600px)': {
                marginBottom: "20px",
                flexWrap: "nowrap",
            }
        },
        imgStyle: {
            justifyContent: 'flex-end',
            '@media (max-width:960px)': {
                justifyContent: "center",
            }

        }
    }),
);

const iconBgColor = ['#D1F2DA', '#E0E0FC', '#F4DCD4']

const HeadingTypography = styled(Typography)({
    fontWeight: 700,
    fontSize: '48px',
    marginBottom: "4px",
    fontFamily: "Montserrat",
    lineHeight: "56px",
    '@media (max-width:600px)': {
        fontSize: '28px',
        lineHeight: '30px',
        margin: "0 auto 20px auto",
    }
});

const HeadingTypography2 = styled(Typography)({
    fontWeight: 600,
    fontSize: '20px',
    marginBottom: "13px",
    fontFamily: "Montserrat",
});

const ContentText = styled(Typography)({
    color: '#34373A',
    fontSize: '15px',
    fontFamily: 'lato',
});

const MainDescription = styled('div')({
    fontSize: '15px',
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Lato",
    '@media (max-width:600px)': {
        marginBottom: "20px",
    }
});

const CustomPaper = styled(Paper)({
    borderRadius: 8,
    padding: 10,
    '@media(max-width: 600px)': {
        padding: 5
    }
  });

export const removeTags = (htmlString: any) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(htmlString, 'text/html');
    const textContent = parsedDocument.body.textContent;
    return textContent;
}

export const getDyanmicLink = (link: string | undefined, defaultLink: string) => {
    if (!link || link === "" || link === "#") {
        return defaultLink;
    } else {
        return link;
    }
}

export default function LpDifferentFromOtherSection(props: any) {
    const [openModal, setOpenModal] = useState(false);
    const { header, description, image, widget_sub_items, widget_item_buttons, info_1, info_2, info_3, video } = props;
    const classes = useStyles();

    const handleOpenCloseModal = (status: boolean) => {
        setOpenModal(status);
    };

    return (
        <div className={classes.root} >
            <Grid container className={classes.platformBox}>
                <Grid container item md={6} sm={12}>
                    <HeadingTypography variant='h5'>{header && removeTags(header)}</HeadingTypography>
                    <MainDescription>{description && parse(description.toString())}</MainDescription>
                    {widget_sub_items && widget_sub_items?.data.map((item: any, index: any) => {
                        return <Grid className={classes.plateformItems} container key={`s_item_${index}`}>
                            <Avatar style={{ backgroundColor: iconBgColor[index] }} className={classes.iconStyle}>
                                {item?.attributes?.image &&
                                    <img src={item.attributes?.image} alt="" />
                                }
                            </Avatar>
                            <Box>
                                <HeadingTypography2 variant='h5'>{removeTags(item?.attributes?.title)}</HeadingTypography2>
                                <ContentText>{item?.attributes?.description && parse(item?.attributes?.description.toString())}</ContentText>
                            </Box>
                        </Grid>
                    })}
                </Grid>
                <Grid container item md={6} sm={12} className={classes.imgStyle}>
                    <Box textAlign='center' sx={{ maxWidth: '436px' }}>
                        <div className={classes.imageContainer}>
                            <img className={classes.image} src={`${image}`} alt="" />
                            <img 
                                data-test-id="play-icon"
                                className={classes.playIcon} 
                                src={playCircleIcon} 
                                alt="play icon" 
                                onClick={() => handleOpenCloseModal(true)}
                            />
                            <div className={classes.boxOverImage}>
                                <h4>{removeTags(info_1)}</h4>
                                <p>{removeTags(info_2)}</p>
                                <Button><ArrowUpwardRoundedIcon className={classes.arrowUPStyles} /> {info_3}</Button>
                            </div>
                        </div>
                        <Link onClick={() => window.scroll(0, 0)} to={getDyanmicLink(widget_item_buttons?.data['0']?.attributes?.link, "/carbonzeroed-software")} className={classes.plateFormButtonLink}>
                            <Button className={classes.plateFormButton}>
                                {widget_item_buttons?.data['0']?.attributes?.name}
                                {widget_item_buttons?.data['0']?.attributes?.icon &&
                                    <img className={classes.buttonIcon} src={widget_item_buttons?.data['0']?.attributes?.icon} alt="" />
                                }
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Dialog 
                open={openModal} 
                onClose={() => handleOpenCloseModal(false)} 
                maxWidth="md"
                PaperComponent={CustomPaper}
            >
                <video height="100%" width="100%" controls>
                    <source src={video} type="video/mp4" />
                    <source src={video} type="video/ogg" />
                    Your browser does not support the video tag.
                </video>
            </Dialog>
        </div >
    );
}