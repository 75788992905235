import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { 
  tableLabels,
  ScopeTableContainer,
  ScopeTable,
  ScopeTableBody,
  ScopeSpacing,
  ScopeTableCellHead,
  ScopeTableHead,
  ScopeTableRowHead,
} from "../../cfnzdpuscope2emissions/src/Cfnzdpuscope2emissions.web";
import TitleLessRows from "./components/TitleLessRows";
import Category1 from "./components/Category1";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

interface EmissionData {
  id: number;
  name: string;
  value: string;
  source: string;
  last_updated: string;
  restatement?: string;
}

interface EmissionRowsProps {
  title?: string;
  rowItems: EmissionData[];
}

// Customizable Area End

import Cfnzdpuscope3emissionsController, {
  Props,
  configJSON,
} from "./Cfnzdpuscope3emissionsController";

export default class Cfnzdpuscope3emissions extends Cfnzdpuscope3emissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.props.value === this.props.index &&
          <ScopeTableContainer>
            <ScopeTable aria-label="users table">
              <ScopeTableHead>
                <ScopeTableRowHead>
                  <ScopeTableCellHead style={{ width: "40%" }} align="left">{tableLabels.title}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.value}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.source}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.update}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.restatement}</ScopeTableCellHead>
                </ScopeTableRowHead>
              </ScopeTableHead>
              <ScopeSpacing />
              <ScopeTableBody>
                <TitleLessRows
                  heading="Breakdown"
                  rowItems={this.state.breakdown}
                  isOpen={this.state.openBreakdown}
                  onToggle={() => this.handleToggleCollaplse("openBreakdown")}
                />
                <TitleLessRows
                  heading="Total Scope 3 Emissions"
                  rowItems={this.state.totalScope3Emissions}
                  isOpen={this.state.openTotalScope3Emissions}
                  onToggle={() => this.handleToggleCollaplse("openTotalScope3Emissions")}
                />
                <Category1 
                  isOpen={this.state.openCategory1}
                  heading="Category 1 (Purchased goods and services)"
                  onToggle={() => this.handleToggleCollaplse("openCategory1")}
                  emissions={this.state.category1["Emissions"]}
                  relevancyAndRationale={this.state.category1["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category1["Methodology 1"]}
                  exclustion1={this.state.category1["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category1["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory2}
                  heading="Category 2 (Capital goods)"
                  onToggle={() => this.handleToggleCollaplse("openCategory2")}
                  emissions={this.state.category2["Emissions"]}
                  relevancyAndRationale={this.state.category2["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category2["Methodology 1"]}
                  exclustion1={this.state.category2["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category2["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory3}
                  heading="Category 3 (Fuel- and energy-related activities not included in Scope 1 or Scope 2)"
                  onToggle={() => this.handleToggleCollaplse("openCategory3")}
                  emissions={this.state.category3["Emissions"]}
                  relevancyAndRationale={this.state.category3["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category3["Methodology 1"]}
                  exclustion1={this.state.category3["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category3["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory4}
                  heading="Category 4 (Upstream transportation and distribution)"
                  onToggle={() => this.handleToggleCollaplse("openCategory4")}
                  emissions={this.state.category4["Emissions"]}
                  relevancyAndRationale={this.state.category4["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category4["Methodology 1"]}
                  exclustion1={this.state.category4["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category4["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory5}
                  heading="Category 5 (Waste generated in operations)"
                  onToggle={() => this.handleToggleCollaplse("openCategory5")}
                  emissions={this.state.category5["Emissions"]}
                  relevancyAndRationale={this.state.category5["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category5["Methodology 1"]}
                  exclustion1={this.state.category5["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category5["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory6}
                  heading="Category 6 (Business travel)"
                  onToggle={() => this.handleToggleCollaplse("openCategory6")}
                  emissions={this.state.category6["Emissions"]}
                  relevancyAndRationale={this.state.category6["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category6["Methodology 1"]}
                  exclustion1={this.state.category6["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category6["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory7}
                  heading="Category 7 (Employee commuting)"
                  onToggle={() => this.handleToggleCollaplse("openCategory7")}
                  emissions={this.state.category7["Emissions"]}
                  relevancyAndRationale={this.state.category7["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category7["Methodology 1"]}
                  exclustion1={this.state.category7["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category7["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory8}
                  heading="Category 8 (Upstream leased assets)"
                  onToggle={() => this.handleToggleCollaplse("openCategory8")}
                  emissions={this.state.category8["Emissions"]}
                  relevancyAndRationale={this.state.category8["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category8["Methodology 1"]}
                  exclustion1={this.state.category8["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category8["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory9}
                  heading="Category 9 (Downstream transportation and distribution)"
                  onToggle={() => this.handleToggleCollaplse("openCategory9")}
                  emissions={this.state.category9["Emissions"]}
                  relevancyAndRationale={this.state.category9["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category9["Methodology 1"]}
                  exclustion1={this.state.category9["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category9["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory10}
                  heading="Category 10 (Processing of sold products)"
                  onToggle={() => this.handleToggleCollaplse("openCategory10")}
                  emissions={this.state.category10["Emissions"]}
                  relevancyAndRationale={this.state.category10["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category10["Methodology 1"]}
                  exclustion1={this.state.category10["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category10["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory11}
                  heading="Category 11 (Use of sold products)"
                  onToggle={() => this.handleToggleCollaplse("openCategory11")}
                  emissions={this.state.category11["Emissions"]}
                  relevancyAndRationale={this.state.category11["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category11["Methodology 1"]}
                  exclustion1={this.state.category11["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category11["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory12}
                  heading="Category 12 (End-of-life treatment of sold products)"
                  onToggle={() => this.handleToggleCollaplse("openCategory12")}
                  emissions={this.state.category12["Emissions"]}
                  relevancyAndRationale={this.state.category12["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category12["Methodology 1"]}
                  exclustion1={this.state.category12["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category12["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory13}
                  heading="Category 13 (Downstream leased assets)"
                  onToggle={() => this.handleToggleCollaplse("openCategory13")}
                  emissions={this.state.category13["Emissions"]}
                  relevancyAndRationale={this.state.category13["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category13["Methodology 1"]}
                  exclustion1={this.state.category13["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category13["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory14}
                  heading="Category 14 (Franchises)"
                  onToggle={() => this.handleToggleCollaplse("openCategory14")}
                  emissions={this.state.category14["Emissions"]}
                  relevancyAndRationale={this.state.category14["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category14["Methodology 1"]}
                  exclustion1={this.state.category14["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category14["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openCategory15}
                  heading="Category 15 (Investments)"
                  onToggle={() => this.handleToggleCollaplse("openCategory15")}
                  emissions={this.state.category15["Emissions"]}
                  relevancyAndRationale={this.state.category15["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.category15["Methodology 1"]}
                  exclustion1={this.state.category15["Exclusion 1"]}
                  changeInScope3Emissions={this.state.category15["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openOtherScope3Emissions}
                  heading="Other Scope 3 emissions"
                  onToggle={() => this.handleToggleCollaplse("openOtherScope3Emissions")}
                  emissions={this.state.otherScope3Emissions["Emissions"]}
                  relevancyAndRationale={this.state.otherScope3Emissions["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.otherScope3Emissions["Methodology 1"]}
                  exclustion1={this.state.otherScope3Emissions["Exclusion 1"]}
                  changeInScope3Emissions={this.state.otherScope3Emissions["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openIndirectGHGEmissionsFromTransportation}
                  heading="Indirect GHG Emissions from transportation"
                  onToggle={() => this.handleToggleCollaplse("openIndirectGHGEmissionsFromTransportation")}
                  emissions={this.state.indirectGHGEmissionsFromTransportation["Emissions"]}
                  relevancyAndRationale={this.state.indirectGHGEmissionsFromTransportation["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.indirectGHGEmissionsFromTransportation["Methodology 1"]}
                  exclustion1={this.state.indirectGHGEmissionsFromTransportation["Exclusion 1"]}
                  changeInScope3Emissions={this.state.indirectGHGEmissionsFromTransportation["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openIndirectGHGEmissionsAssociated}
                  heading="Indirect GHG emissions associated with the use of products from the organization"
                  onToggle={() => this.handleToggleCollaplse("openIndirectGHGEmissionsAssociated")}
                  emissions={this.state.indirectGHGEmissionsAssociated["Emissions"]}
                  relevancyAndRationale={this.state.indirectGHGEmissionsAssociated["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.indirectGHGEmissionsAssociated["Methodology 1"]}
                  exclustion1={this.state.indirectGHGEmissionsAssociated["Exclusion 1"]}
                  changeInScope3Emissions={this.state.indirectGHGEmissionsAssociated["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openIndirectGHGEmissionsFromProduct}
                  heading="Indirect GHG emissions from products used by organization"
                  onToggle={() => this.handleToggleCollaplse("openIndirectGHGEmissionsFromProduct")}
                  emissions={this.state.indirectGHGEmissionsFromProducts["Emissions"]}
                  relevancyAndRationale={this.state.indirectGHGEmissionsFromProducts["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.indirectGHGEmissionsFromProducts["Methodology 1"]}
                  exclustion1={this.state.indirectGHGEmissionsFromProducts["Exclusion 1"]}
                  changeInScope3Emissions={this.state.indirectGHGEmissionsFromProducts["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
                <Category1 
                  isOpen={this.state.openIndirectGHGEmissionsFromOtherSources}
                  heading="Indirect GHG emissions from other sources"
                  onToggle={() => this.handleToggleCollaplse("openIndirectGHGEmissionsFromOtherSources")}
                  emissions={this.state.indirectGHGEmissionsFromOtherSources["Emissions"]}
                  relevancyAndRationale={this.state.indirectGHGEmissionsFromOtherSources["Relevancy and rationale if not disclosed"]}
                  methodology1={this.state.indirectGHGEmissionsFromOtherSources["Methodology 1"]}
                  exclustion1={this.state.indirectGHGEmissionsFromOtherSources["Exclusion 1"]}
                  changeInScope3Emissions={this.state.indirectGHGEmissionsFromOtherSources["Change in Scope 3 Emissions calculation or reporting boundary"]}
                />
              </ScopeTableBody>
            </ScopeTable>
          </ScopeTableContainer>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  title: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "27.5px"
  },
  subtext: {
    color: "#34373A",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    marginTop: '29px'
  },
  appbar: {
    padding: '37px 0',
    maxWidth: '850px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    marginLeft: '-38px'
  },
  tabtext: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.4px",
  },
  name: {
    color: '#7D7D7F',
    fontSize: "12px",
    fontWeight: 400,
  },
  value: {
    fontWeight: 700,
  }
}
// Customizable Area End
