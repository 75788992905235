import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Container } from "@material-ui/core"
import BlogPageHeadingSectionWeb from "./BlogPageHeadingSection.web";
import DynamicContentController, {
	Props,
} from "./DynamicContentController";
import FaqsSection from "./Pricing/FaqsSection.web";
import parse from 'html-react-parser';
import Heading from "../../../components/src/FreeAssessmentPage/Heading";

const theme = createTheme({
	palette: {
		primary: {
			main: "#fff",
			contrastText: "#fff",
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: "20px 0px",
		},
	},
});

export default class FaqsListing extends DynamicContentController {
	constructor(props: Props) {
		super(props);
	}

	async componentDidMount() {
		this.getFaqData()
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Heading
					key={this.state.faqDocumentData?.sub_header}
					heading={this.state.faqDocumentData?.header}
				/>
				{
					this.state.faqDocumentData?.widget_sub_items?.data.map((data: any, index: number) => {

						if (data?.attributes?.body) {
							return <Container style={{ paddingBottom: "70px" }} className="ql-editor typograhy">
								{parse(data?.attributes?.body.toString())}
							</Container>
						}
						if (data?.attributes?.widget_sub_items?.data && data?.attributes?.widget_sub_items?.data.length > 0) {
							return (
								<FaqsSection
									key={`widget_key${index}`}
									{...data}
								/>
							)
						}
					})
				}
			</ThemeProvider>
		);
	}
}