import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Tabs, Tab, AppBar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled, makeStyles } from "@material-ui/core/styles";
import Layout from "../../dashboard/src/Layout.web";
import Cfnzdpuscope1emissions from "./Cfnzdpuscope1emissions.web";
import Cfnzdpuassuranceandverification from "../../cfnzdpuassuranceandverification/src/Cfnzdpuassuranceandverification.web";
import Cfnzdpuscope3emissions from "../../cfnzdpuscope3emissions/src/Cfnzdpuscope3emissions.web";
import Cfnzdpuscope2emissions from "../../cfnzdpuscope2emissions/src/Cfnzdpuscope2emissions.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  customFlexContainer: {
    gap: 40,
  },
}));

const CustomTabs = (props: any) => {
  const classes = useStyles();

  return (
    <Tabs
      {...props}
      classes={{ flexContainer: classes.customFlexContainer }}
    />
  );
};


const CustomTab = styled(Tab)({
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22.4px",
  
  '&.Mui-selected': {
    color: '#329E54',
    fontWeight: 700,
  },
});

const EmissionsContainer = styled(Box)({
  padding: "32px 76px 0 44px",
  "@media(max-width: 600px)": {
    padding: "32px 10px 0 44px",
  }
});

// Customizable Area End

import Cfnzdpuscope1emissionsController, {
  Props,
  configJSON,
} from "./Cfnzdpuscope1emissionsController";

export default class Emissions extends Cfnzdpuscope1emissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Layout>
          <EmissionsContainer>
            <Typography style={webStyle.title}>Lululemon Athletics Inc.,</Typography>
            <Typography style={webStyle.subtext}>Consumer Goods</Typography>
            <AppBar position="static" color="default" style={webStyle.appbar}>
              <CustomTabs
                value={this.state.tabvalue}
                onChange={this.handleChange}
                TabIndicatorProps={{ style: { 
                  background: '#43D270', 
                  height: "4px", 
                  marginLeft: '3px' 
                } }}
                aria-label="full width tabs example"
                variant="scrollable"
                scrollButtons="off"
              >
                <CustomTab label="SCOPE 1 EMISSIONS" />
                <CustomTab label="SCOPE 2 EMISSIONS" />
                <CustomTab label="SCOPE 3 EMISSIONS" />
                <CustomTab label="ASSURANCE & VERIFICATION" />
              </CustomTabs>
            </AppBar>
            <Cfnzdpuscope1emissions navigation={this.props.navigation} id={""} value={this.state.tabvalue} index={0} />
            <Cfnzdpuassuranceandverification navigation={this.props.navigation} id={""} value={this.state.tabvalue} index={3} />
            <Cfnzdpuscope3emissions navigation={this.props.navigation} id={""} value={this.state.tabvalue} index={2} />
            <Cfnzdpuscope2emissions navigation={this.props.navigation} id={""} value={this.state.tabvalue} index={1} />
          </EmissionsContainer>
        </Layout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  title: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "27.5px"
  },
  subtext: {
    color: "#34373A",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    marginTop: '29px'
  },
  appbar: {
    padding: '30px 0',
    maxWidth: '850px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    marginLeft: '-3px'
  },
  tabtext: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.4px",
  }
}
// Customizable Area End
