import React from "react";
import {
  Box,
  Modal,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { successIcon } from "../assets";

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  onContactUsClick: () => void;
}

export default function SuccessDialog(props: SuccessDialogProps) {
  const classes = useStyles();

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box className={classes.modalContainer} >
        <img src={successIcon} className={classes.successIcon} alt="Success icon" />
        <Typography className={classes.successText}>Success!</Typography>
        <Typography className={classes.successDescription}>
          You've successfully completed your emission log. <br />
          Way to go!
        </Typography>
        <Typography className={classes.bottomText}>
          The given data cannot be edited. For assitance 
          <span 
            data-test-id="contact-us-link"
            className={classes.ctaText}
            onClick={props.onContactUsClick}
          >
            {" "}Contact us
          </span>
        </Typography>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(() => ({
  modalContainer: {
    background: 'var(--additional-white, #FFF)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    gap: '32px',
    width: '450px',
    height: '400px',
    padding: "40px",
    borderRadius: "16px",
    position: 'absolute',
    top: "20%",
    '@media(max-width: 700px)': {
      width: '80%'
    },
  },
  successIcon: {
    height: '129.639px',
    width: "66px",
  },
  successText: {
    textAlign: "center",
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '125%',
    letterSpacing: '0.2px',
    alignSelf: "stretch",
    color: "var(--greyscale-900, #0F172A)",
  },
  successDescription: {
    textAlign: 'center',
    fontFamily: "Lato",
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#34373A',
    lineHeight: '26px',
    letterSpacing: '0.085px',
    fontFeatureSettings: "'clig' off, 'liga' off",
    alignSelf: "stretch",
  },
  bottomText: {
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    color: 'var(--greyscale-900, #0F172A)',
  },
  ctaText: {
    cursor: 'pointer',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#43D270',
    lineHeight: '160%',
  },
}))