import React, { ReactElement, useEffect, useState, Ref } from 'react';
import { Grid, Container, Typography, Box, TextField, InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { empty, ic_search, downArrowIcon } from "./assets";
import parse from 'html-react-parser';
import { removeTags } from './landingpage/LpDifferentFromOther';
import { 
    extractContentFromHTMLString, 
    convertTo12HourFormat,
    formatDateToString,
    useStyles,
    getTagColors,
} from './BlogListingSection.web';
import Pagination from '@material-ui/lab/Pagination';

interface PropTypes {
    pageId?: any,
    isLoading?: boolean,
    page?: string,
    header?: string,
    limit?: number,
    data?: any,
    enableMetaTags?: boolean,
    enableButton?: boolean,
    enablePaymentTags?: boolean,
    viewDetails?: any,
    detailPath?: string
    handlePageChange?: any,
    handleChangeSearch?: any,
    handleChangeSort?: any,
    currentPage: number,
    searchString?: string,
    blogsRef?: Ref<null | HTMLDivElement>;
}

const BlogListingEventSection = ({ 
    header, 
    page, 
    enableMetaTags, 
    enableButton, 
    pageId, 
    enablePaymentTags, 
    limit, 
    data, 
    viewDetails = () => { }, 
    handleChangeSearch, 
    handlePageChange, 
    handleChangeSort,
    isLoading,
    currentPage,
    searchString,
    blogsRef,
}: PropTypes) => {
    const classes = useStyles();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const detailPagePath = window.location.pathname?.toLowerCase();
    const postLimit = limit ?? 10000;
    const listOnePage = limit || 6;
    const totalPage = Math.ceil(data?.widget_sub_items_count / listOnePage);

    const handleScroll = () => {
        setIsMenuOpen(false);
    };

    const handleSelectClick = () => {
        setIsMenuOpen((prevIsMenuOpen: any) => !prevIsMenuOpen);
    };

    const handleMenuItemClick = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const notFoundContent = (
        <Box className={classes.emptyResultContainer}>
            <img src={empty} alt="no result" style={{ width: '276.14px', height: '255.29px' }} />
            <Typography className={classes.emptyresutdescription}>
                Sorry, we couldn’t find any results for that search.
                Try again with a different key word.
            </Typography>
        </Box>
    );

    return (
        <Container maxWidth="lg" className={classes.mainContainerStyle} innerRef={blogsRef}>
            <Grid 
                container 
                spacing={2}
                alignItems="center" 
                justifyContent="space-between" 
                style={{ paddingBottom: "30px" }}
            >
                <Grid item lg={7} xs={12}>
                    <Box className={classes.widgetHeading}>
                        {header && parse(header.toString())}
                    </Box>
                </Grid>
                <Grid item lg={5} xs={12}>
                    <Box className={classes.searchSortLabel}>
                        <TextField
                            variant="standard"
                            data-test-id='handle-change-function'
                            placeholder='Search'
                            className={classes.ctaInput}
                            value={searchString}
                            onChange={(e: any) => handleChangeSearch(e.target.value, pageId)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <img src={ic_search} alt='search' height={30} width={31} />
                                    </InputAdornment>
                                ),
                                disableUnderline: true
                            }}
                        />
                        <FormControl className={classes.sortFieldStyle}>
                            <InputLabel shrink={false} className={classes.sortLabel}>
                                Sort by
                            </InputLabel>
                            <Select
                                disableUnderline
                                defaultValue="DESC"
                                variant="standard"
                                data-test-id='handle-change-sort'
                                className={classes.sortInput}
                                onChange={(e: any) => handleChangeSort(e.target.value, pageId)}
                                onOpen={handleSelectClick}
                                open={isMenuOpen}
                                onClose={handleSelectClick}
                                onClick={handleMenuItemClick}
                                IconComponent={(props) => <img {...props} src={downArrowIcon} style={{ top: "unset" }} />}
                                MenuProps={{
                                    anchorOrigin: {
                                        horizontal: "left",
                                        vertical: "bottom",
                                    },
                                    transformOrigin: {
                                        horizontal: "left",
                                        vertical: "top",
                                    },
                                    getContentAnchorEl: null,
                                    disableScrollLock: true
                                }}
                            >
                                <MenuItem value="DESC">Newest</MenuItem>
                                <MenuItem value="ASC">Old</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            {
                !isLoading && data?.widget_sub_items?.data?.length === 0 ?
                    notFoundContent :
                    <>
                        <Grid container spacing={4}>
                            {data.widget_sub_items?.data.slice(0, postLimit).map((item: any, index: any): ReactElement => {
                                const fullDescription = extractContentFromHTMLString(item.attributes.event_description);
                                const slicedDescription = `${fullDescription.slice(0, 74)}...`;
                                const blogPostTimeAndDateArr = [];
                                const blogPostTime = convertTo12HourFormat(item?.attributes?.event_time);
                                if (blogPostTime) {
                                    blogPostTimeAndDateArr.push(blogPostTime);
                                }
                                const blogPostDate = formatDateToString(item?.attributes?.event_date);
                                if (blogPostDate) {
                                    blogPostTimeAndDateArr.push(blogPostDate);
                                }
                                const blogPostTimeAndDate = blogPostTimeAndDateArr.join(", ");
                                return <Grid item md={4} sm={6} xs={12} container key={`s_item_${index}`}>
                                    <Box
                                        data-test-id="tesetid12"
                                        className={classes.genericCard}
                                        data-set-test='genericCard'
                                        onClick={() => {
                                            viewDetails(item.id, detailPagePath);
                                        }}>
                                        <Box className={classes.imageCard}>
                                            {item?.attributes?.event_image && (
                                                <img 
                                                    src={item?.attributes.event_image} 
                                                    className={`${enableButton ? classes.images1 : classes.images}`} 
                                                    alt="" 
                                                />
                                            )}
                                        </Box>
                                        <Box className={classes.gcContent}>
                                            <Typography 
                                                variant='h3' 
                                                className={classes.title} 
                                                style={{ 
                                                    visibility: item?.attributes?.resource_tag_id ? "visible" : "hidden",
                                                    backgroundColor: 
                                                        getTagColors[item?.attributes?.resource_tag_id] || "#F96C30" 
                                                }}
                                            >
                                                {item?.attributes?.resource_tag_id}
                                            </Typography>
                                            <Typography variant='h4' className={classes.text} >{removeTags(item?.attributes?.event_title)}</Typography>
                                            <Typography className={classes.description}>{slicedDescription}</Typography>
                                            {enableMetaTags &&
                                                <div className={classes.cardMetaDataStyle1}>
                                                    <Typography className={classes.description}>
                                                        {blogPostTimeAndDate}
                                                    </Typography>
                                                </div>
                                            }
                                        </Box>
                                    </Box>
                                </Grid >
                            })}
                        </Grid>
                        <Box display="flex" justifyContent="center" pt={5} pb={5}>
                            <Pagination
                                data-test-id="pagination"
                                page={currentPage}
                                count={totalPage}
                                shape="rounded"
                                className={classes.pagination}
                                onChange={(event, value) => handlePageChange(value)}
                            />
                        </Box>
                    </>
            }
        </Container>
    );
}
export default BlogListingEventSection;