import React, { useEffect, useState } from "react";
import {
	FormControl,
	Select,
	Typography,
	styled,
	Box,
	Grid,
	Modal
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import { DropdownIcon } from "./SelectFieldStyled.web";

export const getYearsArray = (
	startYear = 2020,
	limit = 20
) => {
	const years = [];

	for (let year = startYear; year < startYear + limit; year++) {
		years.push(year);
	}

	return years;
};

export const getMonthsArray = (startMonth = "January") => {
	const currentMonthIndex = new Date().getMonth();
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	];

	const startIndex = monthNames.indexOf(startMonth);
	const validStartIndex = startIndex !== -1 ? startIndex : currentMonthIndex;

	const months = [];
	for (let i = validStartIndex; i < validStartIndex + 12; i++) {
		const monthIndex = i % 12;
		months.push(monthNames[monthIndex]);
	}

	return months;
};

export const getMonthName = (index?: number) => {
	const monthNames = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];

	if (index !== undefined && index >= 0 && index < monthNames.length) {
		return { month: monthNames[index], year: new Date().getFullYear() };
	} else {
		const currentDate = new Date();
		const currentMonthIndex = currentDate.getMonth();
		const lastMonthIndex = (currentMonthIndex === 0) ? 11 : currentMonthIndex - 1;
		const lastYear = (currentMonthIndex === 0) ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
		return { month: monthNames[lastMonthIndex], year: lastYear };
	}
};

const FieldLabelStyled = styled(Typography)({
	fontSize: "15px",
	fontFamily: "Lato",
	fontWeight: 400,
	lineHeight: "24px",
	borderRadius: "8px",
	color: "#34373A",
	marginBottom: "8px",
	textAlign: "left"
});

const FieldGroupStyled = styled(Box)({
	marginBottom: "40px"
});

const SelectDropdownStyled = styled(Select)({
	borderRadius: "8px",
	height: "48px",
	padding: "0px!important;",
	"&:focus": {
		borderColor: "#cccccc"
	},
	"& *": {
		background: "#fff !important;"
	},
	"& .MuiSelect-select": {
		display: "flex !important",
		fontFamily: "Lato",
		fontSize: "15px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "24px",
		color: "black !important"
	}
});

const PlaceholderText = styled(Typography)({
	fontFamily: "Lato",
	fontSize: "15px",
	fontStyle: "normal",
	fontWeight: 400,
	lineHeight: "24px",
	color: "#7D7D7F",
	paddingLeft: "12px"
});

const CustomSelectedText = styled(Typography)({
	fontFamily: "Lato",
	fontSize: "15px",
	fontStyle: "normal",
	fontWeight: 400,
	lineHeight: "24px",
	color: "#34373A",
	paddingLeft: "12px"
});

const DropdownFormControl = styled(FormControl)({
	borderRadius: "8px",
	width: "100%",
	border: "1px solid rgba(0, 0, 0, 0.2)"
});

const ErrorAlert = styled("div")({
	color: "#ff0000",
	lineHeight: 1,
	marginTop: "10px"
});

const ModalPapar = styled(Box)({
	maxHeight: "340px",
	borderRadius: "12px",
	padding: "24px",
	background: "#fff",
	width: "334px",
	maxWidth: "95%",
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	outline: "none !important",
	boxSizing: "border-box"
});

const CardHeading = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "16px"
});

const CardBody = styled("div")({
	height: "100%"
});

const MonthDropdown = styled("div")({
	display: "flex",
	cursor: "pointer"
});

const CustomExpandMoreIcon = styled(ExpandMoreIcon)({
	paddingLeft: "3px",
	paddingTop: "2px",
	width: "20px",
	height: "20px",
	color: "#7D7D7F"
});

const ExpandMoreIconSelected = styled(ExpandMoreIcon)({
	color: "#43D270",
	paddingLeft: "3px",
	paddingTop: "2px",
	width: "20px",
	height: "20px"
});

const CheckIconSelected = styled(CheckIcon)({
	color: "#43D270",
	width: "15px",
	paddingBottom: "2px"
});
const MonthText = styled(Typography)({
	fontSize: "16px",
	color: "#34373A",
	fontFamily: "Lato",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "24px",
	letterSpacing: "0.2px"
});

const MonthGridContainer = styled(Grid)({
	borderTop: "1px solid #F1F5F9",
	paddingTop: "12px",
	'@media (max-width:600px)':{
		gap:'60px'
	}
});

const MonthGridStyled = styled(Grid)({
	height: "100%"
});

const SelectedItemName = styled("div")({
	display: "flex",
	alignItems: "center",
	cursor: "pointer",
	margin: "0 auto",
	gap: "50px",
	'@media (max-width:600px)':{
		gap:'15px'
	}
});

const SelectedYearItem = styled(SelectedItemName)({
	display: "flex",
	alignItems: "center",
	cursor: "pointer",
	justifyContent: "center",
	margin: "0 auto",
	gap: "10px",
	paddingLeft: "25px"
});

const MonthName = styled("div")({
	textAlign: "left",
	padding: "10px 7px",
	cursor: "pointer",
	fontSize: "12px",
	lineHeight: "19.2px",
	fontFamily: "Lato",
	fontWeight: 700,
	color: "#34373A"
});

const MonthNameSelected = styled(MonthName)({
	color: "#43D270",
	textAlign: "left",
	padding: "10px 7px",
	cursor: "pointer",
	fontSize: "12px",
	lineHeight: "19.2px",
	fontFamily: "Lato",
	fontWeight: 700
});

const YearDropdown = styled("div")({
	display: "flex",
	cursor: "pointer"
});

const YearText = styled(Typography)({
	fontSize: "16px",
	color: "#34373A",
	fontFamily: "Lato",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "24px",
	letterSpacing: "0.2px"
});

const YearsListing = styled(Box)({
	maxHeight: "250px",
	overflowY: "auto",
	borderTop: "1px solid #F1F5F9",
	paddingTop: "16px"
});

const YearName = styled("div")({
	textAlign: "center",
	padding: "10px 7px",
	cursor: "pointer",
	fontSize: "12px",
	lineHeight: "20px",
	fontFamily: "Lato",
	fontWeight: 700
});

const YearNameSelected = styled(YearName)({
	color: "#43D270"
});

interface CalenderSelectFieldPropsType {
	onChange?: any;
	value?: string;
	label: string;
	placeholder: string;
	defaultSelectLabel?: string;
	fullwidth?: string;
	error?: boolean;
	errorMessage?: string;
	dataTestId?: string;
	esgFactorsStatus?: string;
}

const CalenderSelectField: React.FC<CalenderSelectFieldPropsType> = ({
	onChange,
	label,
	placeholder,
	fullwidth,
	value,
	error,
	errorMessage,
	dataTestId,
	esgFactorsStatus,
}) => {

	const valueArr = value ? value.split(" ") : [];
	
	const defaultMonth = valueArr.length > 0 && valueArr[0] ? valueArr[0] : getMonthName().month;
	const defaultYear = valueArr.length > 1 && valueArr[1] ? valueArr[1] : new Date().getFullYear();

	const [open, setOpen] = useState(false);
	const [month, setMonth] = useState(defaultMonth);
	const [year, setYear] = useState(defaultYear);
	const [selection, setSelection] = useState("month");
	const calenderEditable = !value && esgFactorsStatus && esgFactorsStatus !== "completed";
	
	const handleOpenModal = () => {
		setOpen(true);
	};

	const ddwidth = fullwidth || "404px";

	const handleOnMonthSelect = (monthVal: string) => {
		setMonth(monthVal);
		setSelection("year");
	};

	const handleOnYearSelect = (yearVal: any) => {
		setYear(yearVal);
		setSelection("month");
		setOpen(false);

		if (month && yearVal) {
			const changeEvent = {
				target: {
					value: `${month} ${yearVal}`
				}
			};
			onChange && onChange(changeEvent);
		}
	};

	const handleYearMonthToggle = () => {
		setSelection(selection === "month" ? "year" : "month");
	};

	const handleOnModalClose = () => {
		setOpen(false);
		setSelection("month");
	};

	const yearsArr = getYearsArray();
	const monthsArr = getMonthsArray();
	const placeholderShow = !(month && year);

	const placeholderShowContent = placeholderShow ? (
		<PlaceholderText>{placeholder}</PlaceholderText>
	) : (
		<CustomSelectedText>{`${month} ${year}`}</CustomSelectedText>
	);

	useEffect(() => {
		/**
		 * Invoke the onChange function when:
		 * The value i.e time_period from detailed_first_log api response is not defined
		 * And the esg details status is either "published" or "archived"
		 */
		if (calenderEditable) {
			const changeEvent = {
				target: {
					value: `${month} ${year}`
				}
			};
			onChange(changeEvent);
		}
	}, [calenderEditable]);

	return (
		<>
			<FieldGroupStyled style={{ width: ddwidth }} data-test-id={dataTestId} onClick={handleOpenModal}>
				<FieldLabelStyled>
					{label}
				</FieldLabelStyled>
				<DropdownFormControl>
					<SelectDropdownStyled
						data-test-id="styled_select"
						readOnly
						open={false}
						displayEmpty
						IconComponent={DropdownIcon}
						disableUnderline
						MenuProps={{
							PaperProps: {
								style: {
									width: ddwidth,
									maxHeight: 200,
									maxWidth: "100%",
								},
							},
							anchorOrigin: {
								horizontal: "left",
								vertical: "bottom",
							},
							transformOrigin: {
								horizontal: "left",
								vertical: "top",
							},
							getContentAnchorEl: null,
						}}
						renderValue={() => placeholderShowContent}
					/>

				</DropdownFormControl>
				{error && errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert>}
			</FieldGroupStyled>
			<Modal open={open}
				data-test-id="month_year_close"
				onClose={handleOnModalClose}
			>
				<ModalPapar>
					<CardHeading>
						<MonthDropdown
							data-test-id="month_year_toggle"
							onClick={handleYearMonthToggle}>
							<MonthText>{month}</MonthText>
							{selection === "month" ? (
								<ExpandMoreIconSelected />
							) : (
								<CustomExpandMoreIcon />
							)}
						</MonthDropdown>
						<YearDropdown
							data-test-id="year_month_toggle"
							onClick={handleYearMonthToggle}>
							<YearText>{year}</YearText>
							{selection === "year" ? (
								<ExpandMoreIconSelected />
							) : (
								<CustomExpandMoreIcon />
							)}
						</YearDropdown>
					</CardHeading>
					<CardBody>
						{selection === "month" ? (
							<MonthGridContainer container>
								{[1, 2].map((col, index) => {
									let monthData = monthsArr.slice(0, 6);
									if (col == 2) {
										monthData = monthsArr.slice(6, 12);
									}
									return (
										<MonthGridStyled key={`monthgrid_${index}`} item sm={6}>
											{monthData.map((item, index) => {
												return month === item ? (
													<SelectedItemName key={`month_${index}`}>
														<MonthNameSelected
															data-test-id="month-name-selected"
															onClick={() => handleOnMonthSelect(item)}
														>
															{item}
														</MonthNameSelected>
														<CheckIconSelected />
													</SelectedItemName>
												) : (
													<MonthName
														key={`month_${index}`}
														data-test-id="month-selected"
														onClick={() => handleOnMonthSelect(item)}
													>
														{item}
													</MonthName>
												);
											})}
										</MonthGridStyled>
									);
								})}
							</MonthGridContainer>
						) : (
							<YearsListing>
								{yearsArr.map((item, index) => {
									return year === item ? (
										<SelectedYearItem key={`year_${index}`}>
											<YearNameSelected
												data-test-id="year_name_selected"
												onClick={() => handleOnYearSelect(item)}
											>
												{item}
											</YearNameSelected>
											<CheckIconSelected />
										</SelectedYearItem>
									) : (
										<YearName
											key={`year_${index}`}
											onClick={() => handleOnYearSelect(item)}
											data-test-id="year-name"
										>
											{item}
										</YearName>
									);
								})}
							</YearsListing>
						)}
					</CardBody>
				</ModalPapar>
			</Modal>
		</>
	);
};

export default CalenderSelectField;
