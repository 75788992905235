import React, { ReactElement, useEffect, useState, Ref } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography, Box, TextField, InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Link } from "react-router-dom";
import { empty, ic_search, downArrowIcon } from "./assets";
import parse from 'html-react-parser';
import moment from 'moment';
import { removeTags } from './landingpage/LpDifferentFromOther';
import Pagination from '@material-ui/lab/Pagination';

export const useStyles = makeStyles({
    widgetHeading: {
        "& > *": {
            fontFamily: "Lato",
            fontSize: "28px",
            fontWeight: 600,
            lineHeight: "39.2px",
            letterSpacing: "0.5px",
            color: "#000000",
        }
    },
    mainContainerStyle: {
        alignItems: "center",
        paddingX: "20px",
        paddingBottom: '20px'
    },

    genericCard: {
        background: "#FFFFFF",
        borderRadius: "16px",
        width: "100%",
        '& :hover': {
            transform: "scale(1)",
        },
        cursor: "pointer"
    },

    imageCard: {
        width: "392px",
        height: "399px",
        maxWidth: "100%",
        background: "#FFFFFF",
        borderRadius: "14px",
        margin: "0 auto 30px auto",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        overflow: 'hidden',
    },
    images: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "cover",
        transition: "all ease 0.5s",
        '&:hover': {
            transform: "scale(1.1)",
        },
    },
    images1: {
        maxWidth: "100%",
        objectFit: "cover",
        transition: "all ease 0.5s",
        '&:hover': {
            transform: "scale(1.1)",
        },
    },
    jobCategory: {
        color: "#34373A",
        lineHeight: "30px",
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: 'Montserrat',
    },
    gcContent: {
        padding: "0 10px",
    },
    noDecoration: {
        textDecoration: "none !important",
        cursor: 'pointer',
    },
    title: {
        color: "#fff",
        fontFamily: 'Montserrat',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        width: "fit-content",
        padding: "6px 16px 6px 16px",
        borderRadius: "4px",
        marginBottom: "15px",
    },
    text: {
        minHeight: "70px",
        color: "#000000",
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "22px",
        width: "100%",
        display: "-webkit-box",
        lineHeight: "35.2px",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        '@media (max-width:600px)': {
            minHeight: "auto",
        },
    },
    description: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "25.6px",
        color: "#4F4F4F",
        marginTop: "10px",
        '@media (max-width:600px)': {
            minHeight: "auto",
        },
    },
    cardMetaDataStyle1: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "7px"
    },
    cardMetaDataStyle2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "7px"
    },
    authorName: {
        color: "#34373A",
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        marginBottom: "14px",
        flex: 1,
    },
    date: {
        color: "#34373A",
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        flex: 1,
    },
    cardButtonWr: {
        textAlign: "right",
    },
    cardButton: {
        minHeight: "48px",
        background: "#43D270",
        borderRadius: "8px",
        boxShadow: "none",
        display: "inline-flex",
        minWidth: "131px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none !important",
        color: "#000",
        textTransform: "uppercase",
        fontFamily: 'Lato',
        fontWeight: 500,
        lineHeight: "normal",
    },
    tagButton: {
        minHeight: "32px",
        background: "#F3F3F3",
        borderRadius: "32px",
        boxShadow: "none",
        display: "inline-flex",
        minWidth: "72px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none !important",
        color: "#727272",
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "capitalize",
    },
    tagPaidButton: {
        minHeight: "32px",
        background: "#E0E0FC",
        borderRadius: "32px",
        boxShadow: "none",
        display: "inline-flex",
        minWidth: "72px",
        fontFamily: "Montserrat",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none !important",
        color: "#7166F1",
        fontSize: '14px',
        textTransform: "capitalize",
        fontWeight: 600,
    },
    ctaInput: {
        backgroundColor: "#FAFAFB",
        width: "300px",
        maxWidth: "100%",
        fontSize: "15px",
        borderRadius: "8px !important",
        border: "2px solid #737373 !important",
        overflow: "hidden",
        "& .MuiInputBase-root-630": {
            borderRadius: "8px !important",
            backgroundColor: "#F3F3F3",
        },
        "& .MuiOutlinedInput-input": {
            padding: "15px",
        },
        '& input ': {
            height: "48px",
            boxSizing: "border-box",
            padding: "13px 20px",
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "22.4px",
            letterSpacing: "0.5px",
            color: "#000",
        },
        "@media(max-width: 600px)": {
            width: "100%",
            maxWidth: "100%",
        },
        "@media(max-width: 360px)": {
            width: "100%",
        },
    },

    sortInput: {
        maxWidth: "100%",
        height: "38px",
        borderRadius: "8px !important",
        overflow: "hidden",
        paddingLeft: "75px",
        marginLeft: '0',
        fontFamily: 'Lato !important',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "22.4px",
        color: "#000",
        '& div:focus': {
            backgroundColor: "rgba(0, 0, 0, 0) !important",
        },

    },
    emptyResultContainer: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '110px'
    },
    emptyresutdescription: {
        fontFamily: 'Montserrat !important',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "36px",
        textAlign: "center",
        color: "#34373A",
        maxWidth: '600px',
        marginTop: '50px'
    },
    sortLabel: {
        top: '-1px',
        left: '12px',
        zIndex: 1,
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22.4px",
        letterSpacing: "0.1px",
        color: "#5E5E5E !important",
    }
    ,
    searchSortLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        "@media(max-width: 600px)": {
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
    },
    sortFieldStyle: {
        position: "relative"
    },
    pagination: {
        color: "red",
        '& .Mui-selected': {
            background: '#43D270 !important' ,
            height: "52px",
            width: "52px",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "22.4px",
            color: "#000000",
        },
        '& > ul > li': {
            marginRight: '15px',
        },
        '& > ul > li > button': {
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "22.4px",
            color: "#000000",
        },
    }
})

interface PropTypes {
    page?: string,
    header?: string,
    enableMetaTags?: boolean,
    enableButton?: boolean,
    enablePaymentTags?: boolean,
    limit?: number,
    data?: any,
    viewDetails?: any,
    detailPath?: string
    handleChangeSearch?: any,
    handleChangeSort?: any,
    handlePageChange?: any,
    pageId?: any,
    isLoading?: boolean,
    currentPage: number,
    searchString?: string;
    blogsRef?: Ref<null | HTMLDivElement>;
}

export const getTagColors: any = {
    Environmental: "#43D270",
    Social: "#7166F1",
    Governance: "#F96C30",
    Flexible: "#F96C30",
    OnSite: "#43D270",
    Online: "#43D270",
    Remote: "#7166F1"
}

const dummyShadowColor: any = {
    Environmental: "rgba(67, 210, 112, 0.2)",
    Social: "rgba(171, 158, 255, 0.2)",
    Governance: "rgba(244, 154, 122, 0.2)",
    OnSite: "rgba(67, 210, 112, 0.2)",
    Online: "rgba(67, 210, 112, 0.2)",
    Remote: "rgba(171, 158, 255, 0.2)",
    Flexible: "rgba(244, 154, 122, 0.2)",
}

export function extractContentFromHTMLString(htmlString: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Extract the content inside the <p> tag
    let content = "";
    if (doc) {
        content = doc.querySelector('p')?.textContent ?? "";
    }
    return content;
}

export function convertTo12HourFormat(dateString: string) {
    if (!dateString) {
        return "";
    }
    // Parse the date string into a Date object
    let date = new Date(dateString);

    // Extract hours and minutes
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();

    // Determine AM or PM
    let ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format hours and minutes to always be two digits
    const hoursString = hours < 10 ? '0' + hours : hours;
    const minutesString = minutes < 10 ? '0' + minutes : minutes;

    // Construct the formatted time string
    let formattedTime = hoursString + ':' + minutesString + ' ' + ampm;

    return formattedTime;
}

export function formatDateToString(dateString: string) {
    if (!dateString) {
        return "";
    }
    // Parse the date string into a Date object
    let date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Extract the day, month, and year from the Date object
    let day = date.getUTCDate();
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Construct the formatted date string
    let formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
}

const BlogListingSection = ({ 
    header, 
    page, 
    enableMetaTags, 
    enableButton, 
    pageId, 
    enablePaymentTags, 
    limit, 
    data, 
    viewDetails = () => { }, 
    handleChangeSearch, 
    handlePageChange, 
    handleChangeSort,
    isLoading,
    currentPage,
    searchString,
    blogsRef,
}: PropTypes) => {
    const postLimit = limit ?? 10000;
    const classes = useStyles();
    const detailPagePath = window.location.pathname?.toLowerCase();
    const listOnePage = limit || 6;
    const totalPage = Math.ceil(data?.widget_sub_items_count / listOnePage);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleScroll = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSelectClick = () => {
        setIsMenuOpen((prevIsMenuOpen: any) => !prevIsMenuOpen);
    };

    const handleMenuItemClick = () => {
        setIsMenuOpen(false);
    };
    return (
        <Container maxWidth="lg" className={classes.mainContainerStyle} innerRef={blogsRef}>
            <Grid 
                container 
                justifyContent="space-between" 
                alignItems="center" 
                spacing={2}
                style={{ paddingBottom: "30px" }}
            >
                <Grid item lg={7} xs={12}>
                    <Box className={classes.widgetHeading}>
                        {header && parse(header.toString())}
                    </Box>
                </Grid>
                <Grid item lg={5} xs={12}>
                    <Box className={classes.searchSortLabel}>
                        <TextField
                            data-test-id='handle-change-function'
                            variant="standard"
                            className={classes.ctaInput}
                            value={searchString}
                            onChange={(e: any) => handleChangeSearch(e.target.value, pageId)}
                            placeholder='Search'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <img src={ic_search} alt='search' height={30} width={31} />
                                    </InputAdornment>
                                ),
                                disableUnderline: true
                            }}
                        />
                        <FormControl
                            className={classes.sortFieldStyle}
                        >
                            <InputLabel
                                shrink={false}
                                className={classes.sortLabel}>
                                Sort by
                            </InputLabel>
                            <Select
                                data-test-id='handle-change-sort'
                                variant="standard"
                                className={classes.sortInput}
                                onChange={(e: any) => handleChangeSort(e.target.value, pageId)}
                                disableUnderline
                                defaultValue="DESC"
                                open={isMenuOpen}
                                onOpen={handleSelectClick}
                                onClick={handleMenuItemClick}
                                onClose={handleSelectClick}
                                IconComponent={(props) => <img {...props} src={downArrowIcon} style={{ top: "unset" }} />}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    disableScrollLock: true
                                }}
                            >
                                <MenuItem value="DESC">Newest</MenuItem>
                                <MenuItem value="ASC">Old</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            {
                !isLoading && data?.widget_sub_items?.data?.length === 0 ?
                    <Box className={classes.emptyResultContainer}>
                        <img src={empty} alt="no result" style={{ width: '276.14px', height: '255.29px' }} />
                        <Typography className={classes.emptyresutdescription}>
                            Sorry, we couldn’t find any results for that search.
                            Try again with a different key word.
                        </Typography>
                    </Box>
                    :
                    <>
                        <Grid container spacing={4}>
                            {data?.widget_sub_items?.data && data?.widget_sub_items?.data.slice(0, postLimit).map((item: any, index: any): ReactElement => {
                                const fullDescription = extractContentFromHTMLString(item?.attributes?.description);
                                const slicedDescription = `${fullDescription.slice(0, 74)}...`;
                                const blogPostTimeAndDateArr = [];
                                const blogPostTime = convertTo12HourFormat(item?.attributes?.insight_time);
                                if (blogPostTime) {
                                    blogPostTimeAndDateArr.push(blogPostTime);
                                }
                                const blogPostDate = formatDateToString(item?.attributes?.insight_date);
                                if (blogPostDate) {
                                    blogPostTimeAndDateArr.push(blogPostDate);
                                }
                                const blogPostTimeAndDate = blogPostTimeAndDateArr.join(", ");
                                return <Grid item md={4} sm={6} xs={12} container key={`s_item_${index}`}>
                                    <Box
                                        data-test-id="tesetid12"
                                        className={classes.genericCard}
                                        data-set-test='genericCard'
                                        onClick={() => {
                                            viewDetails(item.id, detailPagePath);
                                        }}>
                                        <Box 
                                            className={classes.imageCard} 
                                            // sx={{ boxShadow: `0px 14px 34px ${dummyShadowColor[item?.attributes?.resource_tag_id]}` }}
                                        >
                                            {item?.attributes?.job_category ?
                                                <Box className={classes.jobCategory}>{removeTags(item?.attributes?.job_category)}</Box>
                                                :
                                                item?.attributes?.insight_image && <img src={item?.attributes?.insight_image} className={`${enableButton ? classes.images1 : classes.images}`} alt="" />
                                            }
                                        </Box>
                                        <Box >
                                            <Typography 
                                                variant='h3' 
                                                className={classes.title} 
                                                style={{ 
                                                    visibility: item?.attributes?.resource_tag_id ? "visible" : "hidden",
                                                    backgroundColor: 
                                                        getTagColors[item?.attributes?.resource_tag_id] || "#F96C30" 
                                                }}
                                            >
                                                {item?.attributes?.resource_tag_id}
                                            </Typography>
                                            <Typography variant='h4' className={classes.text} >{removeTags(item?.attributes?.title)}</Typography>
                                            <Typography className={classes.description}>{slicedDescription}</Typography>
                                            {enableMetaTags &&
                                                <div className={classes.cardMetaDataStyle1}>
                                                    <Typography className={classes.description}>
                                                        {blogPostTimeAndDate}
                                                    </Typography>
                                                </div>
                                            }
                                            {enablePaymentTags &&
                                                <div className={classes.cardMetaDataStyle2}>
                                                    {item?.attributes?.pay_type?.toLowerCase() === "paid" ?
                                                        <Link to="/events" className={classes.tagPaidButton}>{item?.attributes?.pay_type?.toLowerCase()}</Link>
                                                        :
                                                        <Link to="/events" className={classes.tagButton}>{item?.attributes?.pay_type?.toLowerCase()}</Link>
                                                    }
                                                    <Typography variant='h6' className={classes.date}>{`${item?.attributes?.insights_event_time}, ${moment(item?.attributes?.insights_event_date).format("MMMM D, YYYY")}`}</Typography>
                                                </div>
                                            }
                                            {page?.toLowerCase() !== 'job openings list' && enableButton &&
                                                <div className={classes.cardButtonWr}>
                                                    <Link to={`/contact-us`} onClick={(e) => e.stopPropagation()} className={classes.cardButton}>{item?.attributes?.buttonLabel ?? "Contact"}</Link>
                                                </div>
                                            }
                                            {page?.toLowerCase() === 'job openings list' &&
                                                <div className={classes.cardButtonWr}>
                                                    <Link to={`/job-openings-list/${item.id}`} className={classes.cardButton}>Apply</Link>
                                                </div>
                                            }
                                        </Box>
                                    </Box>
                                </Grid >
                            })}
                        </Grid>
                        <Box display="flex" justifyContent="center" pt={5} pb={5}>
                            {data?.widget_sub_items?.data?.length !== 0 && data?.widget_sub_items?.data !== undefined &&
                                <Pagination
                                    page={currentPage}
                                    count={totalPage}
                                    shape="rounded"
                                    className={classes.pagination}
                                    onChange={(event, value) => handlePageChange(value)}
                                />
                            }
                        </Box>
                    </>
            }
        </Container>
    );
}
export default BlogListingSection;