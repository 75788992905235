import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
interface ContactAtrribute1{
    contact_place:string,
    contact_address:string
    contact_phone:string
    contact_whatsapp:string
    contact_text_name:string
    contact_text_link:string
}
interface ContactAdress1 {
    data:{
        id:string,
        attributes:ContactAtrribute1
    }[]
}
interface SocialMedia1 {
    name:string,
    link:string
}
interface Attributes1 {
    contact_header1:string,
    contact_title1:string,
    contact_phone_number:string,
    contact_email:string,
    contact_address:string,
    contact_address_link:string,
    social_medias:SocialMedia1[],
    contact_title2:string,
    contact_description2:string,
    contact_address_details:ContactAdress1
}
interface ContactUsData1 {
    data:{
        id:string,
        attributes:Attributes1
    }
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string};
  pageData:ContactUsData1
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checklistArray:{
    id:number
    title: string
    checked:boolean
  }[];
  nameValue:string;
  companyNameValue:string;
  suggestionValue:string;
  companyMailValue:string;
  nameError:boolean;
  companyError:boolean;
  emailError:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContactUsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  senMessageApiID:string = ""
  dynamicOptionsId = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      checklistArray: [],
      nameValue:'',
      companyNameValue:'',
      suggestionValue:'',
      companyMailValue:'',
      nameError:false,
      companyError:false,
      emailError:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const requestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        this.getSendMessageAPiResponse(requestCallId,message)
       
    }

    if (apiRequestId === this.dynamicOptionsId) {
      this.setOptions(response);
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getOptions();
  }

  getOptions = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dynamicOptionsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.dynamicOptionsId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setOptions = (response: any) => {
    this.setState({checklistArray: response.dynamic_options})
  }

  getSendMessageAPiResponse = (requestCallId:string,message:Message) => {
    if(this.senMessageApiID === requestCallId){
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson){
            this.props.navigation.navigate('ContactSuccess', { page: 'contact' })
        }
    }
  }
  
  handleLinkSocialMedia= (linkname:string) => {
    const link = document.createElement('a');
    this.props.pageData.data.attributes.social_medias.filter(data => data.name === linkname).forEach(data => 
        link.href = data.link
    );
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  handleCheck = (id:number) => {
    this.setState(prevState =>({
        checklistArray:prevState.checklistArray.map(data => 
            data.id === id ? {...data,checked: !data.checked} : data
        ),
    }));
  }
  getCheckedItems = () => {
    let objectData = Object.create({})
     this.state.checklistArray.filter(item => item.checked).forEach((data,index) =>  {
       objectData[`${index + 1}`] = data.title;
    })
        return objectData
  }
  handleViewMap = (linkMap:string) => {
    const link = document.createElement('a');
    link.href = linkMap
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  handleNameChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({nameValue:event.target.value,nameError:false})
  }
  handleCompanyNameChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({companyNameValue:event.target.value,nameError:false})
  }
  handleEmailChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({companyMailValue:event.target.value,nameError:false,emailError:false})
  }
  handleSuggestionlChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({suggestionValue:event.target.value})
  }
  sendMessageApiCall = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const body = {
      name: this.state.nameValue,
      company_name: this.state.companyNameValue,
      company_email: this.state.companyMailValue,
      carbonzeroed_support: this.getCheckedItems(),
      more_info: this.state.suggestionValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.senMessageApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.senMessageAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSendMessage = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const {nameValue,companyNameValue,companyMailValue} = this.state
    if(nameValue === '' || companyNameValue === '' || companyMailValue === ''){
        this.setState({
            nameError:true
        })
    }else if (!emailRegex.test(this.state.companyMailValue)){
        this.setState({
            emailError:true
        })
    }else{
        this.sendMessageApiCall();
    }
  }
  // Customizable Area End
}