import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface initialValues {
  industry: number[],
  country: number[],
  scope: string,
  year: number[],
}

interface Country {
  id: number
  name: string
}


export interface MetadataList {
  id: number
  company_name: string
  reporting_year: string
  data_model: string
  sics_sector: string
  jursdiction: string
  total_scope_1_emission: string
  total_scope_1_ghg_emission: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  initialValues: initialValues;
  industryList: MetadataList[];
  total: number;
  perPage: number;
  page: number;
  industryLists: Country[];
  countryList: Country[];
  scopeList: Country[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfnzdpusearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  industryListApiCallId: string = ""
  industryApiCallId: string = ""
  countryApiCallId: string = ""
  scopeApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseData)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      initialValues: {
        industry: [1],
        country: [163],
        scope: "3",
        year: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
      },
      industryList: [],
      total: 0,
      perPage: 5,
      page: 1,
      industryLists: [],
      countryList: [],
      scopeList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const ApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let Responce = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (ApiCallId) {
        case this.industryListApiCallId:
          if (Responce.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(Responce.errors[0].token);
            return;
          }

          this.setState({ 
            total: Responce.total_metadata_count, 
            industryList: Responce.metadata_list,
            isLoading: false,
          });
          break;

        case this.industryApiCallId:
          if (Responce.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(Responce.errors[0].token);
            return;
          }
          this.setState({ industryLists: Responce.industries })
          break;

        case this.countryApiCallId:
          if (Responce.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(Responce.errors[0].token);
            return;
          }
          this.setState({ countryList: Responce.countries })
          break;

        case this.scopeApiCallId:
          this.setState({ scopeList: Responce.scopes })
          break;
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleSearch = (value: any) => {
    this.setState({
      initialValues: value
    }, () => {
      this.getIndustry(this.state.initialValues);
    })
  }

  async getIndustry(value?: initialValues) {

    this.setState({ isLoading: true });
    
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData("authToken")
    };

    let httpBody = {
      "page_no": this.state.page,
      "items_per_page": this.state.perPage,
      "industry_id": value?.industry,
      "country_id": value?.country,
      "scope_id": value?.scope,
      "reporting_year": value?.year
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.industryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.IndustryListDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getIndustryList() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.industryApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.IndustryDataUrl
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  async getCountryList() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CountryDataUrl
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getscopeList() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.scopeApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ScopeDataUrl
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleTablePageChange = (e: any, page: number) => {
    this.setState({ page }, () => {
      this.getIndustry(this.state.initialValues)
    })
  }

  handleTableNumberChange = (e: any,) => {
    this.setState({ perPage: e.target.value, page: 1 }, () => {
      this.getIndustry(this.state.initialValues)
    })
  }

  handleNavigateToEmissionDetailsPage = (emissionDetailsId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Emissions");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    // Needed if we want to pass state
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      emissionDetailsId,
    });

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    // Needed if we want to pass state
    this.send(msg)
  }

  logoutUser = () => {
    localStorage.clear();
    window.open("/login/primary", "_self");
  }

  saveTokenAlert = (message: string) => {
    const alert = {
      message,
      isOpen: true,
    }

    setStorageData("tokenAlert", JSON.stringify(alert));
  }

  // Customizable Area End
}
