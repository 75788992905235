import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";

const useStyles = makeStyles(() => ({
  heading: {
    "&, & > p": {
      marginTop: "12px",
      fontSize: "48px",
      fontWeight: 700,
      fontFamily: "'Montserrat' !important",
      lineHeight: "64px",
      textTransform: "capitalize",
      "@media (max-width: 768px)": {
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: "8px",
      },
    },
  },
}));

export default function WidgetHeadingText({
  text,
}: {
  text: string;
}) {
  const styles = useStyles();
  return <h2 className={styles.heading}>{parse(text)}</h2>;
}
