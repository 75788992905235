import React from "react";
import { Box, Avatar, TextField, Typography } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { styled, makeStyles } from "@material-ui/core/styles";
import { getTimeAgo } from "../../../dashboard/src/pages/EsgFactorsDetails.web";

interface Activity {
  image?: string;
  activity_date_time: string;
  activity_by?: null;
  activity: string;
}

interface Props {
  comment: string;
  activities: Activity[];
  onComment: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export default function Activity(props: Props) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("userDetails") as string);
  let avatarContent = null;
  if (user?.meta?.photo) {
    avatarContent = <Avatar32 src={user.meta.photo} alt="" />
  } else {
    avatarContent = <Avatar32>{user?.meta?.first_name.at(0)} </Avatar32>
  }

  let pastComments = null;
  if (props.activities && props.activities.length > 0) {
    pastComments = [...props.activities].reverse().map((activity) => {
      const timeAgo = getTimeAgo(activity.activity_date_time);
      return (
        <ProfileCard>
          <ProfileInfoBox>
            <AvatarWr>
              {activity.image ?
                <Avatar24
                  src={activity.image}
                  alt=""
                />
                :
                <Avatar24>{activity?.activity_by?.['0']}</Avatar24>
              }
            </AvatarWr>
            <ActivityInfoRight>
              <ActivityInfo>
                <ProfileName>{activity.activity_by}</ProfileName>
                <InlineTextIcon>
                  <ScheduleIcon />
                  {timeAgo}
                </InlineTextIcon>
              </ActivityInfo>
              <Text>{activity.activity}</Text>
            </ActivityInfoRight>
          </ProfileInfoBox>
        </ProfileCard>
      ); 
    })
  }

  return (
    <ActiviySection>
      <ActiviyHeading>Activity</ActiviyHeading>
      <CommentSection>
        <CommentBox>
          <AvatarWr>
            {avatarContent}
          </AvatarWr>
          <TextInput
            data-test-id="activity_field"
            name="activity"
            multiline
            value={props.comment}
            placeholder="Add a comment..."
            variant="outlined"
            type="textarea"
            FormHelperTextProps={{
              className: classes.helperText
            }}
            helperText={"Max length 250"}
            InputProps={{
              classes: {
                input: classes.textStyle,
              },
              style: { color: "#34373A", borderRadius: "8px !important;" },
            }}
            className={classes.textareaFieldStyle}
            onChange={props.onComment}
          />
        </CommentBox>
      </CommentSection>
      {pastComments}
    </ActiviySection>
  )
}

const useStyles = makeStyles((theme) => ({
  helperText: {
    margin: "2px 0 0 0",
    textAlign: "right",
  },
  textStyle: {
    lineHeight: "24px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "15px",
    borderRadius: "8px",
    color: "#34373A",
    boxSizing: "border-box",
    height: "48px",
    padding: "0 15px",
  },
  textareaFieldStyle: {
    padding: 0,
    '& *': {
      borderRadius: "8px !important",
      borderColor: "#D9D9D9 !important",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px",
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px",
    },
    width: "100%",
  },
}));

const ActiviySection = styled("div")({
  marginBotton: "24px"
});

const ActiviyHeading = styled("div")({
  color: "#0F172A",
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "160%",
  letterSpacing: "0.2px",
  margin: "60px 0 18px 0"
});

const CommentSection = styled("div")({
  marginBottom: "24px"
});

const CommentBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between"
});

const AvatarWr = styled(Box)({
  width: "48px",
  display: "flex"
});

const Avatar32 = styled(Avatar)({
  width: "32px",
  height: "32px",
  textTransform: 'uppercase',
  fontSize: "15px",
});

const TextInput = styled(TextField)({
  width: '100%',
  borderRadius: "8px",
  outline: "1 important",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: 1,
});

const ProfileCard = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
});

const ProfileInfoBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignactivitys: "flex-start",
  marginBottom: "10px",
});

const Avatar24 = styled(Avatar32)({
  width: "24px",
  height: "24px",
});

const ActivityInfo = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "16px",
  alignactivitys: "center",
  marginBottom: "8px"
});

const ActivityInfoRight = styled(Box)({
  display: "block",
});

const ProfileName = styled("span")({
  fontSize: "14px",
  fontWeight: 700,
  color: "#34373A",
  textTransform: 'capitalize',
});

const Text = styled(Typography)({
  color: "#7D7D7F",
  textAlign: "left",
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "19.2px"
});

const InlineTextIcon = styled(Text)({
  display: 'flex',
  alignactivitys: "center",
  justifyContent: "center",
  gap: "8px",
});