import React, {FC, memo} from 'react'
import {
  makeStyles,
} from "@material-ui/core/styles";
import ArrowOutwardIcon from '@material-ui/icons/ArrowForward';
import Typography from "@material-ui/core/Typography";
import {Box, Button, Container} from "@material-ui/core";
import parse from 'html-react-parser';

const useStyles = makeStyles({
  headingCard: (props: any) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: "70px 15px 40px 15px",
    minHeight: '333px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: props.bgColor ?? "#ECFBF1",
    borderRadius: "16px",
    boxSizing: 'border-box',
    marginBottom: '39px',
    '@media(max-width: 600px)': {
      minHeight: "10px",
      paddingBottom:"20px",
      paddingTop: "30px"
    },

  }),
  maintag: (props: any) => ({
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    lineHeight: '24px',
    color: props.tagColor ?? '#43D270',
    marginBottom: '29px',
    textAlign: "center",
  }),
  heading: {
    fontSize: '48px',
    fontWeight: 700,
    fontFamily: 'Montserrat !important',
    lineHeight: '56px',
    textAlign: "center",
    marginBottom: '24px',
    maxWidth: "1100px",
    '@media(max-width: 600px)': {
      fontSize: '28px',
      lineHeight: '30px',
      marginBottom: "20px",
    },
    '& p ': {
      margin: "0"
    }
  },
  description: {
    display: 'flex',
    fontWeight: 600,
    fontSize: '17px',
    fontFamily: 'Montserrat',
    lineHeight: '26px',
    alignItems: "center",
    marginBottom: "30px",
    textAlign: "center",
    maxWidth: "700px",
  },
  buttonIconStyle: {
    marginLeft: '8px',
    transform: "rotate(-45deg)"
  },
  buttonStyle: (props: any) => ({
    padding: "16px 12px",
    width: "180px",
    background: props.primaryButtonBg ?? '#43D270',
    color: props.primaryButtonColor ?? '#101010',
    borderRadius: "8px",
    margin: "0 15px 15px 15px",
    boxShadow: "none !important",
    alignItems: "center",
    '@media(max-width: 600px)': {
      width: "160px",
      margin: "0 10px 10px 10px",
      padding: "14px 10px",
    },
    '@media(max-width: 400px)': {
      margin: "0 5px 5px 5px",
      width: "140px",
      padding: "14px",
      fontSize: "11px",
    },
  }),
  buttonViewPricing: (props: any) => ({
    padding: "16px 12px",
    margin: "0 15px 15px 15px",
    width: "180px",
    borderRadius: "8px",
    background: props.secondaryButtonBg,
    color: "#101010",
    boxShadow: "none !important",
    '@media(max-width: 600px)': {
      margin: "0 10px 10px 10px",
      padding: "14px 10px",
      width: "160px",
    },
    '@media(max-width: 400px)': {
      margin: "0 5px 5px 5px",
      padding: "14px",
      fontSize: "11px",
      width: "140px",
    },
  }),
  buttonLinkStyle: {
    textDecoration: "unset",
  },
  buttonWrStyle: {
    marginBottom: "15px",
    '@media(max-width: 600px)': {
      display: 'flex',
    },
  }
})

interface PropTypes {
  tag?: string;
  heading?: string;
  description?: string;
  button1Label?: string;
  button1Link?: string;
  button2Label?: string;
  button2Link?: string;
  bgColor?: string;
  tagColor?: string;
  primaryButtonBg?: string;
  secondaryButtonBg?: string;
  primaryButtonColor?: string;
}

const BlogPageHeadingSection: FC<PropTypes> = ({
                                                 tag,
                                                 heading,
                                                 bgColor,
                                                 tagColor,
                                                 primaryButtonBg,
                                                 secondaryButtonBg,
                                                 primaryButtonColor,
                                                 description,
                                                 button1Label,
                                                 button1Link,
                                                 button2Label,
                                                 button2Link
                                               }: PropTypes) => {

  const classes = useStyles({bgColor, tagColor, primaryButtonBg, secondaryButtonBg, primaryButtonColor});
  return (
    <Container>
      <Box className={classes.headingCard}>

        {tag &&
            <Typography component="p" className={classes.maintag}>{tag && parse(tag.toString())}</Typography>
        }
        {heading && heading != "" &&
            <Typography component='h3' style={{marginBottom: !button1Label ? "40px" : "20px"}}
                        className={classes.heading}>{heading}</Typography>
        }
        {description && description !== "" &&
            <Typography className={classes.description}>{description && parse(description.toString())}</Typography>
        }

        {button1Label &&
            <Box className={classes.buttonWrStyle}>
              {button1Label &&
                  <div className={classes.buttonLinkStyle}>
                      <Button variant="contained" className={classes.buttonStyle}>{button1Label}
                          <ArrowOutwardIcon className={classes.buttonIconStyle}/>
                      </Button>
                  </div>
              }

              {button2Label &&
                  <div className={classes.buttonLinkStyle}>
                      <Button variant="contained" className={classes.buttonViewPricing}>{button2Label}
                          <ArrowOutwardIcon className={classes.buttonIconStyle}/>
                      </Button>
                  </div>
              }
            </Box>
        }
      </Box>
    </Container>
  )
}

export default memo(BlogPageHeadingSection);