import React, {ReactElement} from 'react';
import Grid from '@material-ui/core/Grid';
import {Typography, Box} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import parse from "html-react-parser";

const useStyles = makeStyles({
  genericCard: {
    background: "#FFFFFF",
    borderRadius: "16px",
    width: "100%",
    '& :hover': {
      transform: "scale(1)",
    },
    cursor: "pointer",
  },

  imageCard: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    overflow: 'hidden',
    height: "399px",
    width: "392px",
    maxWidth: "100%",
    borderRadius: "14px",
    background: "#FFFFFF",
    margin: "0 auto 30px auto",
  },

  images: {
    height: "100%",
    maxWidth: "100%",
    transition: "all ease 0.5s",
    objectFit: "cover",
    '&:hover': {
      transform: "scale(1.1)",
    },
  },
  images1: {
    transition: "all ease 0.5s",
    objectFit: "cover",
    maxWidth: "100%",
    '&:hover': {
      transform: "scale(1.1)",
    },
  },

  relatedGcContent: {
    padding: "0 10px",
  },
  relatedMpContent:{
height:'257px'
  },
  noDecoration: {
    textDecoration: "none !important",
    cursor: 'pointer',
  },

  relatedTitle: {
    color: "#fff",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 600,
    width: "fit-content",
    fontSize: "14px",
    padding: "6px 16px 6px 16px",
    marginBottom: "15px",
    borderRadius: "4px",
  },

  relatedText: {
    height: "70px",
    color: "#000000",
    fontFamily: 'Lato',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "22px",
    width: "100%",
    display: "-webkit-box",
    lineHeight: "35.2px",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
  },

  authorName: {
    color: "#34373A",
    fontFamily: 'Lato',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    marginBottom: "14px",
    flex: 1,
  },

  relatedCardButtonWr: {
    textAlign: "right",
  },

  cardButton: {
    minHeight: "48px",
    background: "#43D270",
    borderRadius: "8px",
    boxShadow: "none",
    display: "inline-flex",
    minWidth: "131px",
    letterSpacing: "0.4px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none !important",
    color: "#000",
    textTransform: "uppercase",
    fontFamily: 'Montserrat',
    fontWeight: 500,
  },

  cardMetaDataStyle1: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    marginBottom: "7px",
    '@media only screen and (min-device-width: 414px) and (max-device-width: 852px)':{
      justifyContent:'flex-start'
  }
    
  },

  ctaButton: {
    color: "#000",
    backgroundColor: "#43d270",
    borderRadius: "8px",
    padding: "12px 24px",
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "15px",
    border: "none"
  },

  cardMetaDataStyle2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "7px"
  },


  date: {
    color: "#34373A",
    fontFamily: 'Lato',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    textAlign: "right",
    flex: 1,
  },

  tagPaidButton: {
    minHeight: "32px",
    background: "#E0E0FC",
    borderRadius: "32px",
    boxShadow: "none",
    display: "inline-flex",
    fontWeight: 600,
    minWidth: "72px",
    fontFamily: "Montserrat",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    textDecoration: "none !important",
    color: "#7166F1",
    fontSize: '14px',
  },

  tagButton: {
    minHeight: "32px",
    background: "#F3F3F3",
    textTransform: "capitalize",
    borderRadius: "32px",
    boxShadow: "none",
    display: "inline-flex",
    minWidth: "72px",
    color: "#727272",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "none !important",
    fontSize: '14px',
  },
  description: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "25.6px",
    color: "#4F4F4F",
    marginTop: "10px",
    height: "55px",
  },
})

const dummyColor: any = {
  Environmental: "#43D270",
  Social: "#7166F1",
  Governance: "#F96C30"
}

interface PropTypes {
  enableButton?: boolean,
  enablePaymentTags?: boolean,
  limit?: number,
  data?: any,
  viewDetails?: any,
  enableMetaTags?: any
}

const RelatedEventBlogListingSection = ({data, viewDetails}: PropTypes) => {
  const styles = useStyles();
  const url = `/${window?.location?.pathname?.toLowerCase()?.replace(/[0-9/]+/g, '')}`
  return (
    <Grid container style={{marginBottom: url === '/events' ? "137px" : "77px"}} spacing={4}>
      {Array.isArray(data) && data.map((item: any, index: any): ReactElement => {
        const fullRelatedDescription = item?.attributes?.marketplace_description;
        const slicedRelatedDescription = `${fullRelatedDescription.slice(0, 74)}...`;

        return <Grid item xs={12} sm={4} container key={`s_item_${index}`}>
          <Box
            className={styles.genericCard}
            data-test-id='genericCard'
            onClick={() => {
              viewDetails(item.id, url, true);
              window.scrollTo(0, 0)
            }}>
            <Box
              className={styles.imageCard}
            >
              {
                item?.attributes?.marketplace_image &&
                  <img
                      src={item.attributes.marketplace_image}
                      className={`${url === '/marketplace' ? styles.images1 : styles.images}`}
                      alt=""
                  />

              }
            </Box>
            <Box className={styles.relatedGcContent}>
              <Box className={styles.relatedMpContent}>
              <Typography
                variant='h3'
                className={styles.relatedTitle}
                style={{
                  visibility: item?.attributes?.resource_tag_id ? "visible" : "hidden",
                  backgroundColor: dummyColor[item?.attributes?.resource_tag_id] || "#F96C30"
                }}
              >
                {item?.attributes?.resource_tag_id}
              </Typography>
              <Typography variant='h4'
                          className={styles.relatedText}>{item?.attributes?.marketplace_title}</Typography>
              <Typography className={styles.description}>{parse(slicedRelatedDescription)}</Typography>
              </Box>
              <div className={styles.cardMetaDataStyle1}>
                <button onClick={() => window.open(item?.attributes.marketplace_link)}
                        className={styles.ctaButton}>Contact
                </button>
              </div>
            </Box>
          </Box>
        </Grid>
      })}
    </Grid>
  );
}
export default RelatedEventBlogListingSection;