import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import parse from "html-react-parser";
import WidgetHeadingText from "./WidgetHeadingText";
import Label from "./Label";

const useStyles = makeStyles(() => ({
  mainBlock: {
    padding: "80px 50px",
    "@media (max-width: 768px)": {
      padding: "40px 20px",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse",
    },
  },
  leftBlock: {
    width: "calc(100% * 7 / 12)",
    paddingRight: "65px",
    "@media (max-width: 768px)": {
      width: "100%",
      paddingRight: 0,
    },
  },
  rightBlock: {
    width: "calc(100% * 5 / 12)",
    "@media (max-width: 768px)": {
      width: "100%",
      marginBottom: "40px",
    },
  },
  description: {
    marginTop: "20px",
  },
  button: {
    marginTop: "40px",
    border: "1px solid black",
    display: "inline-flex",
    fontFamily: "Lato",
    padding: "12px 32px",
    borderRadius: "4px",
    fontWeight: 600,
    textTransform: "capitalize",
    transition: "all ease 0.3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#43d270",
      color: "#fff",
      border: "none",
    },
  },
  image: {
    width: "100%",
    height: "400px",
    borderRadius: "20px",
    "@media (max-width: 768px)": {
      height: "auto",
    },
  },
}));

export default function Carbonzeroed(props: any) {
  const {
    main_header,
    header,
    description,
    image,
    widget_item_buttons: { data },
    navigate
  } = props;
  const styles = useStyles();

  return (
    <div className={styles.mainBlock}>
      <Container className={styles.container}>
        <div className={styles.leftBlock}>
          <Label text={main_header} />
          <WidgetHeadingText text={header} />
          <div className={styles.description}>{parse(description)}</div>
          <div onClick={() => navigate(data[0].attributes.link)} className={styles.button}>
            {data[0].attributes.name}
          </div>
        </div>

        <div className={styles.rightBlock}>
          <img
            src={image}
            alt="greem"
            className={`animated-image ${styles.image}`}
          />
        </div>
      </Container>
    </div>
  );
}
