import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { 
  tableLabels,
  ScopeTableContainer,
  ScopeTable,
  ScopeTableBody,
  ScopeSpacing,
  ScopeTableCellHead,
  ScopeTableHead,
  ScopeTableRowHead,
  EmissionRows
} from "../../cfnzdpuscope2emissions/src/Cfnzdpuscope2emissions.web";
import TitleLessRows from "../../cfnzdpuscope3emissions/src/components/TitleLessRows";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import CfnzdpuassuranceandverificationController, {
  Props,
  configJSON,
} from "./CfnzdpuassuranceandverificationController";

export default class Cfnzdpuassuranceandverification extends CfnzdpuassuranceandverificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.props.value === this.props.index &&
          <ScopeTableContainer>
            <ScopeTable aria-label="users table">
              <ScopeTableHead>
                <ScopeTableRowHead>
                  <ScopeTableCellHead style={{ width: "40%" }} align="left">{tableLabels.title}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.value}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.source}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.update}</ScopeTableCellHead>
                  <ScopeTableCellHead style={{ width: "15%" }} align="left">{tableLabels.restatement}</ScopeTableCellHead>
                </ScopeTableRowHead>
              </ScopeTableHead>
              <ScopeSpacing />
              <ScopeTableBody>
                <EmissionRows leftAlignTableCells rowItems={this.state.noHeadingData} />
                <TitleLessRows
                  heading="Assurance/Verification 1"
                  rowItems={this.state.assurance1}
                  isOpen={this.state.openAssurance1}
                  onToggle={this.handleToggleAssurance1}
                />
              </ScopeTableBody>
            </ScopeTable>
          </ScopeTableContainer>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  allnames: {
    color: '#7D7D7F',
    fontSize: "12px",
    fontWeight: 400,
  },
  allvalue: {
    fontWeight: 700,
  }
}
// Customizable Area End
