import React from "react";
// Customizable Area Start
import {
  Box, Typography, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Modal,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { location, wishlist, more } from "./assets"
import parse from 'html-react-parser';
import KebabOptions from "../../dashboard/src/common/KebabOptions.web";
import moment from 'moment'
import AddAppointment from "./AddAppointment.web";
import TablePagination from "../../dashboard/src/common/TablePagination.web";
import AppointmentDetailsDialog from "./components/AppointmentDetailsDialog";
import AccessRestrictedDialog from "./components/AccessRestrictedDialog";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const MoreOptionIcon = styled("img")({
  width: "20px",
  height: "20px"
});

const CustomButton = styled('button')({
  borderRadius: "8px",
  backgroundColor: "#43d270",
  width: '100%',
  height: "40px",
  display: "flex",
  padding: "8px 8px 8px 12px",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: "#34373A",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: " 19.2px",
  border: "none",
  cursor: "pointer",
  "&:disabled": {
    backgroundColor: "#B1B3C3",
    color: "#101010"
  }
});

const MoreIcon = styled(Box)({});

const tableLabels = {
  date: 'Date',
  title: "Appointment Title",
  description: "Description",
  status: 'Status',
  action: "Action",
  option: <MoreOptionIcon src={more} />,
};

const AppointmentTableContainer = styled(TableContainer)({
  minHeight: "420px",
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  marginTop: '26px',
  padding: '12px',
  paddingRight: '50px'
});

const AppointmentTable = styled(Table)({
  width: "100%",
  borderCollapse: "separate",
});

const AppointmentTableBody = styled(TableBody)({
  color: "inherit",
});

const AppointmentSpacing = styled("div")({
  height: 16,
  display: "table-header-group",
});

const AppointmentTableCellHead = styled(TableCell)({
  minHeight: "48px",
  padding: "8px",
  flexShrink: 0,
  borderBottom: "none",
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
  textAlign: "left"
  // maxWidth: '260px'
});

const AppointmentTableCellHeadTitle = styled(TableCell)({
  minHeight: "48px",
  padding: "8px 18px",
  flexShrink: 0,
  borderBottom: "none",
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

const AppointmentTableCell = styled(TableCell)({
  borderBottom: "1px solid #F1F5F9",
  padding: "8px",
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "19.2px",
  textAlign: "left",
});

const AppointmentTableCellTitle = styled(TableCell)({
  borderBottom: "1px solid #F1F5F9",
  padding: "8px 8px 8px 18px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "21px",
  letterSpacing: "0.2px",
});

const AppointmentTableHead = styled(TableHead)({
  backgroundColor: "#F5F5F5",
  borderRadius: "10px",
});

const AppointmentTableRowHead = styled(TableRow)({
  overflow: "hidden",
  "& th:first-child": {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  "& th:last-child": {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
});

const PaginationContainer = styled(Box)({
  paddingRight: 50,
  '@media(max-width: 700px)': {
    paddingRight: 0
  }
});


// Customizable Area End

import AppointmentmanagementController, {
  Props,
  configJSON,
} from "./AppointmentmanagementController";

export default class Appointments extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAppointments()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { userData, appointmentsList, alert } = this.state;
    const selectedAppointment = appointmentsList.find(
      (appointement: any) => appointement.id === this.state.meetid,
    );
    
    return (
      <ThemeProvider theme={theme}>
        <Modal open={this.state.isAddAppointment} style={webStyle.modal} onClose={this.handleClose}>
          <AddAppointment navigation={this.props.navigation} id={""} onClose={this.handleClose} getAppontment={this.getAppointments} />
        </Modal>
        <Modal open={this.state.isCancled} style={webStyle.modal} onClose={this.handleCloseCancle}>
          <Box style={webStyle.SuccessmodalBox} >
            <img src={alert.image} style={webStyle.Logoimage} alt="" />
            <Typography style={webStyle.modelTitle} >{alert.status}</Typography>
            <Typography style={webStyle.boxContent} >{alert.message}</Typography>
            <Typography style={webStyle.bottommsg}>{alert.bottomMessage} <span style={webStyle.clicklink} onClick={this.handleCloseCancle} data-test-id="navigate" >here</span></Typography>
          </Box>
        </Modal>
        <Box>
          <Box style={webStyle.shedualContainer as any}>
            <Box style={webStyle.profileContainer}>
              <img src={userData?.photo} width={96} height={96} alt="" style={{ borderRadius: '12px' }} />
              <Box style={{ marginLeft: '22px' }}>
                <Typography style={webStyle.nameText}>{`${userData.first_name ?? ""} ${userData.last_name ?? ""}`}</Typography>
                <Typography style={webStyle.LocationText}>
                  <img src={location} style={{ marginRight: "7px" }} />
                  {userData.location}</Typography>
              </Box>
            </Box>
            <Typography style={webStyle.Content}>
              {parse(userData.description ?? "")}
            </Typography>
            {
              userData.account_type !== "portfolio_manager" ?
                <CustomButton disabled={this.state.disableScheduleCall} onClick={this.handlOpen} data-test-id="open_form" style={{ maxWidth: "141px" }}>Schedule Call</CustomButton> : <div />
            }
          </Box>
          <Divider style={{ color: "#EBEBEB" }} />
          {
            appointmentsList.length === 0 ?
              <Box style={webStyle.listContainer as any}>
                <img src={wishlist} alt="list" />
                <Typography style={webStyle.novaluetext as any}>
                  Schedule your first call to start populating this space<br />
                  with call details, recordings and transcripts<br />
                  and other information to track your progress.
                </Typography>
              </Box> :
              <AppointmentTableContainer>
                <AppointmentTable aria-label="users table">
                  <AppointmentTableHead>
                    <AppointmentTableRowHead>
                      <AppointmentTableCellHead>{tableLabels.date}</AppointmentTableCellHead>
                      <AppointmentTableCellHeadTitle>
                        {tableLabels.title}
                      </AppointmentTableCellHeadTitle>
                      <AppointmentTableCellHead>{tableLabels.description}</AppointmentTableCellHead>
                      <AppointmentTableCellHead>{tableLabels.status}</AppointmentTableCellHead>
                      <AppointmentTableCellHead>{tableLabels.action}</AppointmentTableCellHead>
                      {userData.account_type !== "portfolio_manager" &&
                        <AppointmentTableCellHead
                        style={{textAlign: "center"}}
                      >{tableLabels.option}</AppointmentTableCellHead>
                      }
                    </AppointmentTableRowHead>
                  </AppointmentTableHead>
                  <AppointmentSpacing />
                  <AppointmentTableBody>
                    {appointmentsList && appointmentsList.map((user: any) => {
                      return (
                        <TableRow>
                          <AppointmentTableCell style={webStyle.dateText} >
                            {moment(user.meeting_date).format("DD-MM-YYYY")}
                          </AppointmentTableCell>
                          <AppointmentTableCellTitle 
                            style={{ minWidth: '100px' }}
                          >
                            {user.title}
                          </AppointmentTableCellTitle>
                          <AppointmentTableCell 
                            style={{ minWidth: '200px', maxWidth: '400px' }}
                          >
                            {user.description}
                          </AppointmentTableCell>
                          <AppointmentTableCell style={{
                            color: configJSON.Colors[user.status],
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "21px",
                            letterSpacing: "0.2px",
                          }}>
                            {user.status}
                          </AppointmentTableCell>
                          <AppointmentTableCell style={{ minWidth: '100px' }}>
                            {
                              user.join_link && this.checkDateandTime(user.meeting_date, user.start_time) ?
                                <a href={user.join_link} target="_blank" style={{ textDecoration: 'none' }}>
                                  <CustomButton style={{ maxWidth: '97px', height: '32px' }}>Join Call</CustomButton>
                                </a> :
                                <CustomButton style={{ maxWidth: '97px', height: '32px', backgroundColor: '#B1B3C3' }}>Join Call</CustomButton>
                            }
                          </AppointmentTableCell>
                          {userData.account_type !== "portfolio_manager" &&
                            <AppointmentTableCell style={{textAlign: "center"}}>
                              {
                                user.status !== "Cancelled" &&
                                <Box sx={{ position: "relative" }}>
                                  <MoreIcon>
                                    <KebabOptions
                                      data-test-id={`kebab_options_test_${user.id}`}
                                      verticalDotIcon={more}
                                      onToggleOption={(event: any) =>
                                        this.toggleMoreOptions(event, user.meeting_key, user.id, user.status)
                                      }
                                      open={this.state.showMoreOptions}
                                      anchorEl={this.state.anchorEl}
                                      onSelect={this.handleKababOptions}
                                      options={this.state.appointementMoreOptions}
                                    />
                                  </MoreIcon>
                                </Box>
                              }
                            </AppointmentTableCell>
                          }
                        </TableRow>
                      );
                    })}
                  </AppointmentTableBody>
                </AppointmentTable>
              </AppointmentTableContainer>
          }
        </Box>
        {
          appointmentsList.length !== 0 &&
          <PaginationContainer>
            <TablePagination count={this.state.totalUser}
              onChange={this.handleTablePageChange}
              handleChangeNumber={this.handleTableNumberChange}
              perPage={this.state.perPage} />
          </PaginationContainer>
        }
        <AppointmentDetailsDialog
          open={this.state.openAppointmentDetailsDialog}
          onClose={() => this.handleOpenAppointmentDetailsDialog(false)}
          title={selectedAppointment?.title}
          location={selectedAppointment?.start_link}
          description={selectedAppointment?.description}
          host={selectedAppointment?.service_provider_full_name}
          meetingType={selectedAppointment?.meeting_type}
          meetingDate={selectedAppointment?.meeting_date}
          meetingStartTime={selectedAppointment?.start_time}
          meetingEndTime={selectedAppointment?.end_time}
          meetingJoinLink={selectedAppointment?.join_link}
          meetingId={selectedAppointment?.meeting_key}
          meetingPassCode={selectedAppointment?.meeting_passcode}
          meetingStatus={selectedAppointment?.status}
        />
        <AccessRestrictedDialog 
          open={this.state.openAccessRestrictedDialog}
          onCtaClick={this.navigateToGetStartedPage}
          onClose={() => this.handleOpenAccessRestrictedDialog(false)}
        />
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  appointmentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
  },
  shedualContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 50px 26px 38px',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px'
  },
  Content: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: " 19.2px",
    maxWidth: "571px",
    // height: "41px"
  },
  profileContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  nameText: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: " 22.4px",
    letterSpacing: "0.2px"
  },
  LocationText: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: " 19.2px",
    display: "flex",
    alignItems: 'center',
    marginTop: '7px'
  },
  listContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '55px'
  },
  novaluetext: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: " 28px",
    marginTop: '50px',
    textAlign: "center",
    maxWidth: '1003px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateText: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: " 21px",
    minWidth: 100,
  },
  statusText: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px"
  },
  SuccessmodalBox: {
    display: "flex",
    width: '450px',
    height: '400px',
    padding: "40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    gap: '32px',
    borderRadius: "16px",
    background: 'var(--additional-white, #FFF)',
    position: 'absolute',
    top: "20%",
  } as React.CSSProperties,
  Logoimage: {
    width: "145px",
    height: '129.639px'
  } as React.CSSProperties,
  modelTitle: {
    alignSelf: "stretch",
    color: "var(--greyscale-900, #0F172A)",
    textAlign: "center",
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '125%',
    letterSpacing: '0.2px'
  } as React.CSSProperties,
  boxContent: {
    alignSelf: "stretch",
    color: '#34373A',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.085px'
  } as React.CSSProperties,
  bottommsg: {
    color: 'var(--greyscale-900, #0F172A)',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
  } as React.CSSProperties,
  clicklink: {
    color: '#43D270',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '160%',
    cursor: 'pointer'
  },
};
// Customizable Area End
