import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import parse from "html-react-parser";
import WidgetHeadingText from "../WidgetHeadingText";

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontSize: "2rem",
      fontWeight: 700,
      marginBottom: "1rem",
    },

    section: {
      padding: "80px 50px",
      backgroundColor: "transparent",
      overflowX: "hidden",
      width: "100%",
      "@media(max-width: 768px)": {
        padding: "40px 20px",
      },
    },

    heading5: {
      "& > p": {
        fontSize: "48px",
        fontWeight: 700,
        color: "#191919",
        lineHeight: "60px",
        textTransform: "capitalize",
        textAlign: "center",
        "@media (max-width: 768px)": {
          fontSize: "18px",
          lineHeight: "26px",
        },
      },
    },

    specialists: {
      display: "flex",
      marginTop: "40px",
      width: "2000px",
      animation: "scroll 20s linear infinite",
    },

    specialist: {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
    },
  })
);

type SpecialistProps = {
  header: string;
  specialist_logo_widget_items: {
    data: SpecialistLogoItem[];
  };
};

type SpecialistLogoItem = {
  id: string;
  attributes: {
    [key: string]: string;
  };
};

export default function Specialists(props: SpecialistProps) {
  const styles = useStyles();
  const { header, specialist_logo_widget_items } = props;
  return (
    <Container className={styles.section}>
      <h5 className={styles.heading5}>{parse(header)}</h5>
      <div className={styles.specialists}>
        <div className={styles.specialist}>
          {specialist_logo_widget_items.data.map((item) => (
            <Grid xs={12} md={4} lg={2} item key={item.id}>
              <img src={item.attributes.specialist_image} alt="specialist" />
            </Grid>
          ))}
        </div>

        <div className={styles.specialist}>
          {specialist_logo_widget_items.data.map((item) => (
            <Grid xs={12} md={4} lg={2} item key={item.id}>
              <img src={item.attributes.specialist_image} alt="specialist" />
            </Grid>
          ))}
        </div>
      </div>
    </Container>
  );
}
