import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Box, Typography, Modal, Button } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: (props: any) => ({
        backgroundImage: `url(${props.background_image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: 0,
        position: "relative",
        backgroundSize: "cover",
        overflow: 'hidden',
        fontFamily: "Montserrat",
    }),
    imgOverlay: {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: 'rgb(0 0 0 / 50%)',

    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        color: "#fff",
        fontFamily: "Montserrat",
        [theme.breakpoints.up('md')]: {
            padding: '80px 0px 48px 0px',
            paddingRight: 0,
        },
    },
    sliderText: {
        fontSize: "48px",
        fontWeight: 700,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        lineHeight: '56px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "28px",
            lineHeight: "36px"
        },
    },
    linkText: {
        color: "#fff",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        lineHeight: "1",
        textDecoration: "none",
    },
    btn: {
        borderRadius: "8px",
        padding: "16px 24px",
        fontWeight: 500,
        textTransform: "uppercase",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        color: "#000",
        minWidth: "300px",
        boxSizing: 'border-box',
        justifyContent: "center",
        marginBottom: "20px",
        '&:hover': {
            background: "transparent",
            color: "#43D270",
            border: "2px solid #43D270"
        },
    },
    btnGreen: {
        background: "#43D270",
        border: "2px solid #43D270",
    },
    btnGray: {
        background: "#fff",
        border: "2px solid #fff",
        marginLeft: "16px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0",
        },
    },
    actionButtonsBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    videoLinkBox: {
        paddingTop: "7px",
    },
    flex: {
        display: "flex",
        justifyContent: "center"
    },
    dashboard: {
        display: "block",
        margin: "auto",
        maxWidth: "750px",
        borderRadius: "15px 15px 0px 0px",
        position: "relative",
        zIndex: 99,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            borderRadius: 0
        },
    },
    btnSpacing: {
        marginLeft: "12px"
    },
    btnSpacingRight: {
        marginRight: "12px",
        cursor: "pointer"
    },
    heroBoxStyle: {
        maxWidth: "855px",
        width: "100%",
        margin: "0px auto 20px auto",
    },
    heroDescStyle: {
        opacity: "0.72",
        fontFamily: 'Lato',
        maxWidth: "624px",
        margin: "0 auto",
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
            lineHeight: "22px"
        },
    },
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperStyle: {
        width: '700px',
        maxWidth: "100%",
        height: '60%',
        padding: '10px',
        borderRadius: '8px',
        '@media (max-width:900px)': {
            width: "90%",
        }
    },
    invalidUrl: {
        width: "100%",
        height: "100%",
        fontSize: "22px",
        opacity: 0.5,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        alignItems: "center",
        margin: "0"
    },

}));

export const getYoutubeVideoId = (url: any) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

function SliderBox(props: any) {
    const [open, setOpen] = useState(false)

    const { header, description, background_image, image, widget_item_buttons }: {
        header: "string", description: "string", background_image: any, image: "string", widget_item_buttons: any,
    } = props;
    const classes = useStyles({ ...background_image });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${background_image})` }}>
            <div className={classes.imgOverlay}></div>
            <Grid container justifyContent='center'>
                <Grid item md={12}>
                    <div className={classes.mainFeaturedPostContent}>
                        <Box className={classes.heroBoxStyle}>
                            <Typography component="h1" variant="h1" color="inherit" align='center' gutterBottom className={classes.sliderText}>
                                {header && parse(header.toString())}
                            </Typography>
                            <Typography className={classes.heroDescStyle} variant="h6" color="inherit" paragraph align='center'>
                                {description && parse(description.toString())}
                            </Typography>
                        </Box>

                        {widget_item_buttons && widget_item_buttons?.data &&
                            <Box className={classes.actionButtonsBox}  >
                                {widget_item_buttons?.data['0'] &&
                                    <Link to={widget_item_buttons?.data['0']?.attributes?.link ? widget_item_buttons?.data['0']?.attributes?.link : "/free-assessment"} className={clsx(classes.btnGreen, classes.btn)}>
                                        {widget_item_buttons?.data['0']?.attributes?.name}
                                        {widget_item_buttons?.data['0']?.attributes?.icon &&
                                            <img className={classes.btnSpacing} src={widget_item_buttons?.data['0']?.attributes?.icon} alt="" />
                                        }
                                    </Link>
                                }
                                {widget_item_buttons?.data['1'] &&
                                    <Button 
                                        data-test-id="second-cta-btn"
                                        className={clsx(classes.btnGray, classes.btn)}
                                        onClick={() => window.open(widget_item_buttons.data['1'].attributes.link, "_self")}
                                    >
                                        {widget_item_buttons?.data['1']?.attributes?.name}
                                        {widget_item_buttons?.data['1']?.attributes?.icon &&
                                            <img className={classes.btnSpacing} src={widget_item_buttons?.data['1']?.attributes?.icon} alt="" />
                                        }
                                    </Button>
                                }
                            </Box>
                        }
                        <Box display="flex" alignItems="center" justifyContent="center" className={classes.videoLinkBox}>
                            <Modal open={open} onClose={handleClose} className={classes.modalStyle} data-test-id="modalClose">
                                <Paper className={classes.paperStyle}>
                                    {widget_item_buttons?.data[1]?.attributes?.link ?
                                        <iframe
                                            style={{ border: "none", background: "#efefef" }}
                                            width="100%"
                                            height="100%"
                                            src={`https://www.youtube.com/embed/${widget_item_buttons?.data[1]?.attributes?.link && getYoutubeVideoId(widget_item_buttons?.data[1]?.attributes?.link)}?autoplay=1`}
                                            title=""
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        ></iframe>
                                        : <p className={classes.invalidUrl}>Invalid Youtube Video URL. </p>
                                    }
                                </Paper>
                            </Modal>
                        </Box>
                    </div>
                </Grid>
            </Grid>
            {image &&
                <img src={image} alt="" className={classes.dashboard} />
            }
        </Paper>
    );
}

SliderBox.propTypes = {
    post: PropTypes.object,
};

export default SliderBox