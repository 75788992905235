import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { GeoJSON } from "leaflet";
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";

interface FormData {
    origin: string;
    destination: string;
    weight: string;
    weightUnit: string;
    mode: string;
}

export interface Location {
  type: string;
  name: string;
}

export interface Leg {
  type: string;
  co2e: number;
  co2e_unit: string;
  transport_mode: string;
  distance_km: number;
}

export interface Routes extends Array<Location | Leg> {}

interface EmissionResponse {
  error: string;
  co2e: number;
  co2e_unit: string;
  distance_km: number;
  route: Routes;
  extra: {
    path:  GeoJSON.FeatureCollection
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  transportMode: string;
  routes: Routes;
  co2Emissions: number;
  co2EmissionsUnit: string;
  distance: number;
  showFormInfoCard: boolean;
  errorToast: {
    isOpen: boolean;
    message: string;
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfintermodalformController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  calculateEmissionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      transportMode: "",
      routes: [],
      co2Emissions: -1,
      co2EmissionsUnit: "",
      distance: 0,
      showFormInfoCard: false,
      errorToast: {
        isOpen: false,
        message: "",
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Handle api responses
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiRequestCallId === this.calculateEmissionApiCallId) {
        this.handleCalculateEmissionResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  calculateEmission = async (formData: FormData) => {
    this.setState({ transportMode: formData.mode });

    const payload = {
      "api_params": 
      {        
        "origin": formData.origin,
        "destination": formData.destination,        
        "weight": parseInt(formData.weight),
        "weight_unit": formData.weightUnit,
        "transport_mode": formData.mode,
      }
    }
    
    this.calculateEmissionApiCallId = await apiCall({
      contentType: configJSON.apiContentTypeAppJson,
      method: configJSON.postApiMethod,
      endPoint: configJSON.calculateEmissionApiEndpoint,
      payload: JSON.stringify(payload),
    });
  }

  handleCalculateEmissionResponse = (response: EmissionResponse) => {
    if (response.error) {
      this.setState({
        errorToast: {
          isOpen: true,
          message: response.error
        }
      });
      return;
    }

    if (response.extra.path.features.length === 0) {
      this.setState({
        errorToast: {
          isOpen: true,
          message: "Route cannot be retrieved. Please check the locations you have queried"
        }
      });
      return;
    }
    
    const routes = response.route.map(routeItem => {
      if (routeItem.type === "location") {
        const location = routeItem as Location;
        return {
          type: location.type,
          name: location.name,
        }
      } else {
        const leg = routeItem as Leg;
        return {
          type: leg.type,
          co2e: leg.co2e,
          co2e_unit: leg.co2e_unit,
          transport_mode: leg.transport_mode,
          distance_km: leg.distance_km,
        }
      }
    });

    this.setState({ 
      routes,
      co2Emissions: response.co2e,
      co2EmissionsUnit: response.co2e_unit,
      distance: response.distance_km,
      showFormInfoCard: true,
      errorToast: {
        ...this.state.errorToast,
        message: "",
      }
    });

    const locationRoutes = response.route.filter(routeItem => routeItem.type === "location");
    this.sendRoutesDataToMap(locationRoutes, response.extra.path);
  }

  showForm = () => {
    this.setState({ 
      showFormInfoCard: false,
      routes: [],
    });

    // Reset the markers and geojson in maps
    this.sendRoutesDataToMap([], []);
  }

  sendRoutesDataToMap = (routes: Routes, featureCollection: GeoJSON.FeatureCollection | []) => {
    const message: Message = new Message(getName(MessageEnum.IntermodalRouteMessage));
    message.addData(getName(MessageEnum.IntermodalRouteData), { 
      routes, 
      featureCollection,
      transportMode: this.state.transportMode, 
    });
    this.send(message);
  }

  // Customizable Area End
}
