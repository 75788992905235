import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import parse from "html-react-parser";

const useStyles = makeStyles(() => ({
  mainBlock: {
    padding: "80px 0",
    "@media (max-width: 768px)": {
      padding: "40px 0",
    },
  },
  mainContent: {
    borderRadius: "18px",
    padding: "40px 60px",
    backgroundColor: "#43d270",
    "@media (max-width: 768px)": {
      padding: "20px",
    },
  },
  mainContentInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  leftBlock: {
    width: "calc(100% * 8 / 12)",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  leftBlockHeader: {
    "&, & > p": {
      marginTop: "12px",
      fontSize: "46px",
      fontWeight: 700,
      fontFamily: "'Montserrat' !important",
      lineHeight: "64px",
      textTransform: "capitalize",
      "@media (max-width: 768px)": {
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: "8px",
      },
    },
  },
  rightBlock: {
    width: "calc(100% * 4 / 12)",
    textAlign: "right",
    "@media (max-width: 768px)": {
      width: "100%",
      textAlign: "center",
    },
  },
  description: {
    "&, & > p": {
      marginTop: "20px",
      lineHeight: "28px",
      color: "#464646",
      fontSize: "18px",
      "@media (max-width: 768px)": {
        marginTop: "14px",
        fontSize: "16px",
      },
    }
  },
  button: {
    backgroundColor: "#fff",
    color: "#000",
    transition: "all ease 0.3s",
    border: "none",
    display: "inline-flex",
    fontFamily: "Lato",
    padding: "12px 32px",
    fontWeight: 600,
    textTransform: "capitalize",
    borderRadius: "8px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
  },
}));

export default function CallToAction(props: any) {
  const { header, description, widget_link_text, widget_link, navigate } = props;
  const styles = useStyles();

  return (
    <div className={styles.mainBlock}>
      <Container>
        <div className={styles.mainContent}>
          <div className={styles.mainContentInner}>
            <div className={styles.leftBlock}>
              <div className={styles.leftBlockHeader}>{parse(header)}</div>
              <div className={styles.description}>{parse(description)}</div>
            </div>
            <div className={styles.rightBlock}>
              <span onClick={() => navigate(widget_link)} className={styles.button}>
                {widget_link_text}
              </span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
