import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
export const base = require("../../../framework/src/config");

interface Route {
  page_link: string;
  title: string;
}

interface HttpBody {
  page_title: string;
  account_id?: string;
  company_id?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  landingPageData: any;
  pageTitle: string;
  contactusPageData: any;
  topNoticeData: any;
  faqDocumentData: any;
  headerData: any;
  footerData: any;
  dynamicRoutes: any;
  dynamicRoutesFor404Page: Route[];
  jobPageData: any;
  jobDetails: any;
  jobId: number;
  searchResultData: any;
  pageId: string;
  sort: string;
  searchString: string;
  page: number;
  PrivacyPolicyData: any;
  jobApplicationData: any;
  jobApplicationError: any;
  socialLinksData: any;
  showToastMessage: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DynamicContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLandingPageDataApiCallId: string = "";
  getContactUsPageDataApiCallId: string = "";
  getTopNoticeDataApiCallId: string = "";
  getFaqDataApiCallId: string = "";
  getPrivacyPolicyDataApiCallId: string = "";
  getHeaderDataApiCallId: string = "";
  getFooterDataApiCallId: string = "";
  fetchDynamicRoutesApiCallId: string = "";
  serchJobApiCallId: string = "";
  getJobDetailsDataApiCallId: string = "";
  getJobApplicationApi: string = "";
  getSocialLinksDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      landingPageData: [],
      pageTitle: "",
      contactusPageData: {},
      topNoticeData: {},
      faqDocumentData: [],
      headerData: [],
      footerData: [],
      dynamicRoutes: [],
      dynamicRoutesFor404Page: [],
      jobPageData: [],
      jobDetails: [],
      jobId: 0,
      searchResultData: {},
      pageId: "0",
      sort: "DESC",
      searchString: "",
      page: 1,
      PrivacyPolicyData: [],
      jobApplicationData: {},
      jobApplicationError: {},
      socialLinksData: [],
      showToastMessage: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.getLandingPageDataApiCallId:
        this.handleLandingPageResult(response);
        break;

      case this.getContactUsPageDataApiCallId:
        this.setState({ contactusPageData: response });
        break;

      case this.getTopNoticeDataApiCallId:
        this.setState({ topNoticeData: response });
        break;

      case this.getFaqDataApiCallId:
        this.setFaqResponseData(response.data);
        break;

      case this.getHeaderDataApiCallId:
        this.setState({ headerData: response?.data });
        break;

      case this.getFooterDataApiCallId:
        this.setState({ footerData: response?.data });
        break;

      case this.fetchDynamicRoutesApiCallId:
        this.setState({ dynamicRoutes: response.pages });
        break;

      case this.getJobApplicationApi:
        this.setState({ jobApplicationData: response });
        if (response && response.success) {
          this.props.navigation.navigate("JobSuccess");
        }
        break;

      case this.getPrivacyPolicyDataApiCallId:
        this.setState({ PrivacyPolicyData: response?.data });
        break;

      case this.getSocialLinksDataApiCallId:
        this.setState({ socialLinksData: response?.social_media_links });
        break;

      default:
        // Handle default case if needed
        break;
    }


    window && window.scrollTo(0, 0);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    window && window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    // Get the page content once dynamic routes has loaded
    if (this.state.dynamicRoutes.length !== prevState.dynamicRoutes.length) {
      const pageTitle = this.getPageTitle(this.state.dynamicRoutes);
      this.getLandingPageData(pageTitle);
    }

    // Get the page content once user has landed on a new dynamic page route
    if (this.props?.navigation?.getParam("page") !== prevProps?.navigation?.getParam("page")) {
      this.setState({ landingPageData: [] });
      const pageTitle = this.getPageTitle(this.state.dynamicRoutes);
      this.getLandingPageData(pageTitle);
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getLandingPageData = (pageTitle: any) => {
    const header = {
      "Content-Type": configJSON.landingPageApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLandingPageDataApiCallId = requestMessage.messageId;

    const isLoggedin = JSON.parse(localStorage.getItem("isLogin") as string);
    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    const organization = JSON.parse(localStorage.getItem('organization') as string);

    const httpBody: HttpBody = {
      page_title: pageTitle,
    }

    if (isLoggedin) {
      httpBody["account_id"] = userDetails.meta.id;
    }

    if (userDetails?.meta?.user_account_type === "portfolio_manager") {
      httpBody["company_id"] = organization.id;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodPost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleLandingPageResult = (res: any) => {
    if (res) {
      this.setState({ landingPageData: res.data });
    }
  };

  setPageTitle = (title: string) => {
    this.setState({ pageTitle: title });
  };

  getCtaMode() {
    const pathnameLc = window.location.pathname;

    if (pathnameLc == "/pricing") {
      return "light";
    }

    return "dark";
  }

  viewJobDetails = (id: any, type: string) => {
    window.scrollTo(0, 0);

    if (id) {
      this.props.navigation.navigate("careerDetail", {id, type: type})
    }
  };


  getContactUsContent() {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactUsPageDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactuspageApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTopNoticeData() {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopNoticeDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homeNoticeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPageTitle = (dynamicRoutes: Route[]) => {
    const pathName = window.location.pathname.split('?')[0].split('#')[0];
    const pathnameLc = pathName && pathName.toLowerCase();

    // Static Routes
    const staticRoutes = [
      { page_link: "/", title: "Home" },
      { page_link: "/pricing", title: "Pricing" },
      { page_link: "/offerings", title: "Offerings" },
      { page_link: "/carbonzeroed-software", title: "Carbonzeroed Software" },
      { page_link: "/advisory-services", title: "Advisory Services" },
      { page_link: "/marketplace", title: "Marketplace" },
      { page_link: "/implementation-services", title: "Implementation Services" },
      { page_link: "/events", title: "Events" },
      { page_link: "/insights-and-trends", title: "Insights & Trends" },
      { page_link: "/toolkit", title: "Toolkit" },
      { page_link: "/privacy-policy", title: "Privacy Policy" },
      { page_link: "/terms-and-conditions", title: "Terms & Conditions" },
      { page_link: "/company", title: "about-us" },
      { page_link: "/about-us", title: "About us" },
      { page_link: "/careers", title: "Careers" },
      { page_link: "/free-assessment", title: "Free Assessment" },
      { page_link: "/documentation", title: "Documentation" },
      { page_link: "/how-it-works", title: "How it works" },
      { page_link: "/faqs", title: "Faqs" },
      { page_link: "/contact-us", title: "Contact Us" },
      { page_link: "/login/primary", title: "Login" },
      { page_link: "/signup", title: "Sign Up" },
      { page_link: "/success/email", title: "Success Mail" },
      { page_link: "/success/contact", title: "Success Contact" },
      { page_link: "/forgot-password", title: "Forgot Password" },
      { page_link: "/NewPassword", title: "New Password" },
      { page_link: "/ResendEmail", title: "Resend Email" },
      { page_link: "/VerifyEmail", title: "Verify Email" },
      {page_link : "/resend_mail/:mail", title: "Resend Activation Link"},
      { page_link: "/activate_account", title: "Activate Account" },
      { page_link: "/job-openings-list", title: "Job Openings List" },
      { page_link: "/job-application", title: "Job Application" },
      { page_link: "/job-success", title: "Job Success" },
      { page_link: "/industries", title: "Industries" }
    ];

    // Static Path route checking
    const filteredCases = staticRoutes.filter((item) => item.page_link === pathnameLc);
    if (filteredCases && filteredCases.length > 0) {
      return filteredCases[0].title;
    }

    // Dynamic Path route checking
    if (dynamicRoutes && dynamicRoutes.length > 0) {
      const filteredCases2 = dynamicRoutes?.filter((dItem: { page_link: string, title: string }) => dItem.page_link === pathnameLc);
      if (filteredCases2 && filteredCases2.length > 0 && filteredCases2[0].title) {
        return filteredCases2[0].title;
      }
    }

    return "";

  }

  fetchDynamicRoutes() {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDynamicRoutesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dynamicRoutesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPrivacyPolicyData() {
    const header = {
      "Content-Type": configJSON.faqApiContentType,
    };

    let endpoint = "";

    if (window.location.pathname === "/privacy-policy") {
      endpoint = configJSON.privacyPolicyApiEndpoint
    } else if (window.location.pathname === "/terms-and-conditions") {
      endpoint = configJSON.termsconditionApiEndpoint
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.faqApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFaqData() {
    const header = {
      "Content-Type": configJSON.faqApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let endpoint = "";

    if (window.location.pathname === "/faqs") {
      endpoint = configJSON.faqApiEndpoint
    } else if (window.location.pathname === "/documentation") {
      endpoint = configJSON.documentApiEndpoint
    }

    this.getFaqDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.faqApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setFaqResponseData(res: any) {
    this.setState({ faqDocumentData: res?.attributes })
  }
  getHeaderData() {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHeaderDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.headerPageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFooterData() {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFooterDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.footerPageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMarketPlacePageResult = (res: any) => {
    this.setState({ jobPageData: res.data });
  };

  getFormApplicationData = async (data: any) => {

    let formData = new FormData();

    formData.append("data[job_position_name]", data.job_position_name)
    formData.append("data[email_address]", data.email_address)
    formData.append("data[first_name]", data.first_name)
    formData.append("data[last_name]", data.last_name)
    formData.append("data[attach_cv_file]", data.attach_cv_file)
    if (data.attach_cover_letter_file) {
      formData.append("data[attach_cover_letter_file]", data.attach_cover_letter_file)
    }
    formData.append("data[terms_conditions_agree]", data?.terms_conditions_agree)

    const requestOptions = {
      method: configJSON.landingPageApiMethodPost,
      body: formData,
    };

    try {
      await fetch(base.baseURL + '/' + configJSON.jobApplicationEndPoint, requestOptions)
        .then(response => response.json())
        .then((result) => {
          this.setState({ jobApplicationData: result, showToastMessage: true });
          if (result && result.success) {
            this.setState({ jobApplicationError: {} });
            this.props.navigation.navigate("JobSuccess");
          } else {
            this.setState({ jobApplicationError: result.errors });
            console.log('job application error 1', result)
          }
        })
        .catch((error) => {
          this.setState({ jobApplicationError: error });
          console.log('job application error', error)
        });
    } catch (e) {
      console.log("error: ", e);
      this.setState({ jobApplicationData: [] });
    }

  };

  fetchSocialMediaLinks() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSocialLinksDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.socialMediaLinksApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRouteKey = async (link: string) => {
    const routeMap = await getStorageData("routeMap");
    const routeMapJson = JSON.parse(routeMap);
    for (const [key, value] of Object.entries(routeMapJson)) {
      // @ts-ignore
      if (value.path === link) {
        return key;
      }
    }
    return "";
  }

  handleNavigation = async (route: string) => {
    const key = await this.getRouteKey(route);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), key);
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  goToBlogDetails = (id: number) => {
    this.props.navigation.navigate("insightsDetails", {id});
  }
  // Customizable Area End
}
