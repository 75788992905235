import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import parse from "html-react-parser";
import JourneyItem from "../JourneyItem";
import WidgetHeadingText from "../WidgetHeadingText";

const useStyles = makeStyles(() =>
  createStyles({
    block: {
      padding: "80px 0px",
      "@media (max-width: 768px)": {
        padding: "40px 20px",
      },
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    },
    blockHeading: {
      textAlign: "center",
    },
    heading: {
      fontWeight: 700,
      textTransform: "capitalize",
    },
    body: {
      marginTop: "16px",
      color: "#595959",
    },
    mainContent: {
      marginTop: "40px",
      "@media (max-width: 768px)": {
        marginTop: "20px",
      },
    },
    even: {
      "& .blockTextContent::before": {
        left: "-5px",
      },
    },
    odd: {
      "& .blockTextContent::before": {
        right: "-5px",
      },
    },
    heading5: {
      marginTop: "60px",
      padding: "0 20px",
      fontWeight: 700,
      color: "#252525",
      fontSize: "32px",
      lineHeight: "51.2px",
      textTransform: "capitalize",
      textAlign: "center",
      "@media (max-width: 768px)": {
        padding: "0",
        fontSize: "18px",
        lineHeight: "26px",
        marginTop: "40px",
      },
    },
  })
);

export default function Journey(props: any) {
  const { header, description, journey_time_widget_items } = props;
  const styles = useStyles();

  return (
    <div className={styles.block}>
      <Container className={styles.container}>
        <Grid item md={12} lg={12}>
          <div className={styles.blockHeading}>
            <WidgetHeadingText>{parse(header)}</WidgetHeadingText>
            <p className={styles.body}>{parse(description)}</p>
          </div>

          <div className={styles.mainContent}>
            {journey_time_widget_items.data.map((journey: any) => {
              const {
                journey_image,
                journey_title,
                journey_description,
              } = journey.attributes;
              return (
                <JourneyItem
                  key={journey.id}
                  id={journey.id}
                  image={journey_image}
                  title={journey_title}
                  description={journey_description}
                />
              );
            })}
          </div>

          <h5 className={styles.heading5}>
            {
              journey_time_widget_items.data[0].attributes
                .journey_bottom_description
            }
          </h5>
        </Grid>
      </Container>
    </div>
  );
}
