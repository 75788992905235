import React from "react";
import { styled } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import OrganizationInfo from "../../../customform/src/OrganizationInfo.web";
import PlanAndBillingCard from "../common/PlanAndBillingCard.web";
import UsersTable from "../common/UsersTable.web";
import Layout from "../Layout.web";
import DashboardController from "../DashboardController";
import { Link } from "react-router-dom";
import { orgInfoIcon, orgInfoIconActive, planIcon, planIconActive, usersIcon, usersIconActive } from "../assets";

const InnerContainer = styled("div")({
	width: "100%",
	height: "auto",
	padding: "27px 37px 44px 37px"
});

const TabsSection = styled("div")({
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
	gap: "8px",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "32px",
	'@media(max-width: 450px)': {
		alignItems: 'end'
	},
});

const TabItem = styled("div")({
	display: "flex",
	justifyContent: "center",
	padding: "8px 12px",
	alignItems: "center",
	gap: "8px",
	flexShrink: 0,
	borderRadius: "8px",
	backgroundColor: "#FFF",
	color: "grey",
	fontSize: "15px",
	height: "40px",
	cursor: "pointer",
});

const TabItemSelected = styled("div")({
	display: "flex",
	justifyContent: "center",
	padding: "8px 12px",
	alignItems: "center",
	gap: "8px",
	flexShrink: 0,
	borderRadius: "8px",
	backgroundColor: "#ECFBF1",
	color: "grey",
	fontSize: "15px",
	cursor: "pointer",
});

const TabText = styled(Typography)({
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 400,
	letterSpacing: "0.2px",
	cursor: "pointer",
});

const TabTextSelected = styled(TabText)({
	color: "#329E54",
	fontWeight: 700,
});

const Icon = styled("span")({
	width: "20px",
	height: "20px",
});

const TabContent = styled('div')({
	width: "100%",
})

const TabLink = styled(Link)({
	width: "100%",
	display: "flex",
	justifyContent: "center",
	textDecoration: "none",
	alignItems: "center",
	gap: "8px",
	flexShrink: 0,
})

const Tabs = styled('div')({
	maxWidth: '404px',
	display: 'flex',
	gap: '8px',
	'@media(max-width: 450px)': {
		flexDirection: 'column',
		alignItems: 'start'
	},
})


const OrgBaseUrl = "/get-started/map-your-organization";

export default class MapOrganization extends DashboardController {
	constructor(props: any) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	render() {

		const accountPlanData = this.state.planData;

		const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'organization-info';

		const tabsData = [
      {
        id: 1,
        text: "Organization Info",
        icon: orgInfoIcon,
        activeIcon: orgInfoIconActive,
        component: (
          <OrganizationInfo
            description={""}
            description_title={""}
            {...this.props}
          />
        ),
        isActive: activeTab === "organization-info",
        link: "/organization-info"
      },
      {
        id: 2,
        text: "Plan & Billing",
        icon: planIcon,
        activeIcon: planIconActive,
        component: (
          <PlanAndBillingCard
            expiryDate={accountPlanData.expired_date}
            plan={accountPlanData.plan_name}
            higherPlan={accountPlanData.higher_plan_name}
            onTodoListClick={this.navigateToGetStartedPage}
            {...this.props}
          />
        ),
        isActive: activeTab === "plan-&-billing",
        link: "/plan-&-billing"
      },
      {
        id: 3,
        text: "Users",
        icon: usersIcon,
        activeIcon: usersIconActive,
        component: <UsersTable {...this.props} />,
        isActive: activeTab === "users",
        link: "/users"
      }
    ];

		return (
			<>
				<Layout>
					<InnerContainer>
						<TabsSection>
							<Tabs>
								{tabsData.map((tab) => {
									return tab.isActive ? (
										<TabItemSelected
											key={`selected_tab_${tab.id}`}
										>
											<Icon><img src={tab.activeIcon} alt="" /></Icon>
											<TabTextSelected>{tab.text}</TabTextSelected>
										</TabItemSelected>
									) : (
										<TabItem
											key={`noneselected_tab_${tab.id}`}
										>
											<TabLink to={OrgBaseUrl + tab.link}>
												<Icon><img src={tab.icon} alt="" /></Icon>
												<TabText>{tab.text}</TabText>
											</TabLink>
										</TabItem>
									);
								})}
							</Tabs>
						</TabsSection>

						{tabsData.map((content) => {
							return <TabContent key={`content${content.id}`}>{content.isActive ? content.component : ""}</TabContent>
						})}
					</InnerContainer>
				</Layout>
			</>
		);
	}
}