import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from "@material-ui/core";
import { Routes, Location, Leg } from "../CfintermodalformController";
import { locationCircle, truckCircle, shipCircle, trainCircle, airplaneCircle } from "../assets";

function getIcons(transportMode: string) {
  if (transportMode === "road") {
    return <img src={truckCircle} alt="truck" />
  } else if (transportMode === "rail") {
    return <img src={trainCircle} alt="train" />
  } else if (transportMode === "sea") {
    return <img src={shipCircle} alt="ship" />
  } else if (transportMode === "air") {
    return <img src={airplaneCircle} alt="airplane" />
  }
}

function getTransportMode(transportMode: string) {
  switch (transportMode) {
    case "road":
      return "Truck";
    case "sea":
      return "Ship";
    case "air":
      return "Airplane";
    case "rail":
      return "Train";
  }
}

interface Props {
  routes: Routes;
}

export default function TreeView(props: Props) {
  const classes = useStyles();

  return (
    <div data-test-id="tree-view" className={classes.container}>
      <List component="nav" aria-label="intermodal api results">
        {props.routes.map((routeItem, index) => {
          if (routeItem.type === "location") {
            const location = routeItem as Location;
            const key = `${location.type}-${index}`;
            return (
              <ListItem key={key} className={classes.listItem}>
                <ListItemIcon>
                  <img src={locationCircle} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText} primary={location.name} />
              </ListItem>
            );
          } else if (routeItem.type === "leg" && (routeItem as Leg).transport_mode) {
            const key = `${routeItem.type}-${index}`;
            const leg = routeItem as Leg;
            let transportMode = getTransportMode(leg.transport_mode);
            return (
              <ListItem key={key} className={classes.nestedListItem}>
                <ListItemIcon classes={{ root: classes.nestedListItemIcon }}>
                  {getIcons(leg.transport_mode)}
                </ListItemIcon>
                <div className={classes.nestedListItemText}>
                  <Typography>
                    <span className={classes.transportMode}>{transportMode}</span> - {leg.distance_km}km
                  </Typography>
                  <Typography>{`${leg.co2e}${leg.co2e_unit} of CO2e`}</Typography>
                </div>
              </ListItem>
            );
          }
        })}
      </List>
      <div style={{ 
        margin: "30px 17px",
        display: props.routes.length > 0 ? "block" : "none"
      }}>
        <Button
          fullWidth
          disableElevation
          type="submit"
          variant="contained"
          color="primary"
          className={classes.btn}
          data-test-id="talk-to-expert-btn"
          onClick={() => window.open("contact-us", "_self")}
        >
          Talk to an Expert
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "0 35px",
    },
    listItem: {
      paddingTop: "0px",
      paddingBottom: "0px",
      marginLeft: "5px",
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
    nestedListItem: {
      fontSize: "14px",
      borderLeft: "1px dashed black",
      marginLeft: "44px",
      padding: "40px 0 40px 0",
    },
    nestedListItemIcon: {
      "&::before": {
        content: '"---------"',
        marginTop: "10px",
      }
    },
    listItemText: {
      "& *": {
        fontFamily: "Lato",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "22.4px",
        color: "#34373A"
      }
    },
    nestedListItemText: {
      marginLeft: "10px",
      "& *": {
        fontFamily: "Lato",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "18.2px",
        color: "#34373A"
      },
    },
    transportMode: {
      fontFamily: "Lato",
      fontSize: "13px",
      fontWeight: 700,
      lineHeight: "18.2px"
    },
    btn: {
      height: "40px",
      padding: "16px 12px 16px 12px",
      borderRadius: "8px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "19.2px",
      textAlign: "center",
      color: "#34373A",
      textTransform: "none",
      backgroundColor: "#43D270",
      "&:hover": {
        backgroundColor: "#43D270"
      }
    }
  })
);
